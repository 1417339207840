import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JobTimesheet } from './job-timesheet.model';
import { Observable } from 'rxjs';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';


@Injectable({
  providedIn: 'root'
})
export class JobTimesheetService extends CachableModelService<JobTimesheet>{

  constructor(
    protected http: HttpClient
  ) {
    super(http, 'job-timesheets');
  }


  getByOrganizationAndId(orga, id):Observable<JobTimesheet>{
    // get cached timesheet
    let data = this.findModelByOrganizationAndId(orga, id);

    let http = this.http.get<JobTimesheet>('/v1/organizations/'+orga+'/timesheets/'+id);
    return this.cachedRequest(data, http);
  }

  create(orga, job, data){
      console.log('create timesheet', data);
      return this.cachedPost(`/v1/organizations/${orga}/jobs/${job}/timesheet`, data);
  }

  delete(orga, id){
      console.log('delete timesheet', id);
      return this.http.delete(`/v1/organizations/${orga}/timesheets/${id}`).pipe(
          tap(res => { return res; })
      );
  }
}
