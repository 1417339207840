import { Injectable } from '@angular/core';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';

import { File } from '../models/file.model';

@Injectable({
    providedIn: 'root'
})
export class FileService extends CachableModelService<File>{

    constructor(
        protected http: HttpClient,
    ) {
        super(http, 'files');
    }

    getAllForCurrentAccount(){
        // get cached data
        let data = this.models;
        let http = this.http.get<File[]>('/v1/files/public');

        return this.cachedRequest(data, http);
    }

    upload(orga_id, file, payload: object){
		let form_data: FormData = new FormData();
		form_data.append('file', file);

		for(let key in payload)
    		form_data.append(key, payload[key]);

		return this.http.post<File>('/v1/organizations/'+orga_id+'/files', form_data).pipe(
			tap(res => {
				return res;
			})
		)
	}

    update(orga_id, file_id, data){
        return this.http.patch<File>(`/v1/organizations/${orga_id}/files/${file_id}`, data).pipe(
            tap(res => {
                return res;
            })
        );
    }

    delete(orga_id, file_id){
        return this.http.delete<File>(`/v1/organizations/${orga_id}/files/${file_id}`).pipe(
            tap(res => {
                return res;
            })
        );
    }
}
