var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { formatDate } from '@angular/common';
import { createDateFromDatetime, createDateFromDateAndTime } from 'src/app/core/utils/date-utils';
import { WorkService } from '../shared/work.service';
import { WorkScheduleEntryType } from '../shared/interfaces';
import { JobService } from 'src/app/jobs/shared/job.service';
import { ProjectService } from 'src/app/projects/shared/project.service';
import { QualificationService } from 'src/app/core/services/qualification.service';
import { AccountService } from '../../core/services/account.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { toggleFormControl } from 'src/app/core/utils/form-utils';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { MyErrorHandler } from '../../core/classes/my-error-handler.class';
export var DialogType;
(function (DialogType) {
    DialogType[DialogType["StartWork"] = 0] = "StartWork";
    DialogType[DialogType["StartProject"] = 1] = "StartProject";
    DialogType[DialogType["EndProject"] = 2] = "EndProject";
    DialogType[DialogType["EditProject"] = 3] = "EditProject";
    DialogType[DialogType["CreateProject"] = 4] = "CreateProject";
})(DialogType || (DialogType = {}));
;
export var defaultDialogOptions = {
    minWidth: 250,
    maxWidth: 500,
    autoFocus: false,
    width: '95vw'
};
var WorkScheduleDialogComponent = /** @class */ (function () {
    function WorkScheduleDialogComponent(fb, dialogWs, dialog, accountService, projectService, jobService, qualificationService, workService, myErrorHandler, data, locale) {
        this.fb = fb;
        this.dialogWs = dialogWs;
        this.dialog = dialog;
        this.accountService = accountService;
        this.projectService = projectService;
        this.jobService = jobService;
        this.qualificationService = qualificationService;
        this.workService = workService;
        this.myErrorHandler = myErrorHandler;
        this.data = data;
        this.locale = locale;
        this.types = DialogType;
        this.date = new Date();
        this.projectOptionGroups = new BehaviorSubject([]);
        this.jobOptions = [];
        this.qualificationOptions = [];
        this.projectTitleRequest = new Subject();
        switch (this.data.type) {
            case DialogType.EndProject:
            case DialogType.EditProject:
                if (!this.data.entry)
                    console.error('entry not defined');
            case DialogType.StartProject:
            case DialogType.CreateProject:
                if (!this.data.schedule)
                    console.error('schedule not defined');
            default:
                break;
        }
        this.organization = this.data.organization || this.data.schedule && this.data.schedule.organization || null;
        this.company = this.data.company || null;
        this.initLoadProjectsByTitle();
    }
    /**
     * date to date format
     * @param dateString
     */
    WorkScheduleDialogComponent.prototype.formatDate = function (dateString) {
        if (typeof dateString === 'string' && dateString.length > 11)
            dateString = createDateFromDatetime(dateString);
        return formatDate(dateString, 'yyyy-MM-dd', this.locale);
    };
    /**
     * date to time format
     * @param dateString
     */
    WorkScheduleDialogComponent.prototype.formatTime = function (dateString) {
        if (typeof dateString === 'string' && dateString.length > 11)
            dateString = createDateFromDatetime(dateString);
        return formatDate(dateString, 'HH:mm', this.locale);
    };
    WorkScheduleDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Get current account
        this.accountService.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(function (res) {
            if (!res)
                return;
            _this.account = res;
        });
        var group = {
            comment: [''],
            job: [null],
            project: [null],
            qualification: [null],
            date: [this.data.past ? this.formatDate(new Date(this.date.getTime() - (24 * 60000 * 60))) : this.formatDate(this.date),
                Validators.required],
            time: [this.data.past ? '08:00:00' : this.formatTime(this.date), Validators.required],
        };
        switch (this.data.type) {
            case DialogType.EndProject:
                group.comment = [this.data.entry.comment];
                break;
            case DialogType.StartProject:
                group.project = [null];
                break;
            case DialogType.EditProject:
                group.startDate = [this.formatDate(this.data.entry.start), Validators.required];
                group.startTime = [this.formatTime(this.data.entry.start), Validators.required];
                group.endDate = [this.formatDate(this.data.entry.end || this.data.date), Validators.required];
                group.endTime = [this.formatTime(this.data.entry.end || this.data.date), Validators.required];
                group.duration = [Math.round(this.data.entry.duration * 100) / 100, Validators.required];
                group.comment = [this.data.entry.comment];
                group.job = [this.data.job || null];
                group.project = [this.data.project || null];
                group.qualification = [this.data.qualification || null];
                break;
            case DialogType.CreateProject:
                group.startDate = [this.formatDate(this.data.schedule.date_in), Validators.required];
                group.startTime = [null, Validators.required];
                group.endDate = [this.formatDate(this.data.schedule.date_in), Validators.required];
                group.endTime = [null, Validators.required];
                group.duration = [0, Validators.required];
        }
        this.form = this.fb.group(group);
        Object.entries(this.form.controls).forEach(function (_a) {
            var key = _a[0], field = _a[1];
            switch (key) {
                case 'project':
                    field.valueChanges.subscribe(_this.onProjectChange.bind(_this));
                    break;
                case 'qualification':
                    var project = _this.form.value.project;
                    if (!project)
                        field.disable();
                    else
                        _this.loadQualifications(project.id);
                    break;
                case 'startDate':
                case 'endDate':
                    field.valueChanges.subscribe(function () { return _this.loadProjectsByDate(new Date(_this.form.value.startDate)); });
                    // this.loadDispos(new Date(this.form.value.startDate), new Date(this.form.value.endDate))
                    break;
                case 'date':
                    field.valueChanges.subscribe(_this.onDateChange.bind(_this));
                    break;
            }
        });
        var start = this.data.entry ? this.data.entry.start : (group.date ? group.date[0] : this.date);
        this.loadProjectsByDate(start);
    };
    /**
     * calculates duration from two dates in hours
     * @param start
     * @param end
     */
    WorkScheduleDialogComponent.prototype.calculateDuration = function (start, end) {
        return Math.round((end.getTime() - start.getTime()) / 36000) / 100;
    };
    /**
     * updates duration according to dates
     */
    WorkScheduleDialogComponent.prototype.updateDuration = function () {
        var start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
        var end = createDateFromDateAndTime(this.form.value.endDate, this.form.value.endTime);
        if (!start || !end)
            return;
        this.form.controls['duration'].setValue(this.calculateDuration(start, end));
    };
    /**
     * updates end date according to duration
     */
    WorkScheduleDialogComponent.prototype.updateEnd = function () {
        var duration = this.form.value.duration;
        if (!duration)
            return;
        var start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
        var end = new Date(start.getTime() + duration * 3600000);
        this.form.controls['endDate'].setValue(this.formatDate(end));
        this.form.controls['endTime'].setValue(this.formatTime(end));
    };
    WorkScheduleDialogComponent.prototype.buildProjectOptionsGroups = function (project_groups) {
        var projectOptionGroups = [];
        project_groups.forEach(function (project_group) {
            var option_group = {
                title: project_group['title'],
                type: project_group['type'],
                options: []
            };
            project_group['projects'].forEach(function (project) {
                option_group.options.push({
                    id: project.id,
                    title: project.title,
                    headline: {
                        type: 'date',
                        data: {
                            start: project.date_start,
                            end: project.date_end,
                            showWeekday: false,
                            dateOnly: project_group['date_format'] == 'date' ? true : false
                        }
                    }
                });
            });
            projectOptionGroups.push(option_group);
        });
        return projectOptionGroups;
    };
    WorkScheduleDialogComponent.prototype.loadProjectsByDate = function (date) {
        var _this = this;
        var date_obj;
        if (date instanceof Date)
            date_obj = date;
        else
            date_obj = new Date(date);
        if (isNaN(date_obj.getTime()))
            return;
        this.projectService.getAllByOrganizationAndStaffAndDate(this.organization, 'current', date_obj).pipe(untilDestroyed(this))
            .subscribe(function (project_groups) {
            if (!project_groups)
                return _this.projectOptionGroups.next([]);
            var projectOptionGroups = _this.buildProjectOptionsGroups(project_groups);
            _this.projectOptionGroups.next(projectOptionGroups);
            // Check if we have project group "dispos" and assign first entry as "value", when start of dispo is not more than 60 minutes in the future
            if (projectOptionGroups[0].type == 'dispos' && projectOptionGroups[0].options.length) {
                var dispo = projectOptionGroups[0].options[0];
                var dispo_start = dispo.headline.data.start;
                if (createDateFromDatetime(dispo_start).getTime() - (new Date()).getTime() < (1000 * 60 * 30)) {
                    _this.form.controls['project'].setValue(projectOptionGroups[0].options[0]);
                }
            }
        });
    };
    WorkScheduleDialogComponent.prototype.initLoadProjectsByTitle = function () {
        var _this = this;
        this.projectTitleRequest = new Subject();
        this.projectTitleRequest.pipe(switchMap(function (title) {
            return _this.projectService.getAllByOrganizationAndStaffAndTitle(_this.organization, 'current', title);
        })).subscribe(function (project_groups) {
            var current_option_groups = _this.projectOptionGroups.getValue().slice();
            // :: Remove group "search_result"
            if (!project_groups) {
                current_option_groups = current_option_groups.filter(function (g) { return g.type != 'search_result'; });
                _this.projectOptionGroups.next(current_option_groups);
                return;
            }
            var new_option_groups = _this.buildProjectOptionsGroups(project_groups);
            // Replace existing option groups
            new_option_groups.forEach(function (project_option_group) {
                var exisiting_option_group_index = current_option_groups.findIndex(function (g) { return g.type == project_option_group.type; });
                if (exisiting_option_group_index >= 0) {
                    current_option_groups[exisiting_option_group_index] = project_option_group;
                }
                else {
                    current_option_groups.push(project_option_group);
                }
            });
            _this.projectOptionGroups.next(current_option_groups);
        });
    };
    WorkScheduleDialogComponent.prototype.onProjectSearchChange = function (val) {
        if (!val) {
            // :: Remove group "search_result"
            var current_option_groups = this.projectOptionGroups.getValue().slice();
            if (current_option_groups.length) {
                current_option_groups = current_option_groups.filter(function (g) { return g.type != 'search_result'; });
                this.projectOptionGroups.next(current_option_groups);
            }
        }
        else if (this.account && this.account.work_schedule_project_search_orgas && this.account.work_schedule_project_search_orgas.includes(this.organization)) {
            this.projectTitleRequest.next(val);
        }
    };
    /**
     * Called when project selection changes.
     * Loads jobs for project
     * @param project
     */
    WorkScheduleDialogComponent.prototype.onProjectChange = function (project) {
        toggleFormControl(this.form.controls['qualification'], project && project.id);
        // this.form.controls['qualification'].disable();
        if (!project || !project.id)
            return;
        this.loadQualifications(project.id);
        // this.loadJobs(project.id);
    };
    /**
     * loads jobs
     * @param projectId project id
     */
    WorkScheduleDialogComponent.prototype.loadJobs = function (projectId) {
        var _this = this;
        this.jobService.getAllByOrganization(this.organization, {
            projects: [projectId],
        }).pipe(untilDestroyed(this)).subscribe(function (jobs) { return _this.jobOptions = jobs; });
    };
    WorkScheduleDialogComponent.prototype.loadQualifications = function (project_id) {
        var _this = this;
        this.form.controls['qualification'].setValue(null);
        this.qualificationService.getAllByOrganizazionAndCurrentStaff(this.organization, {
            project: project_id,
            date: this.form.value.date ? new Date(this.form.value.date) : this.date
        }).pipe(untilDestroyed(this)).subscribe(function (qualifications) {
            _this.qualificationOptions = qualifications;
            // Select first qualification entry when it has the flag "dispo"
            if (qualifications && qualifications.length && qualifications[0]['dispo']) {
                _this.form.controls['qualification'].setValue(qualifications[0].id);
            }
        });
    };
    /**
     * Called when date changes.
     * Loads dispositions for selected date
     * @param value
     */
    WorkScheduleDialogComponent.prototype.onDateChange = function (value) {
        // this.loadDispos(new Date(value));
        this.form.controls.project.setValue(null);
        this.loadProjectsByDate(new Date(value));
    };
    /**
     * Called when submit button is pressed
     */
    WorkScheduleDialogComponent.prototype.submit = function () {
        this.futureDateError = false;
        this.beforeStartDateError = false;
        if (this.form.valid) {
            var values = __assign({}, this.form.value);
            var now = new Date();
            values.date = createDateFromDateAndTime(values.date, values.time);
            // check for valid date
            if (isNaN(values.date) && (this.data.type == DialogType.EndProject
                || this.data.type == DialogType.StartWork
                || this.data.type == DialogType.StartProject))
                return this.dialog.open(OkDialogComponent, { data: 'Datum/Uhrzeit ist fehlerhaft.' });
            // check for future date
            if (new Date() < values.date) {
                this.futureDateError = true;
                return;
            }
            if (values.startDate)
                values.start = createDateFromDateAndTime(values.startDate, values.startTime);
            if (values.endDate)
                values.end = createDateFromDateAndTime(values.endDate, values.endTime);
            if (values.startDate && values.endDate) {
                if (now < values.start || now < values.end) {
                    this.futureDateError = true;
                    return;
                }
                if (values.start > values.end) {
                    this.beforeStartDateError = true;
                    return;
                }
            }
            if (typeof values.start === 'string')
                values.start = createDateFromDatetime(values.start);
            if (typeof values.end === 'string')
                values.end = createDateFromDatetime(values.end);
            switch (this.data.type) {
                case DialogType.EndProject:
                    this.endProject(values);
                    break;
                case DialogType.StartWork:
                    this.startWork(values);
                    break;
                case DialogType.StartProject:
                    this.startProject(values);
                    break;
                case DialogType.EditProject:
                    this.editProject(values);
                    break;
                case DialogType.CreateProject:
                    this.createProject(values);
                    break;
                default:
                    this.dialog.open(OkDialogComponent, { data: 'Unkown Dialog Type' });
            }
        }
    };
    /**
     * ends work schedule entry
     * @param form
     */
    WorkScheduleDialogComponent.prototype.endProject = function (form) {
        var _this = this;
        return this.workService.updateWorkScheduleEntry(this.organization, this.data.entry.id, {
            end: form.date,
            comment: form.comment
        }).pipe(untilDestroyed(this)).subscribe(function (entry) { return _this.dialogWs.close(entry); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    /**
     * starts work schedule entry
     * @param form
     */
    WorkScheduleDialogComponent.prototype.startProject = function (form) {
        var _this = this;
        return this.workService.createWorkScheduleEntry({
            organization: this.data.schedule.organization,
            start: form.date,
            work_schedule: this.data.schedule.id,
            comment: form.comment,
            project: form.project ? form.project.id : null,
            job: form.job ? form.job.id : null,
            qualification: form.qualification || null
        }).pipe(untilDestroyed(this)).subscribe(function (entry) { return _this.dialogWs.close(entry); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleDialogComponent.prototype.editProject = function (form) {
        var _this = this;
        return this.workService.updateWorkScheduleEntry(this.organization, this.data.entry.id, {
            organization: this.organization,
            type: WorkScheduleEntryType.WORKING,
            work_schedule: this.data.schedule.id,
            start: form.start,
            end: form.end,
            comment: form.comment,
            project: form.project ? form.project.id : null,
            job: form.job ? form.job.id : null,
            qualification: form.qualification || null,
            duration: form.duration
        }).pipe(untilDestroyed(this)).subscribe(function (entry) { return _this.dialogWs.close(entry); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleDialogComponent.prototype.createProject = function (form) {
        var _this = this;
        return this.workService.createWorkScheduleEntry({
            organization: this.organization,
            type: WorkScheduleEntryType.WORKING,
            work_schedule: this.data.schedule.id,
            start: form.start,
            end: form.end,
            comment: form.comment,
            project: form.project ? form.project.id : null,
            job: form.job ? form.job.id : null,
            qualification: form.qualification || null,
            duration: form.duration
        }).pipe(untilDestroyed(this)).subscribe(function (entry) { return _this.dialogWs.close(entry); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    /**
     * starts work schedule
     * @param form
     */
    WorkScheduleDialogComponent.prototype.startWork = function (form) {
        var _this = this;
        var entry = null;
        if (form.project) {
            entry = {
                type: WorkScheduleEntryType.WORKING,
                project: form.project ? form.project.id : null,
                qualification: form.qualification || null
            };
        }
        return this.workService.startWorkSchedule(this.organization, form.comment, form.date, this.date, entry, this.company).pipe(untilDestroyed(this)).subscribe(function (workSchedule) { return _this.dialogWs.close(workSchedule); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleDialogComponent.prototype.ngOnDestroy = function () { };
    return WorkScheduleDialogComponent;
}());
export { WorkScheduleDialogComponent };
