import * as i0 from "@angular/core";
var DateService = /** @class */ (function () {
    function DateService() {
    }
    /**
     * Sets time to beginning of day
     */
    DateService.prototype.startOfDay = function (date) {
        if (date === void 0) { date = new Date(); }
        date = new Date(date);
        date.setHours(0, 0, 0, 0);
        return date;
    };
    /**
     * Sets time to end of day
     * @param date
     */
    DateService.prototype.endOfDay = function (date) {
        if (date === void 0) { date = new Date(); }
        date = new Date(date);
        date.setHours(23, 59, 59, 999);
        return date;
    };
    /**
     * maps hours like 'hh:mm:ss:ms' where (m,s,ms are optional) to a date
     * @param date
     * @param hours
     */
    DateService.prototype.mapHoursToDate = function (date, hours) {
        var arr = hours.split(':').map(function (str) { return parseInt(str); });
        date.setHours(arr[0], arr[1] || 0, arr[2] || 0, arr[3] || 0);
        return date;
    };
    DateService.prototype.secondsToHMS = function (secs) {
        function z(n) { return (n < 10 ? '0' : '') + Math.floor(n); }
        var sign = secs < 0 ? '-' : '';
        secs = Math.abs(secs);
        return sign + z(secs / 3600 | 0) + ':' + z((secs % 3600) / 60 | 0) + ':' + z(secs % 60);
    };
    DateService.ngInjectableDef = i0.defineInjectable({ factory: function DateService_Factory() { return new DateService(); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };
