import { Component } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { Router, ActivatedRoute } from '@angular/router';

import { MissionProcess } from '../shared/mission-process.model';

import { ListGroup } from '../../core/models/list-group.model';
import { MissionProcessService } from '../shared/mission-process.service';

import { ScrollLocations } from '../../core/globals/scroll-locations';

@Component({
  selector: 'app-mission-processes',
  templateUrl: './mission-processes.component.html',
  styleUrls: ['./mission-processes.component.css']
})
export class MissionProcessesComponent extends ComponentInit {

  loadError = false;
  reload = this.loadMissionProcesses.bind(this);

  missionProcesses: MissionProcess[];

  listGroups:ListGroup<MissionProcess>[] = [
    {name:'Ausstehend', data:[]},
    {name:'Beantwortet', data:[]},
  ];

  constructor(
    private route: ActivatedRoute,
    private mps: MissionProcessService,
    protected router: Router,
    protected scrollLocations: ScrollLocations
) { super(router, scrollLocations, route); }

  init(){
    this.loadMissionProcesses();
  }

  loadMissionProcesses(){
    this.loadError = false;
    this.mps.getAllProposalsForCurrentAccount(new Date(), null).subscribe(processes => {
      if(!processes) return;
      let group0 = [];
      let group1 = [];
      processes.forEach(process => {
        if(!process.error){
            if(process.answer)
              group1.unshift(process);
            else
              group0.push(process);
        }
      });

      //asign data
      this.listGroups[0].data = group0;
      this.listGroups[1].data = group1;
      this.missionProcesses = processes;
    }, err => {
      this.loadError = true;
    })
  }
}
