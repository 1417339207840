import { Component, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ComponentInit } from '../../core/classes/component-init.class';

import { ActivatedRoute, Router } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { File } from '../../core/models/file.model';
import { FileService } from '../../core/services/file.service';

import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';

@Component({
	selector: 'app-file-edit-dialog',
	templateUrl: './file-edit-dialog.component.html',
	styleUrls: ['./file-edit-dialog.component.css']
})
export class FileEditDialogComponent extends ComponentInit {
	form: FormGroup;
	file:File;

	loading: boolean = false;
	submitting: boolean = false;

	@ViewChild('titleInput') titleInput;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private fileService: FileService,
		protected router: Router,
		protected scrollLocations: ScrollLocations,
		private dialog: MatDialog,
		public dialogFileEdit: MatDialogRef<FileEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any,
		@Inject(LOCALE_ID) private locale:string,
	) {
		super(router, scrollLocations);
		this.file = this.data.file;

		console.log(this.data, this.data.file);

		this.form = this.fb.group({
			title: [this.file.title, Validators.required],
			cat: [this.file.cat ? this.file.cat.id : 0]
		})
	}

	init(){
		this.titleInput.nativeElement.focus();
		this.titleInput.nativeElement.select();
	}

	onSubmit(){
		if(!this.form.valid)
			return this.dialog.open(OkDialogComponent, {data: 'Bitte fülle das Formular korrekt aus.'});

		this.submitting = true;

		this.fileService.update(this.file.organization, this.file.id, {
			title: this.form.value.title,
			cat: this.form.value.cat
		}).subscribe(
			res => { // Success
				this.submitting = false;

				if(!res || !res.id)
					return this.dialog.open(OkDialogComponent, {data: 'Es ist ein Fehler aufgetreten.'});

				this.dialogFileEdit.close(true);

			}, err => { // Error
				this.submitting = false;
				this.dialog.open(OkDialogComponent, {data:err.error.text});
			}
		);
	}

	onDelete(){
		if(window.confirm("Willst du wirklich diese Datei löschen?")){
			this.submitting = true;

			this.fileService.delete(this.file.organization, this.file.id).subscribe(() => {
				this.submitting = false;
				this.dialogFileEdit.close(true);
			}, err => {
				this.submitting = false;
				this.dialog.open(OkDialogComponent, {data:err.error.text});
			});
		}
	}
}
