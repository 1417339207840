import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IdentityService } from './identity.service';
import { flatMap, tap } from 'rxjs/operators';
import { empty, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

import { Account } from '../models/account.model';
import { AbstractCachableService } from '../classes/abstract-cachable-service.class';
@Injectable({
  providedIn: 'root'
})
export class AccountService implements AbstractCachableService{

  private account: BehaviorSubject<Account>;

  constructor(
    private identity: IdentityService,
    private auth: AuthService,
    private http: HttpClient
  ) {
    // restore account
    this.account = new BehaviorSubject(JSON.parse(localStorage.getItem('account')));
  }

  private update(acc){
    this.account.next(acc);
    localStorage.setItem('account', JSON.stringify(acc));
  }

  clear(){
    this.account.next(null);
    localStorage.removeItem('account');
  }

  /**
   * updates email address
   * @param string  email
   * @return boolean
   *
   */
  updateEmail(email){
    let payload = {
      email: email,
      credentials: {
        email: null,
        password: null
      }
    }

    return this.identity.confirmIdentity()
      .pipe(flatMap(cred => {
        if(!cred) return empty();
        // identity confirmed, now send data to server
        // add credentials to payload
        payload.credentials = cred;
        // set email request
        return this.http.post<Account>('/v1/account/email', payload)
          .pipe(tap(() => {
            //update acc on success
            let acc = this.account.getValue();
            acc.email = email;
            this.update(acc);
          }));
      }), flatMap(obs => {
        // re-login on success, as the auth token is no longer valid
        return this.auth.login(email, payload.credentials.password);
      }));
  }

  /**
   * updates password
   * @param string  password
   * @return boolean
   */
  updatePassword(password){
    let payload = {
      password: password,
      credentials: {
        email: null,
        password: null
      }
    }

    return this.identity.confirmIdentity()
      .pipe(flatMap(cred => {
        if(!cred) return empty();
        // identity confirmed, now send data to server
        // add credentials to payload
        payload.credentials = cred;
        // set email request
        return this.http.post('/v1/account/password', payload);
      }), flatMap(obs => {
        // re-login on success, as the auth token is no longer valid
        return this.auth.login(payload.credentials.email, password);
      }));
  }

  getCurrentAccount():Observable<Account>{
    this.http.get<Account>('/v1/account').subscribe(res => {
      this.account.next(res);
      localStorage.setItem('account', JSON.stringify(res));
    });

    return this.account.asObservable();
  }

  getAccountAsObservable(){
    return this.account.asObservable();
  }

  updateName(firstname:string, lastname:string){
    let payload = {
      first_name: firstname,
      last_name: lastname
    };

    return this.http.post<Account>('/v1/account/name', payload)
      .pipe(tap(() => {
        // update acc on success
        let acc = this.account.getValue();
        acc.first_name = firstname;
        acc.last_name = lastname;
        this.update(acc);
      }));
  }

  /**
   * creates new lupax account
   * @param firstname
   * @param lastname
   * @param email
   * @param password
   * @param inviteToken
   */
  createAccount(firstname, lastname, email, password, inviteToken){
    let payload = {
      account: {
        email: email,
        password: password,
        first_name: firstname,
        last_name: lastname
      },
      inviteToken: undefined
    };

    if(inviteToken) payload.inviteToken = inviteToken;

    return this.http.post('/v1/account', payload);
  }

  /**
   * activates
   * @param token
   */
  activate(token){
    return this.http.post('/v1/account/activate', {token});
  }

  /**
   * resend activation token to email
   * @param email
   */
  resendActivationToken(email){
    return this.http.post('/v1/account/activate/resend', {email});
  }

  applyInvite(token){
    return this.http.post('/v1/account/invite', {token});
  }

  /**
   * resets passwort of account specified by email
   * @param email
   */
  resetPassword(email){
    return this.http.post('/v1/account/password/reset', {email});
  }

  /**
   * sets new password for account specified by token
   * @param password
   * @param token
   */
  setPasswordToken(password, token){
    return this.http.post('/v1/account/password/token', {password, token});
  }

  /**
   * updates one account setting
   * @param name
   * @param value
   */
  updateSetting(name, value){
    let settings = {};
    settings[name] = value;

    return this.updateSettings(settings);
  }

  /**
   * updates settings
   * @param settings
   */
  updateSettings(settings){
    return this.http.post('/v1/account/settings', settings).pipe(
      tap(res => {
        //update chached account
        let a = this.account.getValue();
        a.settings = Object.assign(a.settings, settings);
        this.update(a);
      })
    );
  }

  /**
   * updates staff settings
   * @param settings
   */
  updateStaffSetting(orga_id, type, name, value){
    return this.http.post('/v1/organizations/' + orga_id + '/staffs/current/setting', {
        type: type,
        name: name,
        value: value
    }).pipe(
      tap(res => {
        //update chached account
        let a = this.account.getValue();
        if(!a.staff_settings[orga_id][type]) a.staff_settings[orga_id][type] = {};
        a.staff_settings[orga_id][type][name] = value;
        this.update(a);
      })
    );
  }

  accountWithEmailExists(email):Observable<boolean>{
    return this.http.post<boolean>('/v1/accounts/exists', {email:email});
  }
}
