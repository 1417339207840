var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FileService = /** @class */ (function (_super) {
    __extends(FileService, _super);
    function FileService(http) {
        var _this = _super.call(this, http, 'files') || this;
        _this.http = http;
        return _this;
    }
    FileService.prototype.getAllForCurrentAccount = function () {
        // get cached data
        var data = this.models;
        var http = this.http.get('/v1/files/public');
        return this.cachedRequest(data, http);
    };
    FileService.prototype.upload = function (orga_id, file, payload) {
        var form_data = new FormData();
        form_data.append('file', file);
        for (var key in payload)
            form_data.append(key, payload[key]);
        return this.http.post('/v1/organizations/' + orga_id + '/files', form_data).pipe(tap(function (res) {
            return res;
        }));
    };
    FileService.prototype.update = function (orga_id, file_id, data) {
        return this.http.patch("/v1/organizations/" + orga_id + "/files/" + file_id, data).pipe(tap(function (res) {
            return res;
        }));
    };
    FileService.prototype.delete = function (orga_id, file_id) {
        return this.http.delete("/v1/organizations/" + orga_id + "/files/" + file_id).pipe(tap(function (res) {
            return res;
        }));
    };
    FileService.ngInjectableDef = i0.defineInjectable({ factory: function FileService_Factory() { return new FileService(i0.inject(i1.HttpClient)); }, token: FileService, providedIn: "root" });
    return FileService;
}(CachableModelService));
export { FileService };
