<mat-nav-list>
  <a mat-list-item [ngClass]="{'round-list-item mat-elevation-z1': true, 'mat-1-5-line': d.type == 'tour'}"
        (click)="routeDispo(d)"
        *ngFor="let d of dispositions">

    <!-- Icon -->
    <fa-layers mat-list-icon class="fa-fw">
      <fa-icon [icon]="icons[d.type]" class="color-text-primary"></fa-icon>
      <fa-icon [icon]="faCheck" class="background-green secondary-icon" *ngIf="d.data.completed || d.data.tour_completed"></fa-icon>
    </fa-layers>

    <!-- Title -->
    <h4 mat-line>
      {{d.title}}<span *ngIf="d.data.vehicle">&nbsp;-&nbsp;{{d.data.vehicle.title}}</span>
    </h4>

    <div mat-line [ngClass]="{'mat-line-double': d.type == 'tour'}">
      <!-- Date -->
      <div>
        <fa-icon [icon]="faCalendarAlt"></fa-icon>&nbsp;
        <app-date-span [start]="d.start" [end]="d.end" [relativeWords]="relativeWords"></app-date-span>
      </div>

      <!-- Jobs* -->
      <div *ngIf="d.type == 'tour'">
        <fa-icon [icon]="faBriefcase"></fa-icon>&nbsp;&nbsp;
        <span class="word_wrap">{{ getJobsString(d.data) }}</span>
      </div>
    </div>
  </a>
</mat-nav-list>
