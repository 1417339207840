/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/portal";
import * as i2 from "./toolbar-actions.component";
var styles_ToolbarActionsComponent = [];
var RenderType_ToolbarActionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarActionsComponent, data: {} });
export { RenderType_ToolbarActionsComponent as RenderType_ToolbarActionsComponent };
function View_ToolbarActionsComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_ToolbarActionsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { portal: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarActionsComponent_1)), i0.ɵdid(2, 16384, [[1, 4]], 0, i1.CdkPortal, [i0.TemplateRef, i0.ViewContainerRef], null, null)], null, null); }
export function View_ToolbarActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-actions", [], null, null, null, View_ToolbarActionsComponent_0, RenderType_ToolbarActionsComponent)), i0.ɵdid(1, 4374528, null, 0, i2.ToolbarActionsComponent, [i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], null, null)], null, null); }
var ToolbarActionsComponentNgFactory = i0.ɵccf("app-toolbar-actions", i2.ToolbarActionsComponent, View_ToolbarActionsComponent_Host_0, {}, {}, ["*"]);
export { ToolbarActionsComponentNgFactory as ToolbarActionsComponentNgFactory };
