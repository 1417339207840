var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { faTruck, faCheck, faUsers, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, } from '@fortawesome/free-regular-svg-icons';
import { TourService } from '../shared/tour.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ListGroupService } from '../../core/services/list-group.service';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { DateFilter, GroupToggleSelectFilter, SelectFilter } from 'src/app/shared/filters/shared/classes';
var TourListComponent = /** @class */ (function (_super) {
    __extends(TourListComponent, _super);
    function TourListComponent(route, orgaService, tourService, listGroupService, locale, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.orgaService = orgaService;
        _this.tourService = tourService;
        _this.listGroupService = listGroupService;
        _this.locale = locale;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.loadError = false;
        _this.faCheck = faCheck;
        _this.faTruck = faTruck;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faUsers = faUsers;
        _this.faBriefcase = faBriefcase;
        _this.orgas = _this.orgaService.organizationsOfCurrentAccount;
        _this.hasMultipleOrgas = _this.orgas.length > 1;
        _this.filters = {
            date_from: new DateFilter({
                label: 'Ab',
                value: new Date(),
            }),
            organization: new SelectFilter({
                label: 'Organisation',
                selectable: _this.hasMultipleOrgas,
                visible: _this.hasMultipleOrgas,
                value: _this.hasMultipleOrgas ? null : _this.orgas[0].id,
                storable: true,
                options: _this.orgaService.organizationsToOptions(_this.orgaService.organizationsOfCurrentAccount)
            }),
            vehicle: new GroupToggleSelectFilter({
                label: 'Fahrzeug',
                filterToggle: 'organization',
                groupedOptions: { /*when no group exists for toggle value, than hide input*/},
                value: null,
                storable: true,
            }),
        };
        _this.listGroups = _this.listGroupService.getGroups();
        return _this;
    }
    TourListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.initVehicleFilter();
    };
    TourListComponent.prototype.reload = function () {
        this.filterComponent && this.filterComponent.reload();
    };
    /**
     * Routes to specified tour
     * @param t tour object
     */
    TourListComponent.prototype.routeTour = function (t) {
        this.router.navigate(['tours', t.organization, t.id]);
    };
    /**
     * Loads tours
     * @param options
     */
    TourListComponent.prototype.getTours = function (options) {
        var _this = this;
        options = __assign({}, options);
        this.tourService.getAllForCurrentAccount(options).subscribe(function (res) {
            if (!res)
                return;
            // Check if data has changed
            if (JSON.stringify(_this.tours) == JSON.stringify(res))
                return;
            _this.listGroups = _this.listGroupService.getGroups(res, 'date_start', 'date_end');
            _this.tours = res;
        }, function (res) {
            _this.loadError = true;
        });
    };
    TourListComponent.prototype.getJobsString = function (tour) {
        var jobs = [];
        if (!tour.jobs)
            return '';
        tour.jobs.forEach(function (job) {
            if (job.title && !jobs.includes(job.title))
                jobs.push(job.title);
        });
        return jobs.join(' + ');
    };
    /**
     * Reloads filters
     */
    TourListComponent.prototype.reloadFilters = function () {
        this.filters = this.filters;
    };
    /**
     * Inits vehicle filter
     */
    TourListComponent.prototype.initVehicleFilter = function () {
        var _this = this;
        var emptyFilter = {
            value: null,
            label: 'Alle'
        };
        // retrieve vehicles and add them to orgas
        this.tourService.getAllVehicles().subscribe(function (res) {
            if (!res)
                return;
            // init empty groups
            var groups = {};
            _this.orgas.forEach(function (orga) { return groups[orga.id] = [emptyFilter]; });
            // add vehicles to orga groups
            res.forEach(function (vehicle) {
                if (!groups[vehicle.organization])
                    groups[vehicle.organization] = [];
                groups[vehicle.organization].push({
                    label: vehicle.title,
                    value: vehicle.id,
                });
            });
            _this.filters.vehicle.groupedOptions = groups;
            _this.reloadFilters();
        });
    };
    return TourListComponent;
}(ComponentInit));
export { TourListComponent };
