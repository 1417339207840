import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, NgZone, LOCALE_ID, Inject  } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent, DAYS_OF_WEEK, CalendarMonthViewDay } from 'angular-calendar';
import { ComponentInit } from '../core/classes/component-init.class';
import { ScrollLocations } from '../core/globals/scroll-locations';

import { faChevronLeft, faChevronRight, faInfoCircle, faCalendarCheck, faPlus, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';

import { DispositionService } from '../dispositions/shared/disposition.service';
import { RosterService } from '../rosters/shared/roster.service';
import { AccountService } from '../core/services/account.service';

import { Observable, BehaviorSubject } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Router, ActivatedRoute } from '@angular/router';

import { formatDate } from '@angular/common';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

import { DateService } from '../core/services/date.service';
import { OrganizationService } from '../core/services/organization.service';
import { Organization } from '../core/models/organization.model';
import { AvailabilityService } from '../rosters/shared/availability.service';
import { deleteFromArray } from '../core/helpers/array.helper';
import { updateItemInArray } from '../core/helpers/array.helper';
import { MatDialog } from '@angular/material';
import { RosterModel } from '../rosters/shared/roster.class';
import { RosterDialogComponent, RETURN_ACTIONS } from '../rosters/roster-dialog/roster-dialog.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from '../shared/info-dialog/info-dialog.component';

import { RosterAvailabilityInfoComponent } from '../rosters/roster-availability-info/roster-availability-info.component';

import { MyErrorHandler } from '../core/classes/my-error-handler.class';

// Disable Tooltips
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  dayTooltip(event: CalendarEvent): string { return; }
}

interface OrgasById {
  [id: number]: Organization;
}

const EVENT_TYPES = {
  AVAILABLE: 'available',
  NOT_AVAILABLE: 'not_available',
  JOB: 'job',
  TOUR: 'tour',
  EVENT: 'event',
  WORK: 'work',
  VACATION: 'vacation',
  VACATION_SPECIAL: 'vacation_special',
  FREE: 'free',
  SICK: 'sick',
  HOLIDAY: 'holiday',
};

const COLORS = {
  cyan: '#1abc9c',
  red: '#b6514f',
  green: '#53a93f',
  yellow: '#f2c658',
  blue: '#456292',
  purple: '#966ea9',
  dark: '#363c46',
  grey: '#607D8B',
  light: '#e4e4e4',
  gr: '#53a93f',
  li: '#966ea9',
  lila: '#966ea9',
  bl: '#5290a9',
  ye: '#f2c658',
  re: '#b6514f',
  re_dark: '#76474B',
  or: '#F28349',
  orange: '#F28349',
  or_light: '#FAD4BF',
  orange_light: '#FAD4BF',
  cy: '#1abc9c'
};

const EVENT_TYPE_COLORS = {
  [EVENT_TYPES.JOB]: COLORS.yellow,
  [EVENT_TYPES.TOUR]: COLORS.yellow,
  [EVENT_TYPES.EVENT]: COLORS.cyan,
  [EVENT_TYPES.WORK]: COLORS.blue,
  [EVENT_TYPES.VACATION]: COLORS.red,
  [EVENT_TYPES.VACATION_SPECIAL]: COLORS.red,
  [EVENT_TYPES.FREE]: COLORS.red,
  [EVENT_TYPES.NOT_AVAILABLE]: COLORS.red,
  [EVENT_TYPES.AVAILABLE]: COLORS.green,
  [EVENT_TYPES.SICK]: COLORS.purple,
  [EVENT_TYPES.HOLIDAY]: COLORS.dark,
};

const VIEWS = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ]
})

export class CalendarComponent extends ComponentInit implements OnInit{
  isAvailabilityMode:boolean = false;
  availabilitiesLoadedForMonths:Array<Date> = [];

  faPlus = faPlus;
  faCalendarCheck = faCalendarCheck;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faInfoCircle = faInfoCircle;
  faQuestion = faQuestion;
  faTimes = faTimes;

  navigating = false;

  views = Object.values(VIEWS);

  tabIndex: number = 0;

  weekStart: number = DAYS_OF_WEEK.MONDAY;

  viewDate: Date = new Date();

  disposCheck:boolean = true;
  rostersCheck:boolean = true;

  eventsSubject: BehaviorSubject<any>;
  events$: Observable<Array<CalendarEvent<any>>>;

  availabilities: Array<CalendarEvent<any>> = [];
  rosters: Array<CalendarEvent<any>> = [];
  dispos: Array<CalendarEvent<any>> = [];

  retryCallback = this.fetchEvents.bind(this);
  loadError: boolean;

  availabilityOrgas: Organization[];
  availabilityOrgasbyId: OrgasById = {};
  availabilityOrgasChecks = {};
  availabilityOrgasSelected: Organization[];

  accountSettings: any;

  dialogRef;

  AVAILABLE_ENUM = {
    MIXED: "mixed_available",
    ALL_AVAILABLE: 'all_available',
    SOME_AVAILABLE: 'some_available',
    ALL_NOT_AVAILABLE: 'all_not_available',
    SOME_NOT_AVAILABLE: 'some_not_available',
    UNDEFINED: null,
  };

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected scrollLocations: ScrollLocations,
    private ds: DispositionService,
    private rs: RosterService,
    private change: ChangeDetectorRef,
    private zone: NgZone,
    private date: DateService,
    private orgaService: OrganizationService,
    private avService: AvailabilityService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private myErrorHandler: MyErrorHandler,
    @Inject(LOCALE_ID) public locale: string,
  ){
      super(router, scrollLocations, route);
  }

  ngOnInit(){
    super.ngOnInit();

    if(this.route.snapshot.params.view) this.tabIndex = this.views.indexOf(this.route.snapshot.params.view);
    if(this.route.snapshot.params.date) this.viewDate = new Date(this.route.snapshot.params.date);

    // reset availabilities
    this.avService.clear();

    // listen for url and change calendar view and date accordingly
    this.route.params.subscribe(params => {
      window.setTimeout(()=>{

        if(params.view) this.tabIndex = this.views.indexOf(params.view);
        if(params.date) this.viewDate = new Date(params.date);

        // run inside Angular
        this.zone.run(()=>{
          this.tabIndex = this.views.indexOf(params.view);
          if(params.date) this.viewDate = new Date(params.date);
          // force change detection
          this.change.detectChanges();

          // Scroll
          if(params.view == "day" || params.view == "week"){
            let element = document.querySelector(".mat-tab-body-active .cal-event-container:first-child");
            setTimeout(() => {
              if(element) element.scrollIntoView({ behavior: "smooth"});
            }, 300);
          }
        })
      });
    });

    this.accountService.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(acc => {
      this.accountSettings = acc.settings;
      this.availabilityOrgas = acc.roster_availability_orgas;
      this.availabilityOrgasSelected = [...this.availabilityOrgas];
      this.availabilityOrgasbyId = this.availabilityOrgas.reduce((orgas:OrgasById, orga:Organization) => {
        orgas[orga.id] = orga;
        return orgas;
      }, {});
      // pre-select all orgas
      this.availabilityOrgasChecks = this.availabilityOrgas.reduce((obj:object, orga) => {
        obj[orga.id] = true;
        return obj;
      }, {});
    });
  }

  ngAfterViewInit(){
    this.events$ = this.eventsSubject = new BehaviorSubject(null);
    this.fetchEvents();

    // Switch availability mode when url param is given
    this.route.queryParams.subscribe(params => {
      if(params && params.availability_mode == "1" && !this.isAvailabilityMode){
        this.toggleAvailabilityMode();
      } else if(params && params.availability_mode == "0" && this.isAvailabilityMode){
        this.toggleAvailabilityMode();
      }
    });

    // Show "new: availability mode" announcement
    setTimeout(() => {
      if(this.accountSettings && !this.accountSettings.calendar_availability_announced && !this.dialogRef && this.availabilityOrgas.length){
        this.dialogRef = this.dialog.open(InfoDialogComponent, {
          maxWidth: '90vw',
          data: {
            title: "Neu: Verfügbarkeiten verwalten",
            text: '<p>Durch einen Tap auf das Icon oben rechts im Kalender wird der <strong>Verfügbarkeits-Modus</strong> aktiviert.</p><p>Weitere Informationen können dann über das <strong>Fragezeichen</strong> im Verfügbarkeits-Modus abgerufen werden.</p>',
          }
        }).afterClosed().subscribe(() => {
            this.dialogRef = undefined
            this.accountService.updateSetting('calendar_availability_announced', 1).subscribe();
        })
      }
    });
  }

  reload(){
    this.fetchEvents();
  }

  /**
   * indicates if the user is part of mulitple organizations
   */
  get isMultiOrga():boolean {
    return this.availabilityOrgas.length > 1;
  }

  /**
   * refreshes events which triggeres calendar re-render
   */
  refreshEvents(){
    let dispos = [];
    if(this.disposCheck)
      dispos = [...this.dispos];

    let rosters = [];
    if(this.rostersCheck)
      rosters = [...this.rosters];

    if(!this.isAvailabilityMode)
      rosters.push(...this.availabilities);

    let availabilities = [];
    if (this.isAvailabilityMode)
      availabilities = this.applyOrgasFilterToEvents(this.availabilities);

    this.eventsSubject.next([
      ...dispos,
      ...rosters,
      ...availabilities,
    ]);
  }

  /**
   * Returns availability rosters for day
   * @param day
   */
  getAvailablesOfDay(day) {

    return day.events.filter(this.avService.isAvailability);
  }

  /**
   * Filters out events for unselected organizations
   * @param events
   */
  applyOrgasFilterToEvents(events:Array<any>) {
    return events.filter(event => this.availabilityOrgasChecks[event.organization]);
  }

  /**
   * Evaluates availability type for day
   * @param day
   */
  getAvailabilityOfDay(day:any):string {

    const events = this.applyOrgasFilterToEvents(this.getAvailablesOfDay(day));

    if (!events.length) return this.AVAILABLE_ENUM.UNDEFINED;

    const allOrgasHaveEvents = this.availabilityOrgasSelected.every(orga => events.find(event => event.organization == orga.id));
    const eventsEqualOrgas = events.length === this.availabilityOrgasSelected.length && allOrgasHaveEvents;

    if (events.every(this.avService.isNotAvailableType))
      // all events are not available, but lets check that this is true for all orgas
      return eventsEqualOrgas ? this.AVAILABLE_ENUM.ALL_NOT_AVAILABLE : this.AVAILABLE_ENUM.SOME_NOT_AVAILABLE;

    if(events.every(this.avService.isAvailableType))
      // all events are available, but lets check that this is true for all orgas
      return eventsEqualOrgas ? this.AVAILABLE_ENUM.ALL_AVAILABLE : this.AVAILABLE_ENUM.SOME_AVAILABLE;

    return this.AVAILABLE_ENUM.MIXED;
  }

  /**
   * Toggles availability of day and updates rosters
   * @param day
   */
  toggleAvailability(day:any) {

    const availables = this.getAvailablesOfDay(day);
    switch(day.availability) {
      case this.AVAILABLE_ENUM.MIXED:
        this.showDate(day.date);
        return;

      case this.AVAILABLE_ENUM.ALL_AVAILABLE:
      case this.AVAILABLE_ENUM.SOME_AVAILABLE:
        // mark availability as not available
        this.avService.updateTypeForMultiple(RosterService.TYPES.NOT_AVAILABLE, availables);
        availables.forEach(this.colorEvent);

        day.availability = this.getAvailabilityOfDay(day);
        break;

      case this.AVAILABLE_ENUM.ALL_NOT_AVAILABLE:
      case this.AVAILABLE_ENUM.SOME_NOT_AVAILABLE:
        // next state: delete availabilities
        availables.forEach(event => {
          this.avService.deleteAvailability(event);
          deleteFromArray(event, day.events);
          deleteFromArray(event, this.availabilities);
        });

        day.availability = this.AVAILABLE_ENUM.UNDEFINED;
        break;

      case this.AVAILABLE_ENUM.UNDEFINED:
        // create availability
        const events = this.availabilityOrgasSelected.map(orga =>
          this.avService.createAvailability(
            EVENT_TYPES.AVAILABLE,
            this.date.startOfDay(day.date),
            this.date.endOfDay(day.date),
            '',
            orga
          )
        );
        day.events.push(...events);
        this.availabilities.push(...events);

        day.availability = this.AVAILABLE_ENUM.ALL_AVAILABLE;
        break;

      default:
        throw Error('Unknown available type for ' + JSON.stringify(day));
    }

    this.refreshEvents();
  }

  /**
   * On day clicked handler
   */
  onDayClicked($event) {

    if (this.isAvailabilityMode) {
      this.toggleAvailability($event.day);
    } else {
      this.showDate($event.day.date);
    }
  }

  /**
   * Shows day view for date
   * @param date
   */
  showDate(date) {
    this.viewDate = date;
    this.tabIndex = 2;
  }

  /**
   * adds color to event
   * @param event
   */
  colorEvent(event) {
    let color = EVENT_TYPE_COLORS[event.type] || COLORS.blue;
    if(event.color){
        // is hexcode?
        if(event.color[0] == '#') color = event.color;
        else if(COLORS[event.color]) color = COLORS[event.color];
    }
    event.color = {primary: color, secondary: color};

    return event;
  }

  /**
   * Maps additional data to events
   * @param data
   */
  mapDataToEvents(data:Array<Object>) {

    return data.map((event:any) => {
      event.start = createDateFromDatetime(event.start);
      event.end = createDateFromDatetime(event.end);

      return this.colorEvent(event);
    });
  }

  /**
   * TODO: refactor function that 'refreshEvents' will only be called once at the end
   */
  fetchEvents(){
    // load events only on a monthly basis
    // start from last days in the previous month, to the next days in the upcoming month
    let start:Date = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), -7);
    let end:Date = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth()+1, 7);

    const obs:Observable<any>[] = [];

    // load dispos
    const dispoObs = this.ds.getAllForCurrentAccount({date_from:start, date_to:end});
    obs.push(dispoObs);
    dispoObs.pipe(untilDestroyed(this)).subscribe(dispos => {
      this.dispos = this.mapDataToEvents(dispos);
      this.refreshEvents();
    });

    // load rosters
    const rosterObs = this.rs.getAllForCurrentAccount(start, end);
    obs.push(rosterObs);
    rosterObs.pipe(untilDestroyed(this)).subscribe(rosters => {
      if(!rosters) return;

      // split rosters into EVENT_TYPES
      let others = [];

      rosters.forEach(roster => {
        switch(roster.type) {
          case EVENT_TYPES.AVAILABLE:
          case EVENT_TYPES.NOT_AVAILABLE:
            /* availabel rosters are handled separately down below */
            break;
          default:
            others.push(roster);
        }
      });

      this.rosters = this.mapDataToEvents(others);
      this.refreshEvents();
    });

    // load availabilities
    // Reload stored data
    const availabilityObs = this.avService.getAllForCurrentAccount(start, end);
    obs.push(availabilityObs);
    availabilityObs.pipe(untilDestroyed(this)).subscribe(rosters => {
      this.availabilities = this.mapDataToEvents(rosters);
      this.refreshEvents();
    });
  }

  /**
   * Handles tab changes and changes the calendar view.
   * @param i
   */
  onTabIndexChange(i){
    if(this.navigating) return;
    this.navigating = true;
    //TODO: change locale
    this.router.navigate(['calendar', this.views[i], formatDate(this.viewDate, 'y-MM-dd', this.locale)]).then(() => {
      this.navigating = false;
    });
  }

  /**
   * save the current date, as one might want to navigate back to it after selecting an Event
   */
  onViewChange(){
    this.onTabIndexChange(this.tabIndex);
  }

  /**
   * Navigates to the event's specific app site
   * @param e
   */
  showEvent(e){
    // the timeout is needed, as the calendar will trigger an additional click which will otherwise be executed on the next page
    // dunno why :(
    if(e.event.type == 'job' || e.event.type == 'tour' || e.event.type == 'event')
      setTimeout(() => this.router.navigate(['/'+e.event.type+'s/'+e.event.organization+'/'+e.event.data.id]), 100);
  }

  /**
   * Caled before calendar renders.
   * Last call to initialize calendar objects here.
   */
  beforeMonthViewRender({body}: {body: CalendarMonthViewDay[]}): void {

    body.forEach((day:any) => {
      day.availability = this.getAvailabilityOfDay(day);
      day.events.map(event => {
        if(this.isAvailabilityMode && (event.type == EVENT_TYPES.AVAILABLE || event.type == EVENT_TYPES.NOT_AVAILABLE)){
          event.cssClass = 'hide';
        } else event.cssClass = '';
      });

    });
  }

  /**
   * toggles availability mode.
   * Creates confirm dialog when unsaved changes exist.
   */
  toggleAvailabilityMode() {
    // Switch to month view, when available mode will be activated
    if(!this.isAvailabilityMode) this.tabIndex = 0;

    if(this.isAvailabilityMode && this.avService.unsavedChanges){

      this.dialog.open(ConfirmDialogComponent, {data: {
        text: 'Änderungen wurden noch nicht gespeichert. Verfügbarkeitsmodus trotzdem beenden?',
      }}).afterClosed().subscribe(result => {

        if (result) {
          this.exitAvailabilityMode();
        }
      });

      return;
    } else {
      if(this.isAvailabilityMode){
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {}
        });
      }
      this.isAvailabilityMode = !this.isAvailabilityMode;
      this.refreshEvents();
    }
  }

  /**
   * handles event click action
   * @param e
   */
  onEventClick(e) {
    if(this.avService.isAvailability(e.event))
      this.openAvailabilityForm(e.event);
    else
      this.showEvent(e);
  }

  /**
   * triggered on orga filter change
   */
  orgaFilterChange() {
    this.availabilityOrgasSelected = Object.entries(this.availabilityOrgasChecks)
      .filter(([id, bool]) => bool)
      .map(([id, bool]) => this.availabilityOrgasbyId[id])
    ;
    this.refreshEvents();
  }

  /**
   * esit availability mode
   */
  private exitAvailabilityMode() {
    this.isAvailabilityMode = false;
    this.avService.clear();
    this.fetchEvents();
    this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { availability_mode: 0 },
        queryParamsHandling: 'merge'
    });
  }

  /**
   * save and exit
   */
  async saveAvailabilitiesAndExit() {

    await this.avService.flush().catch(err => this.myErrorHandler.showError(err));
    this.exitAvailabilityMode();
  }

  /**
   * opens roster form dialog
   * @param roster
   * @param date
   */
  openAvailabilityForm(roster:RosterModel = null, date:Date = null) {
    this.dialog.open(RosterDialogComponent, {data: {
      roster: roster,
      date: date,
    }}).afterClosed().subscribe(async data => {
      if (!data) return;

      switch(data.action) {
        case RETURN_ACTIONS.NEW:
          this.availabilities.push(this.colorEvent(data.roster));
          this.avService.addAvailability(data.roster);
          break;

        case RETURN_ACTIONS.CHANGE:
          this.avService.updateAvailability(data.roster);
          delete data.roster.title;
          updateItemInArray(this.colorEvent(data.roster), this.availabilities);
          break;

        case RETURN_ACTIONS.DELETE:
          this.avService.deleteAvailability(roster);
          deleteFromArray(roster, this.availabilities);
          break;
      }

      this.refreshEvents();

      if(!this.isAvailabilityMode){
        await this.avService.flush().catch(err => this.myErrorHandler.showError(err));
        this.rs.clear();
        this.avService.clear();
        this.fetchEvents();
      }
    })
  }

  /**
   * opens availability info guide dialog
   */
  openAvailabilityInfo(){

    this.dialog.open(RosterAvailabilityInfoComponent, {
      width: '600px',
      maxWidth: '90vw'
    });
  }

  /**
   * current date
   */
  get currentView() {

    return this.views[this.tabIndex];
  }

  /**
   * depending in which view we are, we open roster form dialog with pre-set date
   */
  createNewAvailability() {

    // only specify date if we are in date view
    this.openAvailabilityForm(null, this.currentView === VIEWS.DAY ? this.viewDate : null);
  }
}
