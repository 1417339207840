import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { AuthService } from '../../auth/auth.service';
import { tap } from 'rxjs/operators';
import { ClearService } from '../services/clear.service';
import { Router } from '@angular/router';

/** This injector will inject the authoriation token everytime a http request is made */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private auth: AuthService,
		private clear: ClearService,
		private router: Router,
	){ }

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		// Get the auth token from the service.
		const token = JSON.parse(localStorage.getItem('accessToken'));
		
		// Clone the request and replace the original headers with
    	// cloned headers, updated with the authorization.
		const authReq = req.clone({ setHeaders: { Authorization: 'Bearer '+token } });
		
		// send cloned request with header to the next handler.
		return next.handle(authReq).pipe(tap((event:HttpEvent<any>) => {
			//success
		}, (err: any) => {
			//check for authorization error and proceed with logout
			if(err.status == 401){
				this.clear.clear();
				this.auth.logout();
				this.router.navigate(['/auth/login']);
			}
		}));
	}
}