import { Injectable} from '@angular/core';
import { Router } from "@angular/router";
import { JobTimesheet } from './job-timesheet.model';
import { JobTimesheetService } from './job-timesheet.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class JobTimesheetResolver extends CachableClientModelResolver<JobTimesheet>{
	constructor(s:JobTimesheetService, r:Router) {
		super(s, 'job-timesheets', r);
	}
}
