import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { AccountService } from '../../core/services/account.service';

import { Organization } from '../../core/models/organization.model';
import { OrganizationService } from '../../core/services/organization.service';

import { Account } from '../../core/models/account.model';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './calendar-subscribe.component.html',
  styleUrls: ['./calendar-subscribe.component.css']
})
export class CalendarSubscribeComponent{

  account:Account = null;
  orgas:Organization[];
  settings:any = {};

  constructor(
    private router: Router,
    private acc: AccountService,
    private snackbar: MatSnackBar,
    private auth: AuthService,
    public os: OrganizationService,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit(){
    this.acc.getCurrentAccount().pipe(untilDestroyed(this)).subscribe((a) => {
      this.account = a;
      for(let [key, value] of Object.entries(a.staff_settings)){
        this.settings[key] = value['event_agenda_subscribe'] || {};
      }
    });

    this.os.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
      if(!res) return;
      this.orgas = res;
    });
  }

  onSelectionUpdate(e, orga_id){
    this.acc.updateStaffSetting(orga_id, 'event_agenda_subscribe', e.option.value, e.option._selected).subscribe(null, res => {
      if(res.status != 200){
        console.error(res);
        return this.snackbar.open('Einstellung konnte nicht geändert werden: ' + res.message, '', {duration:5000});
      }

      this.settings[e.option.value] = !e.option._selected;
    });
  }

 copyUrl(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);

    this.snackbar.open('Url wurde in die Zwischenablage kopiert', '', {duration:3000})
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  
  ngOnDestroy() {}

}
