import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { JobResolver } from './shared/job.resolver';
import { JobService } from './shared/job.service';

import { JobTimesheetCreateDialogComponent } from './job-timesheet-create-dialog/job-timesheet-create-dialog.component';
import { JobTimesheetCreateComponent } from './job-timesheet-create/job-timesheet-create.component';
import { JobTimesheetComponent } from './job-timesheet/job-timesheet.component';
import { JobTimesheetResolver } from './shared/job-timesheet.resolver';
import { JobTimesheetService } from './shared/job-timesheet.service';

import { SignaturePadModule } from 'angular2-signaturepad';

// import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

@NgModule({
  imports: [
    SharedModule,
    SignaturePadModule
  ],
  declarations: [
    JobDetailComponent,
    JobTimesheetComponent,
    JobTimesheetCreateComponent,
    JobTimesheetCreateDialogComponent
  ],
  entryComponents: [
    JobTimesheetCreateDialogComponent
  ],
  exports: [
    JobTimesheetCreateDialogComponent
  ],
  providers:[
    JobResolver,
    JobService,
    JobTimesheetResolver,
    JobTimesheetService
    /* {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    } */
  ]
})
export class JobsModule { }
