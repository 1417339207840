var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../../core/classes/component-init.class';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { MissionProcessService } from '../../shared/mission-process.service';
import { ScrollLocations } from '../../../core/globals/scroll-locations';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
var MissionProcessConfirmationsComponent = /** @class */ (function (_super) {
    __extends(MissionProcessConfirmationsComponent, _super);
    function MissionProcessConfirmationsComponent(route, router, mps, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.router = router;
        _this.mps = mps;
        _this.scrollLocations = scrollLocations;
        _this.faHandshake = faHandshake;
        _this.loadError = false;
        _this.reload = _this.loadMissionProcesses.bind(_this);
        return _this;
    }
    MissionProcessConfirmationsComponent.prototype.init = function () {
        this.loadMissionProcesses();
    };
    MissionProcessConfirmationsComponent.prototype.loadMissionProcesses = function () {
        var _this = this;
        this.loadError = false;
        // get last year
        var date = new Date();
        date.setFullYear(date.getFullYear() - 1, date.getMonth(), date.getDate());
        var now = new Date();
        //retrieve processes from last year
        this.mps.getAllConfirmationsForCurrentAccount(date, null).subscribe(function (processes) {
            if (!processes)
                return;
            _this.all = processes;
            //reset lists
            _this.pending = [];
            _this.past = [];
            // add each confirmation to a list, sorted specifically
            _this.all.forEach(function (val) {
                if (!val.error) {
                    if (createDateFromDatetime(val.end) < now)
                        _this.past.unshift(val); //old ones
                    else
                        _this.pending.push(val); //upcoming
                }
            });
        }, function (err) {
            _this.loadError = true;
        });
    };
    return MissionProcessConfirmationsComponent;
}(ComponentInit));
export { MissionProcessConfirmationsComponent };
