import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { ComponentInit } from '../../../core/classes/component-init.class';

import { ScrollLocations } from '../../../core/globals/scroll-locations';

import {
  faShareAlt,
  faQuestion,
  faUsers,
  faCheck,
  faTimes,
  faCrosshairs,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFileAlt,
  faHandshake,
  faCalendarAlt,
} from '@fortawesome/free-regular-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { MissionProcess } from '../../shared/mission-process.model';
import { MissionProcessService } from '../../shared/mission-process.service';
import { DashboardService } from '../../../core/services/dashboard.service';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../../shared/ok-dialog/ok-dialog.component';

@Component({
  selector: 'app-mission-process-detail',
  templateUrl: './mission-process-detail.component.html',
  styleUrls: ['./mission-process-detail.component.css']
})
export class MissionProcessDetailComponent extends ComponentInit implements AfterViewChecked {

  faShareAlt = faShareAlt;
  faQuestion = faQuestion;
  faUsers = faUsers;
  faFileAlt = faFileAlt;
  faHandshake = faHandshake;
  faCheck = faCheck;
  faTimes = faTimes;
  faCrosshairs = faCrosshairs;
  faCalendarAlt = faCalendarAlt;

  stages:any[] = [
    {
      position: 'top',
      title: 'Ausschreibung erstellt',
      icon: this.faShareAlt,
      state: 'pale',
      line: {
        y1:30,
        y2:75
      }
    },
    {
      position: 'bottom',
      title: 'Ausschreibung beantworten',
      icon: this.faQuestion,
      state: 'pale',
      line: {
        y1:75,
        y2:30
      }
    },
    {
      position: 'top',
      title: 'Interessenten-Pool antworten',
      icon: this.faUsers,
      state: 'pale',
      line: {
        y1:30,
        y2:30
      }
    },
    {
      position: 'top',
      title: 'Angebot erstellt',
      icon: this.faFileAlt,
      state: 'pale',
      line: {
        y1:30,
        y2:75
      }
    },
    {
      position: 'bottom',
      title: 'Angebot beantworten',
      icon: this.faQuestion,
      state: 'pale',
      line: {
        y1:75,
        y2:75
      }
    },
    {
      position: 'bottom',
      title: 'Auftragsbestätigung',
      icon: this.faHandshake,
      state: 'pale',
      line: null
    }
  ];

  mp:MissionProcess;
  disableAll:boolean = false;
  files:File[];

  selection = {
    files:[],
    contract:[],
  };

  selectionRequiredError:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private mps: MissionProcessService,
    private ds: DashboardService,
    private dialog: MatDialog,
    protected router: Router,
    protected scrollLocations: ScrollLocations
) { super(router, scrollLocations, route); }


  init(){
    this.route.data.subscribe(data => {
      if(data.resolved.model)
        this.update(data.resolved.model);

      // get model update
      data.resolved.updates.subscribe(mp => {
        if(mp) this.update(mp)
      });
    });
  }

  update(missionProcess) {
    if(missionProcess.state == 'confirmation')
      this.ds.setTitle('Auftragsbestätigung');

    let i = 0;

    switch(missionProcess.state){
      case 'confirmation':
      case 'cancel':
        i = 5;
        break;
      case 'offer':
        i = 3;
        break;
      case 'proposal':
        i = 1;
    }

    for(var j=0; j<i; j++){
      this.stages[j].state = 'completed';
    }

    switch(missionProcess.answer){
      case 'rejected':
        this.stages[j].state = 'completed';
        j++;
      case 'declined':
        this.stages[j].state = 'aborted';
        break;
      case 'accepted':
        this.stages[j].state = 'completed';
        j++;
      case null:
        if(missionProcess.state == 'confirmation')
          this.stages[j].state = 'completed';
        else if(missionProcess.state == 'cancel')
          this.stages[j].state = 'aborted';
        else
          this.stages[j].state = 'pending';
        break;
    }

    // reset remaining
    for(var k=j+1; k<this.stages.length; k++){
      this.stages[k].state = 'pale';
    }

    this.mp = missionProcess;
    if(missionProcess.required_files)
      this.files = missionProcess.required_files;
    else if(missionProcess.info_files)
      this.files = missionProcess.info_files;
  }

  ngAfterViewChecked(){
    setTimeout(()=>this.adjustLines());
  }

  linesX1;
  linesX2;

  adjustLines(){
    // get first icon
    let elem = document.querySelector(".stages .stage:first-child");
    if(!elem) return;

    // adjust lines
    let width = document.querySelector(".stages .stage:first-child").clientWidth;
    this.linesX1 = width / 2;
    this.linesX2 = width + (width / 2);
  }

  /**
   * checks selection, only when it is an offer
   */
  checkSelection(){
    if(this.mp.state != 'offer') return true;

    // if there are files / cotnract and the option is not checked --> error
    if((this.mp.contract_for_services && this.selection.contract.length == 0) || (this.mp.required_files && this.selection.files.length == 0)){
      this.selectionRequiredError = true;
      return false;
    }

    this.selectionRequiredError = false;
    return true;
  }

  /**
   * Accept Button
   */
  onProcessAcceptClick(){
    if(!this.checkSelection()) return;

    if(this.disableAll) return;
    this.disableAll = true;

    this.mps.accept(this.mp).subscribe(res => {
      this.disableAll = false;
      this.update(res.mission_process);
      this.dialog.open(OkDialogComponent, {data:res.message});
    }, res => {
      this.disableAll = false;
      this.dialog.open(OkDialogComponent, {data:res.error.text});
    });
  }

  /**
   * Decline button
   */
  onProcessDeclineClick(){
    if(!this.checkSelection()) return;

    if(this.disableAll) return;
    this.disableAll = true;

    this.mps.decline(this.mp).subscribe(res => {
      this.disableAll = false;
      this.update(res.mission_process);
      this.dialog.open(OkDialogComponent, {data:res.message});
    }, res => {
      this.disableAll = false;
      this.dialog.open(OkDialogComponent, {data:res.error.text});
    });
  }

  /**
   * Save preferences
   */
  onProcessSaveClick(){
    if(this.disableAll) return;
    this.disableAll = true;

    // retrieve ansers for each entry
    let entries = this.mp.entries.reduce((total, val, i) => {
      total[val.id] = val.answer;
      return total;
    }, {});

    this.mps.save(this.mp, entries).subscribe(res => {
      this.disableAll = false;
      this.update(res.mission_process);
      this.dialog.open(OkDialogComponent, {data:res.message});
    }, res => {
      this.disableAll = false;
      this.dialog.open(OkDialogComponent, {data:res.error.text});
    });
  }
}
