<main>
    <mat-spinner style="margin:16px auto" diameter="40" *ngIf="!account"></mat-spinner>
    <ng-container *ngIf="account && orgas && orgas.length != 0">
        <section class="tile-container">
            <!-- Work Schedule Time This Week -->
            <mat-card *ngIf="account.summary.work_hours_this_week" class="clickable tile" (click)="router.navigate(['work-schedules'])">
                <h4 class="mat-h4" i18n>Arbeitszeit diese Woche</h4>
                <div class="icon-content" style="font-size:1.5em">
                    <div>
                        <b class="color-text-primary">{{workTimeThisWeek.hours}}</b>
                        <small i18n> Std</small>&nbsp;
                        <b class="color-text-primary"> {{workTimeThisWeek.minutes}}</b>
                        <small i18n> Min</small>
                    </div>
                </div>
            </mat-card>

            <!-- Work Schedule Overtime -->
            <mat-card class="tile" *ngIf="showOvertimeTile" style="min-width:150px">
                <div class="tile-options" *ngIf="account.summary.overtime && account.summary.overtime.length > 1">
                    <mat-menu #overtimeOptions="matMenu">
                        <mat-spinner *ngIf="!orgas"></mat-spinner>
                        <button *ngFor="let orga of account.summary.overtime; let i = index" mat-menu-item (click)="overtimeOrgaIndex = i">
                            {{orga?.company?.title}}
                        </button>
                    </mat-menu>
                    <button mat-icon-button [matMenuTriggerFor]="overtimeOptions"><fa-icon [icon]="faEllipsisV" class="color-text-grey"></fa-icon></button>
                </div>
                <h4 class="mat-h4" i18n>Arbeitszeitkonto</h4>
                <small class="tile-subheader color-text-grey" *ngIf="account.summary.overtime[overtimeOrgaIndex]">
                    {{account.summary.overtime[overtimeOrgaIndex].company.title}}
                </small>
                <div class="icon-content">
                    <mat-spinner *ngIf="!orgas"></mat-spinner>
                    <ng-container *ngIf="orgas">
                        <div>
                            <b class="color-text-primary">{{ hourToTimeObj(account.summary.overtime[overtimeOrgaIndex].value).hours }}</b>
                            <small i18n> Std</small>&nbsp;
                            <b class="color-text-primary">{{ hourToTimeObj(account.summary.overtime[overtimeOrgaIndex].value).minutes }}</b>
                            <small i18n> Min</small>
                        </div>
                    </ng-container>
                </div>
            </mat-card>

            <!-- Vacation Left -->
            <mat-card class="tile" *ngIf="showVacationLeftTile" style="min-width:150px">
                <div class="tile-options" *ngIf="account.summary.vacation_left && account.summary.vacation_left.length > 1">
                    <mat-menu #vacationLeftOptions="matMenu">
                        <mat-spinner *ngIf="!orgas"></mat-spinner>
                        <button *ngFor="let orga of account.summary.vacation_left; let i = index" mat-menu-item (click)="vacationLeftOrgaIndex = i">
                            {{orga?.company?.title}}
                        </button>
                    </mat-menu>
                    <button mat-icon-button [matMenuTriggerFor]="vacationLeftOptions"><fa-icon [icon]="faEllipsisV" class="color-text-grey"></fa-icon></button>
                </div>
                <h4 class="mat-h4" i18n>Resturlaub {{currentDate | date: 'yyyy'}}</h4>
                <small class="tile-subheader color-text-grey" *ngIf="account.summary.vacation_left[vacationLeftOrgaIndex]">
                    {{account.summary.vacation_left[vacationLeftOrgaIndex].company.title}}
                </small>
                <div class="icon-content">
                    <mat-spinner *ngIf="!orgas"></mat-spinner>
                    <ng-container *ngIf="orgas">
                        <div>
                            <b class="color-text-primary">{{account.summary.vacation_left[vacationLeftOrgaIndex].value}}</b>
                            <small i18n> Tage</small>
                        </div>
                    </ng-container>
                </div>
            </mat-card>

            <!-- Incomplete Work Schedules -->
            <mat-card *ngIf="account.summary?.incomplete_work_schedules" class="clickable tile" (click)="router.navigate(['work-schedules'])">
                <h4 class="mat-h4" i18n>Unvollständige Arbeitszeiten</h4>
                <div class="icon-content color-text-primary">
                    <b>{{account.summary.incomplete_work_schedules}}</b>
                    <fa-icon [icon]="faStopwatch"></fa-icon>
                </div>
            </mat-card>

            <!-- Outstanding mission proposals -->
            <mat-card *ngIf="account.summary.outstanding_proposals" class="clickable tile" (click)="router.navigate(['missions', 'processes'])">
                <h4 class="mat-h4" i18n>Offene Ausschreibungen</h4>
                <div class="icon-content color-text-primary">
                    <b>{{account.summary.outstanding_proposals}}</b>
                    <fa-icon [icon]="faShareAlt"></fa-icon>
                </div>
            </mat-card>

            <!-- Tours this week -->
            <mat-card *ngIf="account.summary.tours_this_week" class="clickable tile" (click)="router.navigate(['dispositions'])">
                <h4 class="mat-h4" i18n>Touren diese Woche</h4>
                <div class="icon-content color-text-primary">
                    <b>{{account.summary.tours_this_week}}</b>
                    <fa-icon [icon]="faTruck"></fa-icon>
                </div>
            </mat-card>



        </section>


        <section class="work-schedule-container" *ngIf="account.work_schedule_logging_orgas && account.work_schedule_logging_orgas.length > 0">
            <h2 class="mat-h2 app-list-heading" i18n>
                <span>Zeiterfassung</span>
                <ng-container *ngIf="workSchedule && workSchedule.organization && os.getById(workSchedule.organization) | async; let orga">
                    <small class="color-text-grey">
                        &nbsp;({{orga.title}})
                    </small>
                </ng-container>

                <ng-container  *ngIf="account.work_schedule_logging_past_orgas && account.work_schedule_logging_past_orgas.length > 0">
                    <button mat-button class="pale ws-logging-past"
                            (click)="startWork(account.work_schedule_logging_past_orgas[0].id, true, account.work_schedule_logging_past_orgas[0].company)"
                            *ngIf="account.work_schedule_logging_past_orgas.length == 1">
                        <fa-icon [icon]="faHistory"></fa-icon>&nbsp;
                        <span i18n>Arbeitszeit nachtragen</span>
                    </button>
                    <button mat-button [matMenuTriggerFor]="menu"
                            class="pale ws-logging-past"
                            *ngIf="account.work_schedule_logging_past_orgas.length > 1">
                        <fa-icon [icon]="faHistory"></fa-icon>&nbsp;
                        <span i18n>Arbeitszeit nachtragen</span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="startWork(orga.id, true, orga.company)" *ngFor="let orga of account.work_schedule_logging_past_orgas">
                            {{ orga.title }}
                        </button>
                    </mat-menu>
                </ng-container>
            </h2>
            <div class="tile-container">

                <!-- Project Entry Card (work schedule entry) -->
                <mat-card class="tile" *ngIf="workSchedule">
                    <h4 class="mat-h4">Projekt</h4>
                    <div class="tile-content">
                        <ng-container *ngIf="projectRunningTitle; else noProjectRunning">
                            <div class="project-data">
                                <small>{{ projectRunningTitle }}</small>
                                <small class="color-text-grey" *ngIf="qualificationRunning">({{ qualificationRunning.title }})</small>
                            </div>
                            <div style="margin:3px 0;">
                                <b class="color-text-primary">{{projectTime.hours}}</b>
                                <small i18n> Std</small>&nbsp;
                                <b class="color-text-primary"> {{projectTime.minutes}}</b>
                                <small i18n> Min</small>&nbsp;
                            </div>
                        </ng-container>
                        <ng-template #noProjectRunning>
                            <small class="color-text-grey"><em>Kein laufendes Projekt</em></small>
                        </ng-template>
                    </div>
                    <div class="tile-action">
                        <ng-container *ngIf="isProjectRunning; else newProject">
                            <button *ngIf="isProjectRunning" mat-raised-button (click)="endProject()" color="primary">
                                <fa-icon [icon]="faStop"></fa-icon>&nbsp;
                                <span i18n>Projekt beenden</span>
                            </button>
                        </ng-container>

                        <ng-template #newProject>
                            <button *ngIf="!isProjectRunning" mat-raised-button (click)="startProject()" color="success">
                                <fa-icon [icon]="faPlay"></fa-icon>&nbsp;
                                <span i18n>Projekt starten</span>
                            </button>
                        </ng-template>
                    </div>
                </mat-card>

                <!-- Work schedule Card -->
                <mat-card class="tile" [ngClass]="{'no-ws-active' : !workSchedule}">
                    <h4 class="mat-h4">Arbeitszeit</h4>
                    <ng-container *ngIf="workSchedule == null">
                        <div class="tile-content">
                            <button mat-raised-button (click)="startWork(account.work_schedule_logging_orgas[0].id, false, account.work_schedule_logging_orgas[0].company)" color="success" *ngIf="account.work_schedule_logging_orgas.length == 1">
                                <fa-icon [icon]="faPlay"></fa-icon>&nbsp;
                                <span i18n>Arbeit starten</span>
                            </button>
                            <button mat-raised-button [matMenuTriggerFor]="menu" color="success" *ngIf="account.work_schedule_logging_orgas.length > 1">
                                <fa-icon [icon]="faPlay"></fa-icon>&nbsp;
                                <span i18n>Arbeit starten</span>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="startWork(orga.id, false, orga.company)" *ngFor="let orga of account.work_schedule_logging_orgas">
                                    {{ orga.title }}
                                </button>
                            </mat-menu>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="workSchedule">
                        <div class="tile-content">
                            <div style="margin:0 0 3px 0;">
                                <span [ngClass]="{'pale': workScheduleBreak}">
                                    <b class="color-text-primary">{{workTime.hours}}</b>
                                    <small i18n> Std</small>&nbsp;
                                    <b class="color-text-primary"> {{workTime.minutes}}</b>
                                    <small i18n> Min</small><br *ngIf="isProjectRunning" />
                                    <small *ngIf="!isProjectRunning">&nbsp;</small>
                                    <em><small i18n>(inkl. Pause)</small></em>
                                </span>
                            </div>
                        </div>


                        <div class="tile-action">
                            <button mat-raised-button *ngIf="!workScheduleBreak" (click)="startBreak()" color="yellow">
                                <fa-icon [icon]="faCoffee"></fa-icon>&nbsp;
                                <span i18n>Pause starten</span>
                            </button>

                            <button mat-raised-button *ngIf="workScheduleBreak" (click)="endBreak()" color="yellow" style="line-height: 1.4; padding: 6px 16px">
                                <div class="flexbox row" style="align-items: center; text-align: left;">
                                    <fa-icon [icon]="faCoffee" style="margin-right: 12px;"></fa-icon>
                                    <div>
                                        <div>
                                            <ng-container *ngIf="breakTime().hours > 0">
                                                <b>{{breakTime().hours}}</b>
                                                <small class="pale" i18n> Std</small>&nbsp;
                                            </ng-container>
                                            <b> {{breakTime().minutes}}</b>
                                            <small class="pale" i18n> Min</small>
                                        </div>
                                        <span i18n>Pause beenden</span>
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div class="tile-action" *ngIf="!workScheduleBreak">
                            <button mat-raised-button (click)="endWork()" color="warn">
                                <fa-icon [icon]="faStop"></fa-icon>&nbsp;
                                <span i18n>Arbeit beenden</span>
                            </button>
                        </div>
                    </ng-container>
                </mat-card>
            </div>
        </section>

        <section class="dispositions-container">
            <h2 class="mat-h2 app-list-heading" i18n>Heute & Morgen</h2>
            <mat-spinner *ngIf="!dispos" style="margin:auto" diameter="40"></mat-spinner>
            <p class="color-text-grey" *ngIf="dispos && dispos.length == 0" i18n>
                Für Heute und Morgen stehen keine Ereignisse an!
            </p>
            <app-disposition-list *ngIf="dispos && dispos.length > 0" [dispositions]="dispos" [relativeWords]="true"></app-disposition-list>
            <button mat-raised-button (click)="router.navigate(['dispositions'])" class="flex spinner-button" color="primary" i18n>
                Komplette Agenda ansehen
            </button>
        </section>
    </ng-container>

    <p class="color-text-grey text-center text-top" style="line-height: 1.4" *ngIf="account && orgas?.length == 0" i18n>
        Du bist noch mit keiner Organisation verbunden oder dein App-Zugang wurde deaktiviert!
        <br /><br />
        Organisationen schicken dir E-Mails zu, in denen du einen Einladungslink findest.<br />
        Über diesen Link kannst du Organisationen mit deinem Account verknüpfen.
    </p>
</main>
