<div>
<p class="text-center color-text-grey" i18n *ngIf="!orgas || orgas.length == 0">Du bist mit keiner Organisation verknüpft</p>
<mat-tab-group mat-stretch-tabs>
	<mat-tab *ngFor="let orga of orgas">
		<ng-template mat-tab-label i18n>
			{{orga.title}}
		</ng-template>
		<ng-template matTabContent>
			<main>
				<mat-card class="content-section">
					<mat-card-content>
						<p i18n>Die Kalender und Agenda Einträge von Lupax können für verschiedene Dienste und Geräte im iCal-Format abonniert werden. Diese Einträge werden dann automatisch mit Ihrem Gerät synchronisiert. Eine Anleitung für die Einrichtung finden Sie weiter unten.</p>
					</mat-card-content>
				</mat-card>

				<h2 i18n class="mat-h2 app-list-heading">Optionen</h2>
				<mat-card class="content-section">
					<mat-card-content>
						<p i18n>
							Hier haben Sie die Möglichkeit auszuwählen welche Einträge in Ihrem abbonierten Kalender angezeigt werden sollen.<br />
							<small>Hinweis: Änderungen an diesen Optionen werden ggf. nicht sofort in Ihrem Kalender sichtbar, da je nach Anbieter Ihres Kalenders das Synchronisierungsintervall bei bis zu 24 Stunden liegt.</small>
						</p>
					</mat-card-content>
				</mat-card>

				<mat-selection-list (selectionChange)="onSelectionUpdate($event, orga.id)">
					<mat-list-option class="mat-elevation-z1" [selected]="!!settings[orga.id].data_event" value="data_event">
						<span i18n>Meine Termine</span>
					</mat-list-option>
					<mat-divider></mat-divider>
					<mat-list-option class="mat-elevation-z1" [selected]="!!settings[orga.id].data_task" value="data_task">
						<span i18n>Meine Aufgaben Deadlines</span>
					</mat-list-option>
					<mat-divider></mat-divider>
					<mat-list-option class="mat-elevation-z1" [selected]="!!settings[orga.id].data_dispo" value="data_dispo">
						<span i18n>Meine Jobbuchungen (Dispositionseinträge)</span>
					</mat-list-option>
					<mat-list-option class="mat-elevation-z1" [selected]="!!settings[orga.id].data_roster" value="data_roster">
						<span i18n>Meine Dienstplaneinträge</span>
					</mat-list-option>
				</mat-selection-list>

				<h2 i18n class="mat-h2 app-list-heading">Anleitung</h2>
				<!-- Google -->
				<mat-card class="content-section">
					<mat-card-content>
						<h3 i18n>Android/Google</h3>
						<p i18n>Für die Einrichtung des iCal Kalenders empfehlen wir einen Desktop-PC zu nutzen, da das Aufrufen der Einstellungen vom Google Kalender auf einem mobilen Gerät schwieriger ist. Sie können diese App auch einfach auf ihrem Desktop-PC im Browser unter: app.lupax.org aufrufen.</p>
						<ul>
							<li>Loggen Sie sich mit Ihrem Google-Konto (z.B. max.muster@googlemail.com) <a class="color-text-primary" href="https://www.google.com/calendar" target="_blank"><strong>hier in den Google-Kalender</strong></a> ein (wechseln Sie ggf. zur "Desktop Version").</li>
							<li>Klicken Sie in der linken Sidebar auf den kleinen Pfeil rechts neben "Weitere Kalender"</li>
							<li>Wählen Sie in dem Menü "Über URL hinzufügen"</li>
							<li>
								Kopieren Sie folgende Internetadresse in das Eingabefeld:<br />
								<div class="url_wrapper">
									<textarea readonly #input>https:{{this.account.ics_urls[orga.id]}}</textarea>
									<button mat-raised-button color="primary"
											(click)="copyUrl(input)"
											value="Url in Zwischenablage kopieren"
											style="flex-shrink: 0">
										Url in Zwischenablage kopieren
									</button>
								</div>
							</li>
							<li>Fertig! (Evtl. müssen Sie in den "Konten- & Synchronisierungs-Einstellungen" bzw. "Kalendereinstellungen" Ihres Android-Smartphones noch den Google-Account und dort speziell den Kalender aktivieren)</li>
						</ul>
						<p i18n>Die Synchronisierung über den Google Dienst erfolgt leider nur einmal am Tag, was bedeutet, dass die Einträge in Ihrem Kalender nicht immer ganz aktuell sind.</p>
					</mat-card-content>
				</mat-card>

				<!-- iPhone -->
				<mat-card class="content-section">
					<mat-card-content>
						<h3 i18n>iPhone</h3>
						<ul>
							<li><span i18n>Rufen Sie mit Ihrem iPhone-Browser folgenden Link auf:</span><br />
								<a class="color-text-primary" [href]="sanitize('webcal:' + account.ics_urls[orga.id])" target="_blank">
									<strong i18n>webcal:{{this.account.ics_urls[orga.id]}}</strong>
								</a><br /><br />
							</li>
							<li i18n>Im Popup auf "Kalender abonnieren" klicken.</li>
							<li i18n>Fertig! (Eventuell müssen Sie in den Einstellungen des Kalenders die Aktualisierungsfrequenz aktivieren oder einstellen)</li>
						</ul>
					</mat-card-content>
				</mat-card>

				<!-- Outlook -->
				<mat-card class="content-section">
					<mat-card-content>
						<h3 i18n>Outlook</h3>
						<ul>
							<li>Sie benötigen Outlook 2007, oder eine neuere Version!</li>
							<li>Klicken Sie in Outlook im Menü Extras auf Kontoeinstellungen</li>
							<li>Klicken Sie auf der Registerkarte Internetkalender auf Neu</li>
							<li>
								Fügen Sie nun folgende Internetadresse dort ein:<br />
								<a class="color-text-primary" [href]="sanitize('webcal:' + account.ics_urls[orga.id])" target="_blank">
									<strong i18n>webcal:{{this.account.ics_urls[orga.id]}}</strong>
								</a><br /><br />
							</li>
							<li>Klicken Sie auf Hinzufügen</li>
							<li>Geben Sie im Feld Ordnername den Namen des Kalenders ein, der in Outlook angezeigt werden soll, und klicken Sie dann auf OK</li>
							<li>Fertig! (Eventuell müssen Sie den neu erstellten Kalender in der linken Spalte bei "Alle Kalenderelemente" noch aktivieren)</li>
						</ul>
					</mat-card-content>
				</mat-card>
			</main>
		</ng-template>
	</mat-tab>
</mat-tab-group>

<main>


</main>
