<ng-container *ngIf="!data || data.length < 1">
	<div class="empty-header mat-elevation-z1"></div>
	<main>
		<mat-card class="content-section">
			<mat-card-content>
				<em>Es wurden keine Einträge gefunden</em>
			</mat-card-content>
		</mat-card>
	</main>
</ng-container>

<mat-tab-group mat-stretch-tabs [selectedIndex]="selected" (selectedIndexChange)="selected = $event">
	<mat-tab *ngFor="let obj of data">
		<ng-template mat-tab-label i18n>
			{{obj.companyTitle}}
		</ng-template>
		<ng-template matTabContent>
			<main>
				<h2 i18n class="mat-h2 app-list-heading">Zukünftige Urlaubsanträge</h2>
				<ng-container *ngIf="!obj.entries || obj.entries.length < 1">
					<mat-card class="content-section">
			        	<mat-card-content>
							<em>Es wurden keine Einträge gefunden</em>
						</mat-card-content>
					</mat-card>
				</ng-container>
				<table mat-table [dataSource]="obj.entries" class="mat-elevation-z1">
					<!-- Status & Type -->
					<ng-container matColumnDef="type">
						<td mat-cell *matCellDef="let element" nowrap>
							 <fa-icon [icon]="statusIcon(element)" [ngClass]="statusColor(element)"></fa-icon>&nbsp;
							<span [ngClass]="statusColor(element)">{{element.type_title}}</span>
						</td>
					</ng-container>

					<!-- Period -->
					<ng-container matColumnDef="period">
						<td mat-cell *matCellDef="let element" nowrap>
							<app-date-span [start]="element.start" [end]="element.end"
								[dateOnly]="element.type != 'free' && element.type != 'not_available'"></app-date-span>
						</td>
					</ng-container>

					<!-- Duration -->
					<ng-container matColumnDef="duration">
						<td mat-cell *matCellDef="let element" nowrap>
							{{element.amount_short}}
						</td>
					</ng-container>

					<tr mat-row (click)="routeRequest(obj.organizationId, row.id)" *matRowDef="let row; columns: columnsToDisplay;"></tr>
				</table>

				<button mat-raised-button (click)="newRequest(obj.organizationId, obj.companyId)" class="flex new_request" color="primary" i18n>
					 <fa-icon [icon]="faPlus"></fa-icon>
					Neuer Antrag
				</button>
			</main>
		</ng-template>
	</mat-tab>
</mat-tab-group>
