import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../auth.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../core/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  // contains form data
  form: FormGroup;
  error: string;
  formSubmitted: boolean;
  private rawToken: string;

  loginAttempts:number = 0;
  showActivateButton:boolean = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private acc: AccountService,
    private snack: MatSnackBar
  ) { }

  ngOnInit() {
    // invite token
    this.rawToken = this.route.snapshot.params['token'];

    if(this.auth.isLoggedIn){
      // redirect if already logged in
      if(this.rawToken) this.applyInvite();
      this.router.navigate(['/']);
    }

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }

  applyInvite(){
    let token = jwtHelper.decodeToken(this.rawToken);

    this.acc.applyInvite(this.rawToken)
      .subscribe(res => {
        this.snack.open('Du bist jetzt mit '+token.org+' verknüpft', '', {duration:5000});
      }, res => {
        if(res.error.slug == 'account-already-linked-with-client')
          this.snack.open('Du bist bereits mit '+token.org+' verknüpft', '', {duration:5000});
        else
          this.snack.open(res.error.text, '', {duration:5000});
      });
  }

  isFieldInvalid(field: string){
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitted)
    )
  }

  onSubmit() {
    this.error = null;
    this.loginAttempts++;

    if(this.form.valid && !this.formSubmitted){
      this.formSubmitted = true;
      this.auth.login(this.form.value.email, this.form.value.password)
      .subscribe(result => {
        if(result == true){
          // login successful
          if(this.rawToken) this.applyInvite();
          this.router.navigate(['/']);
        }
        this.formSubmitted = false;
      }, res => {
        if(res.error.slug == 'account-not-yet-activated') this.showActivateButton = true;
        this.error = res.error.text;
        this.formSubmitted = false;
      });
    }
  }
}
