<main>
  <mat-nav-list>
    <app-settings-nav-item routerLink="/settings/account" i18n>Account</app-settings-nav-item>
    <mat-divider></mat-divider>
    <app-settings-nav-item routerLink="/settings/notifications" i18n>Benachrichtigungen</app-settings-nav-item>
  </mat-nav-list>

  <mat-nav-list>
    <app-settings-nav-item routerLink="/settings/calendar-subscribe" i18n>Kalender abonnieren (iCal)</app-settings-nav-item>
  </mat-nav-list>

  <mat-nav-list>
    <mat-list-item (click)="reloadApp()" class="mat-elevation-z1" i18n>App neu laden</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item (click)="cleanCache()" class="mat-elevation-z1" i18n>Cache säubern ({{ localStorageSize }})</mat-list-item>
  </mat-nav-list>
</main>
