import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../core/services/account.service';
import { MatSnackBar } from '@angular/material';
import { NotificationService } from '../../core/services/notification.service';
import { SwPush } from '@angular/service-worker';

@Component({
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['../settings.component.css']
})
export class NotificationsSettingsComponent implements OnInit {

  settings;
  pushEnabled;

  constructor(
    private as: AccountService,
    private snackbar: MatSnackBar,
    private swPush: SwPush,
    private ns: NotificationService,
  ) { }

  ngOnInit() {
    this.pushEnabled = this.ns.isSubscribed;
    this.as.getCurrentAccount().subscribe(res => {
      if(res && res.settings){
        this.settings = res.settings;
      }
    });
  }

  onSelectionUpdate(e){
    let payload = {};
    payload[e.option.value] = e.option._selected;

    this.as.updateSettings(payload).subscribe(null, res => {
      this.settings[e.option.value] = !e.option._selected;
      this.snackbar.open('Einstellung konnte nicht geändert werden', '', {duration:3000})
    });
  }

  onPushChange(e){
    if(e.source.checked){
      if(this.ns.isSubscribed){
        //already subscribed
        this.pushEnabled = true;
        return;
      }
      
      this.swPush.requestSubscription({
        serverPublicKey: this.ns.getVAPID
      }).then(sub => {
        // subscribe
        this.ns.subscribe(sub).subscribe(res => {
          if(res){
            //enabled
            this.pushEnabled = true;
          }
          else {
            e.source.checked = false;
            this.snackbar.open('Push-Benachrichtigungen konnten nicht aktiviert werden', '', {duration:3000})
          }
        }, res => {
          e.source.checked = false;
          this.snackbar.open('Push-Benachrichtigungen konnten nicht aktiviert werden', '', {duration:3000})
        });
      })
      .catch(err => {
        e.source.checked = false;
        this.snackbar.open('Push-Benachrichtigungen konnten nicht aktiviert werden', '', {duration:3000})
      })
    }
    else{
      if(!this.ns.isSubscribed){
        //no subscription to unsubsribe
        this.pushEnabled = false;
        return;
      }

      //unsubscribe
      this.ns.unsubscribe().subscribe(res => {
        //unsubscribed
        this.pushEnabled = false;
      });
    }
  }

  onMailChange(){
    let payload = {
      email_notifications: this.settings.email_notifications,
      mission_change_mail: this.settings.email_notifications,
      mission_offer_mail: this.settings.email_notifications,
      mission_reminder_mail: this.settings.email_notifications,
      dispo_daily_mail: this.settings.email_notifications
    };
    
    this.as.updateSettings(payload).subscribe(res => {
      //update settings on success
      this.settings.mission_change_mail = this.settings.email_notifications;
      this.settings.mission_offer_mail = this.settings.email_notifications;
      this.settings.mission_reminder_mail = this.settings.email_notifications;
      this.settings.dispo_daily_mail = this.settings.email_notifications;
    }, res => {
      // reset on failure
      this.settings.email_notifications = !this.settings.email_notifications;
      this.snackbar.open('Einstellungen konnten nicht geändert werden', '', {duration:3000})
    });
  }
}
