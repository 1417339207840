<mat-dialog-content>
	<h3 class="or_fg">Verfügbarkeitsmodus</h3>
	<p i18n>Der Verfügbarkeitsmodus kann über das Icon <fa-icon [icon]="faCalendarCheck" class="color-text-primary"></fa-icon> oben rechts im Kalender aktiviert und deaktiviert werden.</p>

	<h3 class="or_fg">Verfügbarkeiten definieren</h3>
	<p i18n>Während der <strong>Verfügbarkeitsmodus</strong> aktiv ist, kann durch das Tippen auf einen Tag die Verfügbarkeit verändert werden:</p>
	<ol>
		<li i18n>Tippen: <strong class="gr_fg">Verfügbar (grün)</strong></li>
		<li i18n>Tippen: <strong class="re_fg">Nicht Verfügbar (rot)</strong></li>
		<li i18n>Tippen: Keine Angabe</li>
	</ol>
	<p i18n>Außerdem kann ein einzelner Verfügbarkeits-Eintrag über das <fa-icon [icon]="faPlusCircle" class="or_fg"></fa-icon> Icon im Verfügbarkeitsmodus erstellt werden.</p>

	<h3 class="or_fg">Uhrzeiten</h3>
	<p i18n>Standardmäßig gilt der Verfügbarkeits-Eintrag immer für einen ganzen Tag. Wenn ein neuer Eintrag über das <fa-icon [icon]="faPlusCircle" class="or_fg"></fa-icon> Icon im Verfügbarkeitsmodus erstellt wird, kann die genaue Uhrzeit direkt mit angegeben werden.</p>
	<p i18n>Um die Uhrzeiten bei bereits erstellten Einträgen zu ändern, muss zunächst der Verfügbarkeitsmodus beendet werden. Danach kann durch einen Tap auf den entsprechenden Tag die Tagesansicht aufgerufen werden. Tippt man nun den Verfügbarkeitseintrag an, kann dieser verändert werden (inklusive der Uhrzeit).</p>
</mat-dialog-content>
<mat-dialog-actions style="justify-content:flex-end">
  <button mat-button mat-dialog-close class="light_bg">
    <ng-container i18n>Schließen</ng-container>
  </button>
</mat-dialog-actions>
