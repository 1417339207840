var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var JobTimesheetService = /** @class */ (function (_super) {
    __extends(JobTimesheetService, _super);
    function JobTimesheetService(http) {
        var _this = _super.call(this, http, 'job-timesheets') || this;
        _this.http = http;
        return _this;
    }
    JobTimesheetService.prototype.getByOrganizationAndId = function (orga, id) {
        // get cached timesheet
        var data = this.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/timesheets/' + id);
        return this.cachedRequest(data, http);
    };
    JobTimesheetService.prototype.create = function (orga, job, data) {
        console.log('create timesheet', data);
        return this.cachedPost("/v1/organizations/" + orga + "/jobs/" + job + "/timesheet", data);
    };
    JobTimesheetService.prototype.delete = function (orga, id) {
        console.log('delete timesheet', id);
        return this.http.delete("/v1/organizations/" + orga + "/timesheets/" + id).pipe(tap(function (res) { return res; }));
    };
    JobTimesheetService.ngInjectableDef = i0.defineInjectable({ factory: function JobTimesheetService_Factory() { return new JobTimesheetService(i0.inject(i1.HttpClient)); }, token: JobTimesheetService, providedIn: "root" });
    return JobTimesheetService;
}(CachableModelService));
export { JobTimesheetService };
