var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { formatDuration } from 'src/app/core/utils/number-utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { WorkService } from '../shared/work.service';
import { AccountService } from '../../core/services/account.service';
import { MatDialog } from '@angular/material';
import { WorkScheduleDialogComponent, DialogType, defaultDialogOptions } from '../../work-schedules/work-schedule-dialog/work-schedule-dialog.component';
import { faChevronLeft, faChevronRight, faHistory, faHandPointUp } from '@fortawesome/free-solid-svg-icons';
;
var WorkScheduleListComponent = /** @class */ (function (_super) {
    __extends(WorkScheduleListComponent, _super);
    function WorkScheduleListComponent(ws, activatedRoute, router, scrollLocations, as, dialog) {
        var _this = _super.call(this, router, scrollLocations, activatedRoute) || this;
        _this.ws = ws;
        _this.activatedRoute = activatedRoute;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.as = as;
        _this.dialog = dialog;
        _this.faChevronRight = faChevronRight;
        _this.faChevronLeft = faChevronLeft;
        _this.faHistory = faHistory;
        _this.faHandPointUp = faHandPointUp;
        _this.selected = 0;
        _this.month = new Date();
        _this.current_month = true;
        _this.data = [];
        _this.columnsToDisplay = ['weekday', 'date', 'time', 'break', 'comment', 'duration'];
        _this.formatDuration = formatDuration;
        return _this;
    }
    WorkScheduleListComponent.prototype.init = function () {
        var _this = this;
        if (this.activatedRoute.snapshot.queryParams.month) {
            this.month = new Date(parseInt(this.activatedRoute.snapshot.queryParams.month));
            // Check for current month
            if (this.month.getFullYear() == (new Date()).getFullYear() && this.month.getMonth() == (new Date()).getMonth())
                this.current_month = true;
            else
                this.current_month = false;
        }
        this.as.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(function (res) {
            if (!res)
                return;
            _this.account = res;
            // Default companies
            _this.account.work_schedule_logging_orgas.forEach(function (orga) {
                if (!_this.data.find(function (entry) { return entry.orga_id == orga.id && entry.company.id == orga.company; })) {
                    _this.data.push({
                        company: {
                            id: orga.company,
                            title: orga.title
                        },
                        orga_id: orga.id,
                        weeks: [],
                        work_sum: 0
                    });
                }
            });
            console.log('get entries');
            _this.getEntries();
        });
    };
    WorkScheduleListComponent.prototype.reload = function () {
        this.as.getCurrentAccount();
    };
    WorkScheduleListComponent.prototype.getEntries = function () {
        var _this = this;
        console.log('get entries triggered');
        var year = this.month.getFullYear();
        var month = this.month.getMonth() + 1;
        this.ws.getAllByMonth(year, month).pipe(untilDestroyed(this)).subscribe(function (data) {
            // reset all weeks data
            for (var entry_key in _this.data) {
                _this.data[entry_key].weeks = [];
                _this.data[entry_key].work_sum = 0;
            }
            if (!data)
                return _this.data;
            var date_str = year + "-" + (month.toString().padStart(2, '0')) + "-01";
            var data_month = data.find(function (element) { return element.month == date_str; });
            if (!data_month)
                return _this.data;
            console.log(data_month);
            var _loop_1 = function (orga_id) {
                if (data_month.orgas[orga_id].weeks && typeof data_month.orgas[orga_id].weeks == 'object')
                    data_month.orgas[orga_id].weeks = Object.values(data_month.orgas[orga_id].weeks);
                // If Orga already exists, just update entries
                var orga_index = _this.data.findIndex(function (element) { return element.orga_id == parseInt(orga_id) && element.company.id == data_month.orgas[orga_id].company.id; });
                if (orga_index > -1) {
                    _this.data[orga_index]['weeks'] = data_month.orgas[orga_id].weeks;
                    _this.data[orga_index]['work_sum'] = data_month.orgas[orga_id].work_sum;
                    // Else add orga
                }
                else {
                    _this.data.push(data_month.orgas[orga_id]);
                }
            };
            // Make "weeks" object to array
            for (var orga_id in data_month.orgas) {
                _loop_1(orga_id);
            }
        });
    };
    WorkScheduleListComponent.prototype.monthChange = function (type) {
        if (type == 'prev') {
            var date = new Date(this.month.getTime());
            date.setDate(1);
            date.setMonth(date.getMonth() - 1);
            this.month = date;
            this.current_month = false;
        }
        else {
            var date = new Date(this.month.getTime());
            date.setDate(1);
            date.setMonth(date.getMonth() + 1);
            this.month = date;
            // Check for current month
            if (this.month.getFullYear() == (new Date()).getFullYear() && this.month.getMonth() == (new Date()).getMonth())
                this.current_month = true;
            else
                this.current_month = false;
        }
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { month: this.month.getTime() }
        });
        this.getEntries();
    };
    WorkScheduleListComponent.prototype.isPastLoggingAllowed = function (orga_id) {
        return this.account && this.account.work_schedule_logging_past_orgas && this.account.work_schedule_logging_past_orgas.filter(function (o) { return o.id == orga_id; }).length;
    };
    WorkScheduleListComponent.prototype.logWork = function (orga, company) {
        var _this = this;
        if (company === void 0) { company = null; }
        this.dialog.open(WorkScheduleDialogComponent, __assign({}, defaultDialogOptions, { data: {
                type: DialogType.StartWork,
                past: true,
                organization: orga,
                company: company
            } })).afterClosed().subscribe(function (workSchedule) {
            if (!workSchedule)
                return;
            _this.router.navigateByUrl("/work-schedule/" + workSchedule.organization + "/" + workSchedule.id);
        });
    };
    return WorkScheduleListComponent;
}(ComponentInit));
export { WorkScheduleListComponent };
