var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProjectService = /** @class */ (function (_super) {
    __extends(ProjectService, _super);
    function ProjectService(http) {
        var _this = _super.call(this, http, 'projects') || this;
        _this.http = http;
        _this.filterOptions = new BehaviorSubject(null);
        _this.listCacheModel = new CachableModelService(http, 'projectsList');
        return _this;
    }
    /**
     * Creates params from ListOptions
     * @param options
     */
    ProjectService.prototype.getParamsFromOptions = function (options) {
        var params = __assign({}, options);
        if (options.date_from) {
            if (params.date_from instanceof Date)
                params.date_from.setHours(0, 0, 0, 0);
            params.date_from = this.dateToString(options.date_from);
        }
        if (options.date_to) {
            if (params.date_to instanceof Date)
                params.date_to.setHours(23, 59, 59);
            params.date_to = this.dateToString(options.date_to);
        }
        return params;
    };
    /**
     * Retrieves projects of an organization.
     * Does not cache yet!
     * @param orgaId
     * @param options
     */
    ProjectService.prototype.getAllByOrganization = function (orgaId, options) {
        return this.http.get("/v1/organizations/" + orgaId + "/projects", { params: this.getParamsFromOptions(options) });
    };
    ProjectService.prototype.getAllForCurrentAccount = function (options) {
        var params = this.getParamsFromOptions(options);
        // get cached data
        var data = this.listCacheModel.getAll();
        var http = this.http.get('/v1/projects', { params: params });
        return this.listCacheModel.cachedRequest(data, http);
    };
    ProjectService.prototype.getAllByOrganizationAndStaffAndDate = function (orga_id, staff_id, date) {
        var date_str = this.dateToString(date, 'yyyy-MM-dd');
        return this.http.get("/v1/organizations/" + orga_id + "/staffs/" + staff_id + "/projects/date/" + date_str);
    };
    ProjectService.prototype.getAllByOrganizationAndStaffAndTitle = function (orga_id, staff_id, title) {
        title = encodeURIComponent(title);
        return this.http.get("/v1/organizations/" + orga_id + "/staffs/" + staff_id + "/projects/title/" + title);
    };
    ProjectService.prototype.getByOrganizationAndId = function (orga, id) {
        // Get Data
        var data = this.findModelByOrganizationAndId(orga, id);
        // If we found nothing -> get data from project list
        if (!data)
            data = this.listCacheModel.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/projects/' + id);
        return this.cachedRequest(data, http);
    };
    ProjectService.prototype.getFilterOptionsForCurrentAccount = function () {
        var _this = this;
        this.http.get('/v1/projects/filterOptions')
            .subscribe(function (res) {
            _this.filterOptions.next(res);
        });
        return this.filterOptions.asObservable();
    };
    ProjectService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.inject(i1.HttpClient)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}(CachableModelService));
export { ProjectService };
