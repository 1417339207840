/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mission-process-confirmations.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../mission-process-list/mission-process-list.component.ngfactory";
import * as i3 from "../mission-process-list/mission-process-list.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../shared/loading-spinner/loading-spinner.component.ngfactory";
import * as i6 from "../../../shared/loading-spinner/loading-spinner.component";
import * as i7 from "@angular/common";
import * as i8 from "./mission-process-confirmations.component";
import * as i9 from "../../shared/mission-process.service";
import * as i10 from "../../../core/globals/scroll-locations";
var styles_MissionProcessConfirmationsComponent = [i0.styles];
var RenderType_MissionProcessConfirmationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MissionProcessConfirmationsComponent, data: {} });
export { RenderType_MissionProcessConfirmationsComponent as RenderType_MissionProcessConfirmationsComponent };
function View_MissionProcessConfirmationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-top color-text-grey text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Keine Eintr\u00E4ge vorhanden "]))], null, null); }
function View_MissionProcessConfirmationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "mat-h2 app-list-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Anstehend"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-mission-process-list", [], null, null, null, i2.View_MissionProcessListComponent_0, i2.RenderType_MissionProcessListComponent)), i1.ɵdid(4, 49152, null, 0, i3.MissionProcessListComponent, [i4.Router], { missionProcesses: [0, "missionProcesses"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pending; _ck(_v, 4, 0, currVal_0); }, null); }
function View_MissionProcessConfirmationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "mat-h2 app-list-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vergangen"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-mission-process-list", [], null, null, null, i2.View_MissionProcessListComponent_0, i2.RenderType_MissionProcessListComponent)), i1.ɵdid(4, 49152, null, 0, i3.MissionProcessListComponent, [i4.Router], { missionProcesses: [0, "missionProcesses"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.past; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_MissionProcessConfirmationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i5.View_LoadingSpinnerComponent_0, i5.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i6.LoadingSpinnerComponent, [], { loading: [0, "loading"], loadError: [1, "loadError"], retry: [2, "retry"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MissionProcessConfirmationsComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MissionProcessConfirmationsComponent_2)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MissionProcessConfirmationsComponent_3)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.all; var currVal_1 = _co.loadError; var currVal_2 = _co.reload; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.all && (_co.all.length == 0)); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.pending && (_co.pending.length > 0)); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.past && (_co.past.length > 0)); _ck(_v, 8, 0, currVal_5); }, null); }
export function View_MissionProcessConfirmationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mission-process-confirmations", [], null, null, null, View_MissionProcessConfirmationsComponent_0, RenderType_MissionProcessConfirmationsComponent)), i1.ɵdid(1, 4440064, null, 0, i8.MissionProcessConfirmationsComponent, [i4.ActivatedRoute, i4.Router, i9.MissionProcessService, i10.ScrollLocations], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MissionProcessConfirmationsComponentNgFactory = i1.ɵccf("app-mission-process-confirmations", i8.MissionProcessConfirmationsComponent, View_MissionProcessConfirmationsComponent_Host_0, {}, {}, []);
export { MissionProcessConfirmationsComponentNgFactory as MissionProcessConfirmationsComponentNgFactory };
