import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MissionProcess } from './mission-process.model';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MissionProcessService extends CachableModelService<MissionProcess>{

	proposalCacheModel: CachableModelService<MissionProcess>;
	confirmationCacheModel: CachableModelService<MissionProcess>;

	constructor(
		protected http: HttpClient,
	){
		super(http, 'missionProcesses');

		this.confirmationCacheModel = new CachableModelService(http, 'missionProcessesConfirmations');
		this.proposalCacheModel = new CachableModelService(http, 'missionProcessesProposals');
	}

	/**
	 * Returns mission processes for current account
	 * @param from date from
	 * @param to date to
	 */
	getAllConfirmationsForCurrentAccount(from:Date = null, to:Date = null){
		// set params
		let params = {
			state: 'confirmation,cancel'
		};
		if(from) params['date_from'] = this.dateToString(from);
		if(to) params['date_to'] = this.dateToString(to);

		// get cached data
		let data = this.confirmationCacheModel.getAll();

		// make request
		let http = this.http.get<MissionProcess[]>('/v1/missions/processes', {params:params});
		return this.confirmationCacheModel.cachedRequest(data, http);
	}

	/**
	 * Returns mission processes for current account
	 * @param from date from
	 * @param to date to
	 */
	getAllProposalsForCurrentAccount(from:Date = null, to:Date = null){
		// set params
		let params = {
			state: 'proposal,offer'
		};
		if(from) params['date_from'] = this.dateToString(from);
		if(to) params['date_to'] = this.dateToString(to);

		// get cached data
		let data = this.proposalCacheModel.getAll();

		// make request
		let http = this.http.get<MissionProcess[]>('/v1/missions/processes', {params:params});
		return this.proposalCacheModel.cachedRequest(data, http);
	}

	getByOrganizationAndId(orga, id){
		// Get Data
		let data = this.findModelByOrganizationAndId(orga, id);

		// If we found nothing, try to get data from proposals or confirmations
		// Can not use this anymore, because the data differs to much from single to list entry
		/* if(!data) data = this.proposalCacheModel.findModelByOrganizationAndId(orga, id);
		if(!data) data = this.confirmationCacheModel.findModelByOrganizationAndId(orga, id); */

		let http = this.http.get<MissionProcess>('/v1/organizations/'+orga+'/missions/processes/'+id)
		return this.cachedRequest(data, http);
	}

	accept(mp:MissionProcess):Observable<any>{
		let payload = {
			bundle: !!mp.entries,
			state: mp.state,
		};

		return this.http.post('/v1/organizations/'+mp.organization+'/missions/processes/'+mp.id+'/accept', payload)
			.pipe(tap(res => this.updateModel(res.mission_process)));
	}

	decline(mp:MissionProcess):Observable<any>{
		let payload = {
			bundle: !!mp.entries,
			state: mp.state,
		};

		return this.http.post('/v1/organizations/'+mp.organization+'/missions/processes/'+mp.id+'/decline', payload)
			.pipe(tap(res => this.updateModel(res.mission_process)));
	}

	save(mp:MissionProcess, answers):Observable<any>{
		let payload = {
			answers: answers,
			bundle: !!mp.entries,
			state: mp.state,
		};

		return this.http.post('/v1/organizations/'+mp.organization+'/missions/processes/'+mp.id+'/save', payload)
			.pipe(tap(res => this.updateModel(res.mission_process)));
	}
}
