import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { MissionProcess } from './mission-process.model';
import { MissionProcessService } from './mission-process.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class MissionProcessResolver extends CachableClientModelResolver<MissionProcess>{

	constructor(s:MissionProcessService, r:Router) {
		super(s, 'missions', r);
	}
}
