<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>Filter</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
	<div mat-dialog-content>
		<ng-container *ngFor="let key of inputKeys(inputs); last as isLast">
			<mat-form-field
				*ngIf="inputs[key].selectable"
				[ngSwitch]="inputs[key].type"
			>
				<!-- Date -->
				<input
					*ngSwitchCase="filterType.DATE"
					matInput
					type="date"
					placeholder="{{inputs[key].label}}"
					i18n-placeholder
					formControlName="{{key}}"
					pattern="\d{4}-\d{2}-\d{2}"
					required
				>

				<!-- Select -->
				<mat-select
					*ngSwitchCase="filterType.SELECT"
					placeholder="{{inputs[key].label}}"
					i18n-placeholder
					formControlName="{{key}}"
					(selectionChange)="onValueChange(key, $event.value)"
				>
					<mat-option *ngFor="let entry of inputs[key].options" [value]="entry.value">
						{{entry.label}}
					</mat-option>
				</mat-select>


				<!-- Grouped Select -->
				<mat-select
					*ngSwitchCase="filterType.GROUPED_SELECT"
					placeholder="{{inputs[key].label}}"
					i18n-placeholder
					formControlName="{{key}}"
					(selectionChange)="onValueChange(key, $event.value)"
				>
					<ng-container *ngFor="let option of inputs[key].options">
						<mat-option *ngIf="option.value !== undefined" [value]="option.value">
							{{option.label}}
						</mat-option>
						<mat-optgroup *ngIf="option.options" [label]="option.label">
							<mat-option *ngFor="let entry of option.options" [value]="entry.value">
								{{entry.label}}
							</mat-option>
						</mat-optgroup>
					</ng-container>
				</mat-select>

				<!-- Number -->
				<input
					*ngSwitchCase="filterType.NUMBER"
					matInput
					type="number"
					placeholder="{{inputs[key].label}}"
					i18n-placeholder
					formControlName="{{key}}"
				>

				<!-- Text -->
				<input
					*ngSwitchCase="filterType.TEXT"
					matInput
					type="text"
					placeholder="{{inputs[key].label}}"
					i18n-placeholder
					formControlName="{{key}}"
				>
			</mat-form-field>
		</ng-container>
	</div>
	<mat-dialog-actions>
		<button mat-button i18n type="button" (click)="dialog.close()">
			Abbrechen
		</button>
		<button mat-raised-button color="primary" i18n type="submit">
			Anwenden
		</button>
	</mat-dialog-actions>
</form>
