var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { JobTimesheetService } from '../shared/job-timesheet.service';
import { OrganizationService } from '../../core/services/organization.service';
import { ComponentInit } from '../../core/classes/component-init.class';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { faCheck, faUndo, faFlag, faCalendarAlt, faMale, faSignature, faInfo } from '@fortawesome/free-solid-svg-icons';
var JobTimesheetComponent = /** @class */ (function (_super) {
    __extends(JobTimesheetComponent, _super);
    function JobTimesheetComponent(dialog, route, jobTimesheetService, orgaService, router, scrollLocations, locale) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.dialog = dialog;
        _this.route = route;
        _this.jobTimesheetService = jobTimesheetService;
        _this.orgaService = orgaService;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.locale = locale;
        _this.faCheck = faCheck;
        _this.faUndo = faUndo;
        _this.faFlag = faFlag;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faMale = faMale;
        _this.faSignature = faSignature;
        _this.faInfo = faInfo;
        _this.staffsGrouped = [];
        _this.columnsToDisplay = ['name', 'start', 'end', 'break', 'duration'];
        _this.showSignaturePad = false;
        _this.submitting = false;
        return _this;
    }
    JobTimesheetComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (timesheet) {
                if (timesheet)
                    _this.update(timesheet);
            });
        });
        this.orgaService.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (res && _this.timesheet)
                _this.orga = res.find(function (o) { return o.id == _this.timesheet.organization; });
        });
    };
    JobTimesheetComponent.prototype.update = function (timesheet) {
        this.timesheet = timesheet;
        // set staffs
        this.staffsGrouped = this.getStaffsOfTimesheetGrouped();
        console.log(this.staffsGrouped);
    };
    // buildForm(){
    //     this.form = this.fb.group({
    //         staffs: this.fb.group([]),
    //         note: [this.job.timesheet ? this.job.timesheet.note : (this.formValuesCached ? this.formValuesCached['note'] : '')],
    //         // staffs2: this.fb.group({}),
    //         signature: ['', Validators.required],
    //         legitimate: [this.job.timesheet ? this.job.timesheet.legitimate : (this.formValuesCached ? this.formValuesCached['legitimate'] : '')],
    //         sign_comment: [this.job.timesheet ? this.job.timesheet.sign_comment : (this.formValuesCached ? this.formValuesCached['sign_comment'] : '')],
    //     })
    //
    //     // let form_staffs = this.form.get('staffs') as FormArray;
    //     // let form_staffs2 = this.form.get('staffs2') as FormGroup;
    //     for(let group of this.staffs){
    //         for(let staff of group.staffs){
    //             (this.form.get('staffs') as FormGroup).addControl(staff.id, this.fb.group({
    //                 ...staff, ...{
    //                 start: [this.getTimeForForm('start', staff.id), Validators.required],
    //                 break: [this.getTimeForForm('break', staff.id), Validators.required],
    //                 end: [this.getTimeForForm('end', staff.id), Validators.required],
    //                 qualification: group.qualification
    //             }}))
    //         }
    //     }
    // }
    JobTimesheetComponent.prototype.getStaffsOfTimesheetGrouped = function () {
        var groups = [];
        var _loop_1 = function (staff_id) {
            var staff = this_1.timesheet.staffs[staff_id];
            var index = groups.findIndex(function (g) { return g.qualification.id == staff.qualification.id; });
            if (index < 0) {
                groups.push({
                    qualification: staff.qualification,
                    staffs: [staff]
                });
            }
            else {
                groups[index].staffs.push(staff);
            }
        };
        var this_1 = this;
        for (var staff_id in this.timesheet.staffs) {
            _loop_1(staff_id);
        }
        return groups;
    };
    JobTimesheetComponent.prototype.getStaffCount = function (staffs) {
        var count = staffs.reduce(function (c, s) { return c + (s.custom_amount || 1); }, 0);
        return count;
    };
    JobTimesheetComponent.prototype.staffDuration = function (staff_id) {
        var staff = this.timesheet.staffs[staff_id];
        var start_minutes = this.hmToMinutes(staff.start);
        var end_minutes = this.hmToMinutes(staff.end);
        if (start_minutes > end_minutes)
            end_minutes += 60 * 24;
        // let minutes = this.hmToMinutes(end) - ;
        return (end_minutes - start_minutes - staff.break) / 60;
        // let start = this.form.controls.staffs2.controls[staff_id];
    };
    JobTimesheetComponent.prototype.qualificationDuration = function (quali_id) {
        var group = this.staffsGrouped.find(function (g) { return g.qualification.id == quali_id; });
        var sum = 0;
        for (var _i = 0, _a = group.staffs; _i < _a.length; _i++) {
            var staff = _a[_i];
            sum += (this.staffDuration(staff.id) * (staff.custom_amount || 1));
        }
        return sum;
    };
    JobTimesheetComponent.prototype.hmToMinutes = function (hm) {
        if (!hm)
            return 0;
        var hm_arr = hm.split(':');
        return (+hm_arr[0]) * 60 + (+hm_arr[1]);
    };
    JobTimesheetComponent.prototype.numberToLocaleString = function (number) {
        return number.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    };
    JobTimesheetComponent.prototype.deleteTimesheet = function () {
        var _this = this;
        this.dialog.open(ConfirmDialogComponent, { data: {
                text: 'Möchten Sie wirklich diesen ' + (this.orga && this.orga.timesheet_wording ? this.orga.timesheet_wording.singular : 'Arbeitsschein') + ' löschen? Die Unterschrift geht damit verloren, wiederherstellen nicht möglich!',
                okTitle: 'Löschen',
                okColor: 'warn'
            } }).afterClosed().subscribe(function (result) {
            if (!result)
                return;
            _this.submitting = true;
            _this.jobTimesheetService.delete(_this.timesheet.organization, _this.timesheet.id).subscribe(function (res) {
                console.log(res);
                _this.submitting = false;
                console.log(_this.timesheet.job);
                _this.router.navigate(['/jobs/' + _this.timesheet.organization + '/' + _this.timesheet.job.id], { queryParams: { tab: 2 } });
                // this.update(res);
            }, function (err) {
                _this.submitting = false;
                _this.dialog.open(OkDialogComponent, { data: err.error.text });
                console.log('error', err);
            });
        });
    };
    return JobTimesheetComponent;
}(ComponentInit));
export { JobTimesheetComponent };
