<main>
    <app-loading-spinner [loading]="!all" [loadError]="loadError" [retry]="reload"></app-loading-spinner>
    <p class="text-top color-text-grey text-center" *ngIf="all && all.length == 0" i18n>
      Keine Einträge vorhanden
    </p>
    <ng-container *ngIf="pending && pending.length > 0">
      <h2 class="mat-h2 app-list-heading" i18n>Anstehend</h2>
      <app-mission-process-list [missionProcesses]="pending"></app-mission-process-list>
    </ng-container>
    <ng-container *ngIf="past && past.length > 0">
      <h2 class="mat-h2 app-list-heading" i18n>Vergangen</h2>
      <app-mission-process-list [missionProcesses]="past"></app-mission-process-list>
    </ng-container>
  </main>
