import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService{

  private title: BehaviorSubject<string>;

  constructor(
    private titleService: Title
  ){
    this.title = new BehaviorSubject<string>(this.titleService.getTitle());
  }

  /**
   * returns app title as string
   */
  getTitle(){
    //this.title.next(this.titleService.getTitle());
    return this.title.getValue();
  }

  /**
   * sets new app title
   * @param newTitle 
   */
  setTitle(newTitle: string){
    //this.titleService.setTitle(newTitle);
    this.title.next(newTitle);
  }

  /**
   * returns app title as observable
   */
  getTitleAsObservable(){
    return this.title.asObservable();
  }
}
