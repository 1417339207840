import { Component } from '@angular/core';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-settings-nav-item',
  templateUrl: './settings-nav-item.component.html',
  styleUrls: ['./settings-nav-item.component.css']
})
export class SettingsNavItemComponent {

  routerLink;
  faChevronRight = faChevronRight;

  constructor() { }

}
