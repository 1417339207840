import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthenticateDialogComponent } from '../../shared/authenticate-dialog/authenticate-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(
    private dialog: MatDialog,
  ) { }

  /**
   * Opens a dialog where the user has to confirm their identity
   */
  confirmIdentity(){
    return this.dialog.open(AuthenticateDialogComponent).afterClosed();
  }
}
