import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VacationRequest } from './vacation-request.model';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';

import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class VacationRequestService extends CachableModelService<VacationRequest>{

    constructor(
        protected http: HttpClient,
        @Inject(LOCALE_ID) protected locale: string,
    ) {
        super(http, 'vacationRequests');


    }

    /**
     * Returns all vacation requests groupe by organization
     */
     getVacationRequests():Observable<any[]>{
         // get cached data
         let data = this.models;
         let http = this.http.get<any[]>('/v1/vacation-requests');

         return this.cachedRequest(data, http);
     }

    getByOrganizationAndId(orga, id):Observable<VacationRequest>{
        // Get Data
        let data = this.findModelByOrganizationAndId(orga, id);

        let http = this.http.get<VacationRequest>('/v1/organizations/'+orga+'/vacation-request/'+id);
        return this.cachedRequest(data, http);
    }


    getInfosInPeriodOfType(orga_id, date_from, date_to, type, company_id){
        return this.http.get('/v1/organizations/'+orga_id+'/vacation-requests/info', {
            params: {
                date_from: date_from,
                date_to: date_to,
                type: type,
                company: company_id
            }
        });
    }

    create(orga_id, payload){
        return this.http.post<VacationRequest>('/v1/organizations/'+orga_id+'/vacation-requests', payload).pipe(
            tap(res => { return res; })
        )
    }

    delete(orga_id, entry_id){
        return this.http.delete('/v1/organizations/'+orga_id+'/vacation-request/'+entry_id).pipe(
            tap(res => { return res; })
        )
    }
}
