var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
/* import * as Sentry from '@sentry/angular'
import { RewriteFrames } from '@sentry/integrations' */
// locale registration
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);
// Fixes Scroll Issue on with calendar views (need to overwrite hammerjs)
import { HammerGestureConfig } from '@angular/platform-browser';
var MyHammerConfig = /** @class */ (function (_super) {
    __extends(MyHammerConfig, _super);
    function MyHammerConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyHammerConfig.prototype.buildHammer = function (element) {
        var mc = new Hammer(element, {
            touchAction: "pan"
        });
        // let mc = new Hammer(element);
        return mc;
    };
    return MyHammerConfig;
}(HammerGestureConfig));
export { MyHammerConfig };
// Disable Ripple Effect
import { RippleGlobalOptions } from '@angular/material/core';
var globalRippleConfig = {
    disabled: true
};
// Set Default Options for Dialog
import { MatDialogConfig } from '@angular/material';
var globalMatDialogConfig = __assign({}, new MatDialogConfig(), { maxWidth: '90vw' });
var ɵ0 = adapterFactory, ɵ1 = globalMatDialogConfig, ɵ2 = globalRippleConfig;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
