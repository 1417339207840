import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';

export class CustomDateFormatter extends CalendarDateFormatter {
	// you can override any of the methods defined in the parent class

	public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
	  return formatDate(date, 'EE', locale);
	}

	public monthViewTitle({ date, locale }: DateFormatterParams): string {
	  return formatDate(date, 'MMMM y', locale);
	}

	public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'dd', locale) + '\n' + formatDate(date, 'EEEEEE', locale).slice(0,-1);
  }

	public weekViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'MMMM y (\'W. \'w)', locale);
	}

	public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'shortTime', locale);
  }

	public dayViewTitle({ date, locale }: DateFormatterParams): string {
	  return formatDate(date, 'fullDate', locale);
	}

	public dayViewHour({ date, locale }: DateFormatterParams): string {
	  return formatDate(date, 'shortTime', locale);
	}
}
