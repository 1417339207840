import { NotificationService } from './notification.service';
import { OrganizationService } from './organization.service';
import { DispositionService } from '../../dispositions/shared/disposition.service';
import { JobService } from '../../jobs/shared/job.service';
import { MissionProcessService } from '../../missions/shared/mission-process.service';
import { TourService } from '../../tours/shared/tour.service';
import { EventService } from '../../events/shared/event.service';
import { FileService } from './file.service';
import { AccountService } from './account.service';
import * as i0 from "@angular/core";
import * as i1 from "./notification.service";
import * as i2 from "./organization.service";
import * as i3 from "../../dispositions/shared/disposition.service";
import * as i4 from "../../jobs/shared/job.service";
import * as i5 from "../../missions/shared/mission-process.service";
import * as i6 from "../../tours/shared/tour.service";
import * as i7 from "../../events/shared/event.service";
import * as i8 from "./file.service";
import * as i9 from "./account.service";
/**
 * This class is responsible for clearing data.
 * Add cachableServices here!
 */
var ClearService = /** @class */ (function () {
    function ClearService(notification, organization, dispo, job, missionProcess, tour, event, file, acc) {
        this.notification = notification;
        this.organization = organization;
        this.dispo = dispo;
        this.job = job;
        this.missionProcess = missionProcess;
        this.tour = tour;
        this.event = event;
        this.file = file;
        this.acc = acc;
    }
    /**
     * Clears all services that contain potential critical data.
     * Add more if desired!
     */
    ClearService.prototype.clear = function () {
        this.notification.clear();
        this.organization.clear();
        this.dispo.clear();
        this.job.clear();
        this.missionProcess.clear();
        this.tour.clear();
        this.event.clear();
        this.file.clear();
        this.acc.clear();
        // wipe any data left
        localStorage.clear();
    };
    ClearService.ngInjectableDef = i0.defineInjectable({ factory: function ClearService_Factory() { return new ClearService(i0.inject(i1.NotificationService), i0.inject(i2.OrganizationService), i0.inject(i3.DispositionService), i0.inject(i4.JobService), i0.inject(i5.MissionProcessService), i0.inject(i6.TourService), i0.inject(i7.EventService), i0.inject(i8.FileService), i0.inject(i9.AccountService)); }, token: ClearService, providedIn: "root" });
    return ClearService;
}());
export { ClearService };
