import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {


  constructor() { }

  ngOnInit() {
    
  }
}
