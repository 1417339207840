/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activate.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/router";
import * as i11 from "../../shared/single-page/single-page.component.ngfactory";
import * as i12 from "../../shared/single-page/single-page.component";
import * as i13 from "./activate.component";
import * as i14 from "../../core/services/account.service";
var styles_ActivateComponent = [i0.styles];
var RenderType_ActivateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivateComponent, data: {} });
export { RenderType_ActivateComponent as RenderType_ActivateComponent };
function View_ActivateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ActivateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "textbox-padding color-text-green"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Dein Account wurde erfolgreich aktiviert. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Du wirst in ", "s zur Anmeldung weitergeleitet. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countdown; _ck(_v, 3, 0, currVal_0); }); }
function View_ActivateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "textbox-padding color-text-red"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["class", "color-text-grey"], ["mat-button", ""], ["routerLink", "/auth/activate/resend"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_MatAnchor_0, i7.RenderType_MatAnchor)), i1.ɵdid(4, 180224, null, 0, i8.MatAnchor, [i4.Platform, i9.FocusMonitor, i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(5, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, 0, ["Neuen Aktivierungslink anfordern"]))], function (_ck, _v) { var currVal_7 = "/auth/activate/resend"; _ck(_v, 5, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 4).disabled ? (0 - 1) : (i1.ɵnov(_v, 4).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = i1.ɵnov(_v, 4).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); var currVal_5 = i1.ɵnov(_v, 5).target; var currVal_6 = i1.ɵnov(_v, 5).href; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ActivateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-single-page", [["title", "Accountaktivierung"]], null, null, null, i11.View_SinglePageComponent_0, i11.RenderType_SinglePageComponent)), i1.ɵdid(1, 49152, null, 0, i12.SinglePageComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ActivateComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ActivateComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ActivateComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Accountaktivierung"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.requesting; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.activated && !_co.requesting); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.error; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_ActivateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ActivateComponent_0, RenderType_ActivateComponent)), i1.ɵdid(1, 114688, null, 0, i13.ActivateComponent, [i14.AccountService, i10.Router, i10.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivateComponentNgFactory = i1.ɵccf("ng-component", i13.ActivateComponent, View_ActivateComponent_Host_0, {}, {}, []);
export { ActivateComponentNgFactory as ActivateComponentNgFactory };
