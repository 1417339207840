import { Component } from '@angular/core';
import { DeviceService } from './core/services/device.service';
import { UpdateService } from './core/services/update.service';
import { RegisterPressedEvent } from './core/classes/register-pressed-event.class';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  readonly appTitle = 'Lupax';

  constructor(
    public device: DeviceService,
    private update: UpdateService
  ) {
    this.device.smallScreenObserver().subscribe(mobile => {
      if(mobile){
        document.body.classList.add('is-mobile');
        document.body.classList.remove('not-mobile');
      }
      else{
        document.body.classList.add('not-mobile');
        document.body.classList.remove('is-mobile');
      }
    });
  }

  ngOnInit(){

    this.update.init();
    new RegisterPressedEvent();
  }
}
