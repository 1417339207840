var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Router } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { FileService } from '../../core/services/file.service';
import { AccountService } from '../../core/services/account.service';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { FileEditDialogComponent } from '../../shared/file-edit-dialog/file-edit-dialog.component';
import { faUpload, faCamera } from '@fortawesome/free-solid-svg-icons';
var FileUploadComponent = /** @class */ (function (_super) {
    __extends(FileUploadComponent, _super);
    function FileUploadComponent(fileService, router, scrollLocations, dialog, accountService) {
        var _this = _super.call(this, router, scrollLocations) || this;
        _this.fileService = fileService;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.dialog = dialog;
        _this.accountService = accountService;
        _this.faUpload = faUpload;
        _this.faCamera = faCamera;
        _this.uploading = false;
        _this.fileAdded = new EventEmitter();
        return _this;
    }
    FileUploadComponent.prototype.init = function () {
        var _this = this;
        this.accountService.getAccountAsObservable().pipe(untilDestroyed(this)).subscribe(function (res) {
            if (!res)
                return;
            _this.account = res;
        });
    };
    FileUploadComponent.prototype.permission = function () {
        var _this = this;
        return this.account && this.account.file_upload_orgas
            && this.account.file_upload_orgas.filter(function (o) { return o.id == _this.organization; }).length;
    };
    FileUploadComponent.prototype.onFileSelected = function (event) {
        var _this = this;
        var inputNode = event.target;
        // :: file selected check
        if (!inputNode.files || !inputNode.files[0])
            return;
        // :: check file size
        if (inputNode.files[0].size > 10 * 1048576)
            return this.dialog.open(OkDialogComponent, { data: 'Maximale Dateigröße: 10 MB' });
        this.uploading = true;
        this.fileService.upload(this.organization, inputNode.files[0], {
            table: this.table,
            row: this.row
        }).pipe(untilDestroyed(this)).subscribe(function (file) {
            _this.uploading = false;
            _this.dialog.open(FileEditDialogComponent, {
                data: {
                    file: file,
                    categoriesAvailable: _this.categoriesAvailable
                }
            }).afterClosed().subscribe(function () {
                _this.fileAdded.emit(file);
                inputNode.value = null;
            });
        }, function (err) {
            _this.uploading = false;
            _this.dialog.open(OkDialogComponent, { data: err.error.text });
            inputNode.value = null;
        });
    };
    return FileUploadComponent;
}(ComponentInit));
export { FileUploadComponent };
