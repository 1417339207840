/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mission-processes.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mission-process-list/mission-process-list.component.ngfactory";
import * as i3 from "./mission-process-list/mission-process-list.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../shared/loading-spinner/loading-spinner.component.ngfactory";
import * as i7 from "../../shared/loading-spinner/loading-spinner.component";
import * as i8 from "./mission-processes.component";
import * as i9 from "../shared/mission-process.service";
import * as i10 from "../../core/globals/scroll-locations";
var styles_MissionProcessesComponent = [i0.styles];
var RenderType_MissionProcessesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MissionProcessesComponent, data: {} });
export { RenderType_MissionProcessesComponent as RenderType_MissionProcessesComponent };
function View_MissionProcessesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-top color-text-grey text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Keine Eintr\u00E4ge vorhanden "]))], null, null); }
function View_MissionProcessesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "mat-h2 app-list-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-mission-process-list", [], null, null, null, i2.View_MissionProcessListComponent_0, i2.RenderType_MissionProcessListComponent)), i1.ɵdid(4, 49152, null, 0, i3.MissionProcessListComponent, [i4.Router], { missionProcesses: [0, "missionProcesses"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.data; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_MissionProcessesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MissionProcessesComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.data.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MissionProcessesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i6.View_LoadingSpinnerComponent_0, i6.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i7.LoadingSpinnerComponent, [], { loading: [0, "loading"], loadError: [1, "loadError"], retry: [2, "retry"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MissionProcessesComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MissionProcessesComponent_2)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.missionProcesses; var currVal_1 = _co.loadError; var currVal_2 = _co.reload; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.missionProcesses && (_co.missionProcesses.length == 0)); _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.listGroups; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_MissionProcessesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mission-processes", [], null, null, null, View_MissionProcessesComponent_0, RenderType_MissionProcessesComponent)), i1.ɵdid(1, 4440064, null, 0, i8.MissionProcessesComponent, [i4.ActivatedRoute, i9.MissionProcessService, i4.Router, i10.ScrollLocations], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MissionProcessesComponentNgFactory = i1.ɵccf("app-mission-processes", i8.MissionProcessesComponent, View_MissionProcessesComponent_Host_0, {}, {}, []);
export { MissionProcessesComponentNgFactory as MissionProcessesComponentNgFactory };
