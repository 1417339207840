import { EventEmitter } from '@angular/core';
var TimelineComponent = /** @class */ (function () {
    function TimelineComponent() {
        this.selected = new EventEmitter();
        this.actions = [];
    }
    Object.defineProperty(TimelineComponent.prototype, "sortedEntries", {
        /**
         * gets sorted entries by date
         */
        get: function () {
            return this.entries.sort(function (a, b) {
                return a.start.getTime() - b.start.getTime() + (a.end && b.end ? a.end.getTime() - b.end.getTime() : 0);
            });
        },
        enumerable: true,
        configurable: true
    });
    TimelineComponent.prototype.onClick = function (entry) {
        this.selected.emit(entry);
    };
    return TimelineComponent;
}());
export { TimelineComponent };
