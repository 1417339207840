import { MatDialog } from '@angular/material/dialog';
import { AuthenticateDialogComponent } from '../../shared/authenticate-dialog/authenticate-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var IdentityService = /** @class */ (function () {
    function IdentityService(dialog) {
        this.dialog = dialog;
    }
    /**
     * Opens a dialog where the user has to confirm their identity
     */
    IdentityService.prototype.confirmIdentity = function () {
        return this.dialog.open(AuthenticateDialogComponent).afterClosed();
    };
    IdentityService.ngInjectableDef = i0.defineInjectable({ factory: function IdentityService_Factory() { return new IdentityService(i0.inject(i1.MatDialog)); }, token: IdentityService, providedIn: "root" });
    return IdentityService;
}());
export { IdentityService };
