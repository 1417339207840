var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { faCheck, faPlus, faTimes, faHandPointUp } from '@fortawesome/free-solid-svg-icons';
import { WorkService } from '../shared/work.service';
import { JobService } from 'src/app/jobs/shared/job.service';
import { ProjectService } from 'src/app/projects/shared/project.service';
import { QualificationService } from 'src/app/core/services/qualification.service';
import { formatDuration } from 'src/app/core/utils/number-utils';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { WorkScheduleDialogComponent, defaultDialogOptions, DialogType } from '../work-schedule-dialog/work-schedule-dialog.component';
import { WorkScheduleBreakDialogComponent, defaultBreakDialogOptions, BreakDialogType } from '../work-schedule-break-dialog/work-schedule-break-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { createDateFromDateAndTime, createDateFromDatetime } from 'src/app/core/utils/date-utils';
import { formatDate } from '@angular/common';
import { MyErrorHandler } from '../../core/classes/my-error-handler.class';
var WorkScheduleOverviewComponent = /** @class */ (function (_super) {
    __extends(WorkScheduleOverviewComponent, _super);
    function WorkScheduleOverviewComponent(workService, jobService, projectService, qualificationService, dialog, route, router, myErrorHandler, scrollLocations, locale) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.workService = workService;
        _this.jobService = jobService;
        _this.projectService = projectService;
        _this.qualificationService = qualificationService;
        _this.dialog = dialog;
        _this.route = route;
        _this.router = router;
        _this.myErrorHandler = myErrorHandler;
        _this.scrollLocations = scrollLocations;
        _this.locale = locale;
        _this.faCheck = faCheck;
        _this.faPlus = faPlus;
        _this.faHandPointUp = faHandPointUp;
        _this.entries = [];
        _this.total = 0;
        _this.totalProjects = 0;
        _this.jobs = {};
        _this.projects = {};
        _this.qualifications = {};
        _this.comment = new FormControl('');
        _this.endDate = new FormControl();
        _this.endTime = new FormControl();
        _this.endChanged = false;
        _this.commentChanged = false;
        _this.futureDateError = false;
        _this.readonly = false;
        _this.actions = [
            { icon: faTimes, callback: _this.deleteTimelineEntry.bind(_this), title: "Löschen" }
        ];
        return _this;
    }
    WorkScheduleOverviewComponent.prototype.init = function () {
        var _this = this;
        this.date = new Date();
        this.endDate.setValue(formatDate(this.date, 'yyyy-MM-dd', this.locale));
        this.endTime.setValue(formatDate(this.date, 'HH:mm', this.locale));
        this.date = createDateFromDateAndTime(this.endDate.value, this.endTime.value);
        // Get Entry
        if (this.route.snapshot.data['current']) {
            this.readonly = false;
            this.workService.preselectedDateOut = this.date;
            this.workService.getCurrent().pipe(untilDestroyed(this)).subscribe(this.setWorkSchedule.bind(this));
            setTimeout(function () { _this.workService.preselectedDateOut = null; });
        }
        else {
            // this.readonly = true;
            this.route.data.subscribe(function (data) {
                if (data.resolved.model)
                    _this.setWorkSchedule(data.resolved.model);
                data.resolved.updates.subscribe(function (res) {
                    if (res)
                        _this.setWorkSchedule(res);
                });
            });
        }
    };
    WorkScheduleOverviewComponent.prototype.reload = function () {
        if (this.route.snapshot.data['current']) {
            this.workService.getCurrent();
        }
    };
    WorkScheduleOverviewComponent.prototype.reloadData = function () {
        var _this = this;
        this.workService.preselectedDateOut = this.date;
        _super.prototype.reloadData.call(this);
        setTimeout(function () { _this.workService.preselectedDateOut = null; });
    };
    WorkScheduleOverviewComponent.prototype.loadJob = function (orga, id) {
        var _this = this;
        if (typeof this.jobs[id] !== 'undefined')
            return Promise.resolve();
        this.jobs[id] = null;
        return this.jobService.getByOrganizationAndId(orga, id)
            .toPromise()
            .then(function (job) { return _this.jobs[job.id] = job; });
    };
    WorkScheduleOverviewComponent.prototype.loadProject = function (orga, id) {
        var _this = this;
        if (typeof this.projects[id] !== 'undefined')
            return Promise.resolve();
        this.projects[id] = null;
        return this.projectService.getByOrganizationAndId(orga, id)
            .toPromise()
            .then(function (project) { return _this.projects[project.id] = project; });
    };
    WorkScheduleOverviewComponent.prototype.loadQualification = function (orga, id) {
        var _this = this;
        if (typeof this.qualifications[id] !== 'undefined')
            return Promise.resolve();
        this.qualifications[id] = null;
        return this.qualificationService.getByOrganizationAndId(orga, id)
            .toPromise()
            .then(function (qualification) { return _this.qualifications[qualification.id] = qualification; });
    };
    /* sanitizeWorkSchedule(){
        if(!this.workSchedule) return;
  
        // Start & End Date
        this.workSchedule.entries.forEach(entry => {
            if(entry.start) entry.start = formatDate(entry.start);
        })
  
    } */
    WorkScheduleOverviewComponent.prototype.setWorkSchedule = function (ws) {
        this.workSchedule = ws;
        // adjust current date
        if (ws && ws.date_out) {
            if (!this.endChanged) {
                this.date = createDateFromDatetime(ws.request_pending ? ws.request_pending.data.date_out : ws.date_out);
                this.endDate.setValue(formatDate(this.date, 'yyyy-MM-dd', this.locale));
                this.endTime.setValue(formatDate(this.date, 'HH:mm', this.locale));
            }
        }
        if (!this.breakSuggestion && ws && !ws.date_out) {
            this.breakSuggestion = ws.break_suggestion;
            if (this.breakSuggestion)
                this.breakSuggestion.duration = this.calcDuration(this.breakSuggestion.start, this.breakSuggestion.end);
        }
        else if (this.breakSuggestion && ws && !ws.break_suggestion) {
            this.breakSuggestion = null;
        }
        if (ws && !ws.date_out)
            this.readonly = false;
        if (!this.commentChanged)
            this.comment.setValue(ws ? ws.comment : '');
        if (ws) {
            this.updateEntries(ws.entries, ws.breaks);
        }
        else
            this.entries = [];
        // Check if difference of this.date and date_in is to big
        if (ws && !ws.date_out) {
            if (this.calcDuration(ws.date_in, this.date) > 24) {
                var date_new = createDateFromDatetime(ws.date_in);
                date_new = new Date(date_new.getTime() + (9 * 60000 * 60));
                this.endDate.setValue(formatDate(date_new, 'yyyy-MM-dd', this.locale));
                this.endTime.setValue(formatDate(date_new, 'HH:mm', this.locale));
                this.date = createDateFromDateAndTime(this.endDate.value, this.endTime.value);
                this.updateDuration();
                this.breakSuggestion = null;
                this.reloadData();
            }
        }
    };
    WorkScheduleOverviewComponent.prototype.calcDuration = function (start, end) {
        if (!start || !end)
            return;
        if (typeof start == 'string')
            start = createDateFromDatetime(start);
        if (typeof end == 'string')
            end = createDateFromDatetime(end);
        return (end.getTime() - start.getTime()) / 3600000;
    };
    /**
     * Updates total duration
     */
    WorkScheduleOverviewComponent.prototype.updateDuration = function () {
        var _this = this;
        var ws = this.workSchedule;
        this.total = ws.date_out && ws.duration ? ws.duration : this.calcDuration(ws.date_in, ws.date_out || this.date) - this.totalDurationOfBreaks;
        if (ws.entries.length) {
            // Check if last entry has no end yet -> update its duration using ws.date_out as end
            var last_entry = ws.entries[ws.entries.length - 1];
            if (last_entry && !last_entry.end) {
                ws.entries[ws.entries.length - 1].duration = this.calcDuration(last_entry.start, this.workSchedule.date_out || this.date);
                this.setTimelineEntries(ws.entries);
            }
            this.totalProjects = ws.entries.reduce(function (total, entry) {
                return total + (entry.end ? _this.calcDuration(entry.start, entry.end) : entry.duration);
            }, 0);
        }
        else {
            this.totalProjects = 0;
        }
    };
    WorkScheduleOverviewComponent.prototype.updateEntries = function (workEntries, breaks, breakSuggestion) {
        var _this = this;
        if (workEntries === void 0) { workEntries = this.workSchedule.entries; }
        if (breaks === void 0) { breaks = this.workSchedule.breaks; }
        if (breakSuggestion === void 0) { breakSuggestion = this.breakSuggestion; }
        var promises = [];
        if (workEntries && Array.isArray(workEntries)) {
            workEntries.forEach(function (entry) {
                if (entry.job) {
                    promises.push(_this.loadJob(entry.organization, entry.job));
                }
                if (entry.project) {
                    promises.push(_this.loadProject(entry.organization, entry.project));
                }
                if (entry.qualification) {
                    promises.push(_this.loadQualification(entry.organization, entry.qualification));
                }
                if (!entry.duration && entry.start && entry.end) {
                    entry.duration = _this.calcDuration(entry.start, entry.end);
                }
            });
        }
        this.updateDuration();
        // after al jobs & projects are loaded, construct list entries
        Promise.all(promises).then(function () {
            _this.workSchedule ? _this.setTimelineEntries(_this.workSchedule.entries, breaks, breakSuggestion) : _this.entries = [];
        });
    };
    /**
     * Sets entries for timeline
     * @param workEntries
     */
    WorkScheduleOverviewComponent.prototype.setTimelineEntries = function (workEntries, breaks, breakSuggestion) {
        var _this = this;
        this.entries = [];
        if (workEntries && workEntries.length) {
            this.entries = workEntries.map(function (entry) {
                var duration_complete = entry.end ? _this.calcDuration(entry.start, entry.end) : _this.calcDuration(entry.start, _this.date);
                var duration = entry.end ? entry.duration : _this.calcDuration(entry.start, _this.date);
                return {
                    id: entry.id,
                    type: 'work_time_assign',
                    start: createDateFromDatetime(entry.start),
                    end: entry.end ? createDateFromDatetime(entry.end) : null,
                    title: entry.project && _this.projects[entry.project] ? _this.projects[entry.project].title : entry.comment,
                    subtitle: (entry.qualification && _this.qualifications[entry.qualification] ?
                        _this.qualifications[entry.qualification].title : '') +
                        (entry.qualification && _this.qualifications[entry.qualification] && entry.comment ? ' - ' : '') +
                        (entry.comment || ''),
                    aside: formatDuration(duration_complete) + (duration > 0 && duration < duration_complete && duration_complete - duration > 0.01 ?
                        (' <small>(-' + formatDuration(duration_complete - duration) + ')</small>') : '')
                };
            });
        }
        if (breaks) {
            this.entries = this.entries.concat(breaks.map(function (b) {
                return {
                    id: b.id,
                    type: 'break',
                    start: createDateFromDatetime(b.start),
                    end: b.end ? createDateFromDatetime(b.end) : null,
                    title: 'Pause',
                    aside: b.duration.substring(0, 5),
                    color: 'ye'
                };
            }));
        }
        if (breakSuggestion) {
            this.entries.push({
                id: -1,
                type: 'break_suggestion',
                start: createDateFromDatetime(breakSuggestion.start),
                end: createDateFromDatetime(breakSuggestion.end),
                title: 'Pause',
                aside: formatDuration(this.calcDuration(breakSuggestion.start, breakSuggestion.end)),
                color: 'ye',
                disableAction: true
            });
        }
        return this.entries;
    };
    WorkScheduleOverviewComponent.prototype.formatDuration = function (val) {
        return formatDuration(val);
    };
    Object.defineProperty(WorkScheduleOverviewComponent.prototype, "totalDuration", {
        get: function () {
            return formatDuration(Math.max(0, this.total));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkScheduleOverviewComponent.prototype, "totalDurationOfBreaks", {
        get: function () {
            return this.workSchedule.break / 60 + (this.breakSuggestion ? this.breakSuggestion.duration : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkScheduleOverviewComponent.prototype, "totalProjectDuration", {
        get: function () {
            return formatDuration(Math.max(0, this.totalProjects));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkScheduleOverviewComponent.prototype, "totalDurationWithoutProjects", {
        get: function () {
            return Math.max(0, this.total + this.totalDurationOfBreaks - this.totalProjects);
            // return Math.max(0, this.total + (this.workSchedule.date_out ? (this.workSchedule.break / 60) : 0) - this.totalProjects);
        },
        enumerable: true,
        configurable: true
    });
    WorkScheduleOverviewComponent.prototype.submit = function () {
        var _this = this;
        this.futureDateError = false;
        if (this.date > new Date) {
            this.futureDateError = true;
            return;
        }
        // Replace end of entries with "this.date" when value is null
        this.workSchedule.entries.forEach(function (entry) {
            if (entry.end === null)
                entry.end = formatDate(_this.date, 'yyyy-MM-dd HH:mm:ss', _this.locale);
        });
        if (this.breakSuggestion) {
            this.workService.createBreak(this.workSchedule.organization, this.workSchedule.id, {
                start: this.breakSuggestion.start,
                end: this.breakSuggestion.end,
                automatic: true
            }).pipe(untilDestroyed(this)).subscribe(function () { return _this.endWorkSchedule(); }, function (err) { return _this.myErrorHandler.showError(err); });
        }
        else {
            this.endWorkSchedule();
        }
    };
    WorkScheduleOverviewComponent.prototype.endWorkSchedule = function () {
        var _this = this;
        // save work schedule
        if (this.route.snapshot.data['current']) {
            this.workService.endCurrWorkSchedule(this.comment.value, this.date, this.workSchedule.entries, this.breakSuggestion)
                .subscribe(function () { return _this.router.navigateByUrl('/'); }, function (err) {
                _this.reloadData();
                _this.myErrorHandler.showError(err);
            });
        }
        else {
            this.workService.endWorkSchedule(this.comment.value, this.date, this.workSchedule.entries, this.workSchedule, this.breakSuggestion)
                .subscribe(function () { return _this.router.navigateByUrl('/work-schedules'); }, function (err) {
                _this.reloadData();
                _this.myErrorHandler.showError(err);
            });
        }
    };
    WorkScheduleOverviewComponent.prototype.addEntry = function () {
        var _this = this;
        this.dialog.open(WorkScheduleDialogComponent, __assign({}, defaultDialogOptions, { data: {
                type: DialogType.CreateProject,
                schedule: this.workSchedule,
            } })).afterClosed().subscribe(function (entry) {
            if (!entry)
                return;
            _this.workSchedule.entries.push(entry);
            _this.updateEntries(_this.workSchedule.entries);
        });
    };
    WorkScheduleOverviewComponent.prototype.editWorkTimeAssign = function (entry) {
        var _this = this;
        this.dialog.open(WorkScheduleDialogComponent, __assign({}, defaultDialogOptions, { data: {
                type: DialogType.EditProject,
                entry: entry,
                schedule: this.workSchedule,
                job: this.jobs[entry.job],
                project: this.projects[entry.project],
                date: this.date
            } })).afterClosed().subscribe(function (entry) {
            if (!entry)
                return;
            _this.workSchedule.entries[_this.workSchedule.entries.findIndex(function (e) { return e.id === entry.id; })] = entry;
            _this.updateEntries();
        });
    };
    WorkScheduleOverviewComponent.prototype.deleteWorkTimeAssignById = function (id) {
        var _this = this;
        this.workService.deleteWorkScheduleEntry(this.workSchedule.organization, id).subscribe(function () {
            var index = _this.workSchedule.entries.findIndex(function (e) { return e.id === id; });
            if (index === -1)
                return;
            _this.workSchedule.entries.splice(index, 1);
            _this.updateEntries();
        }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleOverviewComponent.prototype.createBreak = function () {
        var _this = this;
        this.dialog.open(WorkScheduleBreakDialogComponent, __assign({}, defaultBreakDialogOptions, { data: {
                type: BreakDialogType.CreateBreak,
                ws: this.workSchedule,
                ws_date_out: this.date
            } })).afterClosed().subscribe(function (entry) {
            if (!entry)
                return;
            _this.breakSuggestion = null;
            _this.workSchedule.breaks.push(entry);
            _this.updateEntries();
            _this.reloadData();
        });
    };
    WorkScheduleOverviewComponent.prototype.editBreak = function (entry) {
        var _this = this;
        this.dialog.open(WorkScheduleBreakDialogComponent, __assign({}, defaultBreakDialogOptions, { data: {
                type: BreakDialogType.EditBreak,
                id: entry.id,
                ws: this.workSchedule,
                ws_date_out: this.date,
                start: entry.start,
                end: entry.end
            } })).afterClosed().subscribe(function (entry) {
            if (!entry)
                return;
            _this.breakSuggestion = null;
            if (_this.workSchedule.breaks.findIndex(function (e) { return e.id === entry.id; }) >= 0)
                _this.workSchedule.breaks[_this.workSchedule.breaks.findIndex(function (e) { return e.id === entry.id; })] = entry;
            _this.updateEntries();
            _this.reloadData();
        });
    };
    WorkScheduleOverviewComponent.prototype.editBreakSuggestion = function (entry) {
        var _this = this;
        this.dialog.open(WorkScheduleBreakDialogComponent, __assign({}, defaultBreakDialogOptions, { data: {
                type: BreakDialogType.EditBreak,
                ws: this.workSchedule,
                ws_date_out: this.date,
                start: entry.start,
                end: entry.end
            } })).afterClosed().subscribe(function (entry) {
            if (!entry)
                return;
            _this.breakSuggestion = null;
            // this.workSchedule.breaks.push(entry);
            _this.updateEntries();
            _this.reloadData();
        });
    };
    WorkScheduleOverviewComponent.prototype.deleteBreakById = function (id) {
        var _this = this;
        this.workService.deleteBreak(this.workSchedule.organization, id).subscribe(function () {
            _this.breakSuggestion = null;
            _this.workSchedule.breaks = _this.workSchedule.breaks.filter(function (b) { return b.id != id; });
            _this.updateEntries();
            _this.reloadData();
        }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleOverviewComponent.prototype.editTimelineEntry = function (entry) {
        if (entry.type == 'break') {
            this.editBreak(this.workSchedule.breaks.find(function (e) { return e.id === entry.id; }));
        }
        else if (entry.type == 'break_suggestion') {
            this.editBreakSuggestion(this.breakSuggestion);
        }
        else {
            this.editWorkTimeAssign(this.workSchedule.entries.find(function (e) { return e.id === entry.id; }));
        }
    };
    WorkScheduleOverviewComponent.prototype.deleteTimelineEntry = function (entry) {
        if (entry.type == 'break') {
            if (window.confirm("Willst du wirklich diese Pause löschen?"))
                this.deleteBreakById(entry.id);
        }
        else {
            if (window.confirm("Willst du wirklich diesen Projekteintrag löschen?"))
                this.deleteWorkTimeAssignById(entry.id);
        }
    };
    WorkScheduleOverviewComponent.prototype.updateEnd = function () {
        this.endChanged = true;
        this.date = createDateFromDateAndTime(this.endDate.value, this.endTime.value);
        this.updateDuration();
        this.breakSuggestion = null;
        this.reloadData();
    };
    WorkScheduleOverviewComponent.prototype.onCommentChange = function () {
        this.commentChanged = true;
    };
    return WorkScheduleOverviewComponent;
}(ComponentInit));
export { WorkScheduleOverviewComponent };
