import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { UpdateService } from '../core/services/update.service';

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  localStorageSize;
  constructor(public us: UpdateService) {
    this.localStorageSize = this.getLocalStorageSize();
  }

  ngOnInit() {
  }

  reloadApp(){
    for(let name in localStorage){
      if(name != 'accessToken')
        localStorage.removeItem(name);
    }
    document.location.href = '/';
    // document.location.reload();

  }

  cleanCache(){
      console.log('cleaning cache...');
      localStorage.setItem('rosters', '[]');
      localStorage.setItem('projects', '[]');
      localStorage.setItem('missionProcessesConfirmations', '[]');
      localStorage.setItem('vacationRequests', '[]');
      localStorage.setItem('dispositions', '[]');
      localStorage.setItem('tours', '[]');
      localStorage.setItem('workSchedules', '[]');
      localStorage.setItem('jobs', '[]');
      localStorage.setItem('taskAssigns', '[]');
      localStorage.removeItem('currWorkSchedule');
      localStorage.removeItem('currWorkScheduleEntry');
      document.location.reload();
  }

  getLocalStorageSize(){
        var allStrings = '';
        for(var key in window.localStorage){
            if(window.localStorage.hasOwnProperty(key)){
                allStrings += window.localStorage[key];
            }
        }
        return allStrings ? Math.round(3 + ((allStrings.length*16)/(8*1024))) + ' KB' : '0 KB';
  }

  checkForUpdates(){
      console.log('checking for updates');
      this.us.checkForUpdates();
  }

}
