import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Project } from './project.model';
import { ProjectService } from './project.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class ProjectResolver extends CachableClientModelResolver<Project>{

  constructor(s: ProjectService, r:Router) {
    super(s, 'projects', r);
  }
}
