<header *ngIf="inputs" class="" (click)="showFilter()"  [class]="'holder mat-elevation-z1 ' + ((device.smallScreenObserver() | async) ? 'is-mobile':'not-mobile')">
	<fa-icon [icon]="faFilter"></fa-icon>
	<ng-container *ngFor="let key of inputKeys(inputs); last as isLast">
		<div *ngIf="inputs[key].value !== null && inputs[key].value !== 0 && inputs[key].visible !== false">
			<span>{{inputs[key].label}}:</span>
			<strong [ngSwitch]="inputs[key].type">
				<ng-container *ngSwitchCase="filterType.DATE">
					<ng-container *ngIf="inputs[key].today" i18n>
						Heute
					</ng-container>
					<ng-container *ngIf="!inputs[key].today">
						{{inputs[key].value | date:'dd.MM.yy'}}
					</ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="filterType.SELECT">
					{{inputs[key].selected ? inputs[key].selected.label : ''}}
				</ng-container>
				<ng-container *ngSwitchCase="filterType.GROUPED_SELECT">
					{{inputs[key].selected ? inputs[key].selected.label : ''}}
				</ng-container>
				<ng-container *ngSwitchDefault>
					{{inputs[key].value}}
				</ng-container>
			</strong>
		</div>
	</ng-container>
</header>
<section class="filter_list">
	<ng-content></ng-content>
</section>
