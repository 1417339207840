/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./info-dialog.component";
var styles_InfoDialogComponent = [i0.styles];
var RenderType_InfoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoDialogComponent, data: {} });
export { RenderType_InfoDialogComponent as RenderType_InfoDialogComponent };
function View_InfoDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "color-text-primary mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); }); }
export function View_InfoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-dialog-content", [["class", "mat-dialog-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["style", "justify-content:flex-end"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "light_bg"], ["mat-button", ""], ["mat-dialog-close", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).dialogRef.close(i1.ɵnov(_v, 8).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Schlie\u00DFen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); var currVal_5 = ""; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.text; _ck(_v, 2, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 8).ariaLabel || null); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_InfoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-dialog", [], null, null, null, View_InfoDialogComponent_0, RenderType_InfoDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.InfoDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var InfoDialogComponentNgFactory = i1.ɵccf("app-info-dialog", i9.InfoDialogComponent, View_InfoDialogComponent_Host_0, {}, {}, []);
export { InfoDialogComponentNgFactory as InfoDialogComponentNgFactory };
