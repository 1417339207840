import { Injectable } from '@angular/core';
import { TitleService } from './title.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private title: BehaviorSubject<string>;
  private online: BehaviorSubject<boolean>;
  private loading: BehaviorSubject<boolean>;

  constructor(
    private ts: TitleService,
  ) {
    this.title = new BehaviorSubject(this.ts.getTitle());
    this.online = new BehaviorSubject(true);
    this.loading = new BehaviorSubject(false);
  }

  setTitle(t:string){
    this.title.next(t);
  }

  get titleObservable(){
    return this.title.asObservable();
  }

  setOnline(bool:boolean){
    this.online.next(bool);
  }

  get onlineObservable(){
    return this.online.asObservable();
  }

  setLoading(bool:boolean){
    return this.loading.next(bool);
  }

  get loadingObservable(){
    return this.loading.asObservable();
  }
}
