import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
import { formatDate } from '@angular/common';
import { FilterType, Filters } from '../shared/interfaces';
import { ToggableFilter } from '../shared/classes';
import { toggleFilters } from '../shared/utils';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css']
})
export class FilterDialogComponent implements OnInit {
  form: FormGroup;
  inputs: object = {};
  inputKeys = Object.keys;
  filterType = FilterType;

  isArray(array){ return Array.isArray(array); }
  isObject(object){ return typeof object === 'object' && !this.isArray(object); }
  keys(object):Array<string> { return Object.keys(object); }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    @Inject(LOCALE_ID) private locale:string,
  ) {
    this.inputs = this.data.inputs;
  }

  ngOnInit(){
    this.updateForm();
  }

  /**
   * Build form inputs and their values based on filters
   */
  updateForm() {
    // Build input group for form
    let inputsForForm = {};

    for(let key in this.inputs){
      const input = this.inputs[key];

      // set initial value when form value is not set
      let value = this.form ? this.form.value[key] : this.inputs[key].value;

      if(input.type === this.filterType.DATE){
        if(typeof value === 'string') value = createDateFromDatetime(value);
        value = formatDate(value, 'yyyy-MM-dd', this.locale);
    }

      inputsForForm[key] = value;
    }
    this.form = this.fb.group(inputsForForm);
  }

  /**
   * On submit handler, closes dialog when form is valid
   */
  onSubmit(){

    if(this.form.valid){
      this.dialog.close(this.form.value);
    }
  }

  /**
   * On Input change
   * @param inputName
   * @param value
   */
  onValueChange(inputName:string, value:any) {

    toggleFilters(<Filters>this.inputs, inputName, value);
    this.updateForm();
  }
}
