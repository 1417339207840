/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./filter-header.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../core/services/device.service";
var styles_FilterHeaderComponent = [i0.styles];
var RenderType_FilterHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterHeaderComponent, data: {} });
export { RenderType_FilterHeaderComponent as RenderType_FilterHeaderComponent };
function View_FilterHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Heute "]))], null, null); }
function View_FilterHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _co.inputs[_v.parent.parent.parent.context.$implicit].value, "dd.MM.yy")); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputs[_v.parent.parent.context.$implicit].today; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.inputs[_v.parent.parent.context.$implicit].today; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FilterHeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputs[_v.parent.parent.context.$implicit].selected ? _co.inputs[_v.parent.parent.context.$implicit].selected.label : ""); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterHeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputs[_v.parent.parent.context.$implicit].selected ? _co.inputs[_v.parent.parent.context.$implicit].selected.label : ""); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterHeaderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputs[_v.parent.parent.context.$implicit].value; _ck(_v, 1, 0, currVal_0); }); }
function View_FilterHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "strong", [], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_7)), i1.ɵdid(8, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_8)), i1.ɵdid(10, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_9)), i1.ɵdid(12, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.inputs[_v.parent.context.$implicit].type; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.filterType.DATE; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.filterType.SELECT; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.filterType.GROUPED_SELECT; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputs[_v.parent.context.$implicit].label; _ck(_v, 2, 0, currVal_0); }); }
function View_FilterHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.inputs[_v.context.$implicit].value !== null) && (_co.inputs[_v.context.$implicit].value !== 0)) && (_co.inputs[_v.context.$implicit].visible !== false)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FilterHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "header", [["class", ""]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showFilter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faFilter; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.inputKeys(_co.inputs); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("holder mat-elevation-z1 " + (i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.device.smallScreenObserver())) ? "is-mobile" : "not-mobile")); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_1); }); }
export function View_FilterHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "section", [["class", "filter_list"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FilterHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [], null, null, null, View_FilterHeaderComponent_0, RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.FilterHeaderComponent, [i7.MatDialog, i8.DeviceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterHeaderComponentNgFactory = i1.ɵccf("app-filter-header", i6.FilterHeaderComponent, View_FilterHeaderComponent_Host_0, { storage: "storage", inputs: "inputs" }, { callback: "callback" }, ["*"]);
export { FilterHeaderComponentNgFactory as FilterHeaderComponentNgFactory };
