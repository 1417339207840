import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RosterModel } from '../shared/roster.class';
import { formatDate } from '@angular/common';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { DateService } from 'src/app/core/services/date.service';
import { TestBed } from '@angular/core/testing';

export const RETURN_ACTIONS = {
  NEW: 'new',
  CHANGE: 'change',
  DELETE: 'delete',
};

@Component({
  selector: 'app-roster-dialog',
  templateUrl: './roster-dialog.component.html',
  styleUrls: ['./roster-dialog.component.css']
})
export class RosterDialogComponent implements OnInit {

  form: FormGroup;
  orgas: Organization[] = this.orgaService.organizationsOfCurrentAccount;

  constructor(
    private fb: FormBuilder,
    private orgaService: OrganizationService,
    public dialog: MatDialogRef<RosterDialogComponent>,
    public date: DateService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    @Inject(LOCALE_ID) private locale:string,
  ) {}

  ngOnInit() {

    let roster = this.data.roster;
    let date = roster && roster.start || this.data.date || new Date();
    let start = roster && roster.start || this.date.startOfDay(date);
    let end = roster && roster.end || this.date.endOfDay(date);

    this.form = this.fb.group({
      start: formatDate(start, 'HH:mm', this.locale),
      end: formatDate(end, 'HH:mm', this.locale),
      date: formatDate(date, 'yyyy-MM-dd', this.locale),
      type: roster && roster.type || null,
      organization: roster && roster.organization || (this.orgas.length === 1 && this.orgas[0].id) || null,
    });
  }

  /**
   * Updates roster, or creates new roster with applied form values
   * @param data
   */
  updateRosterWithFormValues(data): RosterModel {
    const roster = this.data.roster || new RosterModel({});

    return Object.assign(roster, {
      ...data,
      start: this.date.mapHoursToDate(new Date(data.date), data.start),
      end: this.date.mapHoursToDate(new Date(data.date), data.end),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialog.close({
        action: this.data.roster ? RETURN_ACTIONS.CHANGE : RETURN_ACTIONS.NEW,
        roster: this.updateRosterWithFormValues(this.form.value)
      });
    }
  }

  onDelete() {
    this.dialog.close({action: RETURN_ACTIONS.DELETE});
  }
}
