import { MatDialog } from "@angular/material";
import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import * as globals from '../../core/globals/globals';

@Injectable()
export class DeactivateDialogGuard implements CanDeactivate<any> {

	constructor(private dialog: MatDialog) {}

	canDeactivate() {
		if (this.dialog.openDialogs.length > 0 && !globals.misc['refreshing']) return false;

		return true;
	}
}
