/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-nav-item.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./settings-nav-item.component";
var styles_SettingsNavItemComponent = [i0.styles];
var RenderType_SettingsNavItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsNavItemComponent, data: {} });
export { RenderType_SettingsNavItemComponent as RenderType_SettingsNavItemComponent };
export function View_SettingsNavItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "item right-icon mat-elevation-z1 mat-list-item"], ["mat-list-item", ""]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), i1.ɵdid(5, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, 2, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "fa-icon", [["class", "mat-list-icon ng-fa-icon"], ["mat-list-icon", ""]], [[8, "innerHTML", 1]], null, null, i6.View_FaIconComponent_0, i6.RenderType_FaIconComponent)), i1.ɵdid(9, 16384, [[3, 4]], 0, i3.MatListIconCssMatStyler, [], null, null), i1.ɵdid(10, 573440, null, 0, i7.FaIconComponent, [i8.DomSanitizer], { iconProp: [0, "iconProp"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.routerLink; _ck(_v, 5, 0, currVal_4); var currVal_6 = _co.faChevronRight; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_2 = i1.ɵnov(_v, 5).target; var currVal_3 = i1.ɵnov(_v, 5).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 10).renderedIconHTML; _ck(_v, 8, 0, currVal_5); }); }
export function View_SettingsNavItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings-nav-item", [], null, null, null, View_SettingsNavItemComponent_0, RenderType_SettingsNavItemComponent)), i1.ɵdid(1, 49152, null, 0, i9.SettingsNavItemComponent, [], null, null)], null, null); }
var SettingsNavItemComponentNgFactory = i1.ɵccf("app-settings-nav-item", i9.SettingsNavItemComponent, View_SettingsNavItemComponent_Host_0, {}, {}, ["*"]);
export { SettingsNavItemComponentNgFactory as SettingsNavItemComponentNgFactory };
