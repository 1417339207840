import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
        // reconstruct login if token available
        var token = localStorage.getItem('accessToken');
        if (token) {
            this.loggedIn = new BehaviorSubject(true);
        }
        else {
            this.loggedIn = new BehaviorSubject(false);
        }
    }
    /**
     * login issues an token for further requests.
     * As long as you have the token you are considered logged-in
     * @param email
     * @param password
     */
    AuthService.prototype.login = function (email, password) {
        var _this = this;
        var payload = {
            email: email,
            password: password
        };
        return this.http.post('/v1/auth/login', payload).pipe(map(function (token) {
            if (token) {
                localStorage.setItem('accessToken', JSON.stringify(token));
                _this.loggedIn.next(true);
                return true;
            }
            return false;
        }));
    };
    /**
     * logs you out
     */
    AuthService.prototype.logout = function () {
        this.loggedIn.next(false);
        localStorage.removeItem('accessToken');
    };
    /**
     * checks if credentails match with Authorization token
     * @param email
     * @param password
     */
    AuthService.prototype.validate = function (email, password) {
        return this.http.post('/v1/auth/validate', { email: email, password: password });
    };
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        /**
         * checks whether you are logged in (hold a token)
         */
        get: function () {
            return this.loggedIn.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "loggedInObserver", {
        /**
         * returns an observable for which the subscribed value
         * is either true, if the user is logged in, or false if not
         */
        get: function () {
            return this.loggedIn.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
