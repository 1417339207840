import { Router, ActivatedRoute } from '@angular/router';


import { ScrollLocations } from '../../core/globals/scroll-locations';
import * as globals from '../../core/globals/globals';

export class ComponentInit{
  tabSelected = 0;

  constructor(
    protected router: Router,
    protected scrollLocations: ScrollLocations,
    private _route: ActivatedRoute = null
  ){
    this.reloadData = this.reloadData.bind(this);
  }

  ngOnInit(){
    // get current url
    let url = this.router['url'];
    url = url.split("?")[0]; // remove query params
    // url = url.slice(1, url.length); // remove trailing '/'

    // Init selected tab
    let url_tree = this.router.parseUrl(this.router.url);
    if(url_tree.queryParams && url_tree.queryParams.tab){
      this.tabSelected = url_tree.queryParams.tab;
    } else if(globals.selectedTabs[url]){
      this.tabSelected = globals.selectedTabs[url];
    }


    // Add event listener for window.focus
    console.log('add focus listener');
    window.addEventListener("focus", this.reloadData);
  }

  reload(){}

  reloadData(){
      globals.misc['refreshing'] = true;
      // Reload Route
      if(!this._route){
        console.warn('ActivatedRoute is missing in constructor');
      } else {
        let current_runGuardsAndResolvers_setting = this._route.routeConfig.runGuardsAndResolvers;
        this._route.routeConfig.runGuardsAndResolvers = 'always';

        let url = this.router.url;
        url = url.split("?")[0];
        let url_tree = this.router.parseUrl(this.router.url);

        this.router.navigate([url], { queryParams: url_tree.queryParams });
        setTimeout(() => {
          this._route.routeConfig.runGuardsAndResolvers = current_runGuardsAndResolvers_setting;
        });
      }

      // Reload Data
      if(this.reload) this.reload();

      setTimeout(() => {
        globals.misc['refreshing'] = false;
      })


  }

  ngAfterViewInit(){
    if(this['init'] instanceof Function){
      setTimeout(() => {
        // Execute Init Function
        this['init']();

        if(this['afterInit'] instanceof Function){
          setTimeout(() => {
            this['afterInit']();
          });
        }

        // Wait for Render
        setTimeout(() => {
          // get current url
          let url = this.router['url'];
          url = url.split("?")[0]; // remove query params
          url = url.slice(1, url.length); // remove trailing '/'

          // Set Scroll Position
          let scrollToY = this.scrollLocations['positions'][url] || 0;
          if(scrollToY){
            let scrollContainer = document.querySelector('.filter_list');
            if(!scrollContainer) scrollContainer = document.querySelector('.mat-drawer-content');
            if(scrollContainer){
              if(typeof scrollContainer.scrollTo === "function"){
                  scrollContainer.scrollTo(0, scrollToY);
              } else {
                  scrollContainer.scrollTop = scrollToY;
              }
            }
          }
        });

      });
    }
  }

  ngOnDestroy(){
    window.removeEventListener("focus", this.reloadData);
  }
}
