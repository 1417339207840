import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private subscribed: BehaviorSubject<boolean>;
  readonly VAPID_PUBLIC_KEY = 'BADqZbl6TJSQePWVscn1q4oVEd33ZnjqsjHpMQmMJMez6MGIivZifi36QiIdDAb2t5BKI07vFXdy43zKba4wTCQ';
  
  constructor(
    private http: HttpClient,
  ) {
    let item = localStorage.getItem('pushSubscriptionId');
    this.subscribed = new BehaviorSubject<boolean>(!!item);
  }

  clear(){
    this.unsubscribe().subscribe();
    localStorage.removeItem('changedPushSubscription');
  }

  get getVAPID(){
    return this.VAPID_PUBLIC_KEY;
  }

  /**
   * Subscribes current user to push notifications
   * @param object  browser subscription object
   */
  subscribe(sub){
    localStorage.setItem('changedPushSubscription', 'true');
    // return Observable
    return this.http.post('/v1/push', sub).pipe(map(sid => {
      if(sid){
        localStorage.setItem('pushSubscriptionId', JSON.stringify(sid));
        this.subscribed.next(true);
        return true;
      }

      return false;
    }));
  }

  /**
   * Unsubscribes user from push notifications.
   * Should be called by logout!
   */
  unsubscribe(){
    // read subscription id from local storage
    let sid = localStorage.getItem('pushSubscriptionId');

    // nothing to unsubscribe
    if(!sid) return of(null);

    // remove subscription
    return this.http.delete('/v1/push/'+sid)
      .pipe(tap(() => {
        localStorage.removeItem('pushSubscriptionId');
        this.subscribed.next(false);
      }));
  }

  get isSubscribed(){
    return this.subscribed.getValue();
  }

  /**
   * Returns whether the user has subscribed at some point, or changed the subscription
   */
  changedSubscription():boolean{
    if(localStorage.getItem('changedPushSubscription')) return true;
    else return false;
  }
}
