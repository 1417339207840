import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { WorkScheduleListComponent } from './work-schedule-list/work-schedule-list.component';
import { WorkService } from './shared/work.service';
import { WorkScheduleDialogComponent } from './work-schedule-dialog/work-schedule-dialog.component';
import { WorkScheduleBreakDialogComponent } from './work-schedule-break-dialog/work-schedule-break-dialog.component';
import { WorkScheduleOverviewComponent } from './work-schedule-overview/work-schedule-overview.component';
import { WorkScheduleResolver } from './shared/work-schedule.resolver'

@NgModule({
  imports: [
    SharedModule,
  ],
  exports: [
    WorkScheduleDialogComponent,
    WorkScheduleBreakDialogComponent,
  ],
  declarations: [
    WorkScheduleListComponent,
    WorkScheduleDialogComponent,
    WorkScheduleBreakDialogComponent,
    WorkScheduleOverviewComponent,
  ],
  entryComponents: [
    WorkScheduleDialogComponent,
    WorkScheduleBreakDialogComponent,
  ],
  providers: [
    WorkService,
    WorkScheduleResolver
  ]
})
export class WorkSchedulesModule { }
