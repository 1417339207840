import { Component, OnInit, Input, Output, forwardRef, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Model } from 'src/app/core/interfaces/model';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toggleFormControl } from 'src/app/core/utils/form-utils';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import {Observable} from 'rxjs';
import {map, startWith, distinctUntilChanged} from 'rxjs/operators';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-model-select-input',
  templateUrl: './model-select-input.component.html',
  styleUrls: ['./model-select-input.component.css'],
  /* this registeres the component to be used for forms */
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelSelectInputComponent),
      multi: true
    }
  ]
})
export class ModelSelectInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  
  @ViewChild('input') inputRef: ElementRef;
    
  faTimes = faTimes;
  @Input()
  required: boolean = false;
  @Input()
  options?: Array<Model> = [];
  @Input()
  optionGroups?: Array<Object> = [];
  @Input()
  placeholder?: string = '';
  @Input()
  display?: Function = this.displayOption;
  @Output()
  liveChange: EventEmitter<string> = new EventEmitter<string>();

  inputControl = new FormControl('');
  propagateChange: Function = new Function;
  
  selectedValue:any;

  filteredOptions: Observable<Model[]>;
  filteredGroupOptions: Observable<Object[]>;

  constructor() { }

  /**
   * Changes input value
   * @param obj
   */
  writeValue(obj?: Model): void {

    this.inputControl.setValue(obj);
  }

  /**
   * Registers callback for onChange event
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers callback for onBlur event
   */
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    toggleFormControl(this.inputControl, !isDisabled);
  }

  ngOnInit() {

    this.filteredOptions = this.inputControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
    );

    this.filteredGroupOptions = this.inputControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterGroup(value)
    ));

    this.inputControl.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        if(typeof value === 'string'){
            this.liveChange.emit(value);
        } else {
            this.propagateChange(value);
            this.selectedValue = value;
            setTimeout(() => {
                this.inputRef.nativeElement.blur();
            });
            
        }
    });
  }

  ngOnChanges(){
    // Trigger options filter when input of options or optionGroups has changed
    this.inputControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }
  
  reset(){
      this.inputControl.setValue(null);
      setTimeout(() => this.autocomplete.closePanel());
  }

  /**
   * this function is responsible to display the option in the input element
   * @param option
   */
  displayOption(option:Model) {
    return option && option.title ? option.title : '';
  }
  
  onBlur(event:FocusEvent){
      // Check if an option was clicked (in this case the blur event will also get triggered)
      let related_target = event.relatedTarget as HTMLElement;
      if(related_target && !related_target.classList.contains('mat-option')){
          // Reset value to last selected value
         if(typeof this.inputControl.value === 'string'){
              this.inputControl.setValue(this.selectedValue);
          }
      }
  }

  private _filter(value: string): Model[] {
    if(!value || typeof value !== 'string') return this.options;
    const filterValue = value.toLowerCase();

    return this.options.filter(
        option => option.title.toLowerCase().includes(filterValue)
    );
  }

  private _filterGroup(value: string): Object[] {
    if(!value || typeof value !== 'string') return this.optionGroups.filter(group => group['options'].length > 0);

    const filterValue = value.toLowerCase();
    return this.optionGroups
        .map(group => ({
            title: group['title'],
            options: group['options'].filter(option => option.title.toLowerCase().includes(filterValue))
        }))
        .filter(group => group.options.length > 0);
  }

}
