<ng-container *ngIf="start || end">
    <ng-container *ngIf="start">
        <ng-container i18n *ngIf="startYesterday"><b>Gestern</b></ng-container>
        <ng-container i18n *ngIf="startToday"><b>Heute</b></ng-container>
        <ng-container i18n *ngIf="startTomorrow"><b>Morgen</b></ng-container>
        <ng-container i18n *ngIf="!startYesterday && !startToday && !startTomorrow">
          <ng-container *ngIf="showWeekday">
            <span class="weekday">{{startDate | date:'EEE'}}</span>&nbsp;
          </ng-container>
          <span class="date start">{{startDate | date:startDateFormat}}</span>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="start && end && sameDay && !dateOnly">
         | {{startDate | date:'shortTime'}} - {{endDate | date:'shortTime'}}
     </ng-container>
    <ng-container *ngIf="!sameDay">
      <ng-container i18n *ngIf="!short && !dateOnly">&nbsp;{{startDate | date:'shortTime'}}</ng-container>
      <ng-container *ngIf="end">
          <ng-container>&nbsp;-&nbsp;</ng-container>
          <ng-container i18n *ngIf="endYesterday">Gestern</ng-container>
          <ng-container i18n *ngIf="endToday">Heute</ng-container>
          <ng-container i18n *ngIf="endTomorrow">Morgen</ng-container>
          <ng-container i18n *ngIf="!endYesterday && !endToday && !endTomorrow">
              <span class="date end">{{endDate | date:endDateFormat}}</span>
          </ng-container>
          <ng-container i18n *ngIf="!short && !dateOnly">&nbsp;{{endDate | date:'shortTime'}}</ng-container>
      </ng-container>
    </ng-container>

</ng-container>
