import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './edit-password-dialog.component.html'
})
export class EditPasswordDialogComponent implements OnInit {

  form: FormGroup;
  unequalPassErr: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<EditPasswordDialogComponent>
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password1: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    })
  }

  onSubmit(){
    if(this.form.valid){
      if(this.form.value.password1 != this.form.value.password2)
        this.unequalPassErr = true;
      else this.dialog.close(this.form.value.password1);
    }
  }
}
