import { AbstractControl } from "@angular/forms";

/**
 * Toggles enabled/disabled state of an FormGroup control element
 * @param form 
 * @param control 
 * @param enable when true or disable when false
 */
export function toggleFormControl(control:AbstractControl, enable:any) {
	if (!enable) control.disable();
	else control.enable();
}