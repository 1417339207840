import { Resolve, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, throwError, of } from "rxjs";
import { CachableModelService } from "./cachable-model-service.class";
import { take, map, tap, catchError } from "rxjs/operators";

export interface CachableModelResolverData<S>{
	model: any | S,
	updates: Observable<S>
}

export class CachableClientModelResolver<T> implements Resolve<T>{

	constructor(
		public service: any | CachableModelService<T>,
		private back: string,
		private router: Router
	){
		if(typeof service.getByOrganizationAndId != 'function')
			throw 'service must implement getByOrganizationAndId()';
	}

	resolve(route: ActivatedRouteSnapshot):Observable<any | CachableModelResolverData<T>>{
		this.service.route = route;

		let id = route.paramMap.get('id');
		let orga = route.paramMap.get('orga');

		return this.service.getByOrganizationAndId(orga, id).pipe( // .pipe(take(1)): will cause issues, because after first subject change (getting  cache) the subscribe on this observable will no longer work (we dont get the fresh result from server)
			map(obj => {
				let out:CachableModelResolverData<T> = {
					model: (obj) ? obj: null,
					updates: this.service.getLastCachedObservable()
				};

				return out;
			}),
			catchError(e => {

				// if resource not found -> redirect to home
				if(e && e.error && e.error.slug == 'not-found' && this.router){
					console.log('redirect after not-found');
					this.router.navigate(['/']);
				}

				// I removed this, because we want to still show the cached model, when an error occured, but when we return new object with model = null, no data will be shown
				// return of({
				// 	model: null,
				// 	updates: this.service.getLastCachedObservable()
				// });
				return of();
			})
		);

	}
}
