var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router, ActivatedRoute } from '@angular/router';
import { ComponentInit } from '../../core/classes/component-init.class';
import { createDateFromDatetime } from '../../core/utils/date-utils';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { JobTimesheetCreateDialogComponent } from '../job-timesheet-create-dialog/job-timesheet-create-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { JobService } from '../shared/job.service';
import { OrganizationService } from '../../core/services/organization.service';
import { MatDialog } from '@angular/material';
import { faUser, faMale, faLocationArrow, faInfo, faFlag, faPhone, faRocket, faHandshake, faUsers, faInfoCircle, faBusinessTime, faSignature, faCheckSquare, faCalendarTimes, faExclamation, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faComment, faBuilding, faFileAlt, faSquare, } from '@fortawesome/free-regular-svg-icons';
var JobDetailComponent = /** @class */ (function (_super) {
    __extends(JobDetailComponent, _super);
    function JobDetailComponent(dialog, route, jobService, orgaService, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.dialog = dialog;
        _this.route = route;
        _this.jobService = jobService;
        _this.orgaService = orgaService;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.crewAmount = 0;
        _this.projectFileCategories = [];
        _this.taskGroups = [];
        _this.tab_num = 0;
        _this.tabSelected = 0;
        _this.SWIPE_ACTION = { LEFT: 'swiperight', RIGHT: 'swipeleft' };
        _this.faCalendarAlt = faCalendarAlt;
        _this.faUser = faUser;
        _this.faMale = faMale;
        _this.faLocationArrow = faLocationArrow;
        _this.faInfo = faInfo;
        _this.faComment = faComment;
        _this.faFlag = faFlag;
        _this.faPhone = faPhone;
        _this.faBuilding = faBuilding;
        _this.faRocket = faRocket;
        _this.faHandshake = faHandshake;
        _this.faFileAlt = faFileAlt;
        _this.faUsers = faUsers;
        _this.faInfoCircle = faInfoCircle;
        _this.faBusinessTime = faBusinessTime;
        _this.faSignature = faSignature;
        _this.faCheckSquare = faCheckSquare;
        _this.faSquare = faSquare;
        _this.faCalendarTimes = faCalendarTimes;
        _this.faExclamation = faExclamation;
        _this.faThumbtack = faThumbtack;
        return _this;
    }
    JobDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (job) {
                if (job)
                    _this.update(job);
            });
        });
        this.orgaService.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (res && _this.job)
                _this.orga = res.find(function (o) { return o.id == _this.job.organization; });
        });
    };
    JobDetailComponent.prototype.update = function (job) {
        var _this = this;
        this.job = job;
        this.generateMapsLink();
        // Get Crew Amount
        this.crewAmount = 0;
        if (this.job.crew && this.job.crew.length) {
            this.job.crew.forEach(function (group) {
                _this.crewAmount += group.staffs.length;
            });
        }
        // Group project files by category
        if (job.project && job.project.files) {
            this.projectFileCategories = [];
            job.project.files.forEach(function (file) {
                var category = _this.projectFileCategories.find(function (val) { return val.id == (file.cat ? file.cat.id : 0); });
                if (!category) {
                    _this.projectFileCategories.push({
                        id: file.cat ? file.cat.id : 0,
                        title: file.cat ? file.cat.title : 'Ohne Kategorie',
                        files: [file]
                    });
                }
                else {
                    category.files.push(file);
                }
            });
        }
        // group tasks by completed/incomplete
        if (job.tasks) {
            this.taskGroups = [
                {
                    title: 'Ausstehend',
                    tasks: job.tasks.filter(function (t) { return !t.completed; })
                },
                {
                    title: 'Erledigt',
                    tasks: job.tasks.filter(function (t) { return t.completed; })
                }
            ].filter(function (g) { return g.tasks.length; });
        }
        // Update Tab Count (Wait for rendering)
        setTimeout(function () { _this.tab_num = _this.tabs.length; });
    };
    /**
     * Handler for done checkbox toggle.
     * Opens dialog when job hasn't yet started
     * @param event checkbox event
     */
    JobDetailComponent.prototype.OnDoneToggle = function (event) {
        var _this = this;
        /* job hasn't started yet, so confirm action from user */
        if (event.checked && new Date() < createDateFromDatetime(this.job.dispo_start)) {
            var confirmRef = this.dialog.open(ConfirmDialogComponent, { data: {
                    text: 'Der Job hat noch nicht begonnen! Soll dieser wirklich als erledigt markiert werden?',
                    okTitle: 'Ja',
                } });
            confirmRef.afterClosed().subscribe(function (result) {
                if (result)
                    _this.toggleDone(event);
                else
                    event.source.checked = !event.source.checked;
            }, function (err) {
                event.source.checked = !event.source.checked;
            });
        }
        else {
            this.toggleDone(event);
        }
    };
    /**
     * Toggles Job done, depending on checkbox event
     * @param event checkbox event
     */
    JobDetailComponent.prototype.toggleDone = function (event) {
        var _this = this;
        this.jobService.setCompleted(this.job.organization, this.job.id, event.checked).subscribe(function (res) {
            if (res) {
                //success
                _this.job.tour_completed = event.checked;
            }
            else {
                event.source.checked = !event.source.checked;
            }
        }, function (err) {
            event.source.checked = !event.source.checked;
        });
    };
    JobDetailComponent.prototype.generateMapsLink = function () {
        if (this.job.address)
            this.mapsLink = 'https://maps.google.com/?daddr=' + encodeURIComponent(this.job.address.text);
    };
    JobDetailComponent.prototype.routeMissionProcess = function (mp) {
        this.router.navigate(['/missions/processes/' + mp.organization + '/' + mp.id]);
    };
    JobDetailComponent.prototype.routeProject = function (project) {
        this.router.navigate(['/projects/' + project.organization + '/' + project.id]);
    };
    JobDetailComponent.prototype.routeTask = function (task) {
        this.router.navigate(['/tasks/' + task.organization + '/' + task.id]);
    };
    JobDetailComponent.prototype.showJobTimesheetCreateDialog = function (job) {
        var _this = this;
        this.dialog.open(JobTimesheetCreateDialogComponent, {
            width: '400px',
            data: {
                job: job
            }
        }).afterClosed().subscribe(function (data) {
            if (!data)
                return;
            _this.router.navigate(['/jobs/' + job.organization + '/' + job.id + '/timesheet'], {
                queryParams: {
                    staffs: data.staffs
                }
            });
        });
    };
    JobDetailComponent.prototype.routeTimesheet = function (timesheet) {
        this.router.navigate(['/timesheets/' + timesheet.organization + '/' + timesheet.id]);
    };
    JobDetailComponent.prototype.staffNamesOfTimesheet = function (timesheet) {
        return Object.values(timesheet.staffs).map(function (s) { return (s['custom_amount'] ? '' : '1x ') + s['first_name'] + ' ' + s['last_name']; }).join(', ');
    };
    JobDetailComponent.prototype.swipe = function (eType) {
        if (eType === this.SWIPE_ACTION.LEFT && this.tabSelected > 0)
            this.tabSelected--;
        else if (eType === this.SWIPE_ACTION.RIGHT && this.tabSelected < (this.tab_num - 1))
            this.tabSelected++;
    };
    JobDetailComponent.prototype.onFileAdded = function () {
        this.reloadData();
        document.querySelector('.mat-tab-body-active main').scrollTo(0, 0);
    };
    return JobDetailComponent;
}(ComponentInit));
export { JobDetailComponent };
