import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AuthService } from '../../auth/auth.service';

@Component({
  templateUrl: './authenticate-dialog.component.html',
  styleUrls: ['./authenticate-dialog.component.css']
})
export class AuthenticateDialogComponent implements OnInit {

  form: FormGroup;
  authFailedError: boolean = false;
  disabled:boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<AuthenticateDialogComponent>,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }

  onSubmit(){
    if(this.form.valid && !this.disabled){
      this.disabled = true;
      let email = this.form.value.email;
      let password = this.form.value.password;

      // validate credentials
      this.authService.validate(email, password).subscribe(res => {
        // return credentials on success
        this.dialog.close({email:email, password:password});
      }, err => {
        this.authFailedError = true;
        this.disabled = false;
      })
    }
  }
}
