<app-single-page title="Aktivierungslink anfordern">
  <p class="textbox-padding" i18n>
    Gib die E-Mail Adresse deines Accounts an, welchen du aktivieren möchtest.<br />
    Anschließend wird dir ein neuer Aktivierungslink gesendet.
  </p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput type="email" placeholder="E-Mail Adresse" i18n-placeholder formControlName="email" required>
      <mat-error i18n>
        Gib deine E-Mail Adresse ein
      </mat-error>
    </mat-form-field>
    <br />
    <button mat-raised-button color="primary" i18n class="spinner-button" [disabled]="formSubmitAttempt">
      <mat-spinner diameter="20"></mat-spinner>
      Senden
    </button>
  </form>
  <p class="textbox-padding color-text-red" *ngIf="error && !success">
    {{error}}
  </p>
  <p class="textbox-padding color-text-green" *ngIf="success" i18n>
    Dir wurde ein Aktivierungslink zugestellt!
  </p>
</app-single-page>
