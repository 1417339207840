<main>
    <ng-container *ngIf="job?.crew?.length > 0 && form">

        <h2 class="mat-h2 app-list-heading" i18n>Informationen</h2>
        <mat-list>
            <mat-list-item class="show-all">
                <fa-icon mat-list-icon [icon]="faFlag"></fa-icon>
                <span mat-line>
                    {{job.type.title}}&nbsp;-&nbsp;{{job.title}}
                </span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
                <span mat-line>
                    <app-date-span [start]="job.dispo_start" [end]="job.dispo_end"></app-date-span>&nbsp;
                    <small *ngIf="job.date_start || job.date_end">({{job.date_start | date:'shortTime'}} - {{job.date_end | date:'shortTime'}})</small>
                </span>
            </mat-list-item>

            <mat-divider *ngIf="job.client && job.client.name"></mat-divider>
            <mat-list-item *ngIf="job.client && job.client.name" class="show-all">
                <fa-icon mat-list-icon [icon]="faMale"></fa-icon>
                <span mat-line>
                    {{job.client.name}}
                </span>
            </mat-list-item>
        </mat-list>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <h2 class="mat-h2 app-list-heading">Crew</h2>
            <ng-container *ngFor="let group of staffs">

                <table *ngIf="group.staffs?.length" mat-table [dataSource]="group.staffs" class="mat-elevation-z1" [class.signing]="showSignaturePad" [formGroup]="form.get('staffs')">
                    <!-- Name -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>{{getStaffCount(group.staffs)}}x {{group.qualification.title}}</th>
                        <td mat-cell *matCellDef="let staff" nowrap>
                            {{staff.first_name}} {{staff.last_name}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef nowrap>Gesamt</td>
                    </ng-container>

                    <!-- Start -->
                    <ng-container matColumnDef="start">
                        <th mat-header-cell *matHeaderCellDef i18n class="text-center">Von</th>
                        <td mat-cell *matCellDef="let staff" nowrap class="text-center">
                            <mat-form-field [formGroupName]="staff.id">
                                <input matInput formControlName="start" type="time" (change)="onFormChange()" pattern="\d{2}:\d{2}" class="text-center">
                            </mat-form-field>
                            <!--[value]="(staff.project_assign ? staff.project_assign.start : job.dispo_start) | date:'HH:mm'"-->
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- End -->
                    <ng-container matColumnDef="end" i18n>
                        <th mat-header-cell *matHeaderCellDef i18n class="text-center">Bis</th>
                        <td mat-cell *matCellDef="let staff" nowrap class="text-center">
                            <mat-form-field [formGroupName]="staff.id">
                                <input matInput formControlName="end" type="time" (change)="onFormChange()" pattern="\d{2}:\d{2}" class="text-center">
                            </mat-form-field>
                            <!-- <input matInput type="time" (change)="onFormChange()" pattern="\d{2}:\d{2}"> -->
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- Break -->
                    <ng-container matColumnDef="break" i18n>
                        <th mat-header-cell *matHeaderCellDef i18n class="text-center">Pause <small>(min)</small></th>
                        <td mat-cell *matCellDef="let staff" nowrap class="text-center">
                            <mat-form-field [formGroupName]="staff.id">
                                <input matInput formControlName="break" type="number" (change)="onFormChange()" class="text-center"
                                    onfocus="this.select()">
                            </mat-form-field>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- Duration -->
                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef i18n>Dauer</th>
                        <td mat-cell *matCellDef="let staff" nowrap>
                            {{numberToLocaleString(staffDuration(staff.id))}} <small>Std.</small>
                        </td>
                        <td mat-footer-cell *matFooterCellDef><strong>{{numberToLocaleString(qualificationDuration(group.qualification.id))}} <small>Std.</small></strong></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                    <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
                </table>
            </ng-container>

            <mat-list *ngIf="form" class="mat-elevation-z1" style="background: white;">
                <mat-list-item class="show-all">
                    <mat-form-field style="flex: 1">
                         <textarea matInput placeholder="Notizen" i18n-placeholder formControlName="note" rows="3" (change)="onFormChange()"></textarea>
                    </mat-form-field>
                </mat-list-item>
            </mat-list>

            <!-- Signing Container -->
            <div class="button-wrapper" style="margin-top: 15px;" *ngIf="!showSignaturePad">
                <button type="button" mat-raised-button color="primary" (click)="toggleShowSignaturePad()">
                    <fa-icon mat-icon [icon]="faSignature" style="margin-right: 8px;"></fa-icon>
                    <span i18n>Unterschreiben lassen</span>
                </button>
            </div>

            <ng-container *ngIf="showSignaturePad">
                <h2 class="mat-h2 app-list-heading" style="margin-bottom: 15px; margin-top: 40px; font-size: 17px; line-height: 35px;">
                    Bestätigung durch Berechtigten
                    <button type="button" mat-raised-button class="pale" style="float:right" (click)="toggleShowSignaturePad()">
                        <span i18n>Abbrechen</span>
                    </button>
                </h2>

                <mat-list *ngIf="form" class="mat-elevation-z1 signing-inputs" style="background: white;">
                    <mat-list-item class="show-all">
                        <mat-form-field style="flex: 1">
                            <input matInput formControlName="sign_legitimate" type="text" placeholder="Name des Berechtigten" required (change)="onFormChange()">
                        </mat-form-field>
                    </mat-list-item>
                    <!-- <mat-divider></mat-divider> -->
                    <mat-list-item class="show-all">
                        <mat-form-field style="flex: 1">
                             <textarea matInput placeholder="Kommentar des Berechtigten" i18n-placeholder formControlName="sign_note" (change)="onFormChange()"></textarea>
                        </mat-form-field>
                    </mat-list-item>
                </mat-list>

                <div #signaturePadWrapper class="mat-elevation-z1" >
                    <signature-pad  [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
                </div>

                <div class="button-wrapper" style="margin-top: 15px; justify-content: end">
                    <button type="button" mat-flat-button class="pale flex spinner-button" style="margin-right: 15px" (click)="resetDraw()" [disabled]="submitting">
                        <mat-spinner diameter="20" *ngIf="submitting" ></mat-spinner>
                        <fa-icon mat-icon [icon]="faUndo" style="margin-right: 8px;"></fa-icon>
                        <span i18n>Zurücksetzen</span>
                    </button>
                    <button type="submit" mat-raised-button color="primary" class="flex spinner-button" [disabled]="submitting">
                        <mat-spinner diameter="20" *ngIf="submitting" ></mat-spinner>
                        <fa-icon mat-icon [icon]="faCheck" style="margin-right: 8px;"></fa-icon>
                        <span i18n>Bestätigen</span>
                    </button>
                </div>
            </ng-container>
        </form>
    </ng-container>
    <ng-container *ngIf="job !== undefined && !job?.crew?.length">
        <h2 class="mat-h2" i18n>Es ist keine Crew in diesem Job vorhanden, daher kann auch kein {{orga?.timesheet_wording ? orga.timesheet_wording.singular : 'Arbeitsschein'}} erstellt werden.</h2>
    </ng-container>
    <ng-container *ngIf="job === null">
        <h2 class="mat-h2" i18n>Es konnten keine Daten gefunden werden. Internetverbindung vorhanden?</h2>
    </ng-container>
</main>
