import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import { ScrollLocations } from '../../core/globals/scroll-locations';

import { untilDestroyed } from 'ngx-take-until-destroy';

import {
  faCheck,
  faPlus,
  faTimes,
  faHandPointUp
} from '@fortawesome/free-solid-svg-icons';
import { WorkService } from '../shared/work.service';
import { WorkSchedule, WorkScheduleEntry, WorkScheduleBreak } from '../shared/work-schedule.model';
import { JobService } from 'src/app/jobs/shared/job.service';
import { ProjectService } from 'src/app/projects/shared/project.service';
import { QualificationService } from 'src/app/core/services/qualification.service';
import { formatDuration } from 'src/app/core/utils/number-utils';
import { TimelineEntry, Action } from 'src/app/shared/timeline/timeline.component';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { WorkScheduleDialogComponent, defaultDialogOptions, DialogType } from '../work-schedule-dialog/work-schedule-dialog.component';
import { WorkScheduleBreakDialogComponent, defaultBreakDialogOptions, BreakDialogType } from '../work-schedule-break-dialog/work-schedule-break-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { createDateFromDateAndTime, createDateFromDatetime } from 'src/app/core/utils/date-utils';
import { formatDate } from '@angular/common';

import { MyErrorHandler } from '../../core/classes/my-error-handler.class';

@Component({
  selector: 'app-work-schedule-overview',
  templateUrl: './work-schedule-overview.component.html',
  styleUrls: ['./work-schedule-overview.component.css']
})
export class WorkScheduleOverviewComponent extends ComponentInit implements OnInit{

  faCheck = faCheck;
  faPlus = faPlus;
  faHandPointUp = faHandPointUp;

  date:Date;
  workSchedule: WorkSchedule;
  breakSuggestion: any;
  entries = [];
  total = 0;
  totalProjects = 0;
  jobs = {};
  projects = {};
  qualifications = {};
  comment = new FormControl('');
  endDate = new FormControl();
  endTime = new FormControl();

  endChanged = false;
  commentChanged = false;

  futureDateError:Boolean = false;

  readonly:boolean = false;

  actions:Action[] = [
    {icon: faTimes, callback: this.deleteTimelineEntry.bind(this), title: "Löschen"}
  ]

  constructor(
    private workService: WorkService,
    private jobService: JobService,
    private projectService: ProjectService,
    private qualificationService: QualificationService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    protected router: Router,
    private myErrorHandler: MyErrorHandler,
    protected scrollLocations: ScrollLocations,
    @Inject(LOCALE_ID) private locale:string,
  ) {
    super(router, scrollLocations, route);
  }

  init(): void {
    this.date = new Date();
    this.endDate.setValue(formatDate(this.date, 'yyyy-MM-dd', this.locale));
    this.endTime.setValue(formatDate(this.date, 'HH:mm', this.locale));
    this.date = createDateFromDateAndTime(this.endDate.value, this.endTime.value);

    // Get Entry
    if(this.route.snapshot.data['current']){
        this.readonly = false;
        this.workService.preselectedDateOut = this.date;
        this.workService.getCurrent().pipe(untilDestroyed(this)).subscribe(this.setWorkSchedule.bind(this));
        setTimeout(() => { this.workService.preselectedDateOut = null; });
    } else {
        // this.readonly = true;
        this.route.data.subscribe(data => {
            if(data.resolved.model) this.setWorkSchedule(data.resolved.model);
            data.resolved.updates.subscribe(res => {
                if(res) this.setWorkSchedule(res);
            });
        });
    }
  }

  reload(): void {
    if(this.route.snapshot.data['current']){
        this.workService.getCurrent();
    }
  }

  reloadData(){
    this.workService.preselectedDateOut = this.date;
    super.reloadData();
    setTimeout(() => { this.workService.preselectedDateOut = null; });
  }

  loadJob(orga: number, id: number) {
    if (typeof this.jobs[id] !== 'undefined') return Promise.resolve();
    this.jobs[id] = null;

    return this.jobService.getByOrganizationAndId(orga, id)
      .toPromise()
      .then(job => this.jobs[job.id] = job);
  }

  loadProject(orga: number, id: number): Promise<any> {
    if (typeof this.projects[id] !== 'undefined') return Promise.resolve();
    this.projects[id] = null;

    return this.projectService.getByOrganizationAndId(orga, id)
      .toPromise()
      .then(project => this.projects[project.id] = project);
  }

  loadQualification(orga: number, id: number): Promise<any> {
    if (typeof this.qualifications[id] !== 'undefined') return Promise.resolve();
    this.qualifications[id] = null;

    return this.qualificationService.getByOrganizationAndId(orga, id)
      .toPromise()
      .then(qualification => this.qualifications[qualification.id] = qualification);
  }

  /* sanitizeWorkSchedule(){
      if(!this.workSchedule) return;

      // Start & End Date
      this.workSchedule.entries.forEach(entry => {
          if(entry.start) entry.start = formatDate(entry.start);
      })

  } */

  setWorkSchedule(ws: WorkSchedule) {
    this.workSchedule = ws;

    // adjust current date
    if(ws && ws.date_out){
      if(!this.endChanged){
        this.date = createDateFromDatetime(ws.request_pending ? ws.request_pending.data.date_out : ws.date_out);
        this.endDate.setValue(formatDate(this.date, 'yyyy-MM-dd', this.locale));
        this.endTime.setValue(formatDate(this.date, 'HH:mm', this.locale));
      }
    }


    if(!this.breakSuggestion && ws && !ws.date_out){
      this.breakSuggestion = ws.break_suggestion;
      if(this.breakSuggestion)
        this.breakSuggestion.duration = this.calcDuration(this.breakSuggestion.start, this.breakSuggestion.end);
    } else if(this.breakSuggestion && ws && !ws.break_suggestion){
      this.breakSuggestion = null;
    }

    if(ws && !ws.date_out) this.readonly = false;

    if(!this.commentChanged)
      this.comment.setValue(ws ? ws.comment : '');

    if(ws){
        this.updateEntries(ws.entries, ws.breaks);
    } else this.entries = [];

    // Check if difference of this.date and date_in is to big
    if(ws && !ws.date_out){
        if(this.calcDuration(ws.date_in, this.date) > 24){
            let date_new = createDateFromDatetime(ws.date_in);
            date_new = new Date(date_new.getTime() + (9 * 60000 * 60));

            this.endDate.setValue(formatDate(date_new, 'yyyy-MM-dd', this.locale));
            this.endTime.setValue(formatDate(date_new, 'HH:mm', this.locale));
            this.date = createDateFromDateAndTime(this.endDate.value, this.endTime.value);
            this.updateDuration();
            this.breakSuggestion = null;
            this.reloadData();
        }
    }

  }

  calcDuration(start, end) {
    if(!start || !end) return;

    if(typeof start == 'string') start = createDateFromDatetime(start);
    if(typeof end == 'string') end = createDateFromDatetime(end);

    return (end.getTime() - start.getTime()) / 3600000;
  }

  /**
   * Updates total duration
   */
  updateDuration() {
    const ws = this.workSchedule;
    this.total = ws.date_out && ws.duration ? ws.duration : this.calcDuration(ws.date_in, ws.date_out || this.date) - this.totalDurationOfBreaks;

    if(ws.entries.length){
        // Check if last entry has no end yet -> update its duration using ws.date_out as end
        let last_entry = ws.entries[ws.entries.length - 1];
        if(last_entry && !last_entry.end){
            ws.entries[ws.entries.length - 1].duration = this.calcDuration(last_entry.start, this.workSchedule.date_out || this.date);
            this.setTimelineEntries(ws.entries);
        }


        this.totalProjects = ws.entries.reduce((total, entry) => {
          return total + (entry.end ? this.calcDuration(entry.start, entry.end) : entry.duration);
        }, 0);



    } else {
        this.totalProjects = 0;
    }

  }

  updateEntries(workEntries: WorkScheduleEntry[] = this.workSchedule.entries, breaks: WorkScheduleBreak[] = this.workSchedule.breaks, breakSuggestion: any = this.breakSuggestion) {
    let promises = [];

    if(workEntries && Array.isArray(workEntries)){
        workEntries.forEach(entry => {
          if(entry.job){
            promises.push(this.loadJob(entry.organization, entry.job));
          }

          if(entry.project){
            promises.push(this.loadProject(entry.organization, entry.project));
          }

          if(entry.qualification){
            promises.push(this.loadQualification(entry.organization, entry.qualification));
          }

          if (!entry.duration && entry.start && entry.end) {
            entry.duration = this.calcDuration(entry.start, entry.end);
          }

        });
    }

    this.updateDuration();
    // after al jobs & projects are loaded, construct list entries
    Promise.all(promises).then(() => {
      this.workSchedule ? this.setTimelineEntries(this.workSchedule.entries, breaks, breakSuggestion) : this.entries = []
    });

  }

  /**
   * Sets entries for timeline
   * @param workEntries
   */
  setTimelineEntries(workEntries?: WorkScheduleEntry[], breaks?: WorkScheduleBreak[], breakSuggestion?: any) {
    this.entries = [];
    if(workEntries && workEntries.length) {
        this.entries = workEntries.map(entry => {
          let duration_complete = entry.end ? this.calcDuration(entry.start, entry.end) : this.calcDuration(entry.start, this.date)
          let duration = entry.end ? entry.duration : this.calcDuration(entry.start, this.date);

          return {
            id: entry.id,
            type: 'work_time_assign',
            start: createDateFromDatetime(entry.start),
            end: entry.end ? createDateFromDatetime(entry.end) : null,
            title: entry.project && this.projects[entry.project] ? <string> this.projects[entry.project].title : entry.comment,
            subtitle: (entry.qualification && this.qualifications[entry.qualification] ?
                <string> this.qualifications[entry.qualification].title : '') +
                (entry.qualification && this.qualifications[entry.qualification] && entry.comment ? ' - ' : '') +
                (entry.comment || ''),
            aside: formatDuration(duration_complete) + (duration > 0 && duration < duration_complete && duration_complete - duration > 0.01 ?
                (' <small>(-' + formatDuration(duration_complete - duration) + ')</small>') : '')
          }
        });
    }

    if(breaks){
        this.entries = this.entries.concat(breaks.map(b => {
            return {
                id: b.id,
                type: 'break',
                start: createDateFromDatetime(b.start),
                end: b.end ? createDateFromDatetime(b.end) : null,
                title: 'Pause',
                aside: b.duration.substring(0, 5),
                color: 'ye'
            }
        }));
    }

    if(breakSuggestion){
      this.entries.push({
        id: -1,
        type: 'break_suggestion',
        start: createDateFromDatetime(breakSuggestion.start),
        end: createDateFromDatetime(breakSuggestion.end),
        title: 'Pause',
        aside: formatDuration(this.calcDuration(breakSuggestion.start, breakSuggestion.end)),
        color: 'ye',
        disableAction: true
      })
    }

    return this.entries;
  }

  formatDuration(val){
      return formatDuration(val);
  }

  get totalDuration(): string {

    return formatDuration(Math.max(0, this.total));
  }

  get totalDurationOfBreaks() : number {
    return this.workSchedule.break / 60 + (this.breakSuggestion ? this.breakSuggestion.duration : 0);
  }

  get totalProjectDuration(): string {
      return formatDuration(Math.max(0, this.totalProjects));
  }

  get totalDurationWithoutProjects(): number {
      return Math.max(0, this.total + this.totalDurationOfBreaks - this.totalProjects);
      // return Math.max(0, this.total + (this.workSchedule.date_out ? (this.workSchedule.break / 60) : 0) - this.totalProjects);
  }

  submit() {
    this.futureDateError = false;
    if (this.date > new Date) {

      this.futureDateError = true;
      return;
    }

    // Replace end of entries with "this.date" when value is null
    this.workSchedule.entries.forEach(entry => {
        if(entry.end === null) entry.end = formatDate(this.date, 'yyyy-MM-dd HH:mm:ss', this.locale);
    });

    if(this.breakSuggestion){
      this.workService.createBreak(this.workSchedule.organization, this.workSchedule.id, {
          start: this.breakSuggestion.start,
          end: this.breakSuggestion.end,
          automatic: true
      }).pipe(untilDestroyed(this)).subscribe(
          () => this.endWorkSchedule(),
          err => this.myErrorHandler.showError(err)
      );
    } else {
      this.endWorkSchedule();
    }
  }


  endWorkSchedule(){
    // save work schedule
    if(this.route.snapshot.data['current']){
        this.workService.endCurrWorkSchedule(this.comment.value, this.date, this.workSchedule.entries, this.breakSuggestion)
          .subscribe(
              () => this.router.navigateByUrl('/'),
             err => {
               this.reloadData();
               this.myErrorHandler.showError(err);
            }
          );
    } else {
        this.workService.endWorkSchedule(this.comment.value, this.date, this.workSchedule.entries, this.workSchedule, this.breakSuggestion)
          .subscribe(
              () => this.router.navigateByUrl('/work-schedules'),
              err => {
                this.reloadData();
                this.myErrorHandler.showError(err);
              }
          );
    }
  }

  addEntry() {

    this.dialog.open(WorkScheduleDialogComponent, {
      ...defaultDialogOptions,
      data: {
        type: DialogType.CreateProject,
        schedule: this.workSchedule,
      }
    }).afterClosed().subscribe(entry => {
      if (!entry) return;

      this.workSchedule.entries.push(entry);
      this.updateEntries(this.workSchedule.entries);
    })
  }

  editWorkTimeAssign(entry: WorkScheduleEntry) {

    this.dialog.open(WorkScheduleDialogComponent, {
      ...defaultDialogOptions,
      data: {
        type: DialogType.EditProject,
        entry: entry,
        schedule: this.workSchedule,
        job: this.jobs[entry.job],
        project: this.projects[entry.project],
        date: this.date
      }
    }).afterClosed().subscribe(entry => {
      if (!entry) return;

      this.workSchedule.entries[this.workSchedule.entries.findIndex(e => e.id === entry.id)] = entry;
      this.updateEntries();
    });
  }

  deleteWorkTimeAssignById(id: number) {
      this.workService.deleteWorkScheduleEntry(this.workSchedule.organization, id).subscribe(
        () => {
            const index = this.workSchedule.entries.findIndex(e => e.id === id);
            if (index === -1) return;

            this.workSchedule.entries.splice(index, 1);
            this.updateEntries();
        },
        err => this.myErrorHandler.showError(err)
      )

  }

  createBreak() {
    this.dialog.open(WorkScheduleBreakDialogComponent, {
      ...defaultBreakDialogOptions,
      data: {
        type: BreakDialogType.CreateBreak,
        ws: this.workSchedule,
        ws_date_out: this.date
      }
    }).afterClosed().subscribe(entry => {
      if (!entry) return;

      this.breakSuggestion = null;
      this.workSchedule.breaks.push(entry);
      this.updateEntries();
      this.reloadData();
    })
  }

  editBreak(entry: WorkScheduleBreak) {

    this.dialog.open(WorkScheduleBreakDialogComponent, {
      ...defaultBreakDialogOptions,
      data: {
        type: BreakDialogType.EditBreak,
        id: entry.id,
        ws: this.workSchedule,
        ws_date_out: this.date,
        start: entry.start,
        end: entry.end
      }
    }).afterClosed().subscribe(entry => {
      if (!entry) return;
      this.breakSuggestion = null;
      if(this.workSchedule.breaks.findIndex(e => e.id === entry.id) >= 0)
        this.workSchedule.breaks[this.workSchedule.breaks.findIndex(e => e.id === entry.id)] = entry;
      this.updateEntries();
      this.reloadData();
    });
  }

  editBreakSuggestion(entry: any) {

    this.dialog.open(WorkScheduleBreakDialogComponent, {
      ...defaultBreakDialogOptions,
      data: {
        type: BreakDialogType.EditBreak,
        ws: this.workSchedule,
        ws_date_out: this.date,
        start: entry.start,
        end: entry.end
      }
    }).afterClosed().subscribe(entry => {
      if (!entry) return;

      this.breakSuggestion = null;
      // this.workSchedule.breaks.push(entry);
      this.updateEntries();
      this.reloadData();
    });
  }

  deleteBreakById(id: number) {
      this.workService.deleteBreak(this.workSchedule.organization, id).subscribe(
        () => {
            this.breakSuggestion = null;
            this.workSchedule.breaks = this.workSchedule.breaks.filter(b => b.id != id);
            this.updateEntries();
            this.reloadData();
        },
        err => this.myErrorHandler.showError(err)
      )

  }

  editTimelineEntry(entry: TimelineEntry) {
    if(entry.type == 'break'){
      this.editBreak(this.workSchedule.breaks.find(e => e.id === entry.id));
    } else if(entry.type == 'break_suggestion'){
      this.editBreakSuggestion(this.breakSuggestion);
    } else {
      this.editWorkTimeAssign(this.workSchedule.entries.find(e => e.id === entry.id));
    }
  }

  deleteTimelineEntry(entry: TimelineEntry) {
    if(entry.type == 'break'){
      if(window.confirm("Willst du wirklich diese Pause löschen?"))
          this.deleteBreakById(entry.id);
    } else {
      if(window.confirm("Willst du wirklich diesen Projekteintrag löschen?"))
          this.deleteWorkTimeAssignById(entry.id);
    }
  }

  updateEnd() {
    this.endChanged = true;
    this.date = createDateFromDateAndTime(this.endDate.value, this.endTime.value);
    this.updateDuration();
    this.breakSuggestion = null;
    this.reloadData();
  }

  onCommentChange(){
    this.commentChanged = true;
  }
}
