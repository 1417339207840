<app-single-page title="Neues Passwort setzen">
  <div class="small flex-container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container" *ngIf="!success" autocomplete="off">
      <mat-form-field>
        <input matInput type="password" autocomplete="new-password" placeholder="Neues Passwort" i18n-placeholder formControlName="password" required>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="password" autocomplete="new-password" placeholder="Passwort wiederholen" i18n-placeholder formControlName="passwordRepeat" required>
        <mat-error i18n>Passwörter sind nicht identisch</mat-error>
      </mat-form-field>
      <br />
      <button mat-raised-button color="primary" i18n class="spinner-button" [disabled]="formSubmitted">
        <mat-spinner diameter="20"></mat-spinner>
        Ändern
      </button>
      <a mat-button routerLink="/auth/login" class="color-text-grey" i18n>Zurück zum Login</a>
      <br />
    </form>
    <mat-error *ngIf="error && !success">
      {{error}}
    </mat-error>
    <div *ngIf="success">
      <p class="textbox-padding color-text-green" i18n>
        Dein Passwort wurde erfolgreich geändert
      </p>
      <a mat-button routerLink="/auth/login" color="primary">Zum Login</a>
    </div>
  </div>
</app-single-page>>
