import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

import { ListGroup } from '../../core/models/list-group.model';

@Injectable({
  providedIn: 'root'
})
export class ListGroupService{

  private groupsRaw: ListGroup<any>[] = [
      {slug:'past', name:'Vergangenheit', data:[]},
	  {slug:'today', name:'Heute', data:[]},
	  {slug:'tomorrow', name:'Morgen', data:[]},
	  {slug:'this_week', name:'Diese Woche', data:[]},
	  {slug:'next_week', name:'Nächste Woche', data:[]},
	  {slug:'future', name:'Weitere', data:[]}
  ];

  constructor(
	  @Inject(LOCALE_ID) private locale: string
  ){ }

  getGroups(entries = null, startKey = 'start', endKey = null){
    if(!entries) return this.groupsRaw;

    let groups = JSON.parse(JSON.stringify(this.groupsRaw));

    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);

    let tomorrow = new Date(Date.now()+86400000);  //+1Tag
    let nextWeek = new Date(Date.now()+86400000*7);  //+1Woche

    let todayYearWeek = this.getYearWeek(today);
    let nextYearWeek = this.getYearWeek(nextWeek);

    entries.forEach(entry => {
      // assign entries to specific lists
      let start = createDateFromDatetime(entry[startKey]);
      let end = endKey ? createDateFromDatetime(entry[endKey]) : null;

      let short = formatDate(start, 'shortDate', this.locale);
      let yearWeek = this.getYearWeek(start);

      if(end && end < today)
        groups[0].data.push(entry);
      else if(short == formatDate(today, 'shortDate', this.locale) || (start <= today && end >= today))
        groups[1].data.push(entry);
      else if(short == formatDate(tomorrow, 'shortDate', this.locale))
        groups[2].data.push(entry);
      else if(yearWeek == todayYearWeek)
        groups[3].data.push(entry);
      else if(yearWeek == nextYearWeek)
        groups[4].data.push(entry);
      else
        groups[5].data.push(entry);
    });

    return groups;
  }

  getYearWeek(date){
    // Get YearWeek
    let weekNumber = parseInt(formatDate(date, 'w', this.locale));

    // Fix Bug in angular with weekNumber:
    // date pipe does not recognize "locale" in case of first day of week. It handles sunday as first day of week,
    // so "sunday" will get the next week number. It was fixes in a later angular version
    if(date.getDay() == 0) weekNumber -= 1;

    return formatDate(date, 'y ', this.locale) + weekNumber;
  }

}
