<mat-list>
  <ng-template ngFor let-file [ngForOf]="files" let-i="index">
    <mat-divider *ngIf="i > 0"></mat-divider>
    <app-file-entry [file]="file" [categoriesAvailable]="categoriesAvailable" (fileUpdated)="filesUpdated.emit()"></app-file-entry>
    <!-- <a mat-list-item [href]="file.url+'&t='+accToken" [attr.data-editable]="file.editable" target="_blank">
      <fa-icon mat-list-icon [icon]="mapExtensionToIcon(file.extension)" [fixedWidth]="true"></fa-icon>
      <h4 mat-line class="color-text-primary">{{file.title}}</h4>
      <span>{{file.extension}}</span>
      <fa-icon *ngIf="file.editable" [icon]="faEdit" [fixedWidth]="true" class="pale edit_icon"
        (click)="editFile($event, file)"></fa-icon>
    </a> -->
  </ng-template>
</mat-list>
