import { Component, ViewChildren } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { TaskService } from '../shared/task.service';
import { Task } from '../shared/task.model';
import { SubTaskService } from '../shared/sub-task.service';
import { SubTask } from '../shared/sub-task.model';

import { MatTab } from '@angular/material';

import { MyErrorHandler } from '../../core/classes/my-error-handler.class';

import { faCalendarAlt, faInfo, faUsers, faRocket, faCheckSquare, faInfoCircle, faExclamation, faBriefcase, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faBuilding, faCalendarTimes, faFileAlt } from '@fortawesome/free-regular-svg-icons';

import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

@Component({
	selector: 'app-task-assign-detail',
	templateUrl: './task-detail.component.html',
	styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent extends ComponentInit {
	@ViewChildren(MatTab) tabs;

	faCalendarAlt = faCalendarAlt;
	faBuilding = faBuilding;
	faInfo = faInfo;
	faSquare = faSquare;
	faCheckSquare = faCheckSquare;
	faCalendarTimes = faCalendarTimes;
	faUsers = faUsers;
	faRocket = faRocket;
	faInfoCircle = faInfoCircle;
	faFileAlt = faFileAlt;
	faExclamation = faExclamation;
	faBriefcase = faBriefcase;
	faCheck = faCheck;

	task:Task;
	subTaskGroups: SubTask[][] = [];

	disableCompleteButton = false;

	fileCategories: any[] = [];
    fileUploading: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private ts: TaskService,
		private sts: SubTaskService,
		protected router: Router,
		protected scrollLocations: ScrollLocations,
		private location: Location,
		private myErrorHandler: MyErrorHandler
	) { super(router, scrollLocations, route); }

	init() {
		this.route.data.subscribe(data => {
			if(data.resolved.model) this.update(data.resolved.model);
			data.resolved.updates.subscribe(res => {
				if(res) this.update(res);
			});
		});
	}

	update(task){
		this.task = task;

		// build sub task groups
		if(task.sub_tasks){
			this.subTaskGroups = [
				// editable
				this.task.sub_tasks.filter(s => s.editable),
				// not editable
				this.task.sub_tasks.filter(s => !s.editable),
			].filter(a => a.length);
		}

		// group files by category
	    if(task.files){
	        this.fileCategories = [];
	        task.files.forEach(file => {
	            let category = this.fileCategories.find(val => val.id == (file.cat ? file.cat.id : 0));
	            if(!category){
	                this.fileCategories.push({
	                    id: file.cat ? file.cat.id : 0,
	                    title: file.cat ? file.cat.title : 'Ohne Kategorie',
	                    files: [file]
	                });
	            } else {
	                category.files.push(file);
	            }
	        });
	    }
	}

	deadlineDateFormat(){
		let format = 'dd.MM.yy';
		if(this.task.deadline){
			let date = createDateFromDatetime(this.task.deadline);
		    if(date.getFullYear() == (new Date()).getFullYear())
		       format = 'dd.MM.';
		}
		return format;
	}

	routeProject(project){
    	this.router.navigate(['/projects/'+this.task.organization+'/'+project.id]);
    }

	routeJob(job){
    	this.router.navigate(['/jobs/'+this.task.organization+'/'+job.id]);
    }

	completeButton(){
		this.disableCompleteButton = true;
		this.complete(() => {
			this.location.back();
		});
	}
	complete(callback?: Function){
		if(!callback) this.task.completed = 1;

		// Check if some sub tasks are not completed yet
		if(!this.task.sub_tasks || !this.task.sub_tasks.filter(s => !s.completed).length
			|| window.confirm('Es wurden noch nicht alle Punkte von der Checkliste abgehakt, möchtest du trotzdem diese Aufgabe als erledigt markieren?')
		){
			return this.ts.complete(this.task).subscribe(() => {
		    	this.disableCompleteButton = false;
				this.task.completed = 1;
				if(callback){
					callback();
				}
		    }, err => {
				this.task.completed = 0;
				this.disableCompleteButton = false;
				this.myErrorHandler.showError(err)
		    });
		} else {
			this.disableCompleteButton = false;
		}
	}
	incomplete(){
		this.task.completed = 0;
		return this.ts.incomplete(this.task).subscribe(() => {
			this.task.completed = 0;
		}, err => {
			this.task.completed = 1;
			this.myErrorHandler.showError(err)
	    });
	}

	completeToggle(){
		if(this.task.completed) this.incomplete();
		else this.complete();
	}

	subTaskCompleteToggle(event){
		let sub_task = this.task.sub_tasks.find(s => s.id == event.option.value);
		let request;
		if(event.option.selected){
			request = this.sts.complete(sub_task);
		} else {
			request = this.sts.incomplete(sub_task);
		}

		return request.subscribe(res => {
			// callback success
			this.task.sub_tasks = this.task.sub_tasks.map(s => s.id == sub_task.id ? res : s);
		}, err => this.myErrorHandler.showError(err));
	}

	onFileAdded(){
      this.reloadData();
      document.querySelector('.mat-tab-body-active main').scrollTo(0, 0);
    }
}
