var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { faRocket, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, } from '@fortawesome/free-regular-svg-icons';
import { ProjectService } from '../shared/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ListGroupService } from '../../core/services/list-group.service';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { NumberFilter, GroupedSelectFilter, DateFilter } from 'src/app/shared/filters/shared/classes';
var ProjectListComponent = /** @class */ (function (_super) {
    __extends(ProjectListComponent, _super);
    function ProjectListComponent(route, projectService, listGroupService, locale, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.projectService = projectService;
        _this.listGroupService = listGroupService;
        _this.locale = locale;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.loadError = false;
        _this.faRocket = faRocket;
        _this.faUser = faUser;
        _this.faCalendarAlt = faCalendarAlt;
        _this.filterInputs = {
            date_from: new DateFilter({
                label: 'Ab',
                value: new Date()
            }),
            weeks: new NumberFilter({
                label: 'Wochen',
                value: 3,
                storable: true
            }),
            responsible: new GroupedSelectFilter({
                label: 'Verantwortl.',
                options: [{ value: '0', label: 'Alle' }],
                value: '0',
                storable: true
            }),
            status: new GroupedSelectFilter({
                label: 'Status',
                options: [{ value: '0', label: 'Alle' }],
                value: '0',
                storable: true
            })
        };
        _this.listGroups = _this.listGroupService.getGroups();
        return _this;
    }
    ProjectListComponent.prototype.reload = function () {
        this.filterComponent && this.filterComponent.reload();
    };
    ProjectListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getResponsibles();
    };
    ProjectListComponent.prototype.routeProject = function (p) {
        this.router.navigate(['projects', p.organization, p.id]);
    };
    ProjectListComponent.prototype.getProjects = function (options) {
        var _this = this;
        // clone options
        options = __assign({}, options);
        var date_to = new Date();
        date_to.setDate(options.date_from.getDate() + (7 * options.weeks));
        delete options.weeks;
        this.projectService.getAllForCurrentAccount(__assign({}, options, { date_to: date_to })).subscribe(function (res) {
            if (!res)
                return;
            // Check if data has changed
            if (JSON.stringify(_this.projects) == JSON.stringify(res))
                return;
            _this.listGroups = _this.listGroupService.getGroups(res, 'date_start', 'date_start');
            _this.projects = res;
        }, function (res) {
            _this.loadError = true;
        });
    };
    ProjectListComponent.prototype.reloadFilters = function () {
        this.filterInputs = __assign({}, this.filterInputs);
    };
    ProjectListComponent.prototype.getResponsibles = function () {
        var _this = this;
        this.projectService.getFilterOptionsForCurrentAccount().subscribe(function (res) {
            if (!res)
                return;
            // reset some filters
            var emptyOption = { value: '0', label: 'Alle' };
            _this.filterInputs.responsible.options = [emptyOption];
            _this.filterInputs.status.options = [emptyOption];
            var _loop_1 = function (orga_id) {
                // Company
                var company_title = res[orga_id].company.title;
                // Responsible
                if (res[orga_id].responsibles) {
                    // Build Responsible array
                    var responsibles_1 = [{
                            value: orga_id + '-0',
                            label: "Alle von " + company_title
                        }];
                    res[orga_id].responsibles.forEach(function (user) {
                        var entry = {
                            value: orga_id + '-' + user.id,
                            label: user.first_name + " " + user.last_name
                        };
                        responsibles_1.push(entry);
                    });
                    // add entry for option group with company title
                    _this.filterInputs.responsible.options.push({
                        label: company_title,
                        options: responsibles_1
                    });
                }
                // Status
                if (res[orga_id].status) {
                    // Build status array
                    var status_arr_1 = [{
                            value: orga_id + '-0',
                            label: "Alle von " + company_title
                        }];
                    res[orga_id].status.forEach(function (status_single) {
                        var entry = {
                            value: orga_id + '-' + status_single.id,
                            label: status_single.title
                        };
                        status_arr_1.push(entry);
                    });
                    // add entry for option group with company title
                    _this.filterInputs.status.options.push({
                        label: company_title,
                        options: status_arr_1
                    });
                }
            };
            for (var orga_id in res) {
                _loop_1(orga_id);
            }
            _this.reloadFilters();
        });
    };
    return ProjectListComponent;
}(ComponentInit));
export { ProjectListComponent };
