<main>
  <ng-container *ngIf="event">
    <h2 class="mat-h2 app-list-heading">Informationen</h2>
    <mat-list>
      <mat-list-item class="mat-elevation-z1">
        <fa-icon mat-list-icon [icon]="faCalendarCheck"></fa-icon>
        <span mat-line><b>{{event.title}}</b></span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="mat-elevation-z1">
        <fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
        <span mat-line>{{event.company.title}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="mat-elevation-z1">
        <fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
        <span mat-line><app-date-span [start]="event.start" [end]="event.end"></app-date-span></span>
      </mat-list-item>
      <ng-container *ngIf="event.address">
        <mat-divider></mat-divider>
        <mat-list-item class="mat-elevation-z1">
          <fa-icon mat-list-icon [icon]="faMapMarker"></fa-icon>
          <span mat-line>{{event.address.text}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="mat-elevation-z1" *ngIf="event.address_note">
          <fa-icon mat-list-icon [icon]="faInfo"></fa-icon>
          <span mat-line>{{event.address_note}}</span>
        </mat-list-item>
      </ng-container>
    </mat-list>

    <ng-container *ngIf="event.participants">
        <h2 class="mat-h2 app-list-heading" i18n>Teilnehmer</h2>
        <mat-card class="content-section">
          <mat-card-content>
              <span *ngFor="let participant of event.participants; last as isLast">
                  <span>{{participant.first_name}} {{participant.last_name}}</span>
                  <span *ngIf="!isLast">, </span>
              </span>
          </mat-card-content>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="event.description">
        <h2 class="mat-h2 app-list-heading" i18n>Notizen</h2>
        <mat-card class="content-section">
          <mat-card-content [innerHTML]="event.description"></mat-card-content>
        </mat-card>
    </ng-container>
  </ng-container>
</main>
