<app-single-page title="Accountaktivierung">
  <mat-spinner *ngIf="requesting"></mat-spinner>
  <p class="textbox-padding color-text-green" *ngIf="activated && !requesting" i18n>
    Dein Account wurde erfolgreich aktiviert. <br />
    Du wirst in {{countdown}}s zur Anmeldung weitergeleitet.
  </p>
  <div *ngIf="error">
    <p class="textbox-padding color-text-red">
      {{error}}
    </p>
    <a mat-button routerLink="/auth/activate/resend" class="color-text-grey">Neuen Aktivierungslink anfordern</a>
  </div>
</app-single-page>
