import { OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { DeviceService } from '../../../core/services/device.service';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FilterType } from '../shared/interfaces';
import { toggelAllFilters } from '../shared/utils';
var FilterHeaderComponent = /** @class */ (function () {
    function FilterHeaderComponent(dialog, device) {
        this.dialog = dialog;
        this.device = device;
        this.callback = new EventEmitter();
        this.inputKeys = Object.keys;
        this.filterValues = {};
        this.filterType = FilterType;
        this.faFilter = faFilter;
    }
    Object.defineProperty(FilterHeaderComponent.prototype, "inputs", {
        get: function () {
            return this._inputs;
        },
        set: function (inputs) {
            this._inputs = inputs;
            // Combine saved values
            var savedValues = this.getSavedValues();
            if (savedValues)
                for (var key in savedValues)
                    this._inputs[key].value = savedValues[key];
            toggelAllFilters(this.inputs);
            this.setFilterValues(this._inputs);
        },
        enumerable: true,
        configurable: true
    });
    FilterHeaderComponent.prototype.getSavedValues = function () {
        var store = JSON.parse(localStorage.getItem('filterValues'));
        if (!store)
            return;
        // Check if field is storable
        for (var key in store[this.storage])
            if (!this._inputs[key].storable)
                delete store[this.storage][key];
        return store[this.storage];
    };
    FilterHeaderComponent.prototype.saveValues = function (data) {
        var store = JSON.parse(localStorage.getItem('filterValues'));
        if (!store)
            store = {};
        // Check if field is storable
        for (var key in data)
            if (this._inputs[key] && this._inputs[key].storable)
                store[key] = data[key];
        store[this.storage] = data;
        localStorage.setItem('filterValues', JSON.stringify(store));
    };
    /**
     * Sets filter values
     * @param filters
     */
    FilterHeaderComponent.prototype.setFilterValues = function (filters) {
        this.filterValues = {};
        for (var key in filters) {
            // do not include filters with no selection
            if (filters[key].value === null)
                continue;
            this.filterValues[key] = filters[key].value;
        }
    };
    FilterHeaderComponent.prototype.ngOnInit = function () {
        this.reload();
    };
    FilterHeaderComponent.prototype.reload = function () {
        this.callback.emit(this.filterValues);
    };
    FilterHeaderComponent.prototype.showFilter = function () {
        var _this = this;
        this.dialog.open(FilterDialogComponent, { panelClass: 'bam', autoFocus: false, data: {
                inputs: this._inputs
            } }).afterClosed().subscribe(function (data) {
            if (!data)
                return;
            // Save new values in local Storage
            _this.saveValues(data);
            // Attach new values to _inputs
            for (var key in data)
                _this._inputs[key].value = data[key];
            _this.setFilterValues(_this._inputs);
            _this.reload();
        });
    };
    return FilterHeaderComponent;
}());
export { FilterHeaderComponent };
