import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QualificationService = /** @class */ (function () {
    function QualificationService(http, locale) {
        this.http = http;
        this.locale = locale;
    }
    QualificationService.prototype.getByOrganizationAndId = function (orga_id, id) {
        return this.http.get('/v1/organizations/' + orga_id + '/qualifications/' + id);
    };
    /**
     * Returns all qualfications of current staff for specific organization
     */
    QualificationService.prototype.getAllByOrganizazionAndCurrentStaff = function (orga_id, options) {
        if (options === void 0) { options = {}; }
        var params = {};
        if (options.project)
            params['project'] = options.project;
        if (options.date)
            params['date'] = formatDate(options.date, 'yyyy-MM-dd', this.locale);
        return this.http.get('/v1/organizations/' + orga_id + '/staffs/current/qualifications/', { params: params });
    };
    QualificationService.ngInjectableDef = i0.defineInjectable({ factory: function QualificationService_Factory() { return new QualificationService(i0.inject(i1.HttpClient), i0.inject(i0.LOCALE_ID)); }, token: QualificationService, providedIn: "root" });
    return QualificationService;
}());
export { QualificationService };
