<app-filter-header [inputs]="filters" storage="tour-list" (callback)="getTours($event)">
  <main>
    <app-loading-spinner [loading]="!tours" [loadError]="loadError" [retry]="reload"></app-loading-spinner>
    <p class="text-top color-text-grey text-center" *ngIf="tours && tours.length == 0">
      Keine Einträge vorhanden
    </p>
    <ng-container *ngFor="let list of listGroups">
      <ng-container *ngIf="list.data.length > 0">
        <h2 i18n class="mat-h2 app-list-heading">{{list.name}}</h2>
        <mat-nav-list>
          <a mat-list-item class="round-list-item mat-elevation-z1 mat-1-5-line" (click)="routeTour(t)" *ngFor="let t of list.data">
            <fa-layers mat-list-icon class="fa-fw">
              <fa-icon [icon]="faTruck" class="color-text-primary"></fa-icon>
              <fa-icon [icon]="faCheck" class="background-green secondary-icon" *ngIf="t.completed || t.tour_completed"></fa-icon>
            </fa-layers>
            <h4 mat-line>{{t.title}}&nbsp;-&nbsp;<span *ngIf="t.vehicle">{{t.vehicle.title}}</span></h4>
            <div mat-line class="mat-line-double">
              <div>
                <div style="flex:1; margin-right: 5px;">
                  <fa-icon [icon]="faCalendarAlt"></fa-icon>&nbsp;
                  <app-date-span [start]="t.date_start" [end]="t.date_end"></app-date-span>&nbsp;
                </div>
                <div style="display: flex; overflow: hidden;">
                  <div class="word_wrap">
                    <span *ngFor="let staff of t.drivers.driver; last as isLast">
                        <span>{{staff.first_name}} {{staff.last_name}}</span>
                        <span *ngIf="!isLast">, </span>
                    </span>
                    <span *ngIf="t.drivers.driver && t.drivers.driver.length > 0
                        && t.drivers['co-driver'] && t.drivers['co-driver'].length > 0"> | </span>
                    <span *ngFor="let staff of t.drivers['co-driver']; last as isLast">
                        <span>{{staff.first_name}} {{staff.last_name}}</span>
                        <span *ngIf="!isLast">, </span>
                    </span>
                    &nbsp;
                  </div>
                  <fa-icon [icon]="faUsers" style="flex-shrink: 0;"></fa-icon>
                </div>
              </div>
              <div>
                <fa-icon [icon]="faBriefcase"></fa-icon>&nbsp;&nbsp;
                <span class="word_wrap">{{ getJobsString(t) }}</span>
              </div>

            </div>
          </a>
        </mat-nav-list>
      </ng-container>
    </ng-container>
  </main>
</app-filter-header>
