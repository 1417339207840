<!--
  der benutzer gelangt hierhin über einen Link , welcher einen token beinhaltet mit clientId && staffId (oder mehr, falls bekannt)
  (is_user) wird nicht benötigt, dies wird beim erstellen des accounts ermittelt

  der benutzer entscheided sich dann hier, ob er seinen vorhandenen account benutzen möchte, oder einen neuen erstellen möchte
  je nachdem wird er weitergeleitet

  bei vorhandenen account, wird an die login page der token übergeben und bei erfolgreichem login kann dieser für einen request an den server vernwedet werden.

  andernfalls wird er mit token an eine neue seite zum erstellen eines accounts weitergeleitet

  der user soll die möglichkeit haben zurück-zu-navigieren
-->
<app-single-page title="{{dashboardRedirect ? 'Neue App' : 'Einladung' }}">
  <p class="color-text-red" *ngIf="tokenError" i18n>Der Einladungslink ist ungültig</p>
  <p *ngIf="!tokenError && !dashboardRedirect" i18n>
      Du hast eine Einladung von "<strong>{{token.org}}</strong>" erhalten und kannst somit einen bestehenden Lupax-Account mit dieser Firma verknüpfen oder einen neuen erstellen.
  </p>
  <p *ngIf="!tokenError && dashboardRedirect" i18n>
    Das <strong>Mobile-Dashboard</strong>, das du bis jetzt genutzt hast, wurde durch eine neue App (PWA) ersetzt. Um die neue App zu nutzen, musst du dir einen Account erstellen und ein eigenes Passwort vergeben.<br /><br />
    Wenn du diese Seite "zum Startbildschirm hinzufügst", wird sie im Hintergrund als richtige App installiert. Bei Android sollte automatisch eine Nachfrage kommen, iOs Nutzer müssen die "Teilen" Funktion benutzen.
  </p>

  <div class="acc-selection-container" *ngIf="!tokenError">
    <a mat-raised-button class="mat-primary" [routerLink]="['/auth/register/'+rawToken]">Neuen Account erstellen</a>
    <a class="small" [routerLink]="['/auth/login/'+rawToken]"><small>Bestehenden Account verwenden</small></a>
  </div>
</app-single-page>
