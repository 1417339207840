var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EventService = /** @class */ (function (_super) {
    __extends(EventService, _super);
    function EventService(http) {
        var _this = _super.call(this, http, 'events') || this;
        _this.http = http;
        return _this;
    }
    EventService.prototype.getByOrganizationAndId = function (orga, id) {
        var data = this.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/events/' + id);
        return this.cachedRequest(data, http);
    };
    EventService.ngInjectableDef = i0.defineInjectable({ factory: function EventService_Factory() { return new EventService(i0.inject(i1.HttpClient)); }, token: EventService, providedIn: "root" });
    return EventService;
}(CachableModelService));
export { EventService };
