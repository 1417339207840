/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invitation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "../shared/single-page/single-page.component.ngfactory";
import * as i10 from "../shared/single-page/single-page.component";
import * as i11 from "./invitation.component";
var styles_InvitationComponent = [i0.styles];
var RenderType_InvitationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvitationComponent, data: {} });
export { RenderType_InvitationComponent as RenderType_InvitationComponent };
function View_InvitationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "color-text-red"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Der Einladungslink ist ung\u00FCltig"]))], null, null); }
function View_InvitationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Du hast eine Einladung von \""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\" erhalten und kannst somit einen bestehenden Lupax-Account mit dieser Firma verkn\u00FCpfen oder einen neuen erstellen. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.token.org; _ck(_v, 3, 0, currVal_0); }); }
function View_InvitationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Das "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mobile-Dashboard"])), (_l()(), i1.ɵted(-1, null, [", das du bis jetzt genutzt hast, wurde durch eine neue App (PWA) ersetzt. Um die neue App zu nutzen, musst du dir einen Account erstellen und ein eigenes Passwort vergeben."])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Wenn du diese Seite \"zum Startbildschirm hinzuf\u00FCgst\", wird sie im Hintergrund als richtige App installiert. Bei Android sollte automatisch eine Nachfrage kommen, iOs Nutzer m\u00FCssen die \"Teilen\" Funktion benutzen. "]))], null, null); }
function View_InvitationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "acc-selection-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "mat-primary"], ["mat-raised-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(2, 180224, null, 0, i3.MatAnchor, [i4.Platform, i5.FocusMonitor, i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(3, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, 0, ["Neuen Account erstellen"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["class", "small"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bestehenden Account verwenden"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = _ck(_v, 4, 0, ("/auth/register/" + _co.rawToken)); _ck(_v, 3, 0, currVal_6); var currVal_9 = _ck(_v, 8, 0, ("/auth/login/" + _co.rawToken)); _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled ? (0 - 1) : (i1.ɵnov(_v, 2).tabIndex || 0)); var currVal_1 = (i1.ɵnov(_v, 2).disabled || null); var currVal_2 = i1.ɵnov(_v, 2).disabled.toString(); var currVal_3 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_4 = i1.ɵnov(_v, 3).target; var currVal_5 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 7).target; var currVal_8 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_7, currVal_8); }); }
export function View_InvitationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-single-page", [], null, null, null, i9.View_SinglePageComponent_0, i9.RenderType_SinglePageComponent)), i1.ɵdid(1, 49152, null, 0, i10.SinglePageComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InvitationComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InvitationComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InvitationComponent_3)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InvitationComponent_4)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.dashboardRedirect ? "Neue App" : "Einladung"), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.tokenError; _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.tokenError && !_co.dashboardRedirect); _ck(_v, 5, 0, currVal_2); var currVal_3 = (!_co.tokenError && _co.dashboardRedirect); _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.tokenError; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_InvitationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invitation", [], null, null, null, View_InvitationComponent_0, RenderType_InvitationComponent)), i1.ɵdid(1, 114688, null, 0, i11.InvitationComponent, [i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvitationComponentNgFactory = i1.ɵccf("app-invitation", i11.InvitationComponent, View_InvitationComponent_Host_0, {}, {}, []);
export { InvitationComponentNgFactory as InvitationComponentNgFactory };
