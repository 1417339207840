var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { faTruck, faCheck, faBriefcase, } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
var DispositionListComponent = /** @class */ (function (_super) {
    __extends(DispositionListComponent, _super);
    function DispositionListComponent(route, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.relativeWords = false;
        _this.faCheck = faCheck;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faBriefcase = faBriefcase;
        _this.icons = {
            'job': faBriefcase,
            'tour': faTruck,
            'event': faCalendarCheck
        };
        return _this;
    }
    DispositionListComponent.prototype.routeDispo = function (d) {
        this.router.navigate([d.type + 's', d.organization, d.data.id]);
    };
    DispositionListComponent.prototype.getJobsString = function (tour) {
        var jobs = [];
        if (!tour.jobs)
            return '';
        tour.jobs.forEach(function (job) {
            if (job.title && !jobs.includes(job.title))
                jobs.push(job.title);
        });
        return jobs.join(' + ');
    };
    return DispositionListComponent;
}(ComponentInit));
export { DispositionListComponent };
