import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { DeviceService } from '../../../core/services/device.service';

import {
  faFilter
} from '@fortawesome/free-solid-svg-icons';
import { FilterType, Filters } from '../shared/interfaces';
import { toggelAllFilters } from '../shared/utils';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.css']
})
export class FilterHeaderComponent implements OnInit {
  @Input() storage: string;
  @Output() callback = new EventEmitter<any>();

  private _inputs: Filters;
  inputKeys = Object.keys;
  filterValues = {};
  filterType = FilterType;

  faFilter = faFilter;

  constructor(
    private dialog: MatDialog,
    public device: DeviceService
  ) { }

  get inputs(): Filters{
    return this._inputs;
  }

  @Input()
  set inputs(inputs: Filters){
    this._inputs = inputs;

    // Combine saved values
    let savedValues = this.getSavedValues();
    if(savedValues)
      for(let key in savedValues)
        this._inputs[key].value = savedValues[key];

    toggelAllFilters(this.inputs)

    this.setFilterValues(this._inputs);
  }

  getSavedValues(){
    let store =  JSON.parse(localStorage.getItem('filterValues'));
    if(!store) return;

    // Check if field is storable
    for(let key in store[this.storage])
      if(!this._inputs[key].storable) delete store[this.storage][key];

    return store[this.storage];
  }

  saveValues(data){
    let store = JSON.parse(localStorage.getItem('filterValues'));
    if(!store) store = {};

    // Check if field is storable
    for(let key in data)
      if(this._inputs[key] && this._inputs[key].storable) store[key] = data[key];

    store[this.storage] = data;
    localStorage.setItem('filterValues', JSON.stringify(store));
  }

  /**
   * Sets filter values
   * @param filters
   */
  setFilterValues(filters:Filters) {

    this.filterValues = {};

    for(let key in filters) {

      // do not include filters with no selection
      if (filters[key].value === null)
        continue;

      this.filterValues[key] = filters[key].value;
    }
  }

  ngOnInit() {
    this.reload();
  }

  reload(){
    this.callback.emit(this.filterValues);
  }

  showFilter(){
    this.dialog.open(FilterDialogComponent, { panelClass: 'bam', autoFocus: false, data: {
      inputs: this._inputs
    }}).afterClosed().subscribe(data => {
      if(!data) return;

      // Save new values in local Storage
      this.saveValues(data);

      // Attach new values to _inputs
      for(let key in data)
        this._inputs[key].value = data[key];

      this.setFilterValues(this._inputs);
      this.reload();
    });
  }

}
