var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { RosterModel } from './roster.class';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RosterService = /** @class */ (function (_super) {
    __extends(RosterService, _super);
    function RosterService(http) {
        var _this = _super.call(this, http, 'rosters') || this;
        _this.http = http;
        return _this;
    }
    /**
     * Returns dispositions for current account
     * @param from date from (defaults to current day)
     * @param to date to
     * @param useCache DONT USE IT, AS THE CACHE ISN'T PERMANENT
     */
    RosterService.prototype.getAllForCurrentAccount = function (from, to, useCache) {
        if (from === void 0) { from = null; }
        if (to === void 0) { to = null; }
        if (useCache === void 0) { useCache = false; }
        // set params
        var fromString = null, toString = null;
        var params = {};
        // set from
        if (!from) {
            from = new Date();
            from.setHours(0, 0, 0, 0);
        }
        // from string
        fromString = this.dateToString(from);
        params['date_from'] = fromString;
        // to string
        if (to) {
            toString = this.dateToString(to);
            params['date_to'] = toString;
        }
        // build clear data
        var clearData = {
            type: 'period',
            data: { from: fromString, to: toString }
        };
        // get cached data
        var data = this.filterModelsByDate(fromString, toString);
        var result;
        if (data.length > 0 && useCache) {
            result = of(data);
        }
        else {
            var http = this.http.get('/v1/rosters', { params: params });
            result = this.cachedRequest(data, http, clearData);
        }
        return result.pipe(this.initModelsPipe(RosterModel));
    };
    /**
     * Delete models of organization
     * @param models
     * @param orga
     */
    RosterService.prototype.deleteAllByOrga = function (models, orga) {
        var _this = this;
        var ids = models.map(function (model) { return model.id.toString(); });
        return this.http.delete("/v1/organizations/" + orga + "/rosters", { params: { 'ids[]': ids } })
            .pipe(tap(function () { return _super.prototype.deleteModels.call(_this, models); }));
    };
    /**
     * Replaces models
     * @param models
     * @returns roster models
     */
    RosterService.prototype.replaceAll = function (models) {
        var _this = this;
        return this.http.put('/v1/rosters', this.modelsToApiObjects(models))
            .pipe(this.initModelsPipe(RosterModel), tap(function (models) { return _super.prototype.updateModels.call(_this, models); }));
    };
    /**
     * Creates models
     * @param models
     * @returns roster models
     */
    RosterService.prototype.createAll = function (models) {
        var _this = this;
        return this.http.post('/v1/rosters', this.modelsToApiObjects(models))
            .pipe(this.initModelsPipe(RosterModel), tap(function (models) { return _super.prototype.updateModels.call(_this, models); }));
    };
    RosterService.TYPES = {
        AVAILABLE: 'available',
        NOT_AVAILABLE: 'not_available',
    };
    RosterService.ngInjectableDef = i0.defineInjectable({ factory: function RosterService_Factory() { return new RosterService(i0.inject(i1.HttpClient)); }, token: RosterService, providedIn: "root" });
    return RosterService;
}(CachableModelService));
export { RosterService };
