var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { CalendarEventTitleFormatter, DAYS_OF_WEEK } from 'angular-calendar';
import { ComponentInit } from '../core/classes/component-init.class';
import { ScrollLocations } from '../core/globals/scroll-locations';
import { faChevronLeft, faChevronRight, faInfoCircle, faCalendarCheck, faPlus, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DispositionService } from '../dispositions/shared/disposition.service';
import { RosterService } from '../rosters/shared/roster.service';
import { AccountService } from '../core/services/account.service';
import { BehaviorSubject } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
import { DateService } from '../core/services/date.service';
import { OrganizationService } from '../core/services/organization.service';
import { AvailabilityService } from '../rosters/shared/availability.service';
import { deleteFromArray } from '../core/helpers/array.helper';
import { updateItemInArray } from '../core/helpers/array.helper';
import { MatDialog } from '@angular/material';
import { RosterDialogComponent, RETURN_ACTIONS } from '../rosters/roster-dialog/roster-dialog.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from '../shared/info-dialog/info-dialog.component';
import { RosterAvailabilityInfoComponent } from '../rosters/roster-availability-info/roster-availability-info.component';
import { MyErrorHandler } from '../core/classes/my-error-handler.class';
// Disable Tooltips
var CustomEventTitleFormatter = /** @class */ (function (_super) {
    __extends(CustomEventTitleFormatter, _super);
    function CustomEventTitleFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomEventTitleFormatter.prototype.dayTooltip = function (event) { return; };
    return CustomEventTitleFormatter;
}(CalendarEventTitleFormatter));
export { CustomEventTitleFormatter };
var EVENT_TYPES = {
    AVAILABLE: 'available',
    NOT_AVAILABLE: 'not_available',
    JOB: 'job',
    TOUR: 'tour',
    EVENT: 'event',
    WORK: 'work',
    VACATION: 'vacation',
    VACATION_SPECIAL: 'vacation_special',
    FREE: 'free',
    SICK: 'sick',
    HOLIDAY: 'holiday',
};
var COLORS = {
    cyan: '#1abc9c',
    red: '#b6514f',
    green: '#53a93f',
    yellow: '#f2c658',
    blue: '#456292',
    purple: '#966ea9',
    dark: '#363c46',
    grey: '#607D8B',
    light: '#e4e4e4',
    gr: '#53a93f',
    li: '#966ea9',
    lila: '#966ea9',
    bl: '#5290a9',
    ye: '#f2c658',
    re: '#b6514f',
    re_dark: '#76474B',
    or: '#F28349',
    orange: '#F28349',
    or_light: '#FAD4BF',
    orange_light: '#FAD4BF',
    cy: '#1abc9c'
};
var EVENT_TYPE_COLORS = (_a = {},
    _a[EVENT_TYPES.JOB] = COLORS.yellow,
    _a[EVENT_TYPES.TOUR] = COLORS.yellow,
    _a[EVENT_TYPES.EVENT] = COLORS.cyan,
    _a[EVENT_TYPES.WORK] = COLORS.blue,
    _a[EVENT_TYPES.VACATION] = COLORS.red,
    _a[EVENT_TYPES.VACATION_SPECIAL] = COLORS.red,
    _a[EVENT_TYPES.FREE] = COLORS.red,
    _a[EVENT_TYPES.NOT_AVAILABLE] = COLORS.red,
    _a[EVENT_TYPES.AVAILABLE] = COLORS.green,
    _a[EVENT_TYPES.SICK] = COLORS.purple,
    _a[EVENT_TYPES.HOLIDAY] = COLORS.dark,
    _a);
var VIEWS = {
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
};
var CalendarComponent = /** @class */ (function (_super) {
    __extends(CalendarComponent, _super);
    function CalendarComponent(router, route, scrollLocations, ds, rs, change, zone, date, orgaService, avService, accountService, dialog, myErrorHandler, locale) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.router = router;
        _this.route = route;
        _this.scrollLocations = scrollLocations;
        _this.ds = ds;
        _this.rs = rs;
        _this.change = change;
        _this.zone = zone;
        _this.date = date;
        _this.orgaService = orgaService;
        _this.avService = avService;
        _this.accountService = accountService;
        _this.dialog = dialog;
        _this.myErrorHandler = myErrorHandler;
        _this.locale = locale;
        _this.isAvailabilityMode = false;
        _this.availabilitiesLoadedForMonths = [];
        _this.faPlus = faPlus;
        _this.faCalendarCheck = faCalendarCheck;
        _this.faChevronRight = faChevronRight;
        _this.faChevronLeft = faChevronLeft;
        _this.faInfoCircle = faInfoCircle;
        _this.faQuestion = faQuestion;
        _this.faTimes = faTimes;
        _this.navigating = false;
        _this.views = Object.values(VIEWS);
        _this.tabIndex = 0;
        _this.weekStart = DAYS_OF_WEEK.MONDAY;
        _this.viewDate = new Date();
        _this.disposCheck = true;
        _this.rostersCheck = true;
        _this.availabilities = [];
        _this.rosters = [];
        _this.dispos = [];
        _this.retryCallback = _this.fetchEvents.bind(_this);
        _this.availabilityOrgasbyId = {};
        _this.availabilityOrgasChecks = {};
        _this.AVAILABLE_ENUM = {
            MIXED: "mixed_available",
            ALL_AVAILABLE: 'all_available',
            SOME_AVAILABLE: 'some_available',
            ALL_NOT_AVAILABLE: 'all_not_available',
            SOME_NOT_AVAILABLE: 'some_not_available',
            UNDEFINED: null,
        };
        return _this;
    }
    CalendarComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.route.snapshot.params.view)
            this.tabIndex = this.views.indexOf(this.route.snapshot.params.view);
        if (this.route.snapshot.params.date)
            this.viewDate = new Date(this.route.snapshot.params.date);
        // reset availabilities
        this.avService.clear();
        // listen for url and change calendar view and date accordingly
        this.route.params.subscribe(function (params) {
            window.setTimeout(function () {
                if (params.view)
                    _this.tabIndex = _this.views.indexOf(params.view);
                if (params.date)
                    _this.viewDate = new Date(params.date);
                // run inside Angular
                _this.zone.run(function () {
                    _this.tabIndex = _this.views.indexOf(params.view);
                    if (params.date)
                        _this.viewDate = new Date(params.date);
                    // force change detection
                    _this.change.detectChanges();
                    // Scroll
                    if (params.view == "day" || params.view == "week") {
                        var element_1 = document.querySelector(".mat-tab-body-active .cal-event-container:first-child");
                        setTimeout(function () {
                            if (element_1)
                                element_1.scrollIntoView({ behavior: "smooth" });
                        }, 300);
                    }
                });
            });
        });
        this.accountService.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(function (acc) {
            _this.accountSettings = acc.settings;
            _this.availabilityOrgas = acc.roster_availability_orgas;
            _this.availabilityOrgasSelected = _this.availabilityOrgas.slice();
            _this.availabilityOrgasbyId = _this.availabilityOrgas.reduce(function (orgas, orga) {
                orgas[orga.id] = orga;
                return orgas;
            }, {});
            // pre-select all orgas
            _this.availabilityOrgasChecks = _this.availabilityOrgas.reduce(function (obj, orga) {
                obj[orga.id] = true;
                return obj;
            }, {});
        });
    };
    CalendarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.events$ = this.eventsSubject = new BehaviorSubject(null);
        this.fetchEvents();
        // Switch availability mode when url param is given
        this.route.queryParams.subscribe(function (params) {
            if (params && params.availability_mode == "1" && !_this.isAvailabilityMode) {
                _this.toggleAvailabilityMode();
            }
            else if (params && params.availability_mode == "0" && _this.isAvailabilityMode) {
                _this.toggleAvailabilityMode();
            }
        });
        // Show "new: availability mode" announcement
        setTimeout(function () {
            if (_this.accountSettings && !_this.accountSettings.calendar_availability_announced && !_this.dialogRef && _this.availabilityOrgas.length) {
                _this.dialogRef = _this.dialog.open(InfoDialogComponent, {
                    maxWidth: '90vw',
                    data: {
                        title: "Neu: Verfügbarkeiten verwalten",
                        text: '<p>Durch einen Tap auf das Icon oben rechts im Kalender wird der <strong>Verfügbarkeits-Modus</strong> aktiviert.</p><p>Weitere Informationen können dann über das <strong>Fragezeichen</strong> im Verfügbarkeits-Modus abgerufen werden.</p>',
                    }
                }).afterClosed().subscribe(function () {
                    _this.dialogRef = undefined;
                    _this.accountService.updateSetting('calendar_availability_announced', 1).subscribe();
                });
            }
        });
    };
    CalendarComponent.prototype.reload = function () {
        this.fetchEvents();
    };
    Object.defineProperty(CalendarComponent.prototype, "isMultiOrga", {
        /**
         * indicates if the user is part of mulitple organizations
         */
        get: function () {
            return this.availabilityOrgas.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * refreshes events which triggeres calendar re-render
     */
    CalendarComponent.prototype.refreshEvents = function () {
        var dispos = [];
        if (this.disposCheck)
            dispos = this.dispos.slice();
        var rosters = [];
        if (this.rostersCheck)
            rosters = this.rosters.slice();
        if (!this.isAvailabilityMode)
            rosters.push.apply(rosters, this.availabilities);
        var availabilities = [];
        if (this.isAvailabilityMode)
            availabilities = this.applyOrgasFilterToEvents(this.availabilities);
        this.eventsSubject.next(dispos.concat(rosters, availabilities));
    };
    /**
     * Returns availability rosters for day
     * @param day
     */
    CalendarComponent.prototype.getAvailablesOfDay = function (day) {
        return day.events.filter(this.avService.isAvailability);
    };
    /**
     * Filters out events for unselected organizations
     * @param events
     */
    CalendarComponent.prototype.applyOrgasFilterToEvents = function (events) {
        var _this = this;
        return events.filter(function (event) { return _this.availabilityOrgasChecks[event.organization]; });
    };
    /**
     * Evaluates availability type for day
     * @param day
     */
    CalendarComponent.prototype.getAvailabilityOfDay = function (day) {
        var events = this.applyOrgasFilterToEvents(this.getAvailablesOfDay(day));
        if (!events.length)
            return this.AVAILABLE_ENUM.UNDEFINED;
        var allOrgasHaveEvents = this.availabilityOrgasSelected.every(function (orga) { return events.find(function (event) { return event.organization == orga.id; }); });
        var eventsEqualOrgas = events.length === this.availabilityOrgasSelected.length && allOrgasHaveEvents;
        if (events.every(this.avService.isNotAvailableType))
            // all events are not available, but lets check that this is true for all orgas
            return eventsEqualOrgas ? this.AVAILABLE_ENUM.ALL_NOT_AVAILABLE : this.AVAILABLE_ENUM.SOME_NOT_AVAILABLE;
        if (events.every(this.avService.isAvailableType))
            // all events are available, but lets check that this is true for all orgas
            return eventsEqualOrgas ? this.AVAILABLE_ENUM.ALL_AVAILABLE : this.AVAILABLE_ENUM.SOME_AVAILABLE;
        return this.AVAILABLE_ENUM.MIXED;
    };
    /**
     * Toggles availability of day and updates rosters
     * @param day
     */
    CalendarComponent.prototype.toggleAvailability = function (day) {
        var _this = this;
        var _a, _b;
        var availables = this.getAvailablesOfDay(day);
        switch (day.availability) {
            case this.AVAILABLE_ENUM.MIXED:
                this.showDate(day.date);
                return;
            case this.AVAILABLE_ENUM.ALL_AVAILABLE:
            case this.AVAILABLE_ENUM.SOME_AVAILABLE:
                // mark availability as not available
                this.avService.updateTypeForMultiple(RosterService.TYPES.NOT_AVAILABLE, availables);
                availables.forEach(this.colorEvent);
                day.availability = this.getAvailabilityOfDay(day);
                break;
            case this.AVAILABLE_ENUM.ALL_NOT_AVAILABLE:
            case this.AVAILABLE_ENUM.SOME_NOT_AVAILABLE:
                // next state: delete availabilities
                availables.forEach(function (event) {
                    _this.avService.deleteAvailability(event);
                    deleteFromArray(event, day.events);
                    deleteFromArray(event, _this.availabilities);
                });
                day.availability = this.AVAILABLE_ENUM.UNDEFINED;
                break;
            case this.AVAILABLE_ENUM.UNDEFINED:
                // create availability
                var events = this.availabilityOrgasSelected.map(function (orga) {
                    return _this.avService.createAvailability(EVENT_TYPES.AVAILABLE, _this.date.startOfDay(day.date), _this.date.endOfDay(day.date), '', orga);
                });
                (_a = day.events).push.apply(_a, events);
                (_b = this.availabilities).push.apply(_b, events);
                day.availability = this.AVAILABLE_ENUM.ALL_AVAILABLE;
                break;
            default:
                throw Error('Unknown available type for ' + JSON.stringify(day));
        }
        this.refreshEvents();
    };
    /**
     * On day clicked handler
     */
    CalendarComponent.prototype.onDayClicked = function ($event) {
        if (this.isAvailabilityMode) {
            this.toggleAvailability($event.day);
        }
        else {
            this.showDate($event.day.date);
        }
    };
    /**
     * Shows day view for date
     * @param date
     */
    CalendarComponent.prototype.showDate = function (date) {
        this.viewDate = date;
        this.tabIndex = 2;
    };
    /**
     * adds color to event
     * @param event
     */
    CalendarComponent.prototype.colorEvent = function (event) {
        var color = EVENT_TYPE_COLORS[event.type] || COLORS.blue;
        if (event.color) {
            // is hexcode?
            if (event.color[0] == '#')
                color = event.color;
            else if (COLORS[event.color])
                color = COLORS[event.color];
        }
        event.color = { primary: color, secondary: color };
        return event;
    };
    /**
     * Maps additional data to events
     * @param data
     */
    CalendarComponent.prototype.mapDataToEvents = function (data) {
        var _this = this;
        return data.map(function (event) {
            event.start = createDateFromDatetime(event.start);
            event.end = createDateFromDatetime(event.end);
            return _this.colorEvent(event);
        });
    };
    /**
     * TODO: refactor function that 'refreshEvents' will only be called once at the end
     */
    CalendarComponent.prototype.fetchEvents = function () {
        var _this = this;
        // load events only on a monthly basis
        // start from last days in the previous month, to the next days in the upcoming month
        var start = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), -7);
        var end = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 7);
        var obs = [];
        // load dispos
        var dispoObs = this.ds.getAllForCurrentAccount({ date_from: start, date_to: end });
        obs.push(dispoObs);
        dispoObs.pipe(untilDestroyed(this)).subscribe(function (dispos) {
            _this.dispos = _this.mapDataToEvents(dispos);
            _this.refreshEvents();
        });
        // load rosters
        var rosterObs = this.rs.getAllForCurrentAccount(start, end);
        obs.push(rosterObs);
        rosterObs.pipe(untilDestroyed(this)).subscribe(function (rosters) {
            if (!rosters)
                return;
            // split rosters into EVENT_TYPES
            var others = [];
            rosters.forEach(function (roster) {
                switch (roster.type) {
                    case EVENT_TYPES.AVAILABLE:
                    case EVENT_TYPES.NOT_AVAILABLE:
                        /* availabel rosters are handled separately down below */
                        break;
                    default:
                        others.push(roster);
                }
            });
            _this.rosters = _this.mapDataToEvents(others);
            _this.refreshEvents();
        });
        // load availabilities
        // Reload stored data
        var availabilityObs = this.avService.getAllForCurrentAccount(start, end);
        obs.push(availabilityObs);
        availabilityObs.pipe(untilDestroyed(this)).subscribe(function (rosters) {
            _this.availabilities = _this.mapDataToEvents(rosters);
            _this.refreshEvents();
        });
    };
    /**
     * Handles tab changes and changes the calendar view.
     * @param i
     */
    CalendarComponent.prototype.onTabIndexChange = function (i) {
        var _this = this;
        if (this.navigating)
            return;
        this.navigating = true;
        //TODO: change locale
        this.router.navigate(['calendar', this.views[i], formatDate(this.viewDate, 'y-MM-dd', this.locale)]).then(function () {
            _this.navigating = false;
        });
    };
    /**
     * save the current date, as one might want to navigate back to it after selecting an Event
     */
    CalendarComponent.prototype.onViewChange = function () {
        this.onTabIndexChange(this.tabIndex);
    };
    /**
     * Navigates to the event's specific app site
     * @param e
     */
    CalendarComponent.prototype.showEvent = function (e) {
        var _this = this;
        // the timeout is needed, as the calendar will trigger an additional click which will otherwise be executed on the next page
        // dunno why :(
        if (e.event.type == 'job' || e.event.type == 'tour' || e.event.type == 'event')
            setTimeout(function () { return _this.router.navigate(['/' + e.event.type + 's/' + e.event.organization + '/' + e.event.data.id]); }, 100);
    };
    /**
     * Caled before calendar renders.
     * Last call to initialize calendar objects here.
     */
    CalendarComponent.prototype.beforeMonthViewRender = function (_a) {
        var _this = this;
        var body = _a.body;
        body.forEach(function (day) {
            day.availability = _this.getAvailabilityOfDay(day);
            day.events.map(function (event) {
                if (_this.isAvailabilityMode && (event.type == EVENT_TYPES.AVAILABLE || event.type == EVENT_TYPES.NOT_AVAILABLE)) {
                    event.cssClass = 'hide';
                }
                else
                    event.cssClass = '';
            });
        });
    };
    /**
     * toggles availability mode.
     * Creates confirm dialog when unsaved changes exist.
     */
    CalendarComponent.prototype.toggleAvailabilityMode = function () {
        var _this = this;
        // Switch to month view, when available mode will be activated
        if (!this.isAvailabilityMode)
            this.tabIndex = 0;
        if (this.isAvailabilityMode && this.avService.unsavedChanges) {
            this.dialog.open(ConfirmDialogComponent, { data: {
                    text: 'Änderungen wurden noch nicht gespeichert. Verfügbarkeitsmodus trotzdem beenden?',
                } }).afterClosed().subscribe(function (result) {
                if (result) {
                    _this.exitAvailabilityMode();
                }
            });
            return;
        }
        else {
            if (this.isAvailabilityMode) {
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {}
                });
            }
            this.isAvailabilityMode = !this.isAvailabilityMode;
            this.refreshEvents();
        }
    };
    /**
     * handles event click action
     * @param e
     */
    CalendarComponent.prototype.onEventClick = function (e) {
        if (this.avService.isAvailability(e.event))
            this.openAvailabilityForm(e.event);
        else
            this.showEvent(e);
    };
    /**
     * triggered on orga filter change
     */
    CalendarComponent.prototype.orgaFilterChange = function () {
        var _this = this;
        this.availabilityOrgasSelected = Object.entries(this.availabilityOrgasChecks)
            .filter(function (_a) {
            var id = _a[0], bool = _a[1];
            return bool;
        })
            .map(function (_a) {
            var id = _a[0], bool = _a[1];
            return _this.availabilityOrgasbyId[id];
        });
        this.refreshEvents();
    };
    /**
     * esit availability mode
     */
    CalendarComponent.prototype.exitAvailabilityMode = function () {
        this.isAvailabilityMode = false;
        this.avService.clear();
        this.fetchEvents();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { availability_mode: 0 },
            queryParamsHandling: 'merge'
        });
    };
    /**
     * save and exit
     */
    CalendarComponent.prototype.saveAvailabilitiesAndExit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.avService.flush().catch(function (err) { return _this.myErrorHandler.showError(err); })];
                    case 1:
                        _a.sent();
                        this.exitAvailabilityMode();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * opens roster form dialog
     * @param roster
     * @param date
     */
    CalendarComponent.prototype.openAvailabilityForm = function (roster, date) {
        var _this = this;
        if (roster === void 0) { roster = null; }
        if (date === void 0) { date = null; }
        this.dialog.open(RosterDialogComponent, { data: {
                roster: roster,
                date: date,
            } }).afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!data)
                            return [2 /*return*/];
                        switch (data.action) {
                            case RETURN_ACTIONS.NEW:
                                this.availabilities.push(this.colorEvent(data.roster));
                                this.avService.addAvailability(data.roster);
                                break;
                            case RETURN_ACTIONS.CHANGE:
                                this.avService.updateAvailability(data.roster);
                                delete data.roster.title;
                                updateItemInArray(this.colorEvent(data.roster), this.availabilities);
                                break;
                            case RETURN_ACTIONS.DELETE:
                                this.avService.deleteAvailability(roster);
                                deleteFromArray(roster, this.availabilities);
                                break;
                        }
                        this.refreshEvents();
                        if (!!this.isAvailabilityMode) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.avService.flush().catch(function (err) { return _this.myErrorHandler.showError(err); })];
                    case 1:
                        _a.sent();
                        this.rs.clear();
                        this.avService.clear();
                        this.fetchEvents();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    /**
     * opens availability info guide dialog
     */
    CalendarComponent.prototype.openAvailabilityInfo = function () {
        this.dialog.open(RosterAvailabilityInfoComponent, {
            width: '600px',
            maxWidth: '90vw'
        });
    };
    Object.defineProperty(CalendarComponent.prototype, "currentView", {
        /**
         * current date
         */
        get: function () {
            return this.views[this.tabIndex];
        },
        enumerable: true,
        configurable: true
    });
    /**
     * depending in which view we are, we open roster form dialog with pre-set date
     */
    CalendarComponent.prototype.createNewAvailability = function () {
        // only specify date if we are in date view
        this.openAvailabilityForm(null, this.currentView === VIEWS.DAY ? this.viewDate : null);
    };
    return CalendarComponent;
}(ComponentInit));
export { CalendarComponent };
