/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./files.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/file-list/file-list.component.ngfactory";
import * as i3 from "../shared/file-list/file-list.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i6 from "@angular/material/tabs";
import * as i7 from "./files.component";
import * as i8 from "../core/services/file.service";
var styles_FilesComponent = [i0.styles];
var RenderType_FilesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilesComponent, data: {} });
export { RenderType_FilesComponent as RenderType_FilesComponent };
function View_FilesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.companyTitle; _ck(_v, 0, 0, currVal_0); }); }
function View_FilesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "mat-h2 app-list-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-file-list", [], null, null, null, i2.View_FileListComponent_0, i2.RenderType_FileListComponent)), i1.ɵdid(3, 49152, null, 0, i3.FileListComponent, [], { files: [0, "files"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.files; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_FilesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.categories; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FilesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "mat-tab", [], null, null, null, i5.View_MatTab_0, i5.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i6.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FilesComponent_2)), i1.ɵdid(5, 16384, [[2, 4]], 0, i6.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(0, [[3, 2]], 0, 1, null, View_FilesComponent_3)), i1.ɵdid(7, 16384, null, 0, i6.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_FilesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"], ["mat-stretch-tabs", ""]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i5.View_MatTabGroup_0, i5.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i6.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i6.MAT_TABS_CONFIG]], null, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FilesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_FilesComponent_0, RenderType_FilesComponent)), i1.ɵdid(1, 114688, null, 0, i7.FilesComponent, [i8.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilesComponentNgFactory = i1.ɵccf("ng-component", i7.FilesComponent, View_FilesComponent_Host_0, {}, {}, []);
export { FilesComponentNgFactory as FilesComponentNgFactory };
