import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from '../../core/services/account.service';
import { passwordMatchValidator } from '../../core/validators/passwordMatchValidator';

import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  form: FormGroup;
  formSubmitted: boolean = false;
  accountCreated: boolean = false;

  rawToken: string = undefined;
  token: any;
  tokenError: boolean = false;

  showLoginOption: boolean;
  error: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private acc: AccountService
  ) { }

  ngOnInit() {
    try{
      this.rawToken = this.route.snapshot.params['token'];
      this.token = jwtHelper.decodeToken(this.rawToken);
    }
    catch(e){
      this.tokenError = true;
    }

    this.form = new FormGroup({
      "firstname": new FormControl(this.token ? this.token.s_first_name : '', Validators.required),
      "lastname": new FormControl(this.token ? this.token.s_last_name : '', Validators.required),
      "email": new FormControl(this.token ? this.token.s_email : '', [Validators.required, Validators.email]),
      "password": new FormControl('', Validators.required),
      "passwordRepeat": new FormControl('', Validators.required)
    }, {validators: passwordMatchValidator});
  }

  isFieldInvalid(field: string){
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitted)
    )
  }

  onSubmit(){
    this.error = null;

    if(this.form.value.password != this.form.value.passwordRepeat){
      return;
    }

    if(this.form.valid && !this.formSubmitted){
      this.formSubmitted = true;
      this.acc.createAccount(this.form.value.firstname, this.form.value.lastname, this.form.value.email, this.form.value.password, this.rawToken)
        .subscribe(res =>  {
          this.accountCreated = true;
          this.formSubmitted = false;
        }, res => {
          if(res.error.slug == 'account-already-exists')
            this.showLoginOption = true;

          this.error = res.error.text;
          this.formSubmitted = false;
        });
    }
  }

}
