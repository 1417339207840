import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WorkSchedule, WorkScheduleEntry, WorkScheduleBreak } from './work-schedule.model';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
// import { AbstractCachableService } from '../classes/abstract-cachable-service.class';
import { formatDate } from '@angular/common';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

@Injectable({
    providedIn: 'root'
})
export class WorkService extends CachableModelService<WorkSchedule>{

    protected currWorkSchedule: BehaviorSubject<WorkSchedule>;
    public currWorkScheduleEntry: BehaviorSubject<WorkScheduleEntry>;
    protected working:BehaviorSubject<boolean>;

    public preselectedDateOut: Date;

    constructor(
        protected http: HttpClient,
        @Inject(LOCALE_ID) protected locale: string,
    ) {
        super(http, 'workSchedules', false, 'month');

        // Current work schedule entry
        let workSchedule = localStorage.getItem('currWorkSchedule');
        let workScheduleEntry = localStorage.getItem('currWorkScheduleEntry');

        this.currWorkSchedule = new BehaviorSubject<WorkSchedule>(workSchedule ? JSON.parse(workSchedule) : null);
        this.currWorkScheduleEntry = new BehaviorSubject(workScheduleEntry ? JSON.parse(workScheduleEntry) : null);
        this.working = new BehaviorSubject<boolean>(!!workSchedule);
        this.startCurrentEntryUpdater();

        this.getCurrent();
    }

    getByOrganizationAndId(orga, id):Observable<WorkSchedule>{
        // Get Data
        let data = this.findModelByOrganizationAndId(orga, id);

        let http = this.http.get<WorkSchedule>('/v1/organizations/'+orga+'/work-schedule/'+id, { params: {
            date_out: this.preselectedDateOut ? encodeURIComponent(formatDate(this.preselectedDateOut, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale)) : ''
        }});
        return this.cachedRequest(data, http);
    }

    /**
     * Listens to schedule changes and updates current entry
     */
    private startCurrentEntryUpdater() {
        this.currWorkSchedule.subscribe(schedule => {
            if (!schedule) return;

            const runningEntry = schedule.entries ? schedule.entries.find(entry => !entry.end) : null;
            this.setCurrWorkScheduleEntry(runningEntry || null);
        });
    }

    /**
     * Create new work schedule entry
     * @param data
     */
    createWorkScheduleEntry(data:any) {
        // sanizize data
        if(data.start){
            // string -> Date
            if(typeof data.start === 'string') data.start = createDateFromDatetime(data.start);

            data.start = formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }
        if(data.end){
            // string -> Date
            if(typeof data.end === 'string') data.end = createDateFromDatetime(data.end);

            data.end = formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }

        return this.http.post<WorkScheduleEntry>(`/v1/work-schedule-entries`, data);
    }

    /**
     * updates current works schedule entry
     * @param data
     */
    updateCurrentWorkScheduleEntry(data:object): Observable<WorkScheduleEntry> {
        const currEntry = this.currWorkScheduleEntry.value;
        return this.updateWorkScheduleEntry(currEntry.organization, currEntry.id, data);
    }

    /**
     * Updates work schedules entries
     * @param entries
     */
    private updateCurrentWorkEntries(entries:WorkScheduleEntry[]) {
        const currWorkSchedule = this.currWorkSchedule.value;
        if(!currWorkSchedule) return;

        for(let entry of entries) {
            const index = currWorkSchedule.entries.findIndex(e => e.id === entry.id);
            currWorkSchedule[index] = entry;
        }

        this.currWorkSchedule.next(currWorkSchedule);
    }

    /**
     * patches work schedule entry
     * @param orga
     * @param id
     * @param data
     */
    updateWorkScheduleEntry(orga:number, id:number, data:any): Observable<WorkScheduleEntry> {
        // sanizize data
        if(data.start){
            // string -> Date
            if(typeof data.start === 'string') data.start = createDateFromDatetime(data.start);

            data.start = formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }
        if(data.end){
            // string -> Date
            if(typeof data.end === 'string') data.end = createDateFromDatetime(data.end);

            data.end = formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale);
        }

        return this.http.patch<WorkScheduleEntry>(`/v1/organizations/${orga}/work-schedule-entries/${id}`, data)
            .pipe(tap(entry => this.updateCurrentWorkEntries([entry])));
    }

    /**
     * deletes work schedule entry
     * @param orga
     * @param id
     */
    deleteWorkScheduleEntry(orga:number, id:number){
        return this.http.delete(`/v1/organizations/${orga}/work-schedule-entries/${id}`).pipe(tap(res => {

            // remove entry from currWorkSchedule
            const currWorkSchedule = this.currWorkSchedule.value;
            if(currWorkSchedule){
                currWorkSchedule.entries = currWorkSchedule.entries.filter(e => e.id !== id);

                this.currWorkSchedule.next(currWorkSchedule);
            }
        }));
    }

    clear(){
        this.currWorkSchedule.next(null);
        this.currWorkScheduleEntry.next(null);
        this.working.next(false);
        localStorage.removeItem('currWorkSchedule');
        localStorage.removeItem('currWorkScheduleEntry');
    }

    /**
    * Returns current work schedule.
    */
    getCurrent():Observable<WorkSchedule>{
        this.http.get<WorkSchedule>('/v1/work-schedule/current', { params: {
            date_out: this.preselectedDateOut ? encodeURIComponent(formatDate(this.preselectedDateOut, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale)) : ''
        }})
        .subscribe(res => {
            this.setCurrWorkSchedule(res);
        });

        return this.currWorkSchedule.asObservable();
    }

    /**
     * Returns all work schedule entries of month (yyyy-mm)
     */
    getAllByMonth(year, month){
        // Calculate date_from
        let date_from = new Date();
        date_from.setDate(1);
        date_from.setFullYear(year);
        date_from.setMonth(month-1);

        // Calculate date_to
        let date_to =  new Date(date_from.getFullYear(), date_from.getMonth() + 1, 0);

        let params = {
            date_from: this.dateToString(date_from, 'yyyy-MM-dd'),
            date_to: this.dateToString(date_to, 'yyyy-MM-dd')
        }

        // build clear data
        let clearData = {
            type: 'period',
            data: { from: params.date_from, to: params.date_to, property: 'month' }
        }

        // get cached data
        let data = this.models;
        let http = this.http.get<WorkSchedule[]>('/v1/work-schedules', {params: params});

        return this.cachedRequest(data, http, clearData);
    }

    /**
    * Ends current Work Schedule
    * @param comment comment as string
    */
    endCurrWorkSchedule(comment:string, date:Date, entries:WorkScheduleEntry[], breakSuggestion?: any){
        /* let payload = {
            verify: 'app',
            date: formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            comment,
            entries,
        } */

        let schedule = this.currWorkSchedule.getValue();
        if(!schedule) {
            console.error('User must have a work schedule');
            return;
        }
        return this.endWorkSchedule(comment, date, entries, schedule, breakSuggestion).pipe(
            tap(
                () => this.clear(),
                error => console.log('error: ', error)
            )
        );

        /* return this.http.post<WorkSchedule>('/v1/organizations/'+schedule.organization+'/work-schedule/'+schedule.id+'/end', payload).pipe(
            tap(
                () => this.clear(),
                error => console.log('error: ', error)
            )
        ) */
    }

    /**
    * Ends Work Schedule
    * @param comment comment as string
    */
    endWorkSchedule(comment:string, date:Date, entries:WorkScheduleEntry[], schedule:WorkSchedule, breakSuggestion?: any){
        let payload = {
            verify: 'app',
            date: formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            comment,
            entries,
            break_suggestion: breakSuggestion
        }

        return this.http.post<WorkSchedule>('/v1/organizations/'+schedule.organization+'/work-schedule/'+schedule.id+'/end', payload);
    }

    /**
    * starts new work schedule
    * @param oid
    * @param comment
    */
    startWorkSchedule(oid:number, comment:string, date:Date, date_ref:Date, entry?:object, company:number = null):Observable<WorkSchedule>{
        let payload = {
            verify: 'app',
            date: formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            date_ref: formatDate(date_ref, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            comment: comment,
            entry: entry,
            company: company
        };

        /* if(this.currWorkSchedule.getValue()) {
            console.error('User has work scheduled');
            return;
        } */

        return this.http.post<WorkSchedule>('/v1/organizations/'+oid+'/work-schedule', payload).pipe(
            tap(res => {
                if(res) this.setCurrWorkSchedule(res);
            })
        )
    }

    /**
    * caches work schedule
    * @param workSchedule
    */
    protected setCurrWorkSchedule(workSchedule:WorkSchedule):void{
        localStorage.setItem('currWorkSchedule', JSON.stringify(workSchedule));
        this.currWorkSchedule.next(workSchedule);
        this.working.next(workSchedule ? true : false);
    }

    /**
     * caches work schedule entry
     * @param entry
     */
    public setCurrWorkScheduleEntry(entry:WorkScheduleEntry):void {
        localStorage.setItem('currWorkScheduleEntry', JSON.stringify(entry));
        this.currWorkScheduleEntry.next(entry);
    }

    public createBreak(orga_id:number, ws_id:number, data:any){
        let payload = {
            start: formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            end: data.end ? formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale) : null,
            automatic: data.automatic ? 1 : 0,
            ws: ws_id
        };

        return this.http.post<WorkScheduleBreak>('/v1/organizations/'+orga_id+'/work-schedule-break', payload).pipe(
            tap(res => {
                // update current work Schedule (add break)
                if(res && !payload.automatic && this.currWorkSchedule && this.currWorkSchedule.value){
                    let currWorkSchedule = this.currWorkSchedule.value;
                    if(currWorkSchedule.id == ws_id){
                        currWorkSchedule.breaks.push(res);
                        this.setCurrWorkSchedule(currWorkSchedule);
                    }
                }
            })
        )
    }

    public editBreak(orga_id:number, break_id:number, data:any){
        let payload = {
            start: formatDate(data.start, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale),
            end: data.end ? formatDate(data.end, 'yyyy-MM-ddTHH:mm:ssZZZZZ', this.locale) : null
        };

        return this.http.patch<WorkScheduleBreak>('/v1/organizations/'+orga_id+'/work-schedule-break/' + break_id, payload).pipe(
            tap(res => {
                // update current work Schedule (adjust break)
                if(res && this.currWorkSchedule && this.currWorkSchedule.value){
                    let currWorkSchedule = this.currWorkSchedule.value;
                    console.log(currWorkSchedule);
                    if(currWorkSchedule.id == res.ws){
                        currWorkSchedule.breaks = currWorkSchedule.breaks.map(b => b.id == res.id ? res : b);
                        console.log(currWorkSchedule);
                        this.setCurrWorkSchedule(currWorkSchedule);
                    }
                }
            })
        )
    }

    /**
     * deletes break entry
     * @param orga
     * @param id
     */
    deleteBreak(orga:number, id:number){
        return this.http.delete(`/v1/organizations/${orga}/work-schedule-break/${id}`).pipe(tap(() => {

            if(this.currWorkSchedule && this.currWorkSchedule.value){
                // remove entry from currWorkSchedule
                const currWorkSchedule = this.currWorkSchedule.value;
                if(currWorkSchedule){
                    currWorkSchedule.breaks = currWorkSchedule.breaks.filter(e => e.id !== id);

                    this.currWorkSchedule.next(currWorkSchedule);
                }
            }
        }));
    }

    /**
    * Returns current work schedule observable.
    * does not fetch data from server
    */
    get currentObservable():Observable<WorkSchedule>{
        return this.currWorkSchedule.asObservable();
    }

    /**
    * Returns observable that indicates if the user is working.
    * does not fetch data from server
    */
    get workingObservable():Observable<boolean>{
        return this.working.asObservable();
    }

    /**
    * indicates if the user is working.
    * does not fetch data from server
    */
    get isWorking():boolean{
        return this.working.getValue();
    }
}
