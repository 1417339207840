<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>Passwort ändern</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput type="password" placeholder="Neues Passwort" i18n-placeholder formControlName="password1" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Passwort wiederholen" i18n-placeholder formControlName="password2" required>
    </mat-form-field>
    <mat-error *ngIf="unequalPassErr" i18n>Passwörter sind nicht identisch</mat-error>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" i18n type="submit">
      Ändern
    </button>
  </mat-dialog-actions>
</form>