import { Component, OnInit, LOCALE_ID, Inject, ViewChild } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import {
  faTruck,
  faCheck,
  faUsers,
  faBriefcase
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
} from '@fortawesome/free-regular-svg-icons';

import { TourService } from '../shared/tour.service';
import { Tour } from '../shared/tour.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ListGroup } from '../../core/models/list-group.model';
import { ListGroupService } from '../../core/services/list-group.service';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { FilterHeaderComponent } from 'src/app/shared/filters/filter-header/filter-header.component';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { Organization } from 'src/app/core/models/organization.model';
import { Option } from 'src/app/core/interfaces/option';
import { GroupedOptions, Filters } from 'src/app/shared/filters/shared/interfaces';
import { DateFilter, GroupToggleSelectFilter, SelectFilter } from 'src/app/shared/filters/shared/classes';

@Component({
  selector: 'app-tour-list',
  templateUrl: './tour-list.component.html',
  styleUrls: ['./tour-list.component.css']
})
export class TourListComponent extends ComponentInit implements OnInit{
  @ViewChild(FilterHeaderComponent) filterComponent;

  loadError:boolean = false;

  faCheck = faCheck;
  faTruck = faTruck;
  faCalendarAlt = faCalendarAlt;
  faUsers = faUsers;
  faBriefcase = faBriefcase;

  orgas:Organization[] = this.orgaService.organizationsOfCurrentAccount;
  hasMultipleOrgas = this.orgas.length > 1;

  filters:Filters = {
    date_from: new DateFilter({
      label: 'Ab',
      value: new Date(),
    }),
    organization: new SelectFilter({
      label: 'Organisation',
      selectable: this.hasMultipleOrgas, // when user only has one orga, they shouldn't need to select it
      visible: this.hasMultipleOrgas,
      value: this.hasMultipleOrgas ? null : this.orgas[0].id, // when user only has one orga, pre-select it
      storable: true,
      options: this.orgaService.organizationsToOptions(this.orgaService.organizationsOfCurrentAccount)
    }),
    vehicle: new GroupToggleSelectFilter({
      label: 'Fahrzeug',
      filterToggle: 'organization',
      groupedOptions: {/*when no group exists for toggle value, than hide input*/},
      value: null,
      storable: true,
    }),
  }

  listGroups:ListGroup<Tour>[];
  tours:Tour[];

  constructor(
    private route: ActivatedRoute,
    private orgaService: OrganizationService,
    private tourService: TourService,
    private listGroupService: ListGroupService,
    @Inject(LOCALE_ID) private locale: string,
    protected router: Router,
    protected scrollLocations: ScrollLocations
  ){
    super(router, scrollLocations, route);
    this.listGroups = this.listGroupService.getGroups();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initVehicleFilter()
  }

  reload(){
    this.filterComponent && this.filterComponent.reload();
  }

  /**
   * Routes to specified tour
   * @param t tour object
   */
  routeTour(t:Tour){
    this.router.navigate(['tours', t.organization, t.id]);
  }

   /**
    * Loads tours
    * @param options
    */
  getTours(options){
    options = {...options};

    this.tourService.getAllForCurrentAccount(options).subscribe(res => {
      if(!res) return;

      // Check if data has changed
      if(JSON.stringify(this.tours) == JSON.stringify(res)) return;

      this.listGroups = this.listGroupService.getGroups(res, 'date_start', 'date_end');

      this.tours = res;
    }, res => {
      this.loadError = true;
    })
  }

  getJobsString(tour){
    let jobs = [];
    if(!tour.jobs) return '';

    tour.jobs.forEach(job => {
        if(job.title && !jobs.includes(job.title))
            jobs.push(job.title)
    });
    return jobs.join(' + ');
  }

  /**
   * Reloads filters
   */
  reloadFilters() {
    this.filters = this.filters;
  }

  /**
   * Inits vehicle filter
   */
  initVehicleFilter() {
    const emptyFilter:Option<number> = {
      value: null,
      label: 'Alle'
    };

    // retrieve vehicles and add them to orgas
    this.tourService.getAllVehicles().subscribe(res => {
      if(!res) return;

      // init empty groups
      const groups:GroupedOptions<number> = {};
      this.orgas.forEach(orga => groups[orga.id] = [emptyFilter]);

      // add vehicles to orga groups
      res.forEach(vehicle => {
        if(!groups[vehicle.organization]) groups[vehicle.organization] = [];
        groups[vehicle.organization].push({
          label: vehicle.title,
          value: vehicle.id,
        })
      });

      (<GroupToggleSelectFilter<any>> this.filters.vehicle).groupedOptions = groups;
      this.reloadFilters();
    })
  }
}
