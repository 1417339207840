import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Tour } from './tour.model';
import { TourService } from './tour.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class TourResolver extends CachableClientModelResolver<Tour>{

  constructor(s: TourService, r:Router) {
    super(s, 'dispositions', r);
  }
}
