import { Injectable } from '@angular/core';
import { Organization } from 'src/app/core/models/organization.model';
import { Observable, of } from 'rxjs';
import { RosterService } from './roster.service';
import { map } from 'rxjs/operators';
import { DateService } from 'src/app/core/services/date.service';
import { RosterModel } from './roster.class';
import { AbstractCachableService } from 'src/app/core/classes/abstract-cachable-service.class';
import { deleteFromArray } from 'src/app/core/helpers/array.helper';
import { GroupedList } from 'src/app/core/interfaces/grouped-list';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService implements AbstractCachableService {

  list: RosterModel[] = [];
  createArr: RosterModel[] = []; // contains rosters that need to be created
  updateArr: RosterModel[] = []; // contains rosters that need to be updated
  deleteArr: RosterModel[] = []; // contains rosters that need to be deleted

  cachedRequests:string[] = [];

  constructor(
    protected rosterService: RosterService,
    private dateService: DateService
  ) {}

  /**
   * deletes availability and marks roster as to be deleted
   * @param roster
   */
  public deleteAvailability(roster:RosterModel):void {

    if (roster.id) {
      //roster has to be removed from server
      this.deleteArr.push(roster);
    }

    deleteFromArray(roster, this.updateArr);
    deleteFromArray(roster, this.createArr);
  }

  /**
   * marks availability to be updated with server
   * @param roster
   */
  public updateAvailability(roster:RosterModel):RosterModel {

    // when no id isset, the roster must be created
    if (!roster.id) {
      if (!this.createArr.includes(roster))
        this.createArr.push(roster);

      return roster;
    }

    if (!this.updateArr.includes(roster))
      this.updateArr.push(roster);

    return roster;
  }

  /**
   * Adds availability for server syncing
   * @param roster
   */
  public addAvailability(roster:RosterModel):RosterModel {
    this.createArr.push(roster);
    this.list.push(roster);
    return roster;
  }

  /**
   * Creates availability and prepares it to be saved
   * @param type
   * @param start
   * @param end
   * @param title
   * @param orga
   */
  public createAvailability(type:string, start:Date, end:Date, title:string, orga:Organization): RosterModel {

    const roster = new RosterModel({
      id: null,
      shift: 0,
      start: start,
      duration: this.dateService.secondsToHMS((end.getTime() - start.getTime()) / 1000),
      end: end,
      days: 0,
      type: type,
      title: title,
      organization: orga.id,
      company: {
        id: orga.id,
        title: orga.name,
      }
    });

    return this.addAvailability(roster);
  }

  /**
   * Updates type for multiple availabilities
   * @param type
   * @param rosters
   * @return updated rosters
   */
  updateTypeForMultiple(type:string, rosters:RosterModel[]): RosterModel[] {

    return rosters.map(roster => {
      roster.type = type;
      return this.updateAvailability(roster);
    });
  }

  public isAvailability(roster:RosterModel) {
    return roster.type === RosterService.TYPES.AVAILABLE || roster.type === RosterService.TYPES.NOT_AVAILABLE;
  }

  public isAvailableType(roster:RosterModel) {
    return roster.type === RosterService.TYPES.AVAILABLE;
  }

  public isNotAvailableType(roster:RosterModel) {
    return roster.type === RosterService.TYPES.NOT_AVAILABLE;
  }

  /**
   * Applies any changes to the server
   */
  public async flush():Promise<void> {

    // group delete rosters by orga id
    const deleteListPerOrga:GroupedList<RosterModel[]> = this.deleteArr.reduce((obj, roster) => {
      if (!obj[roster.organization]) {
        obj[roster.organization] = new Array();
      }

      obj[roster.organization].push(roster);
      return obj;
    }, {});

    // do syncing
    const deletePromises = Object.entries(deleteListPerOrga).map(([id, rosters]) =>
      this.rosterService.deleteAllByOrga(rosters, id).toPromise()
    );
    const updatePromise = this.updateArr.length ? this.rosterService.replaceAll(this.updateArr).toPromise() : Promise.resolve();
    const createPromise = this.createArr.length ? this.rosterService.createAll(this.createArr).toPromise() : Promise.resolve();

    const promises:Promise<any>[] = [...deletePromises, updatePromise, createPromise];

    // TODO: merge returned api objects into list, but it isn't really necessary
    return Promise.all(promises).then(() => this.clear());
  }

  /**
   * Clears saved availabilities
   */
  public clear() {
    this.cachedRequests = [];
    this.list = [];
    this.updateArr = [];
    this.deleteArr = [];
    this.createArr = [];
  }

  /**
   * Returns local avialabilities for date range
   * @param start
   * @param end
   */
  private getAvailabilitiesBetween(start:Date, end:Date): RosterModel[] {

    return this.list.filter(roster => start <= roster.start && roster.end <= end);
  }

  get unsavedChanges():boolean {

    return !!(this.createArr.length || this.updateArr.length || this.deleteArr.length);
  }

  /**
   * Returns availabilities in range.
   * These will be cached for later manipulation.
   * @param from
   * @param to
   */
  getAllForCurrentAccount(from:Date, to:Date): Observable<RosterModel[]> {

    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);
    let cacheKey = from.getTime().toString() + to.getTime().toString();

    if (this.cachedRequests.includes(cacheKey)) {

      return of(this.getAvailabilitiesBetween(from, to));
    }

    return this.rosterService.getAllForCurrentAccount(from, to).pipe(
      map(rosters => {
        let result:RosterModel[] = [];
        const remoteAvailabilities = rosters.filter(this.isAvailability);

        const localAvailabilities = this.list.filter(roster => from <= roster.start && roster.end <=to);

        // merge them together, but replace cached ones
        localAvailabilities.forEach((roster, index) => {
          const duplicateIndex = remoteAvailabilities.findIndex(elem => elem.id == roster.id);

          // when duplicate exists, remove it
          if (duplicateIndex >= 0){
            remoteAvailabilities.splice(duplicateIndex, 1);
            result.push(roster);

          // when index was not found in remote -> remove from local list
          } else {
            this.list.splice(index, 1);
          }
        });

        /* As duplicatess have been removed from remoteAvailabilities,
         * we should add them to our local ones and the result.
         */
        this.list = this.list.concat(remoteAvailabilities);
        result = result.concat(remoteAvailabilities);

        this.cachedRequests.push(cacheKey);
        return result;
      })
    )
  }
}
