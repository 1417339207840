<main>
  <ng-container *ngIf="tour">
    <h2 class="mat-h2 app-list-heading" i18n>Informationen</h2>
    <mat-list>
      <mat-list-item class="mat-elevation-z1">
        <fa-icon mat-list-icon [icon]="faRoad"></fa-icon>
        <span mat-line>{{tour.title}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="mat-elevation-z1">
        <fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
        <span mat-line>{{tour.company.title}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="mat-elevation-z1">
        <fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
        <span mat-line><app-date-span [start]="tour.date_start" [end]="tour.date_end"></app-date-span></span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="mat-elevation-z1" *ngIf="tour.vehicle">
        <fa-icon mat-list-icon [icon]="faTruck"></fa-icon>
        <span mat-line>{{tour.vehicle.title}} <small>({{tour.vehicle.code}})</small></span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="mat-elevation-z1">
        <fa-icon mat-list-icon [icon]="faUser"></fa-icon>
        <span mat-line>
            <strong i18n>Fahrer</strong>:
            <span *ngIf="!tour.drivers.driver" i18n>Es wurde noch kein Fahrer ausgewählt</span>
            <span *ngFor="let staff of tour.drivers.driver; last as isLast">
                <span>{{staff.first_name}} {{staff.last_name}}</span>
                <span *ngIf="!isLast">, </span>
            </span>
        </span>
      </mat-list-item>
      <ng-container *ngIf="tour.drivers['co-driver'] && tour.drivers['co-driver'].length > 0">
        <mat-divider></mat-divider>
        <mat-list-item class="mat-elevation-z1">
          <fa-icon mat-list-icon [icon]="faUsers"></fa-icon>
          <span mat-line>
              <strong i18n>Beifahrer</strong>:
              <span *ngFor="let staff of tour.drivers['co-driver']; last as isLast">
                  <span>{{staff.first_name}} {{staff.last_name}}</span>
                  <span *ngIf="!isLast">, </span>
              </span>
          </span>
        </mat-list-item>
      </ng-container>
    </mat-list>

    <ng-container *ngIf="tour.description">
      <h2 class="app-list-heading mat-h2" i18n>Notizen</h2>
      <mat-card class="content-section">
        <mat-card-content [innerHTML]="tour.description"></mat-card-content>
      </mat-card>
    </ng-container>

    <!-- Files -->
    <ng-container *ngIf="fileCategories && fileCategories.length">
      <ng-template *ngIf="fileCategories" ngFor let-cat [ngForOf]="fileCategories">
        <h2 class="mat-h2 app-list-heading">
          <span i18n>Dateien</span>
          <span *ngIf="fileCategories.length > 1"> ({{cat.title}})</span>
        </h2>
        <app-file-list [files]="cat.files"></app-file-list>
      </ng-template>
    </ng-container>

    <!-- Jobs -->
    <h2 class="mat-h2 app-list-heading toolbar-heading" style="margin-bottom: 10px;">
      <span i18n>Jobs</span>
      <a mat-raised-button [href]="mapsLink" target="_blank">
        <fa-icon [icon]="faLocationArrow" class="color-icon"></fa-icon>&nbsp;
        <span i18n>Route aufrufen</span>
      </a>
    </h2>
    <mat-nav-list class="round-list job-list">
      <mat-list-item class="round-list-divider mat-elevation-z1" [class.success-box]="tour.completed" [disableRipple]="true">
        <fa-icon [icon]="faHome" mat-list-icon></fa-icon>
        <h3 mat-line>
            <a [href]="tour.address_start ? getMapsLinkByAddressText(tour.address_start.text) : ''" target="_blank">
                <b i18n>Startpunkt</b> - <small>{{tour.address_start.text || 'Unbekannt'}}</small>
            </a>
        </h3>
        <small style="white-space: nowrap">
          <fa-icon [icon]="faClock"></fa-icon>&nbsp;<b i18n>Abfahrt</b>: {{tour.date_start | date:'shortTime'}}
        </small>
      </mat-list-item>

      <ng-container *ngIf="tour.drive_start || tour.drive_start_cache">
        <br />
        <mat-chip-list [selectable]="false">
          <mat-chip *ngIf="tour.drive_start" [disabled]="true" class="mat-elevation-z1">
            <fa-icon [icon]="faCar"></fa-icon>&nbsp;
            {{tour.drive_start | minuteToHour }}
          </mat-chip>
          <span *ngIf="tour.drive_start && tour.drive_start_cache">+</span>
          <mat-chip *ngIf="tour.drive_start_cache" [disabled]="true" class="mat-elevation-z1">
            <fa-icon [icon]="faBed"></fa-icon>&nbsp;
            {{tour.drive_start_cache | minuteToHour}}
          </mat-chip>
        </mat-chip-list>
      </ng-container>

      <ng-container *ngFor="let j of tour.jobs">
        <a mat-list-item class="round-list-item mat-elevation-z1" (click)="routeJob(j)" [ngClass]="{'mat-1-5-line': j.client}">
          <fa-layers mat-list-icon class="fa-fw">
              <fa-icon [icon]="faBriefcase" class="color-text-primary"></fa-icon>
              <fa-icon [icon]="faCheck" class="background-green secondary-icon" *ngIf="j.tour_completed"></fa-icon>
          </fa-layers>
          <h4 mat-line>
            <b>{{j.type.title}}</b>&nbsp;-&nbsp;{{j.title}}
          </h4>
          <div mat-line class="mat-line-double">
            <div>
              <span style="flex-grow:1">
                <ng-container *ngIf="(j.dispo_start | date:'shortDate') != (tour.date_start | date:'shortDate') || (j.dispo_start | date:'shortDate') != (j.dispo_end | date:'shortDate'); else jobTime">
                    <fa-icon [icon]="faCalendarAlt" class="fixed-width"></fa-icon>&nbsp;
                    <app-date-span [start]="j.dispo_start" [end]="j.dispo_end"></app-date-span>
                </ng-container>

                <ng-template #jobTime>
                    <fa-icon [icon]="faClock" class="fixed-width"></fa-icon>&nbsp;
                    {{j.dispo_start | date:'shortTime'}} - {{j.dispo_end | date:'shortTime'}}
                </ng-template>

              </span>&nbsp;&nbsp;
              <span *ngIf="j.client">
                <fa-icon [icon]="faMale" class="fixed-width"></fa-icon>&nbsp;
                {{j.project_client && j.project_client.name ? j.project_client.name : j.client.name}}
              </span>
            </div>
            <div>
              <span class="pale" style="flex-grow: 1">
                  <ng-container *ngIf="j.dispo_start != j.date_start || j.dispo_end != j.date_end">
                      <fa-icon [icon]="faClock" class="fixed-width"></fa-icon>&nbsp;
                      {{j.date_start | date:'shortTime'}} - {{j.date_end | date:'shortTime'}}
                  </ng-container>
              </span>
              &nbsp;&nbsp;
              <span *ngIf="j.address || (j.project_client && (!j.client || j.project_client.id != j.client.id))">
                <fa-icon [icon]="faMapMarkerAlt"></fa-icon>&nbsp;
                <span *ngIf="j.project_client && j.project_client.name && j.client && j.client.id != j.project_client.id">
                    {{j.client.name}}
                </span>
                <span *ngIf="j.project_client && j.project_client.name && j.client && j.client.id != j.project_client.id && j.address">
                    &nbsp;|&nbsp;
                </span>
                <span *ngIf="j.address">
                    {{j.address.text}}
                </span>
              </span>
            </div>
          </div>

        </a>

        <ng-container *ngIf="j.tour_drive || j.tour_drive_cache">
          <mat-chip-list [selectable]="false">
            <mat-chip *ngIf="j.tour_drive" [disabled]="true" class="mat-elevation-z1">
              <fa-icon [icon]="faCar"></fa-icon>&nbsp;
              {{j.tour_drive | minuteToHour }}
            </mat-chip>
            <span *ngIf="j.tour_drive && j.tour_drive_cache">+</span>
            <mat-chip *ngIf="j.tour_drive_cache" [disabled]="true" class="mat-elevation-z1">
              <fa-icon [icon]="faBed"></fa-icon>&nbsp;
              {{j.tour_drive_cache | minuteToHour}}
            </mat-chip>
          </mat-chip-list>
        </ng-container>
      </ng-container>

      <br />
      <mat-list-item class="round-list-divider mat-elevation-z1" [class.success-box]="tour.completed" [disableRipple]="true">
        <fa-icon [icon]="faHome" mat-list-icon></fa-icon>
        <h3 mat-line>
          <a [href]="tour.address_end ? getMapsLinkByAddressText(tour.address_end.text) : ''" target="_blank">
            <b i18n>Endpunkt</b> - <small>{{tour.address_end.text || 'Unbekannt'}}</small>
          </a>
        </h3>
        <small style="white-space: nowrap">
          <fa-icon [icon]="faClock"></fa-icon>&nbsp;<b i18n>Ankunft</b>: {{tour.date_end | date:'shortTime'}}
        </small>
      </mat-list-item>
    </mat-nav-list>

    <h2 class="mat-h2 app-list-heading toolbar-heading">
      <span i18n>Erledigt</span>
      <mat-slide-toggle color="primary" [checked]="!!tour.completed" (change)="onDoneToggle($event)"></mat-slide-toggle>
    </h2>
  </ng-container>
</main>
