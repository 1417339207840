import { NgModule }       from '@angular/core';

import { SharedModule }   from '../shared/shared.module';

import { MissionProcessDetailComponent } from './mission-processes/mission-process-detail/mission-process-detail.component';
import { MissionProcessResolver } from './shared/mission-process.resolver';
import { MissionProcessService } from './shared/mission-process.service';
import { MissionProcessesComponent } from './mission-processes/mission-processes.component';
import { MissionProcessConfirmationsComponent } from './mission-processes/mission-process-confirmations/mission-process-confirmations.component';
import { MissionProcessListComponent } from './mission-processes/mission-process-list/mission-process-list.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    MissionProcessDetailComponent,
    MissionProcessesComponent,
    MissionProcessConfirmationsComponent,
    MissionProcessListComponent,
  ],
  providers: [ 
    MissionProcessService,
    MissionProcessResolver,
  ],
  exports: [
    MissionProcessListComponent
  ]
})
export class MissionsModule {}