var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TourService = /** @class */ (function (_super) {
    __extends(TourService, _super);
    function TourService(http) {
        var _this = _super.call(this, http, 'tours') || this;
        _this.http = http;
        _this.listCacheModel = new CachableModelService(http, 'toursList');
        _this.listVehicleCacheModel = new CachableModelService(http, 'vehicleList');
        return _this;
    }
    /**
     * Retrieves all tours of all organizations for the current user
     */
    TourService.prototype.getAllForCurrentAccount = function (params) {
        // get cached data
        var data = this.listCacheModel.getAll();
        params = __assign({}, params);
        if (params.date_from instanceof Date)
            params.date_from.setHours(0, 0, 0, 0);
        params.date_from = this.dateToString(params.date_from);
        var http = this.http.get('/v1/tours', { params: params });
        return this.listCacheModel.cachedRequest(data, http);
    };
    /**
     * Retrieves a tour by organization and id
     * @param orga
     * @param id
     */
    TourService.prototype.getByOrganizationAndId = function (orga, id) {
        // Get Data
        var data = this.findModelByOrganizationAndId(orga, id);
        // If we found nothing -> get data from tour list
        if (!data)
            data = this.listCacheModel.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/tours/' + id);
        return this.cachedRequest(data, http);
    };
    TourService.prototype.setCompleted = function (orga, id, bool) {
        return this.http.post('/v1/organizations/' + orga + '/tours/' + id + '/completed', { completed: bool });
    };
    /**
     * Retrieves all vehicles of all tours for the current user
     */
    TourService.prototype.getAllVehicles = function () {
        var cache = this.listVehicleCacheModel;
        var http = this.http.get('/v1/tours/vehicles');
        return cache.cachedRequest(cache.getAll(), http);
    };
    TourService.ngInjectableDef = i0.defineInjectable({ factory: function TourService_Factory() { return new TourService(i0.inject(i1.HttpClient)); }, token: TourService, providedIn: "root" });
    return TourService;
}(CachableModelService));
export { TourService };
