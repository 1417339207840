import { Component, OnInit } from '@angular/core';
import { ComponentInit } from '../../../core/classes/component-init.class';

import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { MissionProcess } from '../../shared/mission-process.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MissionProcessService } from '../../shared/mission-process.service';

import { ScrollLocations } from '../../../core/globals/scroll-locations';

import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

@Component({
  selector: 'app-mission-process-confirmations',
  templateUrl: './mission-process-confirmations.component.html',
  styleUrls: ['./mission-process-confirmations.component.css']
})
export class MissionProcessConfirmationsComponent extends ComponentInit {

  faHandshake = faHandshake;

  loadError = false;
  reload = this.loadMissionProcesses.bind(this);

  all: MissionProcess[];
  pending: MissionProcess[];
  past: MissionProcess[];

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private mps: MissionProcessService,
    protected scrollLocations: ScrollLocations
) { super(router, scrollLocations, route); }

  init(){
    this.loadMissionProcesses();
  }

  loadMissionProcesses(){
    this.loadError = false;

    // get last year
    let date = new Date();
    date.setFullYear(date.getFullYear()-1, date.getMonth(), date.getDate());

    let now = new Date();

    //retrieve processes from last year
    this.mps.getAllConfirmationsForCurrentAccount(date, null).subscribe(processes => {
      if(!processes) return;
      this.all = processes;


      //reset lists
      this.pending = [];
      this.past = [];

      // add each confirmation to a list, sorted specifically
      this.all.forEach(val => {
        if(!val.error){
            if(createDateFromDatetime(val.end) < now) this.past.unshift(val); //old ones
            else this.pending.push(val); //upcoming
        }
      });

    }, err => {
      this.loadError = true;
    });
  }
}
