<app-single-page title="Passwort zurücksetzen">
  <div class="small flex-container">
    <p class="textbox-padding" i18n>
      Gib die E-Mail Adresse deines Accounts an.<br />
      Anschließend wird dir ein Link zugesendet, über den du ein neues Passwort vergeben kannst.
    </p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!success">
      <mat-form-field>
        <input matInput type="email" placeholder="E-Mail Adresse" i18n-placeholder formControlName="email" required>
        <mat-error i18n>
          Gib deine E-Mail Adresse ein
        </mat-error>
      </mat-form-field>
      <br />
      <button mat-raised-button color="primary" i18n class="spinner-button" [disabled]="formSubmitted">
        <mat-spinner diameter="20"></mat-spinner>
        Zurücksetzen
      </button>
      <a mat-button routerLink="/auth/login" class="color-text-grey" i18n>Zurück zum Login</a>
    </form>
    <p class="textbox-padding color-text-red" *ngIf="error && !success">
      {{error}}
    </p>
    <p class="textbox-padding color-text-green" *ngIf="success" i18n>
      Dir wurde eine E-Mail mit weiteren Anweisungen zugesendet!
    </p>
    <button mat-raised-button i18n routerLink="/auth/login" color="primary" *ngIf="success">Zurück zum Login</button>
  </div>
</app-single-page>
