<mat-card class="info_list">
	<ng-container *ngFor="let item of infos; last as isLast">
		<div class="item">
			<div class="title" i18n>{{item.title}}</div>
			<div class="content">
				<div [innerHTML]="item.value"></div>
				<div class="files" *ngIf="item.files">
					<div class="file" *ngFor="let file of item.files">
						<a [href]="file.url+'&t='+accToken" target="_blank" class="color-text-primary">
					        {{file.title + '.' + file.extension}}
					    </a>
					</div>
				</div>
			</div>
			
		</div>
		<mat-divider *ngIf="!isLast"></mat-divider>
	</ng-container>
</mat-card>
