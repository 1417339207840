var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router, ActivatedRoute } from '@angular/router';
import { ComponentInit } from '../../core/classes/component-init.class';
import { faUser, faMale, faLocationArrow, faInfo, faFlag, faPhone, faRocket, faHandshake, faUsers, faInfoCircle, faBriefcase, faClock, faBookmark, faUpload, faCheckSquare, faCalendarTimes, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faComment, faBuilding, faFileAlt, faSquare } from '@fortawesome/free-regular-svg-icons';
import { ScrollLocations } from '../../core/globals/scroll-locations';
var ProjectDetailComponent = /** @class */ (function (_super) {
    __extends(ProjectDetailComponent, _super);
    function ProjectDetailComponent(route, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.fileCategories = [];
        _this.fileUploading = false;
        _this.taskGroups = [];
        _this.tab_num = 0;
        _this.tabSelected = 0;
        _this.SWIPE_ACTION = { LEFT: 'swiperight', RIGHT: 'swipeleft' };
        _this.faCalendarAlt = faCalendarAlt;
        _this.faUser = faUser;
        _this.faMale = faMale;
        _this.faLocationArrow = faLocationArrow;
        _this.faInfo = faInfo;
        _this.faComment = faComment;
        _this.faFlag = faFlag;
        _this.faPhone = faPhone;
        _this.faBuilding = faBuilding;
        _this.faRocket = faRocket;
        _this.faHandshake = faHandshake;
        _this.faFileAlt = faFileAlt;
        _this.faUsers = faUsers;
        _this.faInfoCircle = faInfoCircle;
        _this.faBriefcase = faBriefcase;
        _this.faClock = faClock;
        _this.faBookmark = faBookmark;
        _this.faUpload = faUpload;
        _this.faCheckSquare = faCheckSquare;
        _this.faSquare = faSquare;
        _this.faCalendarTimes = faCalendarTimes;
        _this.faExclamation = faExclamation;
        return _this;
    }
    ProjectDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (project) {
                if (project)
                    _this.update(project);
            });
        });
    };
    ProjectDetailComponent.prototype.update = function (project) {
        var _this = this;
        this.project = project;
        if (project.jobs) {
            var jobsWithCrew = project.jobs.filter(function (job) { return job.crew; });
            /* map staffs per crewGroup per job to job */
            this.staffsPerJob = jobsWithCrew.map(function (job) {
                var staffMap = new Map();
                job.crew.forEach(function (crew) { return crew.staffs.forEach(function (staff) { return staffMap.set(staff.id, staff); }); });
                return { job: job, staffs: Array.from(staffMap.values()).slice(0) };
            });
            /* get total staff, remove duplicates, and return the staff size */
            this.staffSize = this.staffsPerJob.reduce(function (staffSet, group) {
                group.staffs.forEach(function (staff) { return staffSet.add(staff.id); });
                return staffSet;
            }, new Set()).size;
        }
        // group files by category
        if (project.files) {
            this.fileCategories = [];
            project.files.forEach(function (file) {
                var category = _this.fileCategories.find(function (val) { return val.id == (file.cat ? file.cat.id : 0); });
                if (!category) {
                    _this.fileCategories.push({
                        id: file.cat ? file.cat.id : 0,
                        title: file.cat ? file.cat.title : 'Ohne Kategorie',
                        files: [file]
                    });
                }
                else {
                    category.files.push(file);
                }
            });
        }
        // group tasks by completed/incomplete
        if (project.tasks) {
            this.taskGroups = [
                {
                    title: 'Ausstehend',
                    tasks: project.tasks.filter(function (t) { return !t.completed; })
                },
                {
                    title: 'Erledigt',
                    tasks: project.tasks.filter(function (t) { return t.completed; })
                }
            ].filter(function (g) { return g.tasks.length; });
        }
        // Update Tab Count (Wait for rendering)
        setTimeout(function () { _this.tab_num = _this.tabs.length; });
    };
    ProjectDetailComponent.prototype.routeJob = function (job, params) {
        this.router.navigate(['/jobs/' + job.organization + '/' + job.id], { queryParams: params });
    };
    ProjectDetailComponent.prototype.routeTask = function (task) {
        this.router.navigate(['/tasks/' + task.organization + '/' + task.id]);
    };
    ProjectDetailComponent.prototype.swipe = function (eType) {
        if (eType === this.SWIPE_ACTION.LEFT && this.tabSelected > 0)
            this.tabSelected--;
        else if (eType === this.SWIPE_ACTION.RIGHT && this.tabSelected < (this.tab_num - 1))
            this.tabSelected++;
    };
    ProjectDetailComponent.prototype.onFileAdded = function () {
        this.reloadData();
        document.querySelector('.mat-tab-body-active main').scrollTo(0, 0);
    };
    return ProjectDetailComponent;
}(ComponentInit));
export { ProjectDetailComponent };
