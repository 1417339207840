<mat-nav-list>
  <a mat-list-item class="round-list-item mat-elevation-z1" (click)="routeMissionProcess(mp)" *ngFor="let mp of missionProcesses">
    <fa-icon mat-list-icon class="color-text-primary" [icon]="faHandshake" *ngIf="mp.state == 'confirmation'"></fa-icon>
    <fa-icon mat-list-icon class="color-text-red" [icon]="faTimes" *ngIf="mp.state == 'cancel'"></fa-icon>
    <fa-layers mat-list-icon class="fa-fw" *ngIf="mp.state != 'confirmation' && mp.state != 'cancel'">
      <fa-icon [icon]="faShareAlt" class="color-text-primary" *ngIf="mp.state == 'proposal'"></fa-icon>
      <fa-icon [icon]="faFileAlt" class="color-text-primary" *ngIf="mp.state == 'offer'"></fa-icon>
      <fa-icon [icon]="faQuestion" class="background-orange secondary-icon" *ngIf="mp.answer == null && !mp.locked"></fa-icon>
      <fa-icon [icon]="faLock" class="background-red secondary-icon" *ngIf="mp.answer == null && mp.locked"></fa-icon>
      <fa-icon [icon]="faTimes" class="background-red secondary-icon" *ngIf="mp.answer == 'declined' || mp.answer == 'rejected'"></fa-icon>
      <fa-icon [icon]="faCheck" class="background-green secondary-icon" *ngIf="mp.answer == 'accepted'"></fa-icon>
    </fa-layers>
    <div mat-line><span>{{mp.title}}</span></div>
    <div mat-line class="second-line">
      <div style="flex:1">
        <fa-icon [icon]="faCalendarAlt"></fa-icon>&nbsp;
        <app-date-span [start]="mp.start" [end]="mp.end" [short]="true"></app-date-span>
        <ng-container *ngIf="mp.info && mp.info.period && mp.info.period.value_raw">&nbsp;=&nbsp;<b>{{mp.info.period.value_raw[2]}}h</b></ng-container>
      </div>
      <div class="fee" *ngIf="mp.info && mp.info.fee">
        {{mp.info.fee.value_raw}}
        <fa-icon [icon]="faEuroSign"></fa-icon>
      </div>
    </div>
  </a>
</mat-nav-list>
