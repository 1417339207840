import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { formatDate } from '@angular/common';
import { createDateFromDatetime, createDateFromDateAndTime } from 'src/app/core/utils/date-utils';

import { WorkSchedule, WorkScheduleEntry } from '../shared/work-schedule.model';
import { WorkService } from '../shared/work.service';
import { WorkScheduleEntryType } from '../shared/interfaces';

import { Job } from 'src/app/jobs/shared/job.model';
import { JobService } from 'src/app/jobs/shared/job.service';

import { Project } from 'src/app/core/models/project.model';
import { ProjectService } from 'src/app/projects/shared/project.service';

import { Qualification } from 'src/app/core/models/qualification.model';
import { QualificationService } from 'src/app/core/services/qualification.service';

import { AccountService } from '../../core/services/account.service';
import { Account } from '../../core/models/account.model';

import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { toggleFormControl } from 'src/app/core/utils/form-utils';

import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';

import { MyErrorHandler } from '../../core/classes/my-error-handler.class';

export enum DialogType {
  StartWork,
  StartProject,
  EndProject,
  EditProject,
  CreateProject,
};

export const defaultDialogOptions = {
  minWidth: 250,
  maxWidth: 500,
  autoFocus: false,
  width: '95vw'
};

export interface DialogData {
  type: DialogType;
  past: Boolean;
  schedule?: WorkSchedule;
  entry?: WorkScheduleEntry;
  organization: number;
  company: number;
  job?: Job;
  project?: Project;
  qualification?: Qualification;
  date?: Date;
}

@Component({
  selector: 'app-work-schedule-dialog',
  templateUrl: './work-schedule-dialog.component.html',
  styleUrls: ['./work-schedule-dialog.component.css']
})
export class WorkScheduleDialogComponent implements OnInit {
  form: FormGroup;
  futureDateError;
  beforeStartDateError;

  types = DialogType;

  account:Account;

  date = new Date();
  organization: number;
  company: number;
  projectOptionGroups = new BehaviorSubject([]);
  jobOptions: Array<Job> = [];
  qualificationOptions: Array<Qualification> = [];

  private projectTitleRequest:Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    public dialogWs: MatDialogRef<WorkScheduleDialogComponent>,
    public dialog: MatDialog,
    private accountService: AccountService,
    private projectService: ProjectService,
    private jobService: JobService,
    private qualificationService: QualificationService,
    private workService: WorkService,
    private myErrorHandler: MyErrorHandler,
    @Inject(MAT_DIALOG_DATA) public data:DialogData,
    @Inject(LOCALE_ID) private locale:string,
  ) {

    switch(this.data.type) {
      case DialogType.EndProject:
      case DialogType.EditProject:
        if (!this.data.entry) console.error('entry not defined');
      case DialogType.StartProject:
      case DialogType.CreateProject:
        if (!this.data.schedule) console.error('schedule not defined');
      default:
        break;
    }

    this.organization = this.data.organization || this.data.schedule && this.data.schedule.organization || null;
    this.company = this.data.company || null;

    this.initLoadProjectsByTitle();
  }

  /**
   * date to date format
   * @param dateString
   */
  formatDate(dateString: string | number | Date): string {
    if(typeof dateString === 'string' && dateString.length > 11)
      dateString = createDateFromDatetime(dateString);
    return formatDate(dateString, 'yyyy-MM-dd', this.locale)
  }

  /**
   * date to time format
   * @param dateString
   */
  formatTime(dateString: string | number | Date): string {
    if(typeof dateString === 'string' && dateString.length > 11)
      dateString = createDateFromDatetime(dateString);
    return formatDate(dateString, 'HH:mm', this.locale)
  }

  ngOnInit(){
    // Get current account
    this.accountService.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(res => {
        if(!res) return;
        this.account = res;
    });

    const group: any = {
      comment: [''],
      job: [null],
      project: [null],
      qualification: [null],
      date: [this.data.past ? this.formatDate(new Date(this.date.getTime() - (24 * 60000 * 60))) : this.formatDate(this.date),
          Validators.required],
      time: [this.data.past ? '08:00:00' : this.formatTime(this.date), Validators.required],
    };

    switch(this.data.type) {
      case DialogType.EndProject:
        group.comment = [this.data.entry.comment];
        break;
      case DialogType.StartProject:
        group.project = [null];
        break;
      case DialogType.EditProject:
        group.startDate = [this.formatDate(this.data.entry.start), Validators.required];
        group.startTime = [this.formatTime(this.data.entry.start), Validators.required];
        group.endDate = [this.formatDate(this.data.entry.end || this.data.date), Validators.required];
        group.endTime = [this.formatTime(this.data.entry.end || this.data.date), Validators.required];
        group.duration = [Math.round(this.data.entry.duration * 100) / 100, Validators.required];
        group.comment = [this.data.entry.comment];
        group.job = [this.data.job || null];
        group.project = [this.data.project || null];
        group.qualification = [this.data.qualification || null];
        break;
      case DialogType.CreateProject:
        group.startDate = [this.formatDate(this.data.schedule.date_in), Validators.required];
        group.startTime = [null, Validators.required];
        group.endDate = [this.formatDate(this.data.schedule.date_in), Validators.required];
        group.endTime = [null, Validators.required];
        group.duration = [0, Validators.required];
    }

    this.form = this.fb.group(group);

    Object.entries(this.form.controls).forEach(([key, field]) => {
      switch(key) {
        case 'project':
          field.valueChanges.subscribe(this.onProjectChange.bind(this));
          break;

        case 'qualification':
            const project = this.form.value.project;
            if (!project) field.disable();
            else this.loadQualifications(project.id);
            break;
        case 'startDate':
        case 'endDate':
          field.valueChanges.subscribe(() => this.loadProjectsByDate(new Date(this.form.value.startDate)));
          // this.loadDispos(new Date(this.form.value.startDate), new Date(this.form.value.endDate))
          break;
        case 'date':
          field.valueChanges.subscribe(this.onDateChange.bind(this));
          break;
      }
    });

    const start = this.data.entry ? this.data.entry.start : (group.date ? group.date[0] : this.date);
    this.loadProjectsByDate(start);
  }

  /**
   * calculates duration from two dates in hours
   * @param start
   * @param end
   */
  calculateDuration(start:Date, end:Date) {
    return Math.round((end.getTime() - start.getTime()) / 36000) / 100;
  }

  /**
   * updates duration according to dates
   */
  updateDuration() {
    const start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
    const end = createDateFromDateAndTime(this.form.value.endDate, this.form.value.endTime);

    if (!start || !end) return;

    this.form.controls['duration'].setValue(this.calculateDuration(start, end))
  }

  /**
   * updates end date according to duration
   */
  updateEnd() {

    const duration = this.form.value.duration;
    if (!duration) return;

    const start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
    const end = new Date(start.getTime() + duration * 3600000);

    this.form.controls['endDate'].setValue(this.formatDate(end));
    this.form.controls['endTime'].setValue(this.formatTime(end));
  }

  buildProjectOptionsGroups(project_groups){
      let projectOptionGroups = [];
      project_groups.forEach(project_group => {
        let option_group = {
          title: project_group['title'],
          type: project_group['type'],
          options: []
        };
        project_group['projects'].forEach(project => {
          option_group.options.push({
            id: project.id,
            title: project.title,
            headline: {
              type: 'date',
              data: {
                start: project.date_start,
                end: project.date_end,
                showWeekday: false,
                dateOnly: project_group['date_format'] == 'date' ? true : false
              }
            }
          });
        })
        projectOptionGroups.push(option_group);
      })

      return projectOptionGroups;
  }

  loadProjectsByDate(date: string | number | Date){
    let date_obj: Date;

    if(date instanceof Date) date_obj = date;
    else date_obj = new Date(date);

    if(isNaN(date_obj.getTime())) return;

    this.projectService.getAllByOrganizationAndStaffAndDate(this.organization, 'current', date_obj).pipe(untilDestroyed(this))
            .subscribe(project_groups => {
        if(!project_groups) return this.projectOptionGroups.next([]);

        let projectOptionGroups = this.buildProjectOptionsGroups(project_groups);

        this.projectOptionGroups.next(projectOptionGroups);

        // Check if we have project group "dispos" and assign first entry as "value", when start of dispo is not more than 60 minutes in the future
        if(projectOptionGroups[0].type == 'dispos' && projectOptionGroups[0].options.length){
            let dispo = projectOptionGroups[0].options[0];
            let dispo_start = dispo.headline.data.start;
            if(createDateFromDatetime(dispo_start).getTime() - (new Date()).getTime() < (1000 * 60 * 30)){
                this.form.controls['project'].setValue(projectOptionGroups[0].options[0]);
            }
        }
    });
  }

  initLoadProjectsByTitle(){
      this.projectTitleRequest = new Subject();

      this.projectTitleRequest.pipe(switchMap(title =>
          this.projectService.getAllByOrganizationAndStaffAndTitle(this.organization, 'current', title)
      )).subscribe(project_groups => {
          let current_option_groups = [...this.projectOptionGroups.getValue()];

          // :: Remove group "search_result"
          if(!project_groups){
              current_option_groups = current_option_groups.filter(g => g.type != 'search_result');
              this.projectOptionGroups.next(current_option_groups);
              return;
          }

          let new_option_groups = this.buildProjectOptionsGroups(project_groups);

          // Replace existing option groups
          new_option_groups.forEach(project_option_group => {
               let exisiting_option_group_index = current_option_groups.findIndex(g => g.type == project_option_group.type);
               if(exisiting_option_group_index >= 0){
                   current_option_groups[exisiting_option_group_index] = project_option_group;
               } else {
                   current_option_groups.push(project_option_group);
               }
          });

          this.projectOptionGroups.next(current_option_groups);
        });
  }

  onProjectSearchChange(val){
      if(!val){
          // :: Remove group "search_result"
          let current_option_groups = [...this.projectOptionGroups.getValue()];
          if(current_option_groups.length){
              current_option_groups = current_option_groups.filter(g => g.type != 'search_result');
              this.projectOptionGroups.next(current_option_groups);
          }
      } else if(this.account && this.account.work_schedule_project_search_orgas && this.account.work_schedule_project_search_orgas.includes(this.organization)){
          this.projectTitleRequest.next(val);
      }

  }

  /**
   * Called when project selection changes.
   * Loads jobs for project
   * @param project
   */
  onProjectChange(project:Project) {
    toggleFormControl(this.form.controls['qualification'], project && project.id);
    // this.form.controls['qualification'].disable();
    if(!project || !project.id) return;

    this.loadQualifications(project.id);
    // this.loadJobs(project.id);
  }

  /**
   * loads jobs
   * @param projectId project id
   */
  loadJobs(projectId: number) {
    this.jobService.getAllByOrganization(this.organization, {
      projects: [projectId],
    }).pipe(untilDestroyed(this)).subscribe(jobs => this.jobOptions = jobs);
  }

  loadQualifications(project_id: number){
    this.form.controls['qualification'].setValue(null);

    this.qualificationService.getAllByOrganizazionAndCurrentStaff(this.organization, {
        project: project_id,
        date: this.form.value.date ? new Date(this.form.value.date) : this.date
    }).pipe(untilDestroyed(this)).subscribe(qualifications => {
        this.qualificationOptions = qualifications;

        // Select first qualification entry when it has the flag "dispo"
        if(qualifications && qualifications.length && qualifications[0]['dispo']){
            this.form.controls['qualification'].setValue(qualifications[0].id);
        }
    });
  }

  /**
   * Called when date changes.
   * Loads dispositions for selected date
   * @param value
   */
  onDateChange(value) {
    // this.loadDispos(new Date(value));
    this.form.controls.project.setValue(null);
    this.loadProjectsByDate(new Date(value));
  }

  /**
   * Called when submit button is pressed
   */
  submit(){
    this.futureDateError = false;
    this.beforeStartDateError = false;

    if(this.form.valid){
      const values = {...this.form.value};
      const now = new Date();

      values.date = createDateFromDateAndTime(values.date, values.time);

      // check for valid date
      if(isNaN(values.date) && (
          this.data.type == DialogType.EndProject
          || this.data.type == DialogType.StartWork
          || this.data.type == DialogType.StartProject
      )) return this.dialog.open(OkDialogComponent, {data: 'Datum/Uhrzeit ist fehlerhaft.'});

      // check for future date
      if(new Date() < values.date){
        this.futureDateError = true;
        return;
      }

      if(values.startDate) values.start = createDateFromDateAndTime(values.startDate, values.startTime);
      if(values.endDate) values.end = createDateFromDateAndTime(values.endDate, values.endTime);

      if (values.startDate && values.endDate) {
        if (now < values.start || now < values.end) {
          this.futureDateError = true;
          return
        }

        if (values.start > values.end) {
          this.beforeStartDateError = true;
          return;
        }
      }

      if(typeof values.start === 'string') values.start = createDateFromDatetime(values.start);
      if(typeof values.end === 'string') values.end = createDateFromDatetime(values.end);

      switch (this.data.type) {
        case DialogType.EndProject:
          this.endProject(values);
          break;
        case DialogType.StartWork:
          this.startWork(values);
          break;
        case DialogType.StartProject:
          this.startProject(values);
          break;
        case DialogType.EditProject:
          this.editProject(values);
          break;
        case DialogType.CreateProject:
          this.createProject(values);
          break;
        default:
            this.dialog.open(OkDialogComponent, {data: 'Unkown Dialog Type'});
      }
    }
  }

  /**
   * ends work schedule entry
   * @param form
   */
  private endProject(form) {
    return this.workService.updateWorkScheduleEntry(this.organization, this.data.entry.id, {
      end: form.date,
      comment: form.comment
    }).pipe(untilDestroyed(this)).subscribe(
        entry => this.dialogWs.close(entry),
        err => this.myErrorHandler.showError(err)
    );
  }

  /**
   * starts work schedule entry
   * @param form
   */
  private startProject(form) {
    return this.workService.createWorkScheduleEntry({
      organization: this.data.schedule.organization,
      start: form.date,
      work_schedule: this.data.schedule.id,
      comment: form.comment,
      project: form.project ? form.project.id : null,
      job: form.job ? form.job.id : null,
      qualification: form.qualification || null
    }).pipe(untilDestroyed(this)).subscribe(
        entry => this.dialogWs.close(entry),
        err => this.myErrorHandler.showError(err)
    );
  }

  private editProject(form){
      return this.workService.updateWorkScheduleEntry(this.organization, this.data.entry.id, {
        organization: this.organization,
        type: WorkScheduleEntryType.WORKING,
        work_schedule: this.data.schedule.id,
        start: form.start,
        end: form.end,
        comment: form.comment,
        project: form.project ? form.project.id : null,
        job: form.job ? form.job.id : null,
        qualification: form.qualification || null,
        duration: form.duration
      }).pipe(untilDestroyed(this)).subscribe(
          entry => this.dialogWs.close(entry),
          err => this.myErrorHandler.showError(err)
      );
  }

  private createProject(form){
      return this.workService.createWorkScheduleEntry({
        organization: this.organization,
        type: WorkScheduleEntryType.WORKING,
        work_schedule: this.data.schedule.id,
        start: form.start,
        end: form.end,
        comment: form.comment,
        project: form.project ? form.project.id : null,
        job: form.job ? form.job.id : null,
        qualification: form.qualification || null,
        duration: form.duration
      }).pipe(untilDestroyed(this)).subscribe(
          entry => this.dialogWs.close(entry),
          err => this.myErrorHandler.showError(err)
      );
  }

  /**
   * starts work schedule
   * @param form
   */
  private startWork(form) {
    let entry = null;
    if(form.project) {
      entry = {
        type: WorkScheduleEntryType.WORKING,
        project: form.project ? form.project.id : null,
        qualification: form.qualification || null
      }
    }

    return this.workService.startWorkSchedule(
      this.organization,
      form.comment,
      form.date,
      this.date,
      entry,
      this.company
    ).pipe(untilDestroyed(this)).subscribe(
        workSchedule => this.dialogWs.close(workSchedule),
        err => this.myErrorHandler.showError(err)
    )
  }

  ngOnDestroy() {}
}
