import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { OrganizationService } from './organization.service';
import { DispositionService } from '../../dispositions/shared/disposition.service';
import { JobService } from '../../jobs/shared/job.service';
import { MissionProcessService } from '../../missions/shared/mission-process.service';
import { TourService } from '../../tours/shared/tour.service';
import { EventService } from '../../events/shared/event.service';
import { FileService } from './file.service';
import { AccountService } from './account.service';

/**
 * This class is responsible for clearing data.
 * Add cachableServices here!
 */
@Injectable({
  providedIn: 'root'
})
export class ClearService {

  constructor(
    private notification:NotificationService,
    private organization:OrganizationService,
    private dispo:DispositionService,
    private job:JobService,
    private missionProcess:MissionProcessService,
    private tour:TourService,
    private event:EventService,
    private file:FileService,
    private acc:AccountService,
  ) { }

  /**
   * Clears all services that contain potential critical data.
   * Add more if desired!
   */
  clear(){
    this.notification.clear();
    this.organization.clear();
    this.dispo.clear();
    this.job.clear();
    this.missionProcess.clear();
    this.tour.clear();
    this.event.clear();
    this.file.clear();
    this.acc.clear();
    // wipe any data left
    localStorage.clear();
  }
}
