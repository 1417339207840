import { Component, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ComponentInit } from '../../core/classes/component-init.class';
import { createDateFromDatetime } from '../../core/utils/date-utils';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { JobDetails } from '../shared/job.model';
import { Organization } from '../../core/models/organization.model';

import { JobTimesheetCreateDialogComponent } from '../job-timesheet-create-dialog/job-timesheet-create-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

import { JobService } from '../shared/job.service';
import { OrganizationService } from '../../core/services/organization.service';

import { MatTab, MatDialog } from '@angular/material';

import {
  faUser,
  faMale,
  faLocationArrow,
  faInfo,
  faFlag,
  faPhone,
  faRocket,
  faHandshake,
  faUsers,
  faInfoCircle,
  faBusinessTime,
  faSignature,
  faCheckSquare,
  faCalendarTimes,
  faExclamation,
  faThumbtack
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
  faComment,
  faBuilding,
  faFileAlt,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css']
})
export class JobDetailComponent extends ComponentInit {
  @ViewChildren(MatTab) tabs;

  job:JobDetails;
  mapsLink;
  crewAmount:Number = 0;
  projectFileCategories: any[] = [];
  orga:Organization;
  taskGroups: any[] = [];

  tab_num = 0;
  tabSelected = 0;
  SWIPE_ACTION = { LEFT: 'swiperight', RIGHT: 'swipeleft' };

  faCalendarAlt = faCalendarAlt;
  faUser = faUser;
  faMale = faMale;
  faLocationArrow = faLocationArrow;
  faInfo = faInfo;
  faComment = faComment;
  faFlag = faFlag;
  faPhone = faPhone;
  faBuilding = faBuilding;
  faRocket = faRocket;
  faHandshake = faHandshake;
  faFileAlt = faFileAlt;
  faUsers = faUsers;
  faInfoCircle = faInfoCircle;
  faBusinessTime = faBusinessTime;
  faSignature = faSignature;
  faCheckSquare = faCheckSquare;
  faSquare = faSquare;
  faCalendarTimes = faCalendarTimes;
  faExclamation = faExclamation;
  faThumbtack = faThumbtack;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private jobService: JobService,
    private orgaService: OrganizationService,
    protected router: Router,
    protected scrollLocations: ScrollLocations
  ) {
    super(router, scrollLocations, route);
  }

  init(){
    this.route.data.subscribe(data => {
      if(data.resolved.model)
        this.update(data.resolved.model);
      data.resolved.updates.subscribe(job => {
        if(job) this.update(job)
      });
    });

    this.orgaService.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
      if(res && this.job) this.orga = res.find(o => o.id == this.job.organization);
    });
  }

  update(job){
    this.job = job;
    this.generateMapsLink();

    // Get Crew Amount
    this.crewAmount = 0;
    if(this.job.crew && this.job.crew.length){
      this.job.crew.forEach(group => {
        this.crewAmount += group.staffs.length;
      })
    }

    // Group project files by category
    if(job.project && job.project.files){
        this.projectFileCategories = [];
        job.project.files.forEach(file => {
            let category = this.projectFileCategories.find(val => val.id == (file.cat ? file.cat.id : 0));
            if(!category){
                this.projectFileCategories.push({
                    id: file.cat ? file.cat.id : 0,
                    title: file.cat ? file.cat.title : 'Ohne Kategorie',
                    files: [file]
                });
            } else {
                category.files.push(file);
            }
        });
    }

    // group tasks by completed/incomplete
    if(job.tasks){
        this.taskGroups = [
            {
                title: 'Ausstehend',
                tasks: job.tasks.filter(t => !t.completed)
            },
            {
                title: 'Erledigt',
                tasks: job.tasks.filter(t => t.completed)
            }
        ].filter(g => g.tasks.length);
    }

    // Update Tab Count (Wait for rendering)
    setTimeout(() => { this.tab_num = this.tabs.length; });


  }

  /**
   * Handler for done checkbox toggle.
   * Opens dialog when job hasn't yet started
   * @param event checkbox event
   */
  OnDoneToggle(event){

    /* job hasn't started yet, so confirm action from user */
    if (event.checked && new Date() < createDateFromDatetime(this.job.dispo_start)) {

      const confirmRef = this.dialog.open(ConfirmDialogComponent, { data: {
        text: 'Der Job hat noch nicht begonnen! Soll dieser wirklich als erledigt markiert werden?',
        okTitle: 'Ja',
      }});
      confirmRef.afterClosed().subscribe(result => {
        if (result)
          this.toggleDone(event)
        else
          event.source.checked = !event.source.checked
      }, err => {
        event.source.checked = !event.source.checked
      });
    } else {

      this.toggleDone(event)
    }
  }

  /**
   * Toggles Job done, depending on checkbox event
   * @param event checkbox event
   */
  toggleDone(event) {

    this.jobService.setCompleted(this.job.organization, this.job.id, event.checked).subscribe(res => {
      if(res){
        //success
        this.job.tour_completed = event.checked;
      }
      else {
        event.source.checked = !event.source.checked;
      }
    }, err => {
      event.source.checked = !event.source.checked;
    });
  }

  generateMapsLink(){
    if(this.job.address)
      this.mapsLink = 'https://maps.google.com/?daddr='+encodeURIComponent(this.job.address.text);
  }

  routeMissionProcess(mp){
    this.router.navigate(['/missions/processes/'+mp.organization+'/'+mp.id]);
  }

  routeProject(project){
    this.router.navigate(['/projects/'+project.organization+'/'+project.id]);
  }

  routeTask(task){
     this.router.navigate(['/tasks/'+task.organization+'/'+task.id]);
   }

  showJobTimesheetCreateDialog(job){
    this.dialog.open(JobTimesheetCreateDialogComponent, {
      width: '400px',
      data: {
        job: job
      }
    }).afterClosed().subscribe(data => {
          if(!data) return;
          this.router.navigate(['/jobs/'+job.organization+'/'+job.id+'/timesheet'], {
            queryParams: {
              staffs: data.staffs
            }
          });
        }
    );
  }

  routeTimesheet(timesheet){
    this.router.navigate(['/timesheets/'+timesheet.organization+'/'+timesheet.id]);
  }

  staffNamesOfTimesheet(timesheet){
    return Object.values(timesheet.staffs).map(s => (s['custom_amount'] ? '' : '1x ') + s['first_name'] + ' ' + s['last_name']).join(', ');
  }

  swipe(eType){
    if(eType === this.SWIPE_ACTION.LEFT && this.tabSelected > 0)
      this.tabSelected--;
    else if(eType === this.SWIPE_ACTION.RIGHT && this.tabSelected < (this.tab_num - 1))
      this.tabSelected++;
  }

  onFileAdded(){
      this.reloadData();
      document.querySelector('.mat-tab-body-active main').scrollTo(0, 0);
  }

}
