var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VacationRequestService = /** @class */ (function (_super) {
    __extends(VacationRequestService, _super);
    function VacationRequestService(http, locale) {
        var _this = _super.call(this, http, 'vacationRequests') || this;
        _this.http = http;
        _this.locale = locale;
        return _this;
    }
    /**
     * Returns all vacation requests groupe by organization
     */
    VacationRequestService.prototype.getVacationRequests = function () {
        // get cached data
        var data = this.models;
        var http = this.http.get('/v1/vacation-requests');
        return this.cachedRequest(data, http);
    };
    VacationRequestService.prototype.getByOrganizationAndId = function (orga, id) {
        // Get Data
        var data = this.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/vacation-request/' + id);
        return this.cachedRequest(data, http);
    };
    VacationRequestService.prototype.getInfosInPeriodOfType = function (orga_id, date_from, date_to, type, company_id) {
        return this.http.get('/v1/organizations/' + orga_id + '/vacation-requests/info', {
            params: {
                date_from: date_from,
                date_to: date_to,
                type: type,
                company: company_id
            }
        });
    };
    VacationRequestService.prototype.create = function (orga_id, payload) {
        return this.http.post('/v1/organizations/' + orga_id + '/vacation-requests', payload).pipe(tap(function (res) { return res; }));
    };
    VacationRequestService.prototype.delete = function (orga_id, entry_id) {
        return this.http.delete('/v1/organizations/' + orga_id + '/vacation-request/' + entry_id).pipe(tap(function (res) { return res; }));
    };
    VacationRequestService.ngInjectableDef = i0.defineInjectable({ factory: function VacationRequestService_Factory() { return new VacationRequestService(i0.inject(i1.HttpClient), i0.inject(i0.LOCALE_ID)); }, token: VacationRequestService, providedIn: "root" });
    return VacationRequestService;
}(CachableModelService));
export { VacationRequestService };
