import { Pipe, PipeTransform } from '@angular/core';
/*
 * Formats minutes as hour representation
 * Usage:
 *   value | minuteToHour
 * Example:
 *   {{ 115 | minuteToHour }}
 *   formats to: 1:55
*/
@Pipe({name: 'minuteToHour'})
export class MinuteToHourPipe implements PipeTransform {
  transform(value: number): string {
	return Math.floor(value/60).toString().padStart(2, '0') + ':' + Math.floor(value%60).toString().padStart(2, '0');
  }
}
