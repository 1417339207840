import { Component, Input } from '@angular/core';

import {
  faCheck,
  faCircle,
  faShareAlt,
  faTimes,
  faQuestion,
  faEuroSign,
  faHandshake,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFileAlt,
  faCalendarAlt,
} from '@fortawesome/free-regular-svg-icons';
import { MissionProcess } from '../../shared/mission-process.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mission-process-list',
  templateUrl: './mission-process-list.component.html',
  styleUrls: ['./mission-process-list.component.css']
})
export class MissionProcessListComponent{
  @Input() missionProcesses:MissionProcess[];

  faCheck = faCheck;
  faCircle = faCircle;
  faCalendarAlt = faCalendarAlt;
  faQuestion = faQuestion;
  faShareAlt = faShareAlt;
  faTimes = faTimes;
  faEuroSign = faEuroSign;
  faFileAlt = faFileAlt;
  faHandshake = faHandshake;
  faLock = faLock;

  constructor(
    private router: Router,
  ) { }

  routeMissionProcess(mp: MissionProcess){
    this.router.navigate(['/missions/processes/'+mp.organization+'/'+mp.id]);
  }

}
