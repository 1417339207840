import { Component, OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { TaskAssignService } from '../shared/task-assign.service';
import { TaskAssign } from '../shared/task-assign.model';

import { faGavel, faTruckLoading, faCalendarAlt, faCheck, faInfo, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare, faBuilding } from '@fortawesome/free-regular-svg-icons';

@Component({
	selector: 'app-task-assign-detail',
	templateUrl: './task-assign-detail.component.html',
	styleUrls: ['./task-assign-detail.component.css']
})
export class TaskAssignDetailComponent extends ComponentInit {

	faCalendarAlt = faCalendarAlt;
	faBuilding = faBuilding;
	faInfo = faInfo;
	faTruck = faTruck;
	icons = {
		'task': faSquare,
		'activity': faGavel,
		'tour_before': faTruckLoading,
		'tour_after': faTruckLoading
	}
	disableCompleteButton = false;

	taskAssign:TaskAssign;

	constructor(
		private route: ActivatedRoute,
		private ts: TaskAssignService,
		protected router: Router,
		protected scrollLocations: ScrollLocations,
		private location: Location
	) { super(router, scrollLocations, route); }

	init() {
		this.route.data.subscribe(data => {
			if(data.resolved.model) this.update(data.resolved.model);
			data.resolved.updates.subscribe(res => {
				if(res) this.update(res);
			});
		});
	}

	update(taskAssign){
		this.taskAssign = taskAssign;
	}

	icon(t){
		if(t.type == 'task' && t.completed) return faCheckSquare;
		else return this.icons[t.type];
	}

	routeTour(tour_id){
    	this.router.navigate(['/tours/'+this.taskAssign.organization+'/'+tour_id]);
    }

	completeButton(){
		this.disableCompleteButton = true;
		this.complete(() => {
			this.location.back();
		});
	}
	complete(callback?: Function){
		if(!callback) this.taskAssign.completed = 1;
		return this.ts.complete(this.taskAssign).subscribe(res => {
	    	this.disableCompleteButton = false;
			if(callback){
				this.taskAssign.completed = 1;
				callback();
			}
	    }, res => {
			this.taskAssign.completed = 0;
			this.disableCompleteButton = false;
	    });
	}
	incomplete(){
		this.taskAssign.completed = 0;
		return this.ts.incomplete(this.taskAssign).subscribe(res => {}, res => {
			this.taskAssign.completed = 1;
	    });
	}

	completeToggle(){
		if(this.taskAssign.completed) this.incomplete();
		else this.complete();
	}

}
