var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject, of } from 'rxjs';
import { ModelService } from './model-service.class';
import { tap, takeLast } from 'rxjs/operators';
/**
 * This class provides caching functionality for models.
 * For differing data structures you may implement your own functionality and implement AbstractCachableService.
 * All Cachable services should be added to 'ClearService'
 */
var CachableModelService = /** @class */ (function (_super) {
    __extends(CachableModelService, _super);
    /**
     * @param localStorageItemName  Name used for localStorage
     * @param on  unique identifier of model
     * @param orga  wether this model is a client model
     */
    function CachableModelService(http, localStorageItemName, orga, id) {
        if (orga === void 0) { orga = true; }
        if (id === void 0) { id = 'id'; }
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.storageName = localStorageItemName;
        _this.id = id;
        _this.orga = orga;
        _this.lastSubject = new BehaviorSubject(null);
        // restore stored list
        if (localStorage.getItem(localStorageItemName))
            _this.models = JSON.parse(localStorage.getItem(localStorageItemName));
        return _this;
    }
    /**
     * clears stored values
     */
    CachableModelService.prototype.clear = function () {
        this.models = [];
        localStorage.removeItem(this.storageName);
    };
    CachableModelService.prototype.clearModelsByDate = function (from, to, property) {
        var _this = this;
        if (from === void 0) { from = null; }
        if (to === void 0) { to = null; }
        if (property === void 0) { property = "start"; }
        if (!this.models)
            return;
        this.models.forEach(function (model, index) {
            if (from && to) {
                if (model[property] >= from && model[property] <= to)
                    _this.models.splice(index, 1);
            }
            else if (from) {
                if (model[property] >= from)
                    _this.models.splice(index, 1);
            }
            else if (to) {
                if (model[property] <= to)
                    _this.models.splice(index, 1);
            }
        });
    };
    /**
     * when using resolvers to catch data of certain routes,
     * they cannot update the data,
     * thus this function will provide any component with the last observable of the requested data
     */
    CachableModelService.prototype.getLastCachedObservable = function () {
        if (this.lastSubject.isStopped) /* !this.lastSubject.value ||  */
            return of(null);
        return this.lastSubject.asObservable().pipe(takeLast(1));
    };
    CachableModelService.prototype.getAll = function () {
        return this.models;
    };
    /**
     * Returns cached data and performs the http request and returns the result
     * @param data cached data
     * @param request http request observable
     */
    CachableModelService.prototype.cachedRequest = function (data, request, clearData) {
        var _this = this;
        if (clearData === void 0) { clearData = null; }
        // Request Result
        request = request.pipe(tap(function (res) {
            if (!res)
                return;
            if (res instanceof Array)
                _this.updateModels(res, clearData);
            else
                _this.updateModel(res);
        }));
        // Handle Cached Data
        var subject = new BehaviorSubject(data ? data : null);
        this.lastSubject = subject;
        // initiate request and update subject with new data, but also cache results
        request.subscribe(function (res) {
            subject.next(res);
            subject.complete();
        }, function (e) {
            if (e.name == 'HttpErrorResponse' && ((e.error && e.error.text)
                || (e.error && typeof e.error === 'string' && e.error.includes("Fehler aufgetreten"))
                || (e && typeof e === 'string' && e.includes("Fehler aufgetreten")))) {
                var error_string = e.error && e.error.text ? e.error.text : (e.error ? e.error : 'Unbekannter Fehler');
                error_string = error_string.replace(/<\/?[^>]+(>|$)/g, "");
                if (_this.lastError != error_string || !_this.lastErrorTime || Date.now() - _this.lastErrorTime.getTime() > 5000) {
                    alert(error_string);
                }
                _this.lastError = error_string;
                _this.lastErrorTime = new Date();
            }
            subject.error(e);
        });
        return subject.asObservable();
    };
    CachableModelService.prototype.cachedPost = function (url, data) {
        var _this = this;
        return this.http.post(url, data).pipe(tap(function (res) { return _this.updateModel(res); }));
    };
    CachableModelService.prototype.cachedPatch = function (url, data) {
        var _this = this;
        console.log('patch');
        return this.http.patch(url, data).pipe(tap(function (res) { return _this.updateModel(res); }));
    };
    /**
     * finds model of cached data
     * @param orga
     * @param id
     */
    CachableModelService.prototype.findModelByOrganizationAndId = function (orga, id) {
        var _this = this;
        if (!this.models)
            return;
        return this.models.find(function (val) {
            return _this.fetchFromObject(val, _this.id) == id && val['organization'] == orga;
        });
    };
    /**
     * filters models of cached data
     * @param from
     * @param to
     * @returns array
     */
    CachableModelService.prototype.filterModelsByDate = function (from, to) {
        if (from === void 0) { from = null; }
        if (to === void 0) { to = null; }
        if (!this.models)
            return [];
        return _super.prototype.filterListByDate.call(this, this.models, from, to);
    };
    /**
     * updates/inserts model in cache
     * @param model
     */
    CachableModelService.prototype.updateModel = function (model) {
        if (!this.models)
            this.models = [];
        this.models = this.updateListItem(this.models, model, this.id, this.orga);
        this.updateStorage();
    };
    /**
     * Deletes model from cache
     * @param model
     */
    CachableModelService.prototype.deleteModel = function (model) {
        this.models = this.deleteListItem(this.models, model, this.id, this.orga);
        this.updateStorage();
    };
    /**
     * Deletes multiple models at once from cache
     * @param models
     */
    CachableModelService.prototype.deleteModels = function (models) {
        var _this = this;
        this.models = models.reduce(function (list, model) { return _this.deleteListItem(list, model, _this.id, _this.orga); }, this.models);
        this.updateStorage();
    };
    CachableModelService.prototype.updateStorage = function () {
        try {
            localStorage.setItem(this.storageName, JSON.stringify(this.models));
        }
        catch (e) {
            console.error(e);
            this.cleanCache();
        }
    };
    /**
     * updates/inserts models in cache
     * @param arr
     * @return models array
     */
    CachableModelService.prototype.updateModels = function (arr, clearData) {
        if (clearData === void 0) { clearData = null; }
        // Clear specific entries
        if (clearData && this.models) {
            if (clearData.type == 'period')
                this.clearModelsByDate(clearData.data['from'], clearData.data['to'], clearData.data['property']);
            else
                this.clear();
            // Update Models List
            this.models = this.updateList(this.models, arr, this.id, this.orga);
            // Replace Models List
        }
        else {
            this.models = arr;
        }
        this.updateStorage();
        return arr;
    };
    CachableModelService.prototype.cleanCache = function () {
        console.debug('cleaning cache...');
        localStorage.setItem('rosters', '[]');
        localStorage.setItem('projects', '[]');
        localStorage.setItem('missionProcessesConfirmations', '[]');
        localStorage.setItem('vacationRequests', '[]');
        localStorage.setItem('dispositions', '[]');
        localStorage.setItem('tours', '[]');
        localStorage.setItem('workSchedules', '[]');
        localStorage.setItem('jobs', '[]');
        localStorage.setItem('taskAssigns', '[]');
        document.location.reload();
    };
    return CachableModelService;
}(ModelService));
export { CachableModelService };
