import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResponsiveModule } from 'ngx-responsive';

import { throwIfAlreadyLoaded } from './guards/module-import.guard';

import { DeactivateDialogGuard } from './guards/deactivate-dialog.guard';

import { httpInterceptorProviders } from './interceptors';

/**
 * This module exports all commonly used components
 */
@NgModule({
	imports: [
		CommonModule,
		ResponsiveModule.forRoot(),
	],
	providers: [
		DeactivateDialogGuard,
		httpInterceptorProviders
	]
})
export class CoreModule {
	constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}