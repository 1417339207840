import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './edit-name-dialog.component.html'
})
export class EditNameDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<EditNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public name
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      firstname: [this.name.firstname, [Validators.required]],
      lastname: [this.name.lastname, [Validators.required]],
    })
  }

  onSubmit(){
    if(this.form.valid){
      this.dialog.close({firstname: this.form.value.firstname, lastname:this.form.value.lastname});
    }
  }
}
