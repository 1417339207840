import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AppMaterialModule } from '../app-material.module';

import { SettingsComponent } from './settings.component';
import { NotificationsSettingsComponent } from './notifications-settings/notifications-settings.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { SettingsNavItemComponent } from './settings-nav-item/settings-nav-item.component';
import { AddAccountDialogComponent } from './add-account-dialog/add-account-dialog.component';
import { EditEmailDialogComponent } from './edit-email-dialog/edit-email-dialog.component';
import { EditPasswordDialogComponent } from './edit-password-dialog/edit-password-dialog.component';
import { EditNameDialogComponent } from './edit-name-dialog/edit-name-dialog.component';
import { CalendarSubscribeComponent } from './calendar-subscribe/calendar-subscribe.component';

@NgModule({
  imports: [
    SharedModule,
    AppMaterialModule,
    RouterModule,
  ],
  entryComponents: [
    AddAccountDialogComponent,
    EditEmailDialogComponent,
    EditPasswordDialogComponent,
    EditNameDialogComponent,
  ],
  declarations: [
    SettingsComponent,
    NotificationsSettingsComponent,
    AccountSettingsComponent,
    SettingsNavItemComponent,
    AddAccountDialogComponent,
    EditEmailDialogComponent,
    EditPasswordDialogComponent,
    EditNameDialogComponent,
    CalendarSubscribeComponent
  ]
})
export class SettingsModule { }
