<mat-toolbar #toolbar class="app-toolbar mat-elevation-z2" color="primary">
  <mat-toolbar-row>
    <button mat-icon-button class="nav-toogle" (click)="sidenav.toggle()" *ngIf="isMenuToggable" title="Menü" i18n-title>
      <fa-icon [icon]="faBars" size="lg"></fa-icon>
    </button>
    <button mat-icon-button class="nav-toogle" (click)="onBackClick()" *ngIf="back" title="Zurück" i18n-title>
      <fa-icon [icon]="faArrowLeft" size="lg"></fa-icon>
    </button>
    <button mat-icon-button class="nav-toogle" (click)="onBackClick()" *ngIf="close" title="Schließen" i18n-title>
      <fa-icon [icon]="faTimes" size="lg"></fa-icon>
    </button>

    <div id="app-title-container">{{title}}</div>
    <span class="spacer"></span>
    <app-loading-spinner [loading]="loading" [diameter]="30"></app-loading-spinner>

    <div id="toolbar-actions-container"></div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <!-- drawer content -->
  <mat-sidenav #sidenav class="app-sidenav mat-elevation-z2" [mode]="(device.smallScreenObserver() | async) ? 'over' : 'side'">
    <mat-nav-list>
      <a mat-list-item routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faHome" [fixedWidth]="true"></fa-icon>
        <span i18n>Home</span>
      </a>
      <a mat-list-item routerLink="/calendar" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faCalendarAlt" [fixedWidth]="true"></fa-icon>
        <span i18n>Kalender</span>
      </a>
      <a mat-list-item routerLink="/dispositions" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faBriefcase" [fixedWidth]="true"></fa-icon>
        <span i18n>Agenda</span>
      </a>
      <a mat-list-item routerLink="/work-schedules" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''"
            *ngIf="this.account?.work_schedule_show_orgas?.length > 0 && this.account?.work_schedule_entries_count > 0">

        <fa-icon [icon]="faClock" [fixedWidth]="true"></fa-icon>
        <span i18n>Arbeitszeiten</span>
      </a>
      <a mat-list-item routerLink="/task-assigns" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faCheckSquare" [fixedWidth]="true"></fa-icon>
        <span i18n>Aufgaben</span>
      </a>
      <a mat-list-item routerLink="/calendar" [queryParams]="{availability_mode: 1}"
          *ngIf="this.account && this.account.roster_availability_orgas && this.account.roster_availability_orgas.length > 0"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faCalendarCheck" [fixedWidth]="true"></fa-icon>
        <span i18n>Verfügbarkeiten</span>
      </a>
      <a *ngIf="showVacationRequestItem" mat-list-item routerLink="/vacation-requests" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faPlane" [fixedWidth]="true"></fa-icon>
        <span i18n>Urlaubsanträge</span>
      </a>
      <mat-divider style="margin: 5px 0;"></mat-divider>
      <a mat-list-item routerLink="/missions/processes" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faShareAlt" [fixedWidth]="true"></fa-icon>
        <span i18n>Ausschreibungen</span>
      </a>
      <a mat-list-item routerLink="/missions/processes/confirmations" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faHandshake" [fixedWidth]="true"></fa-icon>
        <span i18n>Auftragsbestätigungen</span>
      </a>
      <mat-divider style="margin: 5px 0;"></mat-divider>
      <a mat-list-item routerLink="/tours"
          *ngIf="this.account && this.account.permissions_max && this.account.permissions_max.tour > 0"
          routerLinkActive="active"
          (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faTruck" [fixedWidth]="true"></fa-icon>
        <span i18n>Touren</span>
      </a>
      <a mat-list-item routerLink="/projects"
          *ngIf="this.account && this.account.permissions_max && this.account.permissions_max.project > 0"
          routerLinkActive="active"
          (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faRocket" [fixedWidth]="true"></fa-icon>
        <span i18n>Projekte</span>
      </a>
      <a mat-list-item routerLink="/files" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faFile" [fixedWidth]="true"></fa-icon>
        <span i18n>Dateien</span>
      </a>
      <mat-divider style="margin: 5px 0;"></mat-divider>
      <a mat-list-item routerLink="/settings/calendar-subscribe" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faCalendarPlus" [fixedWidth]="true"></fa-icon>
        <span i18n>Kalender abonnieren<small> - iCal</small></span>
      </a>
      <a mat-list-item routerLink="/imprint" routerLinkActive="active" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faInfo" [fixedWidth]="true"></fa-icon>
        <span i18n>Impressum/Datenschutz</span>
      </a>
      <a mat-list-item routerLink="/settings" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="(device.isMobile())?sidenav.toggle():''">
        <fa-icon [icon]="faCog" [fixedWidth]="true"></fa-icon>
        <span i18n>Einstellungen</span>
      </a>

    </mat-nav-list>

    <div style="text-align: center;padding: 8px;" color="primary" m-colo>
      <small><span i18n>Version</span>&nbsp;<span>{{environment.version}}</span></small>
    </div>
  </mat-sidenav>

  <!-- main content -->
  <mat-sidenav-content>
    <div class="warn-box offline mat-elevation-z1" *ngIf="!online">Offline</div>
    <router-outlet>
      <!-- content will be loaded here -->
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
