import { Injectable} from '@angular/core';
import { Router } from "@angular/router";
import { JobDetails } from './job.model';
import { JobService } from './job.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class JobResolver extends CachableClientModelResolver<JobDetails>{
	constructor(s:JobService, r:Router) {
		super(s, 'dispositions', r);
	}
}
