<app-single-page title="Registrieren" i18n-title>
  <p class="color-text-red" *ngIf="tokenError" i18n>Der Einladungslink ist ungültig.<br />Bitte wende dich an die Firma, die dir eine Einladung geschickt hat und beantrage eine neue Einladung.</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!accountCreated && !tokenError">
    <mat-form-field>
      <input matInput type="text" placeholder="Vorname" i18n-placeholder formControlName="firstname" required name="firstname">
      <mat-error *ngIf="isFieldInvalid('firstname')" i18n>
        Gib deinen Vornamen ein
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Nachname" i18n-placeholder formControlName="lastname" required name="lastname">
      <mat-error *ngIf="isFieldInvalid('lastname')" i18n>
        Gib deinen Nachnamen ein
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="email" placeholder="E-Mail Adresse" i18n-placeholder formControlName="email" required name="email">
      <mat-error *ngIf="isFieldInvalid('email')" i18n>
        Gib deine E-Mail Adresse ein
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Passwort" i18n-placeholder formControlName="password" required name="password">
      <mat-error *ngIf="isFieldInvalid('password')" i18n>
        Setze ein Passwort
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Passwort Wiederholen" i18n-placeholder formControlName="passwordRepeat" required name="passwordRepeat">
      <mat-error *ngIf="isFieldInvalid('passwordRepeat')" i18n>
        Passswörter sind nicht identisch
      </mat-error>
    </mat-form-field>
    <small i18n>Mit der Registrierung stimmst du unseren <a href="https://lupax.org/datenschutz/" target="_blank" title="Datenschutzerklärung aufrufen" i18n>Datenschutzbestimmungen</a> zu.</small>
    <br />
    <button mat-raised-button color="primary" i18n class="spinner-button" [disabled]="formSubmitted">
      <mat-spinner diameter="20"></mat-spinner>
      Registrieren
    </button>
    <br />
    <a mat-button [routerLink]="['/auth/login/'+rawToken]" class="color-text-grey" i18n *ngIf="showLoginOption">Zum Login</a>
    <p class="textbox-padding color-text-red" *ngIf="error">
      {{error}}
    </p>
  </form>
  <p *ngIf="accountCreated" i18n class="textbox-padding">
    Dir wurde eine E-Mail an die angegebene Adresse gesendet. <br />
    Bitte aktiviere darüber deinen Account um ihn nutzen zu können.
  </p>
</app-single-page>
