import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { passwordMatchValidator } from '../../core/validators/passwordMatchValidator';
import { AccountService } from '../../core/services/account.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './password-reset2.component.html',
})
export class PasswordReset2Component implements OnInit {

  form: FormGroup;
  formSubmitted:boolean;
  error: string;
  success: boolean;

  rawToken:string;

  constructor(
    private route: ActivatedRoute,
    private acc: AccountService,
  ) { }

  ngOnInit() {
    this.rawToken = this.route.snapshot.params['token'];

    this.form = new FormGroup({
      "password": new FormControl('', Validators.required),
      "passwordRepeat": new FormControl('', Validators.required)
    }, {validators: passwordMatchValidator});
  }

  onSubmit(){
    this.error = null;

    if(this.form.valid && !this.formSubmitted){
      this.acc.setPasswordToken(this.form.value.password, this.rawToken)
        .subscribe(res =>  {
          this.success = true;
          this.formSubmitted = false;
        }, res => {
          this.error = res.error.text;
          this.formSubmitted = false;
        });
    }
    this.formSubmitted = true;
  }

}
