import { FormGroup } from '@angular/forms';

export function passwordMatchValidator(g: FormGroup) {
  let pw1 = g.get('password');
  let pw2 = g.get('passwordRepeat');

  if(pw1.value == '') return null;

  if(pw2.value != pw1.value){
	pw2.setErrors({'mismatch': true});
  }

  return;
}