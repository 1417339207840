import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent {

  /**
   * This component provides you with a Confirm dialog which can be opened by MatDialog.
   * you can specify the dialog, as well as button texts in data
   *
   * @param dialogRef
   * @param data
   */
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title:String,
      text:String
    }
  ) { }

}
