import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TaskService } from './shared/task.service';
import { TaskResolver } from './shared/task.resolver';

import { TaskAssignDetailComponent } from './task-assign-detail/task-assign-detail.component';
import { TaskAssignListComponent } from './task-assign-list/task-assign-list.component';
import { TaskAssignService } from './shared/task-assign.service';
import { TaskAssignResolver } from './shared/task-assign.resolver';



@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TaskDetailComponent,
    TaskAssignListComponent,
    TaskAssignDetailComponent
  ],
  providers: [
    TaskService,
    TaskResolver,
    TaskAssignService,
    TaskAssignResolver
  ]
})
export class TasksModule { }
