/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "@angular/common";
import * as i5 from "../file-entry/file-entry.component.ngfactory";
import * as i6 from "../file-entry/file-entry.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i9 from "@angular/material/list";
import * as i10 from "./file-list.component";
var styles_FileListComponent = [i0.styles];
var RenderType_FileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileListComponent, data: {} });
export { RenderType_FileListComponent as RenderType_FileListComponent };
function View_FileListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(1, 49152, null, 0, i3.MatDivider, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 1).vertical; var currVal_2 = !i1.ɵnov(_v, 1).vertical; var currVal_3 = i1.ɵnov(_v, 1).inset; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_FileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileListComponent_2)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-file-entry", [], null, [[null, "fileUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileUpdated" === en)) {
        var pd_0 = (_co.filesUpdated.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FileEntryComponent_0, i5.RenderType_FileEntryComponent)), i1.ɵdid(3, 49152, null, 0, i6.FileEntryComponent, [i7.MatDialog], { file: [0, "file"], categoriesAvailable: [1, "categoriesAvailable"] }, { fileUpdated: "fileUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.categoriesAvailable; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_FileListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i8.View_MatList_0, i8.RenderType_MatList)), i1.ɵdid(1, 704512, null, 0, i9.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FileListComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_FileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-list", [], null, null, null, View_FileListComponent_0, RenderType_FileListComponent)), i1.ɵdid(1, 49152, null, 0, i10.FileListComponent, [], null, null)], null, null); }
var FileListComponentNgFactory = i1.ɵccf("app-file-list", i10.FileListComponent, View_FileListComponent_Host_0, { files: "files", categoriesAvailable: "categoriesAvailable" }, { filesUpdated: "filesUpdated" }, []);
export { FileListComponentNgFactory as FileListComponentNgFactory };
