import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // read about BehaviorSubject here: http://reactivex.io/documentation/subject.html
  private loggedIn: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
  ) {
    // reconstruct login if token available
    let token = localStorage.getItem('accessToken');
    if(token){
      this.loggedIn = new BehaviorSubject<boolean>(true);
    }
    else {
      this.loggedIn = new BehaviorSubject<boolean>(false);
    }
  }

  /**
   * login issues an token for further requests.
   * As long as you have the token you are considered logged-in
   * @param email 
   * @param password 
   */
  login(email: string, password: string): Observable<boolean> {
    let payload = {
      email: email,
      password: password
    }

    return this.http.post('/v1/auth/login', payload).pipe(map(token => {
      if(token){
        localStorage.setItem('accessToken', JSON.stringify(token));
        this.loggedIn.next(true);
        return true;
      }

      return false;
    }));
  }

  /**
   * logs you out
   */
  logout() {
    this.loggedIn.next(false);
    localStorage.removeItem('accessToken');
  }

  /**
   * checks if credentails match with Authorization token
   * @param email 
   * @param password 
   */
  validate(email: string, password: string){
    return this.http.post('/v1/auth/validate', {email:email,password:password});
  }

  /**
   * checks whether you are logged in (hold a token)
   */
  get isLoggedIn() {
    return this.loggedIn.getValue();
  }

  /**
   * returns an observable for which the subscribed value 
   * is either true, if the user is logged in, or false if not
   */
  get loggedInObserver() {
    return this.loggedIn.asObservable();
  }
}
