import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Task } from './task.model';
import { TaskService } from './task.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class TaskResolver extends CachableClientModelResolver<Task>{

	constructor(s:TaskService, r:Router) {
		super(s, 'tasks', r);
	}
}
