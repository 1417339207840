import { Component, ViewChild, ElementRef, LOCALE_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ScrollLocations } from '../../core/globals/scroll-locations';

import { JobTimesheetDetails } from '../shared/job-timesheet.model';
import { Organization } from '../../core/models/organization.model';

import { JobTimesheetService } from '../shared/job-timesheet.service';
import { OrganizationService } from '../../core/services/organization.service';

import { ComponentInit } from '../../core/classes/component-init.class';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

import {
    faCheck,
    faUndo,
    faFlag,
    faCalendarAlt,
    faMale,
    faSignature,
    faInfo
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-job-timesheet',
    templateUrl: './job-timesheet.component.html',
    styleUrls: ['./job-timesheet.component.css']
})
export class JobTimesheetComponent extends ComponentInit {
    faCheck = faCheck;
    faUndo = faUndo;
    faFlag = faFlag;
    faCalendarAlt = faCalendarAlt;
    faMale = faMale;
    faSignature = faSignature;
    faInfo = faInfo;

    timesheet:JobTimesheetDetails;
    orga:Organization;
    staffsGrouped = [];
    columnsToDisplay = ['name', 'start', 'end', 'break', 'duration'];

    showSignaturePad = false;
    submitting: boolean = false;


    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private jobTimesheetService: JobTimesheetService,
        private orgaService: OrganizationService,
        protected router: Router,
        protected scrollLocations: ScrollLocations,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        super(router, scrollLocations, route);
    }

    init(){
        this.route.data.subscribe(data => {
            if(data.resolved.model) this.update(data.resolved.model);
            data.resolved.updates.subscribe(timesheet => {
                if(timesheet) this.update(timesheet)
            });
        });

        this.orgaService.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
            if(res && this.timesheet) this.orga = res.find(o => o.id == this.timesheet.organization);
        });
    }

    update(timesheet){
        this.timesheet = timesheet;

        // set staffs
        this.staffsGrouped = this.getStaffsOfTimesheetGrouped();
        console.log(this.staffsGrouped);
    }

    // buildForm(){
    //     this.form = this.fb.group({
    //         staffs: this.fb.group([]),
    //         note: [this.job.timesheet ? this.job.timesheet.note : (this.formValuesCached ? this.formValuesCached['note'] : '')],
    //         // staffs2: this.fb.group({}),
    //         signature: ['', Validators.required],
    //         legitimate: [this.job.timesheet ? this.job.timesheet.legitimate : (this.formValuesCached ? this.formValuesCached['legitimate'] : '')],
    //         sign_comment: [this.job.timesheet ? this.job.timesheet.sign_comment : (this.formValuesCached ? this.formValuesCached['sign_comment'] : '')],
    //     })
    //
    //     // let form_staffs = this.form.get('staffs') as FormArray;
    //     // let form_staffs2 = this.form.get('staffs2') as FormGroup;
    //     for(let group of this.staffs){
    //         for(let staff of group.staffs){
    //             (this.form.get('staffs') as FormGroup).addControl(staff.id, this.fb.group({
    //                 ...staff, ...{
    //                 start: [this.getTimeForForm('start', staff.id), Validators.required],
    //                 break: [this.getTimeForForm('break', staff.id), Validators.required],
    //                 end: [this.getTimeForForm('end', staff.id), Validators.required],
    //                 qualification: group.qualification
    //             }}))
    //         }
    //     }
    // }

    getStaffsOfTimesheetGrouped(){
        let groups = [];
        for(let staff_id in this.timesheet.staffs){
            let staff = this.timesheet.staffs[staff_id];
            let index = groups.findIndex(g => g.qualification.id == staff.qualification.id);
            if(index < 0){
                groups.push({
                    qualification: staff.qualification,
                    staffs: [staff]
                });
            } else {
                groups[index].staffs.push(staff);
            }
        }
        return groups;
    }

    getStaffCount(staffs){
        let count = staffs.reduce((c, s) => c + (s.custom_amount || 1), 0);
        return count;
    }



    staffDuration(staff_id){
        let staff = this.timesheet.staffs[staff_id];

        let start_minutes = this.hmToMinutes(staff.start);
        let end_minutes = this.hmToMinutes(staff.end);

        if(start_minutes > end_minutes) end_minutes += 60 * 24;

        // let minutes = this.hmToMinutes(end) - ;

        return (end_minutes - start_minutes - staff.break) / 60;
        // let start = this.form.controls.staffs2.controls[staff_id];
    }

    qualificationDuration(quali_id){
        let group = this.staffsGrouped.find(g => g.qualification.id == quali_id);
        let sum = 0;
        for(let staff of group.staffs)
            sum += (this.staffDuration(staff.id) * (staff.custom_amount || 1));

        return sum;
    }

    hmToMinutes(hm){
        if(!hm) return 0;
        let hm_arr = hm.split(':');
        return (+hm_arr[0]) * 60 + (+hm_arr[1]);
    }

    numberToLocaleString(number){
        return number.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }

    deleteTimesheet(){
        this.dialog.open(ConfirmDialogComponent, {data: {
          text: 'Möchten Sie wirklich diesen ' + (this.orga && this.orga.timesheet_wording ? this.orga.timesheet_wording.singular : 'Arbeitsschein') + ' löschen? Die Unterschrift geht damit verloren, wiederherstellen nicht möglich!',
          okTitle: 'Löschen',
          okColor: 'warn'
      }}).afterClosed().subscribe(result => {
            if(!result) return;

            this.submitting = true;
            this.jobTimesheetService.delete(this.timesheet.organization, this.timesheet.id).subscribe(res => {
                console.log(res);
                this.submitting = false;
                console.log(this.timesheet.job);
                this.router.navigate(['/jobs/'+this.timesheet.organization+'/'+this.timesheet.job.id], {queryParams: {tab: 2}});
                // this.update(res);
            }, err => {
                this.submitting = false;
                this.dialog.open(OkDialogComponent, {data:err.error.text});
                console.log('error', err);
            });
        });
    }
}
