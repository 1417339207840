<main>
    <ng-container *ngIf="staffsGrouped && timesheet">

        <h2 class="mat-h2 app-list-heading" i18n>Informationen</h2>
        <mat-list>
            <mat-list-item class="show-all">
                <fa-icon mat-list-icon [icon]="faFlag"></fa-icon>
                <span mat-line>
                    {{timesheet.job.type.title}}&nbsp;-&nbsp;{{timesheet.job.title}}
                </span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
                <span mat-line>
                    <app-date-span [start]="timesheet.job.dispo_start" [end]="timesheet.job.dispo_end"></app-date-span>&nbsp;
                    <small *ngIf="timesheet.job.date_start || timesheet.job.date_end">({{timesheet.job.date_start | date:'shortTime'}} - {{timesheet.job.date_end | date:'shortTime'}})</small>
                </span>
            </mat-list-item>

            <mat-divider *ngIf="timesheet.job.client && timesheet.job.client.name"></mat-divider>
            <mat-list-item *ngIf="timesheet.job.client && timesheet.job.client.name" class="show-all">
                <fa-icon mat-list-icon [icon]="faMale"></fa-icon>
                <span mat-line>
                    {{timesheet.job.client.name}}
                </span>
            </mat-list-item>
        </mat-list>

        <h2 class="mat-h2 app-list-heading">Crew</h2>
        <ng-container *ngFor="let group of staffsGrouped">

            <table *ngIf="group.staffs?.length" mat-table [dataSource]="group.staffs" class="mat-elevation-z1 signed">
                <!-- Name -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{getStaffCount(group.staffs)}}x {{group.qualification.title}}</th>
                    <td mat-cell *matCellDef="let staff" nowrap>
                        {{staff.first_name}} {{staff.last_name}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef nowrap>Gesamt</td>
                </ng-container>

                <!-- Start -->
                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef i18n class="text-center">Von</th>
                    <td mat-cell *matCellDef="let staff" nowrap class="text-center">
                        {{staff.start}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- End -->
                <ng-container matColumnDef="end" i18n>
                    <th mat-header-cell *matHeaderCellDef i18n class="text-center">Bis</th>
                    <td mat-cell *matCellDef="let staff" nowrap class="text-center">
                        {{staff.end}}
                        <!-- <input matInput type="time" (change)="onFormChange()" pattern="\d{2}:\d{2}"> -->
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Break -->
                <ng-container matColumnDef="break" i18n>
                    <th mat-header-cell *matHeaderCellDef i18n class="text-center">Pause <small>(min)</small></th>
                    <td mat-cell *matCellDef="let staff" nowrap class="text-center">
                        {{staff.break}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Duration -->
                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef i18n>Dauer</th>
                    <td mat-cell *matCellDef="let staff" nowrap>
                        {{numberToLocaleString(staffDuration(staff.id))}} <small>Std.</small>
                    </td>
                    <td mat-footer-cell *matFooterCellDef><strong>{{numberToLocaleString(qualificationDuration(group.qualification.id))}} <small>Std.</small></strong></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
            </table>
        </ng-container>

        <!-- Note -->
        <ng-container *ngIf="timesheet.note">
            <h2 class="app-list-heading mat-h2" i18n>Notizen</h2>
            <mat-card class="content-section">
              <mat-card-content [innerHTML]="timesheet.note"></mat-card-content>
            </mat-card>
        </ng-container>



        <!-- Already signed -->
        <h2 class="mat-h2 app-list-heading" style="margin-bottom: 15px; margin-top: 15px;">
            Mit Unterschrift bestätigt
        </h2>
        <mat-list class="mat-elevation-z1" style="background: white; margin-bottom: 20px;">
            <mat-list-item>
                <fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
                <span mat-line>
                    {{timesheet.sign_date | date:'short'}}
                </span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item class="show-all">
                <fa-icon mat-list-icon [icon]="faSignature"></fa-icon>
                <span mat-line>
                    {{timesheet.sign_legitimate}}
                </span>
            </mat-list-item>
            <mat-divider *ngIf="timesheet.sign_note"></mat-divider>
            <mat-list-item class="show-all" *ngIf="timesheet.sign_note">
                <fa-icon mat-list-icon [icon]="faInfo"></fa-icon>
                <span mat-line>
                    {{ timesheet.sign_note}}
                </span>
            </mat-list-item>
        </mat-list>

        <div class="mat-elevation-z1 round-borders" style="background: white; text-align: center;">
            <img [src]="timesheet.signature" style="width: auto; max-width: 100%;"/>
        </div>

        <app-file-list [files]="[timesheet.file]" style="margin-top: 15px;"></app-file-list>

        <button type="button" mat-raised-button class="pale spinner-button"
                (click)="deleteTimesheet()"
                [disabled]="submitting"
                color="warn"
                style="margin-top: 15px;">
            <mat-spinner diameter="20" *ngIf="submitting" ></mat-spinner>
            <span i18n>{{orga?.timesheet_wording ? orga.timesheet_wording.singular : 'Arbeitsschein'}} löschen</span>
        </button>
    </ng-container>

    <ng-container *ngIf="!timesheet">
        <h2 class="mat-h2" i18n>Es konnten keine Daten gefunden werden. Internetverbindung vorhanden?</h2>
    </ng-container>
</main>
