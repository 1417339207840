var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterType } from "./interfaces";
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
/**
 * Abstract Filter class.
 * Extend this class when defining new Filters
 */
var Filter = /** @class */ (function () {
    function Filter(config) {
        this.storable = false; //whether filter shall be stored
        this._visible = true; //whether filter shall be displayed
        this._selectable = true; //whether filter shall be selectable
        // copy values from config into class
        return Object.assign(this, config);
    }
    Object.defineProperty(Filter.prototype, "visible", {
        get: function () {
            return this._visible;
        },
        set: function (value) {
            this._visible = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Filter.prototype, "selectable", {
        get: function () {
            return this._selectable;
        },
        set: function (value) {
            this._selectable = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Filter.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
        },
        enumerable: true,
        configurable: true
    });
    return Filter;
}());
export { Filter };
/**
 * This class describes toggable filters.
 * These Filters can only be used in a Filters Object ()Object containing multiple filters)
 */
var ToggableFilter = /** @class */ (function () {
    function ToggableFilter() {
    }
    return ToggableFilter;
}());
export { ToggableFilter };
/**
 * abstract select filter
 */
var AbstractSelectFilter = /** @class */ (function (_super) {
    __extends(AbstractSelectFilter, _super);
    function AbstractSelectFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AbstractSelectFilter;
}(Filter));
export { AbstractSelectFilter };
/**
 * Date Filter
 */
var DateFilter = /** @class */ (function (_super) {
    __extends(DateFilter, _super);
    function DateFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = FilterType.DATE;
        return _this;
    }
    Object.defineProperty(DateFilter.prototype, "today", {
        /**
         * Returns whether today is selected
         */
        get: function () {
            return this.value.toDateString() === new Date().toDateString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateFilter.prototype, "value", {
        get: function () {
            return this._value;
        },
        /**
         * Sets new date
         */
        set: function (value) {
            var new_value = createDateFromDatetime(value);
            if (isNaN(new_value.getTime())) {
                alert('Datum ist fehlerhaft');
            }
            else {
                this._value = new_value;
            }
        },
        enumerable: true,
        configurable: true
    });
    return DateFilter;
}(Filter));
export { DateFilter };
/**
 * Select Filter
 */
var SelectFilter = /** @class */ (function (_super) {
    __extends(SelectFilter, _super);
    function SelectFilter(config) {
        var _this = _super.call(this, config) || this;
        _this.type = FilterType.SELECT;
        return _this;
    }
    Object.defineProperty(SelectFilter.prototype, "selected", {
        /**
         * Returns selected option
         */
        get: function () {
            var _this = this;
            return this.options.find(function (o) { return o.value == _this.value; });
        },
        enumerable: true,
        configurable: true
    });
    return SelectFilter;
}(AbstractSelectFilter));
export { SelectFilter };
/**
 * Select Filter for nested/grouped options
 */
var GroupedSelectFilter = /** @class */ (function (_super) {
    __extends(GroupedSelectFilter, _super);
    function GroupedSelectFilter(config) {
        var _this = _super.call(this, config) || this;
        _this.type = FilterType.GROUPED_SELECT;
        return _this;
    }
    Object.defineProperty(GroupedSelectFilter.prototype, "selected", {
        /**
         * Retrieves selected option
         */
        get: function () {
            var _this = this;
            // when calling this getter mutiple times, return the previous value if it hasn't changed
            if (this.selectedOption && this.selectedOption.value === this.value) {
                return this.selectedOption;
            }
            /**
             * recurively find the matched value option and return it
             * @param options
             */
            var findMatch = function (options) {
                for (var _i = 0, options_1 = options; _i < options_1.length; _i++) {
                    var option = options_1[_i];
                    if (option.value && option.value === _this.value)
                        return option;
                    if (option.options) {
                        // when options contains a match, return it
                        var match = findMatch(option.options);
                        if (match)
                            return match;
                    }
                }
                // when no match, return null
                return null;
            };
            this.selectedOption = findMatch(this.options);
            return this.selectedOption;
        },
        enumerable: true,
        configurable: true
    });
    return GroupedSelectFilter;
}(Filter));
export { GroupedSelectFilter };
/**
 * Select Filter for grouped options that shall be toggled by another filter value
 */
var GroupToggleSelectFilter = /** @class */ (function (_super) {
    __extends(GroupToggleSelectFilter, _super);
    function GroupToggleSelectFilter(config) {
        var _this = _super.call(this, config) || this;
        _this.toggleValue = null;
        return _this;
    }
    Object.defineProperty(GroupToggleSelectFilter.prototype, "options", {
        /**
         * Return options based in group toggle filter
         */
        get: function () {
            return (this.groupedOptions[this.toggleValue] || []);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupToggleSelectFilter.prototype, "selectable", {
        get: function () {
            return this._selectable && !!this.toggleValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupToggleSelectFilter.prototype, "visible", {
        get: function () {
            return this._visible && !!this.toggleValue;
        },
        enumerable: true,
        configurable: true
    });
    GroupToggleSelectFilter.prototype.toggle = function (value) {
        this.toggleValue = value;
    };
    return GroupToggleSelectFilter;
}(SelectFilter));
export { GroupToggleSelectFilter };
/**
 * Number Filter
 */
var NumberFilter = /** @class */ (function (_super) {
    __extends(NumberFilter, _super);
    function NumberFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = FilterType.NUMBER;
        return _this;
    }
    return NumberFilter;
}(Filter));
export { NumberFilter };
/**
 * Text Filter
 */
var TextFilter = /** @class */ (function (_super) {
    __extends(TextFilter, _super);
    function TextFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = FilterType.TEXT;
        return _this;
    }
    return TextFilter;
}(Filter));
export { TextFilter };
