var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { of } from 'rxjs';
import { RosterService } from './roster.service';
import { map } from 'rxjs/operators';
import { DateService } from 'src/app/core/services/date.service';
import { RosterModel } from './roster.class';
import { deleteFromArray } from 'src/app/core/helpers/array.helper';
import * as i0 from "@angular/core";
import * as i1 from "./roster.service";
import * as i2 from "../../core/services/date.service";
var AvailabilityService = /** @class */ (function () {
    function AvailabilityService(rosterService, dateService) {
        this.rosterService = rosterService;
        this.dateService = dateService;
        this.list = [];
        this.createArr = []; // contains rosters that need to be created
        this.updateArr = []; // contains rosters that need to be updated
        this.deleteArr = []; // contains rosters that need to be deleted
        this.cachedRequests = [];
    }
    /**
     * deletes availability and marks roster as to be deleted
     * @param roster
     */
    AvailabilityService.prototype.deleteAvailability = function (roster) {
        if (roster.id) {
            //roster has to be removed from server
            this.deleteArr.push(roster);
        }
        deleteFromArray(roster, this.updateArr);
        deleteFromArray(roster, this.createArr);
    };
    /**
     * marks availability to be updated with server
     * @param roster
     */
    AvailabilityService.prototype.updateAvailability = function (roster) {
        // when no id isset, the roster must be created
        if (!roster.id) {
            if (!this.createArr.includes(roster))
                this.createArr.push(roster);
            return roster;
        }
        if (!this.updateArr.includes(roster))
            this.updateArr.push(roster);
        return roster;
    };
    /**
     * Adds availability for server syncing
     * @param roster
     */
    AvailabilityService.prototype.addAvailability = function (roster) {
        this.createArr.push(roster);
        this.list.push(roster);
        return roster;
    };
    /**
     * Creates availability and prepares it to be saved
     * @param type
     * @param start
     * @param end
     * @param title
     * @param orga
     */
    AvailabilityService.prototype.createAvailability = function (type, start, end, title, orga) {
        var roster = new RosterModel({
            id: null,
            shift: 0,
            start: start,
            duration: this.dateService.secondsToHMS((end.getTime() - start.getTime()) / 1000),
            end: end,
            days: 0,
            type: type,
            title: title,
            organization: orga.id,
            company: {
                id: orga.id,
                title: orga.name,
            }
        });
        return this.addAvailability(roster);
    };
    /**
     * Updates type for multiple availabilities
     * @param type
     * @param rosters
     * @return updated rosters
     */
    AvailabilityService.prototype.updateTypeForMultiple = function (type, rosters) {
        var _this = this;
        return rosters.map(function (roster) {
            roster.type = type;
            return _this.updateAvailability(roster);
        });
    };
    AvailabilityService.prototype.isAvailability = function (roster) {
        return roster.type === RosterService.TYPES.AVAILABLE || roster.type === RosterService.TYPES.NOT_AVAILABLE;
    };
    AvailabilityService.prototype.isAvailableType = function (roster) {
        return roster.type === RosterService.TYPES.AVAILABLE;
    };
    AvailabilityService.prototype.isNotAvailableType = function (roster) {
        return roster.type === RosterService.TYPES.NOT_AVAILABLE;
    };
    /**
     * Applies any changes to the server
     */
    AvailabilityService.prototype.flush = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deleteListPerOrga, deletePromises, updatePromise, createPromise, promises;
            var _this = this;
            return __generator(this, function (_a) {
                deleteListPerOrga = this.deleteArr.reduce(function (obj, roster) {
                    if (!obj[roster.organization]) {
                        obj[roster.organization] = new Array();
                    }
                    obj[roster.organization].push(roster);
                    return obj;
                }, {});
                deletePromises = Object.entries(deleteListPerOrga).map(function (_a) {
                    var id = _a[0], rosters = _a[1];
                    return _this.rosterService.deleteAllByOrga(rosters, id).toPromise();
                });
                updatePromise = this.updateArr.length ? this.rosterService.replaceAll(this.updateArr).toPromise() : Promise.resolve();
                createPromise = this.createArr.length ? this.rosterService.createAll(this.createArr).toPromise() : Promise.resolve();
                promises = deletePromises.concat([updatePromise, createPromise]);
                // TODO: merge returned api objects into list, but it isn't really necessary
                return [2 /*return*/, Promise.all(promises).then(function () { return _this.clear(); })];
            });
        });
    };
    /**
     * Clears saved availabilities
     */
    AvailabilityService.prototype.clear = function () {
        this.cachedRequests = [];
        this.list = [];
        this.updateArr = [];
        this.deleteArr = [];
        this.createArr = [];
    };
    /**
     * Returns local avialabilities for date range
     * @param start
     * @param end
     */
    AvailabilityService.prototype.getAvailabilitiesBetween = function (start, end) {
        return this.list.filter(function (roster) { return start <= roster.start && roster.end <= end; });
    };
    Object.defineProperty(AvailabilityService.prototype, "unsavedChanges", {
        get: function () {
            return !!(this.createArr.length || this.updateArr.length || this.deleteArr.length);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Returns availabilities in range.
     * These will be cached for later manipulation.
     * @param from
     * @param to
     */
    AvailabilityService.prototype.getAllForCurrentAccount = function (from, to) {
        var _this = this;
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        var cacheKey = from.getTime().toString() + to.getTime().toString();
        if (this.cachedRequests.includes(cacheKey)) {
            return of(this.getAvailabilitiesBetween(from, to));
        }
        return this.rosterService.getAllForCurrentAccount(from, to).pipe(map(function (rosters) {
            var result = [];
            var remoteAvailabilities = rosters.filter(_this.isAvailability);
            var localAvailabilities = _this.list.filter(function (roster) { return from <= roster.start && roster.end <= to; });
            // merge them together, but replace cached ones
            localAvailabilities.forEach(function (roster, index) {
                var duplicateIndex = remoteAvailabilities.findIndex(function (elem) { return elem.id == roster.id; });
                // when duplicate exists, remove it
                if (duplicateIndex >= 0) {
                    remoteAvailabilities.splice(duplicateIndex, 1);
                    result.push(roster);
                    // when index was not found in remote -> remove from local list
                }
                else {
                    _this.list.splice(index, 1);
                }
            });
            /* As duplicatess have been removed from remoteAvailabilities,
             * we should add them to our local ones and the result.
             */
            _this.list = _this.list.concat(remoteAvailabilities);
            result = result.concat(remoteAvailabilities);
            _this.cachedRequests.push(cacheKey);
            return result;
        }));
    };
    AvailabilityService.ngInjectableDef = i0.defineInjectable({ factory: function AvailabilityService_Factory() { return new AvailabilityService(i0.inject(i1.RosterService), i0.inject(i2.DateService)); }, token: AvailabilityService, providedIn: "root" });
    return AvailabilityService;
}());
export { AvailabilityService };
