<mat-form-field style="width:100%">
  <input matInput #input type="text" [placeholder]="placeholder" [required]="required" [formControl]="inputControl" [matAutocomplete]="auto" (blur)="onBlur($event)">
  <fa-icon *ngIf="inputControl.value" mat-list-icon matSuffix [icon]="faTimes" class="reset re_fg" (click)="reset();"></fa-icon>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption">
    <div class="single_options mat-elevation-z1">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.title}}
        </mat-option>
    </div>
    <ng-container *ngIf="!(filteredGroupOptions | async) || !(filteredGroupOptions | async).length">
        <mat-option><em>Kein Eintrag gefunden</em></mat-option>
    </ng-container>
    <mat-optgroup *ngFor="let group of filteredGroupOptions | async" class="mat-elevation-z1">
      <h2 class="mat-h2">{{group.title}}</h2>
      <mat-option *ngFor="let option of group.options" [value]="option" [ngClass]="{'selected': inputControl.value == option}">
        <span class="headline" *ngIf="option.headline">
          <ng-container [ngSwitch]="option.headline.type">
            <ng-container *ngSwitchCase="'date'">
              <app-date-span [start]="option.headline.data.start" [end]="option.headline.data.end"
                [showWeekday]="option.headline.data.showWeekday"
                [dateOnly]="option.headline.data.dateOnly"></app-date-span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{option.headline.value}}
            </ng-container>
          </ng-container>
        </span>
        <span class="title">{{option.title}}</span>
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</mat-form-field>
