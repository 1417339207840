<app-filter-header [inputs]="filterInputs" storage="task-assign-list" (callback)="getEntries($event)">
	<main>
		<app-loading-spinner [loading]="!taskAssigns" [loadError]="loadError" [retry]="reload" (click)="reload()"></app-loading-spinner>
		<p class="text-top color-text-grey text-center" *ngIf="taskAssigns && taskAssigns.length == 0">
			Keine Einträge vorhanden
		</p>
		<ng-container *ngFor="let list of listGroups">
			<div class="group-{{list.slug}}" *ngIf="list.data.length > 0">
				<h2 i18n class="mat-h2 app-list-heading group-{{list.slug}}-header">{{list.name}}</h2>
				<mat-nav-list>
					<a mat-list-item class="round-list-item mat-elevation-z1" (click)="routeTaskAssign(t)" *ngFor="let t of list.data">
						<fa-layers mat-list-icon class="fa-fw">
							<fa-icon [icon]="icon(t)" class="{{ t.completed ? 'color-text-green' : 'color-text-primary' }}"></fa-icon>
							<fa-icon [icon]="faCheck" class="background-green secondary-icon" *ngIf="t.completed && (!t.task || t.task=='0')"></fa-icon>
						</fa-layers>
						<h4 mat-line>{{t.title}}</h4>
						<div mat-line>
							<div style="flex:1 0;">
								<ng-container *ngIf="t.start || t.end">
									<fa-icon [icon]="faCalendarTimes"></fa-icon>&nbsp;
	    							<app-date-span [start]="t.start" [end]="t.end" [relativeWords]="true"></app-date-span>
								</ng-container>
			                </div>
			                <div style="flex:1;" *ngIf="t.priority" style="text-align: right;">

								<span *ngIf="t.priority > 0" class="color-text-red">
								   <fa-icon [icon]="faExclamation" class="color-text-red"></fa-icon>&nbsp;
								   <strong>Hoch</strong>
							  	</span>
							  	<span *ngIf="t.priority < 0" class="color-text-grey">
									<fa-icon [icon]="faExclamation" class="color-text-grey"></fa-icon>&nbsp;
									<strong>Niedrig</strong>
								</span>
			                </div>

						</div>
					</a>
				</mat-nav-list>
			</div>
		</ng-container>
	</main>
</app-filter-header>
