<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n [ngSwitch]="file.type">
	Datei bearbeiten
</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
	<div mat-dialog-content>
		<mat-form-field>
			<input
			matInput
			type="text"
			formControlName="title"
			required
			placeholder="Titel der Datei"
			autofocus
			i18n-placeholder
			#titleInput>
		</mat-form-field>

		<mat-form-field *ngIf="data.categoriesAvailable && data.categoriesAvailable.length">
			<mat-select floatLabel="always" formControlName="cat" placeholder="Kategorie">
				<mat-option [value]="0" i18n>Ohne Kategorie</mat-option>
				<ng-container *ngFor="let cat of data.categoriesAvailable">
					<mat-option [value]="cat.id">{{ cat.title }}</mat-option>
				</ng-container>
			</mat-select>
		</mat-form-field>
	</div>

	<mat-dialog-actions>
		<button mat-button i18n type="button" (click)="dialogFileEdit.close()">
			Abbrechen
		</button>
		<button mat-raised-button i18n type="button" (click)="onDelete()" color="warn">
			Löschen
		</button>
		<button mat-raised-button color="primary" i18n type="submit">
			Bestätigen
		</button>
	</mat-dialog-actions>
</form>
