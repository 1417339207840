var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ElementRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ComponentInit } from '../../core/classes/component-init.class';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { SignaturePad } from 'angular2-signaturepad';
import { JobTimesheetService } from '../shared/job-timesheet.service';
import { OrganizationService } from '../../core/services/organization.service';
import { faCheck, faUndo, faFlag, faCalendarAlt, faMale, faSignature, faInfo } from '@fortawesome/free-solid-svg-icons';
var JobTimesheetCreateComponent = /** @class */ (function (_super) {
    __extends(JobTimesheetCreateComponent, _super);
    function JobTimesheetCreateComponent(fb, dialog, route, jobTimesheetService, orgaService, router, scrollLocations, locale) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.fb = fb;
        _this.dialog = dialog;
        _this.route = route;
        _this.jobTimesheetService = jobTimesheetService;
        _this.orgaService = orgaService;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.locale = locale;
        _this.signaturePadOptions = {
            'canvasWidth': 500,
            'canvasHeight': 250
        };
        _this.faCheck = faCheck;
        _this.faUndo = faUndo;
        _this.faFlag = faFlag;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faMale = faMale;
        _this.faSignature = faSignature;
        _this.faInfo = faInfo;
        _this.staffs = [];
        _this.columnsToDisplay = ['name', 'start', 'end', 'break', 'duration'];
        _this.showSignaturePad = false;
        _this.submitting = false;
        _this.formValuesCached = {};
        return _this;
    }
    JobTimesheetCreateComponent.prototype.init = function () {
        var _this = this;
        console.log(this.job);
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (job) {
                if (job)
                    _this.update(job);
                else if (!_this.job)
                    _this.job = null;
            });
        });
        this.orgaService.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (res && _this.job)
                _this.orga = res.find(function (o) { return o.id == _this.job.organization; });
        });
    };
    JobTimesheetCreateComponent.prototype.afterInit = function () {
        var _this = this;
        this.adjustDrawCanvasWidth();
        window.onresize = function () {
            setTimeout(function () { return _this.adjustDrawCanvasWidth(); }, 100);
        };
    };
    JobTimesheetCreateComponent.prototype.update = function (job) {
        this.job = job;
        // Get cached form value
        if (localStorage.getItem('timesheets')) {
            try {
                this.formValuesCached = JSON.parse(localStorage.getItem('timesheets'))[job.id];
            }
            catch (e) { }
        }
        // set staffs
        this.staffs = job.crew;
        // filter staffs when param "staffs" is given
        if (this.route.snapshot.queryParamMap.getAll('staffs').length) {
            var staffs_selected_1 = this.route.snapshot.queryParamMap.getAll('staffs');
            this.staffs = this.staffs.filter(function (g) {
                console.log('before', g.staffs);
                g.staffs = g.staffs.filter(function (s) { return staffs_selected_1.includes(s.id.toString()); });
                console.log(g.staffs);
                return g.staffs.length;
            });
        }
        this.buildForm();
    };
    JobTimesheetCreateComponent.prototype.buildForm = function () {
        this.form = this.fb.group({
            staffs: this.fb.group([]),
            note: [this.formValuesCached ? this.formValuesCached['note'] : ''],
            signature: ['', Validators.required],
            sign_legitimate: [this.formValuesCached ? this.formValuesCached['sign_legitimate'] : '', Validators.required],
            sign_note: [this.formValuesCached ? this.formValuesCached['sign_note'] : ''],
        });
        for (var _i = 0, _a = this.staffs; _i < _a.length; _i++) {
            var group = _a[_i];
            for (var _b = 0, _c = group.staffs; _b < _c.length; _b++) {
                var staff = _c[_b];
                this.form.get('staffs').addControl(staff.id, this.fb.group(__assign({}, staff, {
                    start: [this.getTimeForForm('start', staff.id), Validators.required],
                    break: [this.getTimeForForm('break', staff.id), Validators.required],
                    end: [this.getTimeForForm('end', staff.id), Validators.required],
                    qualification: group.qualification
                })));
            }
        }
    };
    JobTimesheetCreateComponent.prototype.getStaffCount = function (staffs) {
        var count = staffs.reduce(function (c, s) { return c + (s.custom_amount || 1); }, 0);
        return count;
    };
    JobTimesheetCreateComponent.prototype.getTimeForForm = function (type, staff_id, use_cache) {
        if (use_cache === void 0) { use_cache = true; }
        // get staff data from cache
        if (use_cache && this.formValuesCached && this.formValuesCached['staffs'] && this.formValuesCached['staffs'][staff_id] && this.formValuesCached['staffs'][staff_id][type])
            return this.formValuesCached['staffs'][staff_id][type];
        // get staff data from crew
        var staff = this.staffs.reduce(function (p, c) { return c.staffs.find(function (s) { return s.id == staff_id; }) || p; }, {});
        var date = staff.project_assign ? staff.project_assign[type] : this.job['dispo_' + type];
        if (type == 'break')
            return date || 0;
        else
            return formatDate(date, 'HH:mm', this.locale);
    };
    JobTimesheetCreateComponent.prototype.staffDuration = function (staff_id) {
        var start = this.form.get('staffs')['controls'][staff_id]['controls']['start'].value;
        var end = this.form.get('staffs')['controls'][staff_id]['controls']['end'].value;
        var pause_minutes = this.form.get('staffs')['controls'][staff_id]['controls']['break'].value;
        var start_minutes = this.hmToMinutes(start);
        var end_minutes = this.hmToMinutes(end);
        if (start_minutes > end_minutes)
            end_minutes += 60 * 24;
        return (end_minutes - start_minutes - pause_minutes) / 60;
    };
    JobTimesheetCreateComponent.prototype.qualificationDuration = function (quali_id) {
        var group = this.staffs.find(function (g) { return g.qualification.id == quali_id; });
        var sum = 0;
        for (var _i = 0, _a = group.staffs; _i < _a.length; _i++) {
            var staff = _a[_i];
            sum += (this.staffDuration(staff.id) * (staff.custom_amount || 1));
        }
        return sum;
    };
    JobTimesheetCreateComponent.prototype.hmToMinutes = function (hm) {
        if (!hm)
            return 0;
        var hm_arr = hm.split(':');
        return (+hm_arr[0]) * 60 + (+hm_arr[1]);
    };
    JobTimesheetCreateComponent.prototype.numberToLocaleString = function (number) {
        return number.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    };
    JobTimesheetCreateComponent.prototype.onFormChange = function () {
        var timesheets = localStorage.getItem('timesheets') ? JSON.parse(localStorage.getItem('timesheets')) : {};
        if (!timesheets[this.job.id])
            timesheets[this.job.id] = { staffs: {} };
        // Save note and signature
        timesheets[this.job.id].note = this.form.value.note;
        timesheets[this.job.id].sign_legitimate = this.form.value.sign_legitimate;
        timesheets[this.job.id].sign_note = this.form.value.sign_note;
        timesheets[this.job.id].signature = this.form.value.signature;
        // Save only start & end of staffs, when its value was changed
        for (var _i = 0, _a = Object.values(this.form.value.staffs); _i < _a.length; _i++) {
            var staff = _a[_i];
            console.log(staff);
            if (!timesheets[this.job.id].staffs[staff['id']])
                timesheets[this.job.id].staffs[staff['id']] = {};
            if (staff['start'] != this.getTimeForForm('start', staff['id'], false))
                timesheets[this.job.id].staffs[staff['id']].start = staff['start'];
            if (staff['end'] != this.getTimeForForm('end', staff['id'], false))
                timesheets[this.job.id].staffs[staff['id']].end = staff['end'];
            if (staff['break'] != this.getTimeForForm('break', staff['id'], false))
                timesheets[this.job.id].staffs[staff['id']].break = staff['break'];
        }
        localStorage.setItem('timesheets', JSON.stringify(timesheets));
    };
    JobTimesheetCreateComponent.prototype.toggleShowSignaturePad = function () {
        var _this = this;
        if (this.showSignaturePad)
            this.showSignaturePad = false;
        else {
            this.showSignaturePad = true;
            setTimeout(function () {
                _this.adjustDrawCanvasWidth();
            }, 10);
        }
    };
    JobTimesheetCreateComponent.prototype.adjustDrawCanvasWidth = function () {
        if (!this.showSignaturePad || !this.signaturePadWrapper)
            return;
        var width = this.signaturePadWrapper.nativeElement.clientWidth;
        this.signaturePadWrapper.nativeElement.querySelectorAll('canvas')[0].setAttribute('width', width);
    };
    JobTimesheetCreateComponent.prototype.resetDraw = function () {
        this.signaturePad.clear();
        this.adjustDrawCanvasWidth();
        this.form.controls['signature'].setValue('');
    };
    JobTimesheetCreateComponent.prototype.drawComplete = function () {
        // will be notified of szimek/signature_pad's onEnd event
        this.form.controls['signature'].setValue(this.signaturePad.toDataURL());
    };
    JobTimesheetCreateComponent.prototype.drawStart = function () {
        // will be notified of szimek/signature_pad's onBegin event
        console.log('begin drawing');
    };
    JobTimesheetCreateComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.form.valid)
            return this.dialog.open(OkDialogComponent, { data: 'Bitte fülle das Formular korrekt aus.' });
        this.submitting = true;
        var data = {
            staffs: this.form.value.staffs,
            note: this.form.value.note,
            sign_legitimate: this.form.value.sign_legitimate,
            sign_note: this.form.value.sign_note,
            signature: this.signaturePad.toDataURL()
        };
        this.jobTimesheetService.create(this.job.organization, this.job.id, data).subscribe(function (res) {
            _this.router.navigate(["/jobs/" + _this.job.organization + "/" + _this.job.id], { queryParams: { tab: 2 }, replaceUrl: true });
            _this.submitting = false;
        }, function (err) {
            _this.submitting = false;
            _this.dialog.open(OkDialogComponent, { data: err.error.text });
            console.log('error', err);
        });
    };
    return JobTimesheetCreateComponent;
}(ComponentInit));
export { JobTimesheetCreateComponent };
