import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// icons
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// material components
import { AppMaterialModule } from '../app-material.module';

import { DialogComponent } from './dialog/dialog.component';
import { DialogActionsComponent } from './dialog-actions/dialog-actions.component';
import { AuthenticateDialogComponent } from './authenticate-dialog/authenticate-dialog.component';
import { SinglePageComponent } from './single-page/single-page.component';
import { DateSpanComponent } from './date-span/date-span.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MinuteToHourPipe } from './pipes/minute-to-hour.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { OkDialogComponent } from './ok-dialog/ok-dialog.component';
import { FileListComponent } from './file-list/file-list.component';
import { FileEntryComponent } from './file-entry/file-entry.component';
import { InfoListComponent } from './info-list/info-list.component';
import { FilterHeaderComponent } from './filters/filter-header/filter-header.component';
import { FilterDialogComponent } from './filters/filter-dialog/filter-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ModelSelectInputComponent } from './model-select-input/model-select-input.component';
import { ToolbarActionsComponent } from './toolbar-actions/toolbar-actions.component';
import { TitleComponent } from './title/title.component';
import { TimelineComponent } from './timeline/timeline.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { FileEditDialogComponent } from './file-edit-dialog/file-edit-dialog.component';
import { FileUploadComponent } from './file-upload/file-upload.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  entryComponents: [
    AuthenticateDialogComponent,
    OkDialogComponent,
    FilterDialogComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    FileEditDialogComponent
  ],
  declarations: [
    DialogComponent,
    DialogActionsComponent,
    AuthenticateDialogComponent,
    SinglePageComponent,
    DateSpanComponent,
    LoadingSpinnerComponent,
    MinuteToHourPipe,
    KeysPipe,
    OkDialogComponent,
    FileListComponent,
    FileEntryComponent,
    InfoListComponent,
    FilterHeaderComponent,
    FilterDialogComponent,
    ConfirmDialogComponent,
    ModelSelectInputComponent,
    ToolbarActionsComponent,
    TitleComponent,
    TimelineComponent,
    InfoDialogComponent,
    FileEditDialogComponent,
    FileUploadComponent
  ],
  exports: [
    CommonModule,
    AppMaterialModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    DialogComponent,
    DialogActionsComponent,
    AuthenticateDialogComponent,
    SinglePageComponent,
    DateSpanComponent,
    LoadingSpinnerComponent,
    MinuteToHourPipe,
    KeysPipe,
    FileListComponent,
    FileEntryComponent,
    InfoListComponent,
    FilterHeaderComponent,
    FilterDialogComponent,
    ModelSelectInputComponent,
    FilterDialogComponent,
    ToolbarActionsComponent,
    TitleComponent,
    TimelineComponent,
    FileEditDialogComponent,
    FileUploadComponent
  ]
})
export class SharedModule { }
