import { Component } from '@angular/core';

import { faPlus, faEdit, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditEmailDialogComponent } from '../edit-email-dialog/edit-email-dialog.component';
import { EditPasswordDialogComponent } from '../edit-password-dialog/edit-password-dialog.component';
import { EditNameDialogComponent } from '../edit-name-dialog/edit-name-dialog.component';
import { AccountService } from '../../core/services/account.service';

import { Account } from '../../core/models/account.model';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { OrganizationService } from '../../core/services/organization.service';
import { Organization } from '../../core/models/organization.model';
import { ClearService } from '../../core/services/clear.service';

@Component({
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css','../settings.component.css']
})
export class AccountSettingsComponent{

  faPlus = faPlus;
  faEdit = faEdit;
  faSignOutAlt = faSignOutAlt;

  account:Account = null;
  organizations:Organization[] = null;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private snackbar: MatSnackBar,
    private acc: AccountService,
    private auth: AuthService,
    private orga: OrganizationService,
    private clear: ClearService,
  ) { }

  onEditNameClick(){
    this.dialog.open(EditNameDialogComponent, {maxWidth: 300, data:{firstname:this.account.first_name,lastname:this.account.last_name}}).afterClosed().subscribe(name => {
      if(name) this.acc.updateName(name.firstname, name.lastname).subscribe();
    });
  }

  onEditEmailClick(){
    this.dialog.open(EditEmailDialogComponent, {maxWidth: 300, data:this.account.email}).afterClosed().subscribe(email => {
      if(email){
        this.acc.updateEmail(email).subscribe();
      }
    });
  }

  onEditPasswordClick(){
    this.dialog.open(EditPasswordDialogComponent, {maxWidth: 300}).afterClosed().subscribe(password => {
      if(password){
        this.acc.updatePassword(password).subscribe(bool => {
          this.snackbar.open('Passwort wurde geändert', '', {duration:3000})
        });
      }
    });
  }

  onAddConnectionClick(){
    console.debug('TODO');
  }

  onLogoutClick(){
    this.clear.clear();
    this.auth.logout();
    this.router.navigate(['/auth/login']);
  }

  ngOnInit(){
    this.acc.getCurrentAccount().subscribe((a) => {
      this.account = a;
    });

    this.orga.getAllForCurrentAccount().subscribe(res => {
      this.organizations = res;
    });
  }

}
