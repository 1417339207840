import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
// import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { JobTimesheet } from '../shared/job-timesheet.model';
// import { OrganizationService } from 'src/app/core/services/organization.service';
import { DateService } from 'src/app/core/services/date.service';

@Component({
    selector: 'app-job-timesheet-create-dialog',
    templateUrl: './job-timesheet-create-dialog.component.html',
    styleUrls: ['./job-timesheet-create-dialog.component.css']
})
export class JobTimesheetCreateDialogComponent implements OnInit {

    // allSelected:Boolean = true;
    staffs:any[] = [];

    constructor(
        public dialog: MatDialogRef<JobTimesheetCreateDialogComponent>,
        public date: DateService,
        protected router: Router,
        @Inject(MAT_DIALOG_DATA) public data:any,
        // @Inject(LOCALE_ID) private locale:string,
    ) {}

    ngOnInit() {
        let job = this.data.job;

        this.staffs = JSON.parse(JSON.stringify(job.crew));

        // filter staffs which are already in a timesheet of job
        this.staffs.forEach((g, k) => {
            this.staffs[k].staffs = g.staffs.filter(s => !job.timesheets || !job.timesheets.find(t => t.staffs[s.id]));
        })
        // remove empty groups
        this.staffs = this.staffs.filter(g => g.staffs.length > 0);



        // mark all as selected
        this.setAllSelected(true);

        // for(let group of job.crew){
        //   for(let staff of group.staffs){
        //     this.staffs.push(staff);
        //   }
        // }
    }

    someSelected(){
        return this.staffs.filter(g => g.staffs.find(s => s.selected)).length > 0 && !this.allSelected();
    }

    allSelected(){
        return this.staffs.every(g => g.staffs.every(s => s.selected));
    }

    someInGroupSelected(group_index: number){
        return this.staffs[group_index].staffs.find(s => s.selected) && !this.allInGroupSelected(group_index);
    }

    allInGroupSelected(group_index: number){
        return this.staffs[group_index].staffs.every(s => s.selected);
    }

    selectedChanged(){
        // Update group
        // let group = this.staffs[group_index];
        // group.selected = group.staffs.every(s => s.selected);

        // Update allSelected
        // this.allSelected = this.staffs.every(g => g.staffs.every(s => s.selected));
    }

    updateAllSelected(){

    }

    setAllSelected(selected: Boolean){
        this.staffs.forEach(g => {
            g.staffs.forEach(s => s.selected = selected);
        })
    }

    setGroupSelected(group_index: number, selected: Boolean){
        // this.staffs[group_index].selected =
        this.staffs[group_index].staffs.forEach(s => {
            s.selected = selected;
        })
    }

    onSubmit() {
        let selected_staff_ids = [];
        this.staffs.forEach(g => {
            g.staffs.forEach(s => {
                if(s.selected) selected_staff_ids.push(s.id);
            });
        })

        this.dialog.close({
            staffs: selected_staff_ids
        });

        // console.log(selected_staff_ids);
        //
        // let result = this.router.navigate(['/jobs/'+this.data.job.organization+'/'+this.data.job.id+'/timesheet']);
        // console.log(result);
        // if (this.form.valid) {
        //   this.dialog.close({
        //     action: this.data.roster ? RETURN_ACTIONS.CHANGE : RETURN_ACTIONS.NEW,
        //     roster: this.updateRosterWithFormValues(this.form.value)
        //   });
        // }
    }
}
