<ng-container *ngIf="task">
	<mat-tab-group mat-stretch-tabs headerPosition="above" class="tabs-with-icon"
            [selectedIndex]="tabSelected" (selectedIndexChange)="tabSelected = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                <fa-icon mat-list-icon [icon]="faInfoCircle"></fa-icon> <span i18n class="label-text">Allgemein</span>
            </ng-template>
			<main>
				<h2 class="mat-h2 app-list-heading">Informationen</h2>
				<mat-list class="mat-elevation-z1">
					<!-- Title -->
					<mat-list-item class="show-all">
						<fa-icon mat-list-icon [icon]="task.completed ? faCheckSquare : faSquare"
							class="{{'mat-list-icon ng-fa-icon' + (task.completed ? ' color-text-green' : '') }}"></fa-icon>
						<span mat-line><b>{{task.title}}</b></span>
					</mat-list-item>
					<mat-divider></mat-divider>

					<!-- Company -->
					<mat-list-item>
						<fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
						<span mat-line>{{task.company.title}}</span>
					</mat-list-item>
					<mat-divider></mat-divider>

					<!-- Deadline -->
					<ng-container *ngIf="task.deadline || task.task_assign">
						<mat-list-item class="show-all">
							<fa-icon mat-list-icon [icon]="faCalendarTimes"></fa-icon>
							<span mat-line>
								<ng-container *ngIf="task.deadline">
									<small style="width:56px;display:inline-block">Deadline:</small>
									<strong>{{task.deadline | date:'EEE'}}</strong>
									{{task.deadline | date:deadlineDateFormat()}} | {{task.deadline | date:'shortTime'}}
								</ng-container>
								<br *ngIf="task.deadline && task.task_assign" />
								<ng-container *ngIf="task.task_assign">
									<small style="width:56px;display:inline-block">Geplant:</small>
									<app-date-span
										[start]="task.task_assign.start"
										[end]="task.task_assign.end"
										[relativeWords]="true"></app-date-span>
								</ng-container>
							</span>
						</mat-list-item>
						<mat-divider></mat-divider>
					</ng-container>

					<!-- Priority -->
					<ng-container *ngIf="task.priority">
						<mat-list-item class="show-all">
							<fa-icon mat-list-icon [icon]="faExclamation"></fa-icon>
							<span mat-line>
								<small style="width:56px;display:inline-block">Priorität:</small>
								<strong *ngIf="task.priority > 0" class="color-text-red">Hoch</strong>
								<strong *ngIf="task.priority < 0" class="color-text-grey">Niedrig</strong>
							</span>
						</mat-list-item>
						<mat-divider></mat-divider>
					</ng-container>

					<!-- Responsible -->
					<mat-list-item class="show-all" *ngIf="task.responsibles">
						<fa-icon mat-list-icon [icon]="faUsers"></fa-icon>
						<span mat-line>
				            <span *ngFor="let responsible of task.responsibles; last as isLast">
				                <span>{{responsible.first_name}} {{responsible.last_name}}</span>
				                <span *ngIf="!isLast">, </span>
				            </span>
						</span>
					</mat-list-item>
				</mat-list>

				<!-- Project -->
				<ng-container *ngIf="task.project || task.job">
					<h2 class="mat-h2 app-list-heading" i18n>Projekt</h2>
					<mat-list>
						<mat-list-item class="show-all" *ngIf="task.project">
							<fa-icon mat-list-icon [icon]="faRocket"></fa-icon>
							<a mat-line
									class="color-text-primary"
									style="text-decoration: none; cursor: pointer;"
									(click)="routeProject(task.project)">
								{{task.project.title}}
							</a>
						</mat-list-item>
						<mat-divider *ngIf="task.project && task.job"></mat-divider>
						<mat-list-item class="show-all" *ngIf="task.job">
							<fa-icon mat-list-icon [icon]="faBriefcase"></fa-icon>
							<a mat-line
									class="color-text-primary"
									style="text-decoration: none; cursor: pointer;"
									(click)="routeJob(task.job)">
								<app-date-span [start]="task.job.dispo_start" [end]="task.job.dispo_end" [relativeWords]="true"></app-date-span> |
								<strong>{{task.job.type.title}}</strong> - <span>{{task.job.title}}</span>
							</a>
						</mat-list-item>
					</mat-list>
				</ng-container>

				<!-- Notes -->
				<ng-container *ngIf="task.description">
					<h2 class="mat-h2 app-list-heading" i18n>Notizen</h2>
					<mat-card class="content-section">
						<mat-card-content [innerHTML]="task.description"></mat-card-content>
					</mat-card>
				</ng-container>

				<!-- Sub Tasks -->
				<ng-container *ngIf="subTaskGroups && subTaskGroups.length > 0">
					<h2 class="mat-h2 app-list-heading" i18n>Checkliste</h2>
					<mat-selection-list class="mat-elevation-z1"
							*ngFor="let sub_tasks of subTaskGroups"
							(selectionChange)="subTaskCompleteToggle($event)"
							style="margin-bottom: 12px;">
				        <mat-list-option checkboxPosition="before"
								[disabled]="!sub_task.editable"
								[value]="sub_task.id"
								[selected]="!!sub_task.completed"
								*ngFor="let sub_task of sub_tasks">
							<div class="flexbox" style="justify-content: space-between; align-items: center">
					            <span>{{ sub_task.title }}</span>
								<small style="flex: 0 auto; margin-left: 8px; text-align: right">{{ sub_task.responsible?.first_name }} {{ sub_task.responsible?.last_name }}</small>
							</div>
				        </mat-list-option>
				    </mat-selection-list>
				</ng-container>



				<!-- Action (Completed) -->
				<ng-container *ngIf="!task.completed">
					<div class="flexbox row button-wrapper">
						<button mat-raised-button
								class="flex spinner-button"
								color="success"
								i18n
								(click)="completeButton()" [disabled]="disableCompleteButton">
							<mat-spinner diameter="20"></mat-spinner>
							<fa-icon mat-icon [icon]="faCheck"></fa-icon>&nbsp;
							Als erledigt markieren
						</button>
					</div>
				</ng-container>
				<h2 class="mat-h2 app-list-heading toolbar-heading" *ngIf="task.completed">
					<span i18n>Erledigt</span>
					<mat-slide-toggle color="success" [checked]="!!task.completed" (change)="completeToggle($event)"></mat-slide-toggle>
				</h2>
			</main>
		</mat-tab>

		<!-- Files -->
		<mat-tab>
			<ng-template mat-tab-label>
				<fa-icon mat-list-icon [icon]="faFileAlt"></fa-icon>
				<span i18n class="label-text">Dateien ({{task.files ? task.files.length : 0}})</span>
			</ng-template>
			<main>
				<h2 class="mat-h2 app-list-heading" i18n
						*ngIf="!fileCategories || !fileCategories.length">
					Dateien
				</h2>
				<mat-card class="content-section" *ngIf="!task.files || task.files.length < 1">
					<mat-card-content i18n><em>Es sind noch keine Dateien vorhanden!</em></mat-card-content>
				</mat-card>
				<ng-template *ngIf="fileCategories" ngFor let-cat [ngForOf]="fileCategories">
				  <h2 class="mat-h2 app-list-heading">{{cat.title}}</h2>
				  <app-file-list [files]="cat.files" [categoriesAvailable]="task.file_categories" (filesUpdated)="reloadData()"></app-file-list>
				</ng-template>

				<app-file-upload table='task' [row]="task.id"
					[categoriesAvailable]="task.file_categories"
					[organization]="task.organization"
					(fileAdded)="onFileAdded()"></app-file-upload>
			</main>
		</mat-tab>
	</mat-tab-group>
</ng-container>
