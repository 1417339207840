import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { DeactivateDialogGuard } from './core/guards/deactivate-dialog.guard';

import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ActivateComponent } from './auth/activate/activate.component';
import { ActivateResendComponent } from './auth/activate-resend/activate-resend.component';
import { PasswordReset1Component } from './auth/password-reset1/password-reset1.component';
import { PasswordReset2Component } from './auth/password-reset2/password-reset2.component';
import { InvitationComponent } from './invitation/invitation.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FilesComponent } from './files/files.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountSettingsComponent } from './settings/account-settings/account-settings.component';
import { NotificationsSettingsComponent } from './settings/notifications-settings/notifications-settings.component';
import { CalendarSubscribeComponent } from './settings/calendar-subscribe/calendar-subscribe.component';
import { DispositionsComponent } from './dispositions/dispositions.component';
import { TourListComponent } from './tours/tour-list/tour-list.component';
import { TourDetailComponent } from './tours/tour-detail/tour-detail.component';
import { TourResolver } from './tours/shared/tour.resolver';
import { ProjectListComponent } from './projects/project-list/project-list.component';
import { ProjectDetailComponent } from './projects/project-detail/project-detail.component';
import { ProjectResolver } from './projects/shared/project.resolver';

import { JobDetailComponent } from './jobs/job-detail/job-detail.component';
import { JobTimesheetComponent } from './jobs/job-timesheet/job-timesheet.component';
import { JobTimesheetCreateComponent } from './jobs/job-timesheet-create/job-timesheet-create.component';
import { JobResolver } from './jobs/shared/job.resolver';
import { JobTimesheetResolver } from './jobs/shared/job-timesheet.resolver';

import { TaskDetailComponent } from './tasks/task-detail/task-detail.component';
import { TaskResolver } from './tasks/shared/task.resolver';

import { TaskAssignListComponent } from './tasks/task-assign-list/task-assign-list.component';
import { TaskAssignDetailComponent } from './tasks/task-assign-detail/task-assign-detail.component';
import { TaskAssignResolver } from './tasks/shared/task-assign.resolver';

import { MissionProcessResolver } from './missions/shared/mission-process.resolver';
import { MissionProcessDetailComponent } from './missions/mission-processes/mission-process-detail/mission-process-detail.component';
import { MissionProcessesComponent } from './missions/mission-processes/mission-processes.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { EventResolver } from './events/shared/event.resolver';
import { MissionProcessConfirmationsComponent } from './missions/mission-processes/mission-process-confirmations/mission-process-confirmations.component';
import { ImprintComponent } from './imprint/imprint.component';
import { WorkScheduleListComponent } from './work-schedules/work-schedule-list/work-schedule-list.component';

import { VacationRequestResolver } from './vacation-requests/shared/vacation-request.resolver';
import { VacationRequestListComponent } from './vacation-requests/vacation-request-list/vacation-request-list.component';
import { VacationRequestNewComponent } from './vacation-requests/vacation-request-new/vacation-request-new.component';
import { VacationRequestDetailComponent } from './vacation-requests/vacation-request-detail/vacation-request-detail.component';

import { WorkScheduleResolver } from './work-schedules/shared/work-schedule.resolver';
import { WorkScheduleOverviewComponent } from './work-schedules/work-schedule-overview/work-schedule-overview.component';

const appRoutes: Routes = [
	{ path: 'invite/:token', component: InvitationComponent },
	{ path: 'auth/login/:token', component: LoginComponent },
	{ path: 'auth/login', component: LoginComponent },
	{ path: 'auth/register/:token', component: RegisterComponent },
	{ path: 'auth/register', component: RegisterComponent },
	{ path: 'auth/activate/resend', component: ActivateResendComponent },
	{ path: 'auth/activate/:token', component: ActivateComponent },
	{ path: 'auth/password/reset', component: PasswordReset1Component },
	{ path: 'auth/password/reset/:token', component: PasswordReset2Component },
	{ path: '', component: DashboardComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateDialogGuard], children: [
		{ path: '', component: HomeComponent, canDeactivate: [DeactivateDialogGuard], data: {title:'Home'}},
		{ path: 'calendar', redirectTo:'calendar/month/'},
		{ path: 'calendar/:view', component: CalendarComponent, canDeactivate: [DeactivateDialogGuard], data: {title:'Kalender'}},
		{ path: 'calendar/:view/:date', component: CalendarComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Kalender'}
		},
		{ path: 'events/:orga/:id', component: EventDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Termin', back:true}, resolve:{resolved:EventResolver}
		},
		{ path: 'dispositions',  component: DispositionsComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Meine Agenda'}
		},
		{ path: 'tours',  component: TourListComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Alle Touren'}
		},
		{ path: 'tours/:orga/:id',  component: TourDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Tour', back:true}, resolve:{resolved:TourResolver}
		},
		{ path: 'projects',  component: ProjectListComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Alle Projekte'}
		},
		{ path: 'projects/:orga/:id',  component: ProjectDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Projekt', back:true},
			resolve:{resolved:ProjectResolver}
		},
		{ path: 'jobs/:orga/:id',  component: JobDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Job', back:true}, resolve:{resolved:JobResolver}
		},
        { path: 'jobs/:orga/:id/timesheet',  component: JobTimesheetCreateComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Arbeitsschein erstellen', back:true}, resolve:{resolved:JobResolver}
		},
        { path: 'timesheets/:orga/:id',  component: JobTimesheetComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Arbeitsschein', back:true}, resolve:{resolved:JobTimesheetResolver}
		},

		/* TASK */
		{ path: 'task-assigns',  component: TaskAssignListComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Meine Aufgaben'}
		},
		{ path: 'task-assigns/:orga/:id',  component: TaskAssignDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Aufgabe', back:true}, resolve:{resolved:TaskAssignResolver}
		},
		{ path: 'tasks/:orga/:id',  component: TaskDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Aufgabe', back:true}, resolve:{resolved:TaskResolver}
		},

		/* MISSIONS */
		{ path: 'missions/processes',  component: MissionProcessesComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Ausschreibungen'}
		},
		{ path: 'missions/processes/confirmations', component: MissionProcessConfirmationsComponent,
			canDeactivate: [DeactivateDialogGuard], data: {title:'Auftragsbestätigungen'}
		},
		{ path: 'missions/processes/:orga/:id',  component: MissionProcessDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Ausschreibung', back:true}, resolve:{resolved:MissionProcessResolver}
		},

		/* FILES */
		{ path: 'files',  component: FilesComponent, canDeactivate: [DeactivateDialogGuard], data: {title:'Dateien'}},

		/* SETTINGS */
		{ path: 'settings', component: SettingsComponent, canDeactivate: [DeactivateDialogGuard], data: {title:'Einstellungen'}},
		{ path: 'settings/account', component: AccountSettingsComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Account', back:true}
		},
		{ path: 'settings/calendar-subscribe', component: CalendarSubscribeComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Kalender abonnieren', back: false}
		},
		{ path: 'settings/notifications', component: NotificationsSettingsComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Benachrichtigungen', back:true}
		},

		/* IMPRINT */
		{ path: 'imprint', component: ImprintComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Impressum/Datenschutz'}
		},

		/* WORK SCHEDULES */
		{ path: 'work-schedules', component: WorkScheduleListComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Arbeitszeiten'}
		},
		{
			path: 'work-schedules/review',
			component: WorkScheduleOverviewComponent,
			canDeactivate: [DeactivateDialogGuard],
			data: {
				back: true,
				title: 'Arbeitsübersicht',
				current: true
			}
		},
		{ path: 'work-schedule/:orga/:id', component: WorkScheduleOverviewComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Arbeitsübersicht', back:true }, resolve: { resolved: WorkScheduleResolver }
		},

		/* VACATION REQUESTS */
		{ path: 'vacation-requests', component: VacationRequestListComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Urlaubsanträge'}
		},
		{ path: 'vacation-request-new/:orga', component: VacationRequestNewComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Urlaubsantrag erstellen', back:true}
		},
		{ path: 'vacation-request/:orga/:id', component: VacationRequestDetailComponent, canDeactivate: [DeactivateDialogGuard],
			data: {title:'Urlaubsantrag', back:true }, resolve: { resolved: VacationRequestResolver }
		}
	] },

	// otherwise redirect to home
	{ path: '**', redirectTo: '' }
	// or alternatively to a Page Not Found page
	//{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(
			appRoutes,
			{
				onSameUrlNavigation: 'reload'
			}
			//{ enableTracing: true } // <-- debugging purposes only
		)
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {}
