import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { formatDate } from '@angular/common';
import { createDateFromDatetime, createDateFromDateAndTime } from 'src/app/core/utils/date-utils';

import { WorkSchedule } from '../shared/work-schedule.model';
import { WorkService } from '../shared/work.service';

import { AccountService } from '../../core/services/account.service';
import { Account } from '../../core/models/account.model';

import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';

import { MyErrorHandler } from '../../core/classes/my-error-handler.class';

export enum BreakDialogType {
    StartBreak,
    EditBreak,
    EndBreak,
    CreateBreak,
};

export const defaultBreakDialogOptions = {
    minWidth: 250,
    maxWidth: 430,
    autoFocus: false,
    width: '95vw'
};

export interface DialogData {
    type: BreakDialogType;
    id?: number;
    ws?: WorkSchedule;
    ws_date_out?: Date;
    organization: number;
    start?: Date;
    end?: Date;
}

@Component({
    selector: 'app-work-schedule-break-dialog',
    templateUrl: './work-schedule-break-dialog.component.html',
    styleUrls: ['./work-schedule-break-dialog.component.css']
})
export class WorkScheduleBreakDialogComponent implements OnInit {
    form: FormGroup;
    futureDateError;
    beforeStartDateError;

    types = BreakDialogType;

    account:Account;

    date = new Date();
    organization: number;
    showDateInput: boolean = false;

    constructor(
        private fb: FormBuilder,
        public dialogBreak: MatDialogRef<WorkScheduleBreakDialogComponent>,
        public dialog: MatDialog,
        private accountService: AccountService,
        private workService: WorkService,
        private myErrorHandler: MyErrorHandler,
        @Inject(MAT_DIALOG_DATA) public data:DialogData,
        @Inject(LOCALE_ID) private locale:string,
    ) {

        this.organization = this.data.organization || this.data.ws && this.data.ws.organization || null;
    }

    /**
    * date to date format
    * @param dateString
    */
    formatDate(dateString: string | number | Date): string {
        if(typeof dateString === 'string' && dateString.length > 11)
            dateString = createDateFromDatetime(dateString);
        return formatDate(dateString, 'yyyy-MM-dd', this.locale)
    }

    /**
    * date to time format
    * @param dateString
    */
    formatTime(dateString: string | number | Date): string {
        if(typeof dateString === 'string' && dateString.length > 11)
        dateString = createDateFromDatetime(dateString);
        return formatDate(dateString, 'HH:mm', this.locale)
    }

    ngOnInit(){
        // Get current account
        this.accountService.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(res => {
            if(!res) return;
            this.account = res;
        });

        // adjust this.date
        if(this.data.ws.date_in)
            this.date = createDateFromDatetime(this.data.ws.date_in);

        const group: any = {
            startDate: [this.formatDate(this.data.start || this.date), Validators.required],
            startTime: [this.formatTime(this.data.start || new Date()), Validators.required],
            endDate: [this.formatDate(this.data.end || this.date), Validators.required],
            endTime: [this.formatTime(this.data.end || new Date()), Validators.required],
        };

        this.form = this.fb.group(group);

        if(this.data.ws.date_in && (this.data.ws.date_out || this.data.ws_date_out || this.date)){
            if(this.formatDate(this.data.ws.date_in) != this.formatDate(this.data.ws.date_out || this.data.ws_date_out || this.date))
                this.showDateInput = true;
        }
    }

    /**
    * calculates duration from two dates in hours
    * @param start
    * @param end
    */
    calculateDuration(start:Date, end:Date) {
        return Math.round((end.getTime() - start.getTime()) / 36000) / 100;
    }

    /**
    * updates duration according to dates
    */
    updateDuration() {
        const start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
        const end = createDateFromDateAndTime(this.form.value.endDate, this.form.value.endTime);

        if (!start || !end) return;

        this.form.controls['duration'].setValue(this.calculateDuration(start, end))
    }

    breakDuration(){
        const start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
        const end = createDateFromDateAndTime(this.form.value.endDate, this.form.value.endTime);

        let minutes = start && end ? Math.max(0, (end.getTime() - start.getTime())/1000/60) : 0;

        return {
            hours: Math.floor(minutes/60),
            minutes: Math.floor(minutes%60)
        };

        // return Math.round((end.getTime() - start.getTime()) / 36000) / 100;
    }

        /**
    * Called when submit button is pressed
    */
    submit(){
        this.futureDateError = false;
        this.beforeStartDateError = false;

        if(this.form.valid){
            const values = {...this.form.value};
            const now = new Date();

            if(values.startDate) values.start = createDateFromDateAndTime(values.startDate, values.startTime);
            if(values.endDate) values.end = createDateFromDateAndTime(values.endDate, values.endTime);

            // check for valid date
            if(isNaN(values.start) || isNaN(values.end)) return this.dialog.open(OkDialogComponent, {data: 'Datum/Uhrzeit ist fehlerhaft.'});

            if (values.startDate && values.endDate) {
                if (now < values.start || now < values.end) {
                    this.futureDateError = true;
                    return
                }

                if (this.data.type != BreakDialogType.StartBreak && values.start > values.end) {
                    this.beforeStartDateError = true;
                    return;
                }
            }

            if(typeof values.start === 'string') values.start = createDateFromDatetime(values.start);
            if(typeof values.end === 'string') values.end = createDateFromDatetime(values.end);

            switch (this.data.type) {
                case BreakDialogType.StartBreak:
                    this.startBreak(values);
                break;
                case BreakDialogType.CreateBreak:
                    this.createBreak(values);
                break;
                case BreakDialogType.EditBreak:
                    if(!this.data.id)
                        this.createBreak(values);
                    else
                        this.editBreak(values);
                break;
                case BreakDialogType.EndBreak:
                    this.editBreak(values);
                break;
                default:
                    this.dialog.open(OkDialogComponent, {data: 'Unkown Dialog Type'});
            }
        }
    }


    /**
    * starts work schedule entry
    * @param form
    */
    private startBreak(form) {
        return this.workService.createBreak(this.data.ws.organization, this.data.ws.id, {
            start: form.start,
        }).pipe(untilDestroyed(this)).subscribe(
            entry => this.dialogBreak.close(entry),
            err => this.myErrorHandler.showError(err)
        );
    }

    private createBreak(form) {
        return this.workService.createBreak(this.data.ws.organization, this.data.ws.id, {
            start: form.start,
            end: form.end,
        }).pipe(untilDestroyed(this)).subscribe(
            entry => this.dialogBreak.close(entry),
            err => this.myErrorHandler.showError(err)
        );
    }

    private editBreak(form){
        return this.workService.editBreak(this.organization, this.data.id, {
            start: form.start,
            end: form.end
        }).pipe(untilDestroyed(this)).subscribe(
            entry => this.dialogBreak.close(entry),
            err => this.myErrorHandler.showError(err)
        );
    }


    ngOnDestroy() {}
}
