import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { environment } from '../../../environments/environment';
// import {Observable} from 'rxjs/Observable';
import { MatSnackBar } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/material/snack-bar";
var UpdateService = /** @class */ (function () {
    function UpdateService(swUpdate, http, snackbar) {
        this.swUpdate = swUpdate;
        this.http = http;
        this.snackbar = snackbar;
        this.date_version = null;
        this.last_update_check = null;
    }
    UpdateService.prototype.init = function () {
        var _this = this;
        var date_version_stored = localStorage.getItem('versionDate');
        if (!date_version_stored)
            this.setVersionDate(new Date());
        else
            this.date_version = new Date(date_version_stored);
        if (environment.production) {
            interval(3 * 60 * 1000).subscribe(function () { return _this.checkForUpdates(); });
            window.addEventListener("focus", function () {
                if (!_this.last_update_check || Date.now() - _this.last_update_check > 30 * 1000) {
                    _this.checkForUpdates();
                }
            });
            this.checkForUpdates();
        }
        this.last_update_check = Date.now();
    };
    UpdateService.prototype.setVersionDate = function (date) {
        this.date_version = date;
        localStorage.setItem('versionDate', date.toString());
    };
    UpdateService.prototype.getVersionDate = function (callback) {
        this.http.get("./index.html?no-cache=" + Date.now(), { observe: 'response', responseType: 'text' }).subscribe(function (res) {
            var date = null;
            if (res && res.headers)
                date = new Date(res.headers.get('last-modified'));
            callback(date);
        });
    };
    UpdateService.prototype.checkForUpdates = function () {
        var _this = this;
        console.log('checking for updates ...');
        this.last_update_check = Date.now();
        this.getVersionDate(function (date) {
            console.log(_this.date_version, date);
            if (date && date > _this.date_version) {
                _this.setVersionDate(date);
                document.location.reload();
            }
        });
    };
    UpdateService.prototype.promptUser = function () {
        /* console.log('updating to new version');
        alert('updating to new version');
        this.swUpdate.activateUpdate().then(() => document.location.reload()); */
    };
    UpdateService.ngInjectableDef = i0.defineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.inject(i1.SwUpdate), i0.inject(i2.HttpClient), i0.inject(i3.MatSnackBar)); }, token: UpdateService, providedIn: "root" });
    return UpdateService;
}());
export { UpdateService };
