<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>E-Mail Adresse ändern</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput type="email" placeholder="Neue E-Mail Adresse" i18n-placeholder formControlName="email" required [value]="email">
    </mat-form-field>
    <small class="color-text-red" *ngIf="existsError" style="margin-top:-8px;" i18n>Email-Adresse ist bereits vergeben</small>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" i18n class="spinner-button" type="submit" [disabled]="disabled">
      <mat-spinner diameter="20"></mat-spinner>
      Ändern
    </button>
  </mat-dialog-actions>
</form>