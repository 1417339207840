import { Injectable } from '@angular/core';
import { ResponsiveState, ResponsiveSizeInfoRx } from 'ngx-responsive';
import { BehaviorSubject } from 'rxjs';

/**
 * This service provides features from the ngx-responsive library and exposes easy to handle observables.
 */

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private smallScreen: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private state: ResponsiveState,
    private responsive: ResponsiveSizeInfoRx,
  ) {
    this.responsive.connect();
    // subscribe for screen size changes
    this.responsive.getResponsiveSize.subscribe((data) => {
      if(data){
        switch(data){
          case 'xs':
            this.smallScreen.next(true);
            break;
          default:
            this.smallScreen.next(false);
        }
      }
      
    })
  }

  /**
   * returns an observable that is either true if the screen size is small or false otherwise
   */
  smallScreenObserver() {
    return this.smallScreen.asObservable();
  }

  /**
   * returns a boolean that indicates if the device is a mobile device
   */
  isMobile() {
    return this.smallScreen.getValue();
  }
}
