import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { CachableModelService } from '../../core/classes/cachable-model-service.class';

import { Task } from './task.model';
import { TaskAssignService } from './task-assign.service';

import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class TaskService extends CachableModelService<Task>{


    constructor(
        protected http: HttpClient,
        @Inject(LOCALE_ID) protected locale: string,
        protected tas: TaskAssignService
    ) {
        super(http, 'tasks');
    }

    getByOrganizationAndId(orga, id):Observable<Task>{
        let data = this.findModelByOrganizationAndId(orga, id);

        // If we found nothing -> get data from task assigns
        if(!data){
            let taskAssigns = JSON.parse(localStorage.getItem('taskAssigns'));
            if(taskAssigns){
                let task = taskAssigns.find(val => {
                    return val['task'] == id && val['organization'] == orga;
                });
                if(task) data = task;
            }
        }

        let http = this.http.get<Task>('/v1/organizations/'+orga+'/tasks/'+id);
        return this.cachedRequest(data, http);
    }

    complete(task:Task):Observable<any>{
		return this.http.post('/v1/organizations/'+task.organization+'/tasks/'+task.id+'/complete', {})
			.pipe(tap(res => {
                this.updateModel(res);

                // Update task assign entry
                let taskAssigns = JSON.parse(localStorage.getItem('taskAssigns'));
                if(taskAssigns){
                    let entry = taskAssigns.find(entry => {
                        return entry['task'] == task.id && entry['organization'] == task.organization;
                    });
                    if(entry){
                        entry.completed = 1;
                        this.tas.updateModel(entry);
                    }
                }
            }));
    }

    incomplete(task:Task):Observable<any>{
		return this.http.post('/v1/organizations/'+task.organization+'/tasks/'+task.id+'/incomplete', {})
			.pipe(tap(res => {
                this.updateModel(res);

                // Update task assign entry
                let taskAssigns = JSON.parse(localStorage.getItem('taskAssigns'));
                if(taskAssigns){
                    let entry = taskAssigns.find(entry => {
                        return entry['task'] == task.id && entry['organization'] == task.organization;
                    });
                    if(entry){
                        entry.completed = 0;
                        this.tas.updateModel(entry);
                    }
                }
            }));
    }
}
