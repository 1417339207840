import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { ComponentInit } from '../core/classes/component-init.class';
import { ScrollLocations } from '../core/globals/scroll-locations';

import { Router, ActivatedRoute } from '@angular/router';

import { DispositionService } from './shared/disposition.service';
import { Disposition } from './shared/disposition.model';
import { ListGroup } from '../core/models/list-group.model';
import { ListGroupService } from '../core/services/list-group.service';

@Component({
  templateUrl: './dispositions.component.html',
  styleUrls: ['./dispositions.component.css']
})
export class DispositionsComponent extends ComponentInit implements OnInit {

  loadError:boolean = false;
  // retryCallback = this.retry.bind(this);


  listGroups:ListGroup<Disposition>[];

  dispos:Disposition[];

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected scrollLocations: ScrollLocations,
    private disposService: DispositionService,
    private listGroupService: ListGroupService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    super(router, scrollLocations, route);
    this.listGroups = this.listGroupService.getGroups();
  }

  init() {
    this.reload();
  }

  reload(){
    this.loadError = false;
    this.disposService.getAllForCurrentAccount({date_from: new Date()}).subscribe(res => {
      if(!res) return;

      this.listGroups = this.listGroupService.getGroups(res, 'start');
      this.dispos = res;

    }, res => {
      this.loadError = true;
    })
  }
}
