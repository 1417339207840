import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthService } from './auth.service';
import { ClearService } from '../core/services/clear.service';
 
@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(
        private router: Router, 
        private auth: AuthService,
        private clear: ClearService,
    ) {}
 
    canActivate() {
		if(!this.auth.isLoggedIn){
            // not logged in so logout, clear data, and redirect to login page
            this.auth.logout();
            this.clear.clear();
            this.router.navigate(['/auth/login']);
            return false;
        }
        
        return true;
    }
}