import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Organization } from '../models/organization.model';
import { Option } from '../interfaces/option';
import { CachableModelService } from '../classes/cachable-model-service.class';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends CachableModelService<Organization>{

  private organizationsOfAcc:BehaviorSubject<Organization[]>;

  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'organizations', false);
    this.organizationsOfAcc = new BehaviorSubject(JSON.parse(localStorage.getItem('organizationsOfAcc')));
  }

  clear(){
    super.clear();
    this.organizationsOfAcc.next([]);
    localStorage.removeItem('organizationsOfAcc');
  }

  getAllForCurrentAccount():Observable<Organization[]>{
    let http = this.http.get<Organization[]>('/v1/organizations')
      .pipe(tap(res => {
        this.updateModels(res);
        this.organizationsOfAcc.next(res);
        localStorage.setItem('organizationsOfAcc', JSON.stringify(res));
      }));

    // check if data is available
    if(localStorage.getItem('organizationsOfAcc')){
      // if so, return cached data and make a request for possible changed in the background
      http.subscribe();
      return this.organizationsOfAcc.asObservable();
    }
    else
      // otherwise just return the request
      return http;
  }

  getById(id):Observable<Organization>{
    let orga = this.models.find(val => {return val.id == id});

    if(orga) return of(orga);

    return this.http.get<Organization>('/v1/organizations/'+id);
  }

  getOrganizationsOfCurrentAccountObservable(){
    return this.organizationsOfAcc.asObservable();
  }

  /**
   * Returns orgas of account.
   * Does not sync with server.
   */
  get organizationsOfCurrentAccount():Organization[]{
    let orgas = this.organizationsOfAcc.getValue();
    
    if(!orgas) return [];
    return orgas;
  }

  /**
   * Converts organizations to select options
   * @param orgas 
   * @param addEmptyOption
   */
  organizationsToOptions(orgas:Organization[], addEmptyOption:boolean = true): Option<number>[] {

    const options = orgas.map<Option<number>>(orga => {
      return {value: orga.id, label: orga.title}
    })

    if (addEmptyOption){
      options.unshift({
        value: null,
        label: 'Alle'
      });
    }

    return options;
  }
}
