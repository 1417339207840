/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./single-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./single-page.component";
var styles_SinglePageComponent = [i0.styles];
var RenderType_SinglePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SinglePageComponent, data: {} });
export { RenderType_SinglePageComponent as RenderType_SinglePageComponent };
export function View_SinglePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "assets/logos/lupax.png"], ["style", "max-height:20vh;max-width:100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "mat-h2"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_SinglePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-single-page", [], null, null, null, View_SinglePageComponent_0, RenderType_SinglePageComponent)), i1.ɵdid(1, 49152, null, 0, i2.SinglePageComponent, [], null, null)], null, null); }
var SinglePageComponentNgFactory = i1.ɵccf("app-single-page", i2.SinglePageComponent, View_SinglePageComponent_Host_0, { title: "title" }, {}, ["*"]);
export { SinglePageComponentNgFactory as SinglePageComponentNgFactory };
