 /**
   * helper function that deletes element from array if present
   * @param item
   * @param array
   */
  export function deleteFromArray(item:any, arr:Array<any>) {

    const index = arr.indexOf(item);
    if (index < 0) return;  // not present in list

    arr.splice(index, 1);
  }

  export function updateItemInArray(item:any, arr:Array<any>, key:string = 'id'){
    const index = arr.findIndex(item_loop => item_loop[key] == item[key]);
    if(index < 0)
      arr.push(item);
    else
      arr[index] = item;
  }
