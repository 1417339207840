/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i7 from "@angular/material/card";
import * as i8 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i9 from "@angular/material/list";
import * as i10 from "./timeline.component";
var styles_TimelineComponent = [i0.styles];
var RenderType_TimelineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineComponent, data: {} });
export { RenderType_TimelineComponent as RenderType_TimelineComponent };
function View_TimelineComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), (_v.parent.context.$implicit.end || _co.end), "shortTime")); _ck(_v, 3, 0, currVal_0); }); }
function View_TimelineComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_TimelineComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "aside"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.aside; _ck(_v, 0, 0, currVal_0); }); }
function View_TimelineComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "action ng-fa-icon"], ["size", "lg"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.callback(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer], { iconProp: [0, "iconProp"], title: [1, "title"], size: [2, "size"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.icon; var currVal_2 = _v.parent.parent.context.$implicit.title; var currVal_3 = "lg"; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0); }); }
function View_TimelineComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineComponent_6)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TimelineComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "entry"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "time"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 8, "mat-card", [["class", "mat-elevation-z1 item mat-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatCard_0, i6.RenderType_MatCard)), i1.ɵdid(8, 49152, null, 0, i7.MatCard, [], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 4, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineComponent_3)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TimelineComponent_4)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineComponent_5)), i1.ɵdid(17, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "time"; var currVal_1 = (_v.context.$implicit.color ? (_v.context.$implicit.color + "_bg") : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_v.context.$implicit.end || _co.end); _ck(_v, 6, 0, currVal_3); var currVal_5 = _v.context.$implicit.subtitle; _ck(_v, 13, 0, currVal_5); var currVal_6 = _v.context.$implicit.aside; _ck(_v, 15, 0, currVal_6); var currVal_7 = (_co.actions.length && !_v.context.$implicit.disableAction); _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.start, "shortTime")); _ck(_v, 3, 0, currVal_2); var currVal_4 = _v.context.$implicit.title; _ck(_v, 11, 0, currVal_4); }); }
function View_TimelineComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "time"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.end, "shortTime")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.end, "mediumDate")); _ck(_v, 5, 0, currVal_1); }); }
export function View_TimelineComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "time"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 3, "mat-nav-list", [["class", "list mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i8.View_MatNavList_0, i8.RenderType_MatNavList)), i1.ɵdid(10, 704512, null, 0, i9.MatNavList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TimelineComponent_1)), i1.ɵdid(12, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineComponent_7)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.sortedEntries; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.end; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.start, "shortTime")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.start, "mediumDate")); _ck(_v, 7, 0, currVal_1); }); }
export function View_TimelineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline", [], null, null, null, View_TimelineComponent_0, RenderType_TimelineComponent)), i1.ɵdid(1, 49152, null, 0, i10.TimelineComponent, [], null, null)], null, null); }
var TimelineComponentNgFactory = i1.ɵccf("app-timeline", i10.TimelineComponent, View_TimelineComponent_Host_0, { start: "start", end: "end", entries: "entries", actions: "actions" }, { selected: "selected" }, []);
export { TimelineComponentNgFactory as TimelineComponentNgFactory };
