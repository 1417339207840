<app-single-page>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput type="email" placeholder="E-Mail Adresse" i18n-placeholder formControlName="email" required>
      <mat-error *ngIf="isFieldInvalid('email')" i18n>
        Gib deine E-Mail Adresse ein
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Passwort" i18n-placeholder formControlName="password" required>
      <mat-error *ngIf="isFieldInvalid('password')" i18n>
        Gib dein Passwort ein
      </mat-error>
    </mat-form-field>
    <br />
    <button mat-raised-button class="spinner-button" color="primary" [disabled]="formSubmitted">
      <mat-spinner diameter="20"></mat-spinner>
      Anmelden
    </button>
    <a mat-button routerLink="/auth/password/reset" class="color-text-grey" i18n>Passwort vergessen</a>
    <a mat-button routerLink="/auth/activate/resend" class="color-text-grey" i18n *ngIf="showActivateButton">Aktivierungslink anfordern</a>
    <!-- <br />
    <a mat-button routerLink="/auth/register" class="color-text-grey" i18n>Registrieren</a> -->
  </form>
  <mat-error class="textbox-padding" *ngIf="error">
    {{error}}
  </mat-error>
</app-single-page>
