var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TaskAssignService = /** @class */ (function (_super) {
    __extends(TaskAssignService, _super);
    function TaskAssignService(http, locale) {
        var _this = _super.call(this, http, 'taskAssigns') || this;
        _this.http = http;
        _this.locale = locale;
        return _this;
    }
    /**
     * Returns all work schedule entries of month (yyyy-mm)
     */
    TaskAssignService.prototype.getTaskAssigns = function (options) {
        var params = {
            date_from: this.dateToString(options.date_from, 'yyyy-MM-dd'),
            state: options.state
        };
        // get cached data
        var data = this.models;
        var http = this.http.get('/v1/task-assigns', { params: params });
        return this.cachedRequest(data, http);
    };
    TaskAssignService.prototype.getByOrganizationAndId = function (orga, id) {
        var data = this.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/task-assigns/' + id);
        return this.cachedRequest(data, http);
    };
    TaskAssignService.prototype.complete = function (taskAssign) {
        var _this = this;
        return this.http.post('/v1/organizations/' + taskAssign.organization + '/task-assigns/' + taskAssign.id + '/complete', {})
            .pipe(tap(function (res) { return _this.updateModel(res); }));
    };
    TaskAssignService.prototype.incomplete = function (taskAssign) {
        var _this = this;
        return this.http.post('/v1/organizations/' + taskAssign.organization + '/task-assigns/' + taskAssign.id + '/incomplete', {})
            .pipe(tap(function (res) { return _this.updateModel(res); }));
    };
    TaskAssignService.ngInjectableDef = i0.defineInjectable({ factory: function TaskAssignService_Factory() { return new TaskAssignService(i0.inject(i1.HttpClient), i0.inject(i0.LOCALE_ID)); }, token: TaskAssignService, providedIn: "root" });
    return TaskAssignService;
}(CachableModelService));
export { TaskAssignService };
