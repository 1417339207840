<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>Verfügbarkeit</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field *ngIf="orgas.length > 1" >
      <mat-select 
        placeholder="Organisation" 
        formControlName="organization" 
        i18n-placeholder 
        required
      >
        <mat-option *ngFor="let orga of orgas" [value]="orga.id">{{orga.title}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select 
        placeholder="Status" 
        formControlName="type" 
        i18n-placeholder 
        required
      >
        <mat-option value="available" i18n>Verfügbar</mat-option>
        <mat-option value="not_available" i18n>Nicht Verfügbar</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n>Datum</mat-label>
      <input matInput type="date" formControlName="date" required>
    </mat-form-field>
    
    <mat-form-field>
      <mat-label i18n>Start</mat-label>
      <input matInput type="time" formControlName="start" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Ende</mat-label>
      <input matInput type="time" formControlName="end" required>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button *ngIf="data.roster" mat-button i18n (click)="onDelete()" type="button" color="warn">
      Entfernen
    </button>
    <button mat-button i18n type="submit" color="primary">
      Speichern
    </button>
  </mat-dialog-actions>
</form>
