import { Component, OnInit, LOCALE_ID, Inject, ViewChild } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { FilterHeaderComponent } from '../../shared/filters/filter-header/filter-header.component';

import {
  faRocket,
  faUser
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
} from '@fortawesome/free-regular-svg-icons';

import { ProjectService } from '../shared/project.service';
import { Project } from '../shared/project.model';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ListGroup } from '../../core/models/list-group.model';
import { ListGroupService } from '../../core/services/list-group.service';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { NumberFilter, GroupedSelectFilter, DateFilter } from 'src/app/shared/filters/shared/classes';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent extends ComponentInit implements OnInit{
  @ViewChild(FilterHeaderComponent) filterComponent;

  loadError:boolean = false;

  reload(){
    this.filterComponent && this.filterComponent.reload();
  }

  faRocket = faRocket;
  faUser = faUser;
  faCalendarAlt = faCalendarAlt;

  filterInputs = {
    date_from: new DateFilter({
      label: 'Ab',
      value: new Date()
    }),
    weeks: new NumberFilter({
      label: 'Wochen',
      value: 3,
      storable: true
    }),
    responsible: new GroupedSelectFilter({
      label: 'Verantwortl.',
      options: [{value: '0', label: 'Alle'}],
      value: '0',
      storable: true
    }),
    status: new GroupedSelectFilter({
      label: 'Status',
      options: [{value: '0', label: 'Alle'}],
      value: '0',
      storable: true
    })
  };

  listGroups:ListGroup<Project>[];

  projects:Project[];

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private listGroupService: ListGroupService,
    @Inject(LOCALE_ID) private locale: string,
    protected router: Router,
    protected scrollLocations: ScrollLocations
  ){
    super(router, scrollLocations, route);
    this.listGroups = this.listGroupService.getGroups();
  }

  ngOnInit() {
    super.ngOnInit();
    this.getResponsibles();
  }

  routeProject(p){
     this.router.navigate(['projects', p.organization, p.id]);
   }

  getProjects(options){
    // clone options
    options = {...options};

    let date_to = new Date();
    date_to.setDate(options.date_from.getDate() + (7 * options.weeks));
    delete options.weeks;

    this.projectService.getAllForCurrentAccount({...options, date_to}).subscribe(res => {
      if(!res) return;

      // Check if data has changed
      if(JSON.stringify(this.projects) == JSON.stringify(res)) return;

      this.listGroups = this.listGroupService.getGroups(res, 'date_start', 'date_start');
      this.projects = res;
    }, res => {
      this.loadError = true;
    })
  }

  reloadFilters() {
    this.filterInputs = {...this.filterInputs};
  }

  getResponsibles(){
    this.projectService.getFilterOptionsForCurrentAccount().subscribe(res => {
      if(!res) return;

      // reset some filters
      const emptyOption = {value: '0', label: 'Alle'};
      this.filterInputs.responsible.options = [emptyOption];
      this.filterInputs.status.options = [emptyOption];

      for(let orga_id in res){
          // Company
          let company_title = res[orga_id].company.title;

          // Responsible
          if(res[orga_id].responsibles){
              // Build Responsible array
              let responsibles = [{
                  value: orga_id + '-0',
                  label: "Alle von " + company_title
              }];
              res[orga_id].responsibles.forEach(user => {
                let entry = {
                  value: orga_id + '-' + user.id,
                  label: user.first_name + " " + user.last_name
                }
                responsibles.push(entry);
              })
              // add entry for option group with company title
              this.filterInputs.responsible.options.push({
                  label: company_title,
                  options: responsibles
              })
          }

          // Status
          if(res[orga_id].status){
              // Build status array
              let status_arr = [{
                  value: orga_id + '-0',
                  label: "Alle von " + company_title
              }];
              res[orga_id].status.forEach(status_single => {
                let entry = {
                  value: orga_id + '-' + status_single.id,
                  label: status_single.title
                }
                status_arr.push(entry);
              })
              // add entry for option group with company title
              this.filterInputs.status.options.push({
                  label: company_title,
                  options: status_arr
              })
          }

      }
      this.reloadFilters();
    });
  }

}
