import { Filters } from "./interfaces";
import { ToggableFilter } from "./classes";

/**
 * Updates all filters that specified a filterToggle
 * @param filters
 * @param filterName
 * @param value 
 */
export function toggleFilters(filters:Filters, filterName:string, value:any) {

	// update toggles, find inputs that are toggled by this filter and update them
	Object.values(filters)
		.filter((filter:any) => filter.toggle && filter.filterToggle === filterName)
		.forEach((filter:any) => filter.toggle(value))
	;
}

/**
 * Toggles all toggable filters with their filterToggles
 * @param filters
 */
export function toggelAllFilters(filters:Filters) {
	
	getFilterTogglesFromFilters(filters).forEach(filterName => 
		toggleFilters(<Filters> filters, filterName, filters[filterName].value)
	);
}

/**
 * Retreives filter names that toggle other filters
 * @param filter
 */
export function getFilterTogglesFromFilters(filters:Filters) {

	return Object.values(filters)
		.filter((input:any) => !!input.toggle)
		.map((input:any) => input.filterToggle)
	;
}