/**
 * This model class provides data hydration of an object to class values
 */
export default abstract class Model {

	/**
	 * Hydrates model with object data
	 * @param data 
	 */
	constructor(data:object) {

		Object.assign(this, data);
	}

	/**
	 * Converts class properties to api object
	 */
	public abstract toApiObject(): object;

	/**
	 * Converts model to JSON 
	 */
	public toJson():string {
		return JSON.stringify(this.toApiObject());
	}
}