import { Component, OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { formatDuration } from 'src/app/core/utils/number-utils';

import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { WorkSchedule } from '../shared/work-schedule.model';
import { WorkService } from '../shared/work.service';

import { AccountService } from '../../core/services/account.service';
import { Account } from '../../core/models/account.model';

import { MatDialog } from '@angular/material';
import { WorkScheduleDialogComponent, DialogType, defaultDialogOptions } from '../../work-schedules/work-schedule-dialog/work-schedule-dialog.component';

import { faChevronLeft, faChevronRight, faHistory, faHandPointUp } from '@fortawesome/free-solid-svg-icons';

interface Data {
	company: any,
	orga_id: number,
	weeks: Object[],
	work_sum: number
};

@Component({
	templateUrl: './work-schedule-list.component.html',
	styleUrls: ['./work-schedule-list.component.css']
})
export class WorkScheduleListComponent extends ComponentInit implements OnInit {
	faChevronRight = faChevronRight;
	faChevronLeft = faChevronLeft;
	faHistory = faHistory;
	faHandPointUp = faHandPointUp;

	selected = 0;

	month = new Date();
	current_month = true;
	data:Data[] = [];
	columnsToDisplay = ['weekday', 'date', 'time', 'break', 'comment', 'duration'];

	account:Account;

	formatDuration = formatDuration;

	constructor(
		private ws: WorkService,
		private activatedRoute: ActivatedRoute,
		protected router: Router,
	    protected scrollLocations: ScrollLocations,
		private as: AccountService,
		private dialog: MatDialog
	) { super(router, scrollLocations, activatedRoute); }

	init(){
		if(this.activatedRoute.snapshot.queryParams.month){
			this.month = new Date(parseInt(this.activatedRoute.snapshot.queryParams.month));

			// Check for current month
			if(this.month.getFullYear() == (new Date()).getFullYear() && this.month.getMonth() == (new Date()).getMonth())
				this.current_month = true;
			else
				this.current_month = false;
		}
		this.as.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(res => {
			if(!res) return;
			this.account = res;

			// Default companies
			this.account.work_schedule_logging_orgas.forEach(orga => {
				if(!this.data.find(entry => entry.orga_id == orga.id && entry.company.id == orga.company)){
					this.data.push({
						company: {
							id: orga.company,
							title: orga.title
						},
						orga_id: orga.id,
						weeks: [],
						work_sum: 0
					});
				}
			})
			console.log('get entries');
			this.getEntries();
		});
	}

	reload(){
		this.as.getCurrentAccount();
	}

	getEntries(){
		console.log('get entries triggered');
		let year = this.month.getFullYear();
		let month = this.month.getMonth() + 1;

		this.ws.getAllByMonth(year, month).pipe(untilDestroyed(this)).subscribe(data => {
			// reset all weeks data
			for(let entry_key in this.data){
				this.data[entry_key].weeks = [];
				this.data[entry_key].work_sum = 0;
			}

			if(!data) return this.data;

			let date_str = year+"-"+(month.toString().padStart(2, '0'))+"-01";
			let data_month = data.find(element => element.month == date_str);
			if(!data_month) return this.data;

			console.log(data_month);

			// Make "weeks" object to array
			for(let orga_id in data_month.orgas){
				if(data_month.orgas[orga_id].weeks && typeof data_month.orgas[orga_id].weeks == 'object')
					data_month.orgas[orga_id].weeks = Object.values(data_month.orgas[orga_id].weeks);

				// If Orga already exists, just update entries
				let orga_index = this.data.findIndex(element => element.orga_id == parseInt(orga_id) && element.company.id == data_month.orgas[orga_id].company.id);
				if(orga_index > -1){
					this.data[orga_index]['weeks'] = data_month.orgas[orga_id].weeks;
					this.data[orga_index]['work_sum'] = data_month.orgas[orga_id].work_sum;
				// Else add orga
				} else {
					this.data.push(data_month.orgas[orga_id]);
				}
			}
		});
	}

	monthChange(type){
		if(type == 'prev'){
			let date = new Date(this.month.getTime());
			date.setDate(1);
			date.setMonth(date.getMonth() - 1);
			this.month = date;
			this.current_month = false;
		} else {
			let date = new Date(this.month.getTime());
			date.setDate(1);
			date.setMonth(date.getMonth() + 1);
			this.month = date;

			// Check for current month
			if(this.month.getFullYear() == (new Date()).getFullYear() && this.month.getMonth() == (new Date()).getMonth())
				this.current_month = true;
			else
				this.current_month = false;
		}

		this.router.navigate([], {
		    relativeTo: this.activatedRoute,
		    queryParams: { month: this.month.getTime() }
		  });
		this.getEntries();
	}

	isPastLoggingAllowed(orga_id){
		return this.account && this.account.work_schedule_logging_past_orgas && this.account.work_schedule_logging_past_orgas.filter(o => o.id == orga_id).length;
	}

	logWork(orga:number, company:number = null){
		this.dialog.open(WorkScheduleDialogComponent, {
			...defaultDialogOptions,
			data:{
				type: DialogType.StartWork,
				past: true,
				organization: orga,
				company: company

			}
		}).afterClosed().subscribe(workSchedule => {
			if(!workSchedule) return;
			this.router.navigateByUrl(`/work-schedule/${workSchedule.organization}/${workSchedule.id}`);
		});
	}
}
