<main>
	<ng-container *ngIf="!orga || !orga.vacation_request_active">
		<h2 class="mat-h2 app-list-heading" i18n>Nicht möglich</h2>
		<mat-card class="content-section mat-elevation-z1">
			<mat-card-content><p i18n>Die Urlaubseinträge sind bei dieser Firma nicht aktiviert.</p></mat-card-content>
		</mat-card>
	</ng-container>
	<ng-container *ngIf="orga && orga.vacation_request_active">
		<h2 class="mat-h2 app-list-heading" i18n>Aktuelles Konto</h2>
		<mat-list class="mat-elevation-z1">
			<!-- Company -->
			<mat-list-item>
				<fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
				<span mat-line *ngIf="orga">{{orga.title}}</span>
			</mat-list-item>
			<mat-divider></mat-divider>

			<!-- Vacation left -->
			<mat-list-item class="show-all">
				<fa-icon mat-list-icon [icon]="faPlane"
					class="mat-list-icon ng-fa-icon"></fa-icon>
				<span mat-line><b>{{ vacation_left === null ? '?' : vacation_left | number:'1.0-1' }}</b>&nbsp;<span i18n>Tage Resturlaub</span></span>
			</mat-list-item>
			<mat-divider></mat-divider>

			<!-- Overtime left -->
			<mat-list-item class="show-all">
				<fa-icon mat-list-icon [icon]="faClock"
					class="mat-list-icon ng-fa-icon"></fa-icon>
				<span mat-line><b>{{ overtime === null ? '?' : overtime | number:'1.0-1' }}</b>&nbsp;<span i18n>Überstunden</span></span>
			</mat-list-item>
		</mat-list>

		<h2 class="mat-h2 app-list-heading" i18n>Antrag</h2>
		<form mat-list [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
			<mat-list class="mat-elevation-z1">
				<!-- Type -->
				<mat-list-item class="show-all">
					<fa-icon mat-list-icon [icon]="faTag"
						class="mat-list-icon ng-fa-icon"></fa-icon>
					<div mat-line>
						<mat-form-field>
							<mat-label i18n>Typ</mat-label>
							<mat-select floatLabel="always" formControlName="type" required (selectionChange)="onFormChange()">
								<mat-option value="vacation" i18n>Urlaub</mat-option>
								<mat-option value="vacation_special" i18n>Sonderurlaub</mat-option>
								<mat-option value="free" i18n>Freizeitausgleich</mat-option>
								<mat-option value="not_available" i18n>Nicht verfügbar</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</mat-list-item>
				<mat-divider></mat-divider>

				<!-- Start -->
				<mat-list-item class="show-all">
					<fa-icon mat-list-icon [icon]="faStepBackward"
						class="mat-list-icon ng-fa-icon"></fa-icon>
					<div mat-line class="flex">
						<mat-form-field>
							<mat-label i18n>Von</mat-label>
							<input matInput type="date" formControlName="start_date" (change)="onFormChange()"
								 required min="{{ date | date:'yyyy-MM-dd'}}" pattern="\d{4}-\d{2}-\d{2}">
						</mat-form-field>
						<mat-form-field floatLabel="always" *ngIf="form.value.type == 'free' || form.value.type == 'not_available'">
							<mat-label i18n>Uhrzeit</mat-label>
							<input matInput type="time" formControlName="start_time" (change)="onFormChange()" pattern="\d{2}:\d{2}">
						</mat-form-field>
					</div>
				</mat-list-item>
				<mat-divider></mat-divider>

				<!-- End -->
				<mat-list-item class="show-all">
					<fa-icon mat-list-icon [icon]="faStepForward"
						class="mat-list-icon ng-fa-icon"></fa-icon>
					<div mat-line class="flex">
						<mat-form-field floatLabel="always">
							<mat-label i18n>Bis</mat-label>
							<input matInput type="date" formControlName="end_date" (change)="onFormChange()"
								required min="{{ date | date:'yyyy-MM-dd'}}" pattern="\d{4}-\d{2}-\d{2}">
						</mat-form-field>
						<mat-form-field floatLabel="always" *ngIf="form.value.type == 'free' || form.value.type == 'not_available'">
							<mat-label i18n>Uhrzeit</mat-label>
							<input matInput type="time" formControlName="end_time" (change)="onFormChange()" pattern="\d{2}:\d{2}">
						</mat-form-field>
					</div>
				</mat-list-item>
				<mat-divider *ngIf="form.value.amount !== null || loading || amount_initialised"></mat-divider>

				<!-- Amount -->
				<mat-list-item class="show-all" *ngIf="form.value.amount !== null || loading || amount_initialised">
					<fa-icon mat-list-icon [icon]="faArrowsAltH"
						class="mat-list-icon ng-fa-icon"></fa-icon>
					<div mat-line class="flex">
						<app-loading-spinner [loading]="loading" [diameter]="30" *ngIf="loading"></app-loading-spinner>
						<ng-container *ngIf="(form.value.amount !== null || amount_initialised) && !loading">
							<mat-form-field floatLabel="always">
								<mat-label i18n>Dauer</mat-label>
								<input matInput type="number" formControlName="amount" readonly>
							</mat-form-field>
							<span>{{ amount_type }}</span>
						</ng-container>
					</div>
				</mat-list-item>
			</mat-list>

			<!-- Conflicts -->
			<ng-container *ngIf="conflicts && conflicts.length > 0">
				<h2 class="app-list-heading mat-h2" i18n>Konflikte</h2>
				<mat-card class="content-section" [ngStyle]="{backgroundColor: '#b6514f', color: 'white'}">
					<mat-card-content>
						<p><b i18n>Achtung, es gibt Konflikte für den ausgewählten Zeitraum:</b></p>
						<ul>
							<li *ngFor="let conflict of conflicts">{{ conflict }}</li>
						</ul>
					</mat-card-content>
				</mat-card>
			</ng-container>

			<!-- Locks -->
			<ng-container *ngIf="locks && locks.length > 0">
				<h2 class="app-list-heading mat-h2" i18n>Urlaubssperren</h2>
				<mat-list class="mat-elevation-z1">
					<ng-container *ngFor="let lock of locks; last as isLast">
						<mat-list-item class="show-all">
							<fa-icon mat-list-icon [icon]="faBan" class="mat-list-icon ng-fa-icon"></fa-icon>
							<span mat-line>
								<app-date-span [start]="lock.start" [end]="lock.end" [dateOnly]="true"></app-date-span>
								&nbsp;<b>{{lock.title}}</b>
							</span>
						</mat-list-item>
						<mat-divider *ngIf="!isLast"></mat-divider>
					</ng-container>
				</mat-list>
			</ng-container>


			<!-- Notes -->
			<h2 class="mat-h2 app-list-heading" i18n>Kommentar</h2>
			<mat-card class="content-section mat-elevation-z1 note_wrapper">
				<mat-card-content>
					<mat-form-field>
						<textarea matInput placeholder="Kommentar" i18n-placeholder formControlName="note"></textarea>
					</mat-form-field>
				</mat-card-content>
			</mat-card>

			<!-- Submit -->
			<div class="flexbox row button-wrapper">
				<button mat-raised-button class="flex spinner-button" color="primary" i18n type="submit" [disabled]="submitting">
					<mat-spinner diameter="20" *ngIf="submitting" ></mat-spinner>
			    	Antrag erstellen
			    </button>
			</div>
		</form>
	</ng-container>
</main>
