import { BehaviorSubject, Observable, of } from 'rxjs';
import { ModelService } from './model-service.class';
import { tap, takeLast } from 'rxjs/operators';
import { AbstractCachableService } from './abstract-cachable-service.class';
import { HttpClient } from '@angular/common/http';

import { ClearData } from '../models/clear-data';
/**
 * This class provides caching functionality for models.
 * For differing data structures you may implement your own functionality and implement AbstractCachableService.
 * All Cachable services should be added to 'ClearService'
 */
export class CachableModelService<T> extends ModelService<T> implements AbstractCachableService{
  protected models:T[];
  protected route;
  private storageName;
  private id;
  private orga;
  private lastSubject:BehaviorSubject<T>;

  lastError:string;
	lastErrorTime:Date;

  /**
   * @param localStorageItemName  Name used for localStorage
   * @param on  unique identifier of model
   * @param orga  wether this model is a client model
   */
  constructor(
    protected http:HttpClient,
    localStorageItemName:string,
    orga:boolean = true,
    id:string = 'id'
  ) {
    super();
    this.storageName = localStorageItemName;
    this.id = id;
    this.orga = orga;

    this.lastSubject = new BehaviorSubject(null);

    // restore stored list
    if(localStorage.getItem(localStorageItemName))
      this.models = JSON.parse(localStorage.getItem(localStorageItemName));
  }

  /**
   * clears stored values
   */
  public clear(){
    this.models = [];
	  localStorage.removeItem(this.storageName);
  }

  public clearModelsByDate(from:string = null, to:string = null, property:string="start"){
      if(!this.models) return;
      this.models.forEach((model:T, index:number) => {
          if(from && to){
            if(model[property] >= from && model[property] <= to) this.models.splice(index, 1);
          } else if(from){
            if(model[property] >= from) this.models.splice(index, 1);
          } else if(to){
            if(model[property] <= to) this.models.splice(index, 1);
          }
      });
  }

  /**
   * when using resolvers to catch data of certain routes,
   * they cannot update the data,
   * thus this function will provide any component with the last observable of the requested data
   */
  public getLastCachedObservable(){
    if(this.lastSubject.isStopped) /* !this.lastSubject.value ||  */
      return of(null);
    return this.lastSubject.asObservable().pipe(takeLast(1));
  }

  public getAll(){
      return this.models;
  }

  /**
   * Returns cached data and performs the http request and returns the result
   * @param data cached data
   * @param request http request observable
   */
  public cachedRequest(data:any, request:Observable<any>, clearData:ClearData = null){
    // Request Result
    request = request.pipe(tap(res => {
      if(!res) return;
      if(res instanceof Array)
        this.updateModels(res, clearData);
      else
        this.updateModel(res);
    }));

    // Handle Cached Data
    let subject = new BehaviorSubject(data ? data : null);
    this.lastSubject = subject;

    // initiate request and update subject with new data, but also cache results
    request.subscribe(res => {
      subject.next(res);
      subject.complete();
    }, e => {
        if(e.name == 'HttpErrorResponse' && (
            (e.error && e.error.text)
            || (e.error && typeof e.error === 'string' && e.error.includes("Fehler aufgetreten"))
            || (e && typeof e === 'string' && e.includes("Fehler aufgetreten"))
        )){
    			let error_string = e.error && e.error.text ? e.error.text : (e.error ? e.error : 'Unbekannter Fehler');
    			error_string = error_string.replace(/<\/?[^>]+(>|$)/g, "");

    			if(this.lastError != error_string || !this.lastErrorTime || Date.now() - this.lastErrorTime.getTime() > 5000){
    				alert(error_string);
    			}

    			this.lastError = error_string;
    			this.lastErrorTime = new Date();
    		}
        subject.error(e);
    });


    return subject.asObservable();
  }

  protected cachedPost(url:string, data:any):Observable<T>{
    return this.http.post<T>(url, data).pipe(tap(res => this.updateModel(res)));
  }

  protected cachedPatch(url:string, data:any):Observable<T>{
    console.log('patch');
    return this.http.patch<T>(url, data).pipe(tap(res => this.updateModel(res)));
  }

  /**
   * finds model of cached data
   * @param orga
   * @param id
   */
  public findModelByOrganizationAndId(orga, id){
    if(!this.models) return;
    return this.models.find(val => {
      return this.fetchFromObject(val, this.id) == id && val['organization'] == orga;
    });
  }

  /**
   * filters models of cached data
   * @param from
   * @param to
   * @returns array
   */
  public filterModelsByDate(from:string = null, to:string = null){
    if(!this.models) return [];
    return super.filterListByDate(this.models, from, to);
  }

  /**
   * updates/inserts model in cache
   * @param model
   */
  public updateModel(model:T){
    if(!this.models) this.models = [];
    this.models = this.updateListItem(this.models, model, this.id, this.orga);

    this.updateStorage();
  }

  /**
   * Deletes model from cache
   * @param model
   */
  protected deleteModel(model:T) {
    this.models = this.deleteListItem(this.models, model, this.id, this.orga);
    this.updateStorage();
  }

  /**
   * Deletes multiple models at once from cache
   * @param models
   */
  protected deleteModels(models:T[]) {
    this.models = models.reduce((list, model) => this.deleteListItem(list, model, this.id, this.orga), this.models);
    this.updateStorage();
  }

  private updateStorage() {
    try{
      localStorage.setItem(this.storageName, JSON.stringify(this.models));
    } catch(e){
      console.error(e);
      this.cleanCache();
    }
  }

  /**
   * updates/inserts models in cache
   * @param arr
   * @return models array
   */
  protected updateModels(arr:T[], clearData:ClearData = null):T[] {
    // Clear specific entries
    if(clearData && this.models){
      if(clearData.type == 'period')
        this.clearModelsByDate(clearData.data['from'], clearData.data['to'], clearData.data['property']);
      else
        this.clear();

      // Update Models List
      this.models = this.updateList(this.models, arr, this.id, this.orga);

    // Replace Models List
    } else {
      this.models = arr;
    }

    this.updateStorage();
    return arr;
  }

  protected cleanCache(){
    console.debug('cleaning cache...');
    localStorage.setItem('rosters', '[]');
    localStorage.setItem('projects', '[]');
    localStorage.setItem('missionProcessesConfirmations', '[]');
    localStorage.setItem('vacationRequests', '[]');
    localStorage.setItem('dispositions', '[]');
    localStorage.setItem('tours', '[]');
    localStorage.setItem('workSchedules', '[]');
    localStorage.setItem('jobs', '[]');
    localStorage.setItem('taskAssigns', '[]');
    document.location.reload();
  }
}
