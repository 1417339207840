import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../core/services/account.service';

@Component({
  templateUrl: './password-reset1.component.html',
})
export class PasswordReset1Component implements OnInit {

  form: FormGroup;
  error:string;
  success:boolean;
  formSubmitted:boolean = false;

  constructor(
    private fb: FormBuilder,
    private acc: AccountService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  onSubmit(){
    if(this.form.valid && !this.formSubmitted){
      this.formSubmitted = true;
      this.acc.resetPassword(this.form.value.email)
        .subscribe(res => {
          this.success = true;
          this.formSubmitted = false;
        }, res => {
          this.error = res.error.text;
          this.formSubmitted = false;
        })
    }
  }

}
