var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { DateFilter, SelectFilter } from 'src/app/shared/filters/shared/classes';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { ListGroupService } from '../../core/services/list-group.service';
import { TaskAssignService } from '../shared/task-assign.service';
import { faGavel, faTruckLoading, faCalendarTimes, faCheck, faCheckSquare, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
var TaskAssignListComponent = /** @class */ (function (_super) {
    __extends(TaskAssignListComponent, _super);
    function TaskAssignListComponent(route, ts, listGroupService, router, scrollLocations, locale) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.ts = ts;
        _this.listGroupService = listGroupService;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.locale = locale;
        _this.loadError = false;
        _this.taskAssigns = [];
        _this.filterInputs = {
            date_from: new DateFilter({
                label: 'Ab',
                value: new Date()
            }),
            state: new SelectFilter({
                label: 'Status',
                options: [
                    { value: 'all', label: 'Alle' },
                    { value: 'incomplete', label: 'Nicht Erledigt' },
                    { value: 'completed', label: 'Erledigt' }
                ],
                value: 'all',
                storable: true
            })
        };
        _this.faCheck = faCheck;
        _this.faCalendarTimes = faCalendarTimes;
        _this.faExclamation = faExclamation;
        _this.icons = {
            'task': faSquare,
            'activity': faGavel,
            'tour_before': faTruckLoading,
            'tour_after': faTruckLoading
        };
        _this.listGroups = _this.listGroupService.getGroups();
        return _this;
    }
    TaskAssignListComponent.prototype.reload = function () {
        this.filterComponent && this.filterComponent.reload();
    };
    TaskAssignListComponent.prototype.init = function () {
        // this.getEntries();
    };
    TaskAssignListComponent.prototype.getEntries = function (options) {
        var _this = this;
        this.ts.getTaskAssigns(options).subscribe(function (res) {
            if (!res)
                return;
            // Check if data has changed
            if (JSON.stringify(_this.taskAssigns) == JSON.stringify(res))
                return;
            _this.listGroups = _this.listGroupService.getGroups(res, 'start', 'end');
            _this.taskAssigns = res;
            // Scroll to "today"
            if (!document.querySelector('.filter_list').scrollTop && !_this.scrollLocations['positions'][_this.router['url']]) {
                setTimeout(function () {
                    // Get Position of "Today" Chapter
                    var group_past = document.querySelector('.group-past');
                    if (group_past)
                        document.querySelector('.filter_list').scrollTo(0, group_past.clientHeight);
                });
            }
        }, function (res) { _this.loadError = true; });
    };
    TaskAssignListComponent.prototype.icon = function (t) {
        if (t.type == 'task' && t.completed)
            return faCheckSquare;
        else
            return this.icons[t.type];
    };
    TaskAssignListComponent.prototype.routeTaskAssign = function (t) {
        if (t.type == 'task') {
            this.router.navigate(['tasks', t.organization, t.task]);
        }
        else {
            this.router.navigate(['task-assigns', t.organization, t.id]);
        }
    };
    return TaskAssignListComponent;
}(ComponentInit));
export { TaskAssignListComponent };
