import { Component } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import {
  faHome,
  faCheck,
  faTruck,
  faRoad,
  faLocationArrow,
  faUser,
  faUsers,
  faMapMarkerAlt,
  faBriefcase,
  faCar,
  faBed,
  faMale,
} from '@fortawesome/free-solid-svg-icons';

import {
  faClock,
  faCalendarAlt,
  faBuilding,
} from '@fortawesome/free-regular-svg-icons';

import { ActivatedRoute, Router } from '@angular/router';
import { Tour } from '../shared/tour.model';
import { JobDetails } from '../../jobs/shared/job.model';
import { TourService } from '../shared/tour.service';
import { JobService } from '../../jobs/shared/job.service';
import { OrganizationService } from '../../core/services/organization.service';

import { ScrollLocations } from '../../core/globals/scroll-locations';

@Component({
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.css']
})
export class TourDetailComponent extends ComponentInit {
  faHome = faHome;
  faCheck = faCheck;
  faClock = faClock;
  faTruck = faTruck;
  faRoad = faRoad;
  faLocationArrow = faLocationArrow;
  faCalendarAlt = faCalendarAlt;
  faUser = faUser;
  faUsers = faUsers;
  faMapMarkerAlt = faMapMarkerAlt;
  faBriefcase = faBriefcase;
  faCar = faCar;
  faBed = faBed;
  faBuilding = faBuilding;
  faMale = faMale;

  tour:Tour;
  mapsLink:string;

  falseVar = false;

  fileCategories: any[] = [];

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private ts: TourService,
    private js: JobService,
    private os: OrganizationService,
    protected scrollLocations: ScrollLocations
) { super(router, scrollLocations, route); }

  init() {
    this.route.data.subscribe(data => {
      if(data.resolved.model)
          this.update(data.resolved.model);
      data.resolved.updates.subscribe(tour => {
        if(tour) this.update(tour);
      });
    });
  }

  update(tour){
    this.tour = tour;
    this.generateMapsLink();

    // group files by category
    if(tour.files){
        this.fileCategories = [];
        tour.files.forEach(file => {
            let category = this.fileCategories.find(val => val.id == (file.cat ? file.cat.id : 0));
            if(!category){
                this.fileCategories.push({
                    id: file.cat ? file.cat.id : 0,
                    title: file.cat ? file.cat.title : 'Ohne Kategorie',
                    files: [file]
                });
            } else {
                category.files.push(file);
            }
        });
    }
  }

  generateMapsLink(){
    let s = 'https://www.google.com/maps/dir';
    let last = null;

    // add start address
    if(this.tour.address_start){
        s += '/'+encodeURIComponent(this.tour.address_start.text);
        last = this.tour.address_start.text;
    }
    // add jobs
    for(let j in this.tour.jobs){
      if(this.tour.jobs[j].address){
          if(this.tour.jobs[j].address.text != last){
            s += '/'+encodeURIComponent(this.tour.jobs[j].address.text);
            last = this.tour.jobs[j].address.text;
          }
      }
    }
    // add end address
    if(this.tour.address_end && this.tour.address_end.text != last)
        s += '/'+encodeURIComponent(this.tour.address_end.text);

    this.mapsLink = s;
  }

  getMapsLinkByAddressText(text){
      return text ? 'https://maps.google.com/?daddr='+encodeURIComponent(text) : '';
  }

  onDoneToggle(e){
    this.ts.setCompleted(this.tour.organization, this.tour.id, e.checked).subscribe(res => {
      if(res){
        //set completed flag to all jobs
        for(let key in this.tour.jobs)
          this.tour.jobs[key].tour_completed = e.checked;

        //success
        this.tour.completed = e.checked;
      } else {
        e.source.checked = !e.source.checked;
      }
    }, err => {
      e.source.checked = !e.source.checked;
    });
  }

  routeJob(j:JobDetails){
    this.router.navigate([`/jobs/${this.tour.organization}/${j.id}`], { queryParams: {tour_id: j.tour} });
  }
}
