import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { faCalendarCheck, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-roster-availability-info',
  templateUrl: './roster-availability-info.component.html',
  styleUrls: ['./roster-availability-info.component.css']
})
export class RosterAvailabilityInfoComponent implements OnInit {
  faCalendarCheck = faCalendarCheck;
  faPlus = faPlus;
  faPlusCircle = faPlusCircle;

  constructor(
    public dialog: MatDialogRef<RosterAvailabilityInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}

  ngOnInit() {


  }
}
