import { ResponsiveState, ResponsiveSizeInfoRx } from 'ngx-responsive';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-responsive";
/**
 * This service provides features from the ngx-responsive library and exposes easy to handle observables.
 */
var DeviceService = /** @class */ (function () {
    function DeviceService(state, responsive) {
        var _this = this;
        this.state = state;
        this.responsive = responsive;
        this.smallScreen = new BehaviorSubject(false);
        this.responsive.connect();
        // subscribe for screen size changes
        this.responsive.getResponsiveSize.subscribe(function (data) {
            if (data) {
                switch (data) {
                    case 'xs':
                        _this.smallScreen.next(true);
                        break;
                    default:
                        _this.smallScreen.next(false);
                }
            }
        });
    }
    /**
     * returns an observable that is either true if the screen size is small or false otherwise
     */
    DeviceService.prototype.smallScreenObserver = function () {
        return this.smallScreen.asObservable();
    };
    /**
     * returns a boolean that indicates if the device is a mobile device
     */
    DeviceService.prototype.isMobile = function () {
        return this.smallScreen.getValue();
    };
    DeviceService.ngInjectableDef = i0.defineInjectable({ factory: function DeviceService_Factory() { return new DeviceService(i0.inject(i1.ResponsiveState), i0.inject(i1.ResponsiveSizeInfoRx)); }, token: DeviceService, providedIn: "root" });
    return DeviceService;
}());
export { DeviceService };
