/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i4 from "@angular/material/divider";
import * as i5 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i6 from "@angular/material/card";
import * as i7 from "./info-list.component";
var styles_InfoListComponent = [i0.styles];
var RenderType_InfoListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoListComponent, data: {} });
export { RenderType_InfoListComponent as RenderType_InfoListComponent };
function View_InfoListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "color-text-primary"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.url + "&t=") + _co.accToken); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.title + ".") + _v.context.$implicit.extension); _ck(_v, 2, 0, currVal_1); }); }
function View_InfoListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "files"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoListComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.files; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InfoListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i3.View_MatDivider_0, i3.RenderType_MatDivider)), i1.ɵdid(1, 49152, null, 0, i4.MatDivider, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 1).vertical; var currVal_2 = !i1.ɵnov(_v, 1).vertical; var currVal_3 = i1.ɵnov(_v, 1).inset; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_InfoListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoListComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoListComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.files; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_v.context.last; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 5, 0, currVal_1); }); }
export function View_InfoListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "info_list mat-card"]], null, null, null, i5.View_MatCard_0, i5.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i6.MatCard, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InfoListComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infos; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_InfoListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-list", [], null, null, null, View_InfoListComponent_0, RenderType_InfoListComponent)), i1.ɵdid(1, 49152, null, 0, i7.InfoListComponent, [], null, null)], null, null); }
var InfoListComponentNgFactory = i1.ɵccf("app-info-list", i7.InfoListComponent, View_InfoListComponent_Host_0, { infos: "infos" }, {}, []);
export { InfoListComponentNgFactory as InfoListComponentNgFactory };
