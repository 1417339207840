var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { EventService } from '../shared/event.service';
import { OrganizationService } from '../../core/services/organization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { faMapMarker, faInfo, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck, faCalendarAlt, faBuilding, } from '@fortawesome/free-regular-svg-icons';
var EventDetailComponent = /** @class */ (function (_super) {
    __extends(EventDetailComponent, _super);
    function EventDetailComponent(route, es, os, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.es = es;
        _this.os = os;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.faCalendarCheck = faCalendarCheck;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faBuilding = faBuilding;
        _this.faMapMarker = faMapMarker;
        _this.faInfo = faInfo;
        _this.faUsers = faUsers;
        return _this;
    }
    EventDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (res) {
                if (res)
                    _this.update(res);
            });
        });
    };
    EventDetailComponent.prototype.update = function (event) {
        this.event = event;
    };
    return EventDetailComponent;
}(ComponentInit));
export { EventDetailComponent };
