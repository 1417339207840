import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from './project.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';

export interface ListOptions {
  date_from?: Date;
  date_to?: Date;
  responsible?: number;
  status?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends CachableModelService<Project>{
  fromMin:Date;
  toMax:Date;

  private filterOptions: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  listCacheModel: CachableModelService<Project>;

  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'projects');

    this.listCacheModel = new CachableModelService(http, 'projectsList');
  }

  /**
   * Creates params from ListOptions
   * @param options
   */
  private getParamsFromOptions(options:ListOptions): any {

    const params:any = {...options};

    if (options.date_from){
        if(params.date_from instanceof Date) params.date_from.setHours(0,0,0,0);
        params.date_from = this.dateToString(options.date_from);
    }
    if (options.date_to){
        if(params.date_to instanceof Date) params.date_to.setHours(23,59,59);
        params.date_to = this.dateToString(options.date_to);
    }

    return params;
  }

  /**
   * Retrieves projects of an organization.
   * Does not cache yet!
   * @param orgaId
   * @param options
   */
  getAllByOrganization(orgaId:number, options:ListOptions): Observable<Project[]> {

    return this.http.get<Project[]>(`/v1/organizations/${orgaId}/projects`, {params: this.getParamsFromOptions(options)});
  }

  getAllForCurrentAccount(options):Observable<Project[]>{
    let params = this.getParamsFromOptions(options);

    // get cached data
    let data = this.listCacheModel.getAll();

    let http = this.http.get<Project[]>('/v1/projects', {params: params});

    return this.listCacheModel.cachedRequest(data, http);
  }

  getAllByOrganizationAndStaffAndDate(orga_id:number, staff_id:any, date:Date):Observable<Object[]>{
      let date_str = this.dateToString(date, 'yyyy-MM-dd');
      return this.http.get<Project[]>(`/v1/organizations/${orga_id}/staffs/${staff_id}/projects/date/${date_str}`);
  }

  getAllByOrganizationAndStaffAndTitle(orga_id:number, staff_id:any, title:string):Observable<Object[]>{
      title = encodeURIComponent(title);
      return this.http.get<Project[]>(`/v1/organizations/${orga_id}/staffs/${staff_id}/projects/title/${title}`);
  }

  getByOrganizationAndId(orga, id):Observable<Project>{
    // Get Data
    let data = this.findModelByOrganizationAndId(orga, id);
    // If we found nothing -> get data from project list
    if(!data) data = this.listCacheModel.findModelByOrganizationAndId(orga, id);

    let http = this.http.get<Project>('/v1/organizations/'+orga+'/projects/'+id);
    return this.cachedRequest(data, http);
  }

  getFilterOptionsForCurrentAccount():Observable<any>{
    this.http.get<any>('/v1/projects/filterOptions')
      .subscribe(res => {
        this.filterOptions.next(res);
      });

    return this.filterOptions.asObservable();
  }
}
