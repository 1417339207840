<div class="button-wrapper" *ngIf="permission()">
    <button type="button" mat-raised-button
            color="primary"
            (click)="fileInput.click()"
            class="spinner-button"
            [disabled]="uploading">
        <fa-icon mat-icon [icon]="faUpload" style="margin-right: 8px;"></fa-icon>
        <span i18n>Datei hochladen</span>
        <mat-spinner diameter="20"></mat-spinner>
    </button>

    <button type="button" mat-raised-button color="primary" (click)="cameraInput.click()"
            class="spinner-button"
            [disabled]="uploading">
        <fa-icon mat-icon [icon]="faCamera" style="margin-right: 8px;"></fa-icon>
        <span i18n>Foto aufnehmen</span>
        <mat-spinner diameter="20"></mat-spinner>
    </button>
    <input hidden (change)="onFileSelected($event)" #fileInput
        type="file"
        accept="image/*,application/pdf">
    <input hidden (change)="onFileSelected($event)" #cameraInput
        type="file"
        accept="image/*,application/pdf"
        capture="environment">
</div>
