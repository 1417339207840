import { OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SwPush } from '@angular/service-worker';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter, map, switchMap, distinct } from 'rxjs/operators';
// icons
import { faBars, faHome, faShareAlt, faBriefcase, faUsers, faFile, faCog, faArrowLeft, faHandshake, faTruck, faInfo, faRocket, faClock, faCheckSquare, faPlane, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faCalendarPlus, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { DeviceService } from '../core/services/device.service';
import { NotificationService } from '../core/services/notification.service';
import { TitleService } from '../core/services/title.service';
import { AccountService } from '../core/services/account.service';
import { OrganizationService } from '../core/services/organization.service';
import { DashboardService } from '../core/services/dashboard.service';
import { ScrollLocations } from '../core/globals/scroll-locations';
import * as globals from '../core/globals/globals';
import { environment } from '../../environments/environment';
// var tabsSelected:Object = {};
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(router, activatedRoute, location, titleService, ns, swPush, device, accountService, organizationService, ds, scrollLocations) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.location = location;
        this.titleService = titleService;
        this.ns = ns;
        this.swPush = swPush;
        this.device = device;
        this.accountService = accountService;
        this.organizationService = organizationService;
        this.ds = ds;
        this.scrollLocations = scrollLocations;
        this.back = false; // renders a back button
        this.close = false; // renders a close button
        this.loading = false;
        this.showVacationRequestItem = false;
        // icons
        this.faBars = faBars;
        this.faHome = faHome;
        this.faCalendarAlt = faCalendarAlt;
        this.faCalendarPlus = faCalendarPlus;
        this.faShareAlt = faShareAlt;
        this.faBriefcase = faBriefcase;
        this.faUsers = faUsers;
        this.faFile = faFile;
        this.faCog = faCog;
        this.faArrowLeft = faArrowLeft;
        this.faHandshake = faHandshake;
        this.faTruck = faTruck;
        this.faInfo = faInfo;
        this.faRocket = faRocket;
        this.faClock = faClock;
        this.faCheckSquare = faCheckSquare;
        this.faPlane = faPlane;
        this.faTimes = faTimes;
        this.faCalendarCheck = faCalendarCheck;
        this.environment = environment;
        if (!ns.isSubscribed && !this.ns.changedSubscription()) {
            this.swPush.requestSubscription({
                serverPublicKey: this.ns.getVAPID
            }).then(function (sub) {
                // subscribe
                _this.ns.subscribe(sub).subscribe();
            })
                .catch(function (err) { return console.error("Could not subscribe to notifications", err); });
        }
        // retrieve some data
        this.accountService.getCurrentAccount().subscribe(function (res) {
            if (!res)
                return;
            _this.account = res;
        });
        this.organizationService.getAllForCurrentAccount().subscribe(function () {
            console.log('got orgas');
        });
    }
    Object.defineProperty(DashboardComponent.prototype, "isMenuToggable", {
        /**
         * Whether menu is toggable
         */
        get: function () {
            return !this.back && !this.close;
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        // get current url
        var url = this.router.routerState.snapshot.url;
        url = url.split("?")[0]; // remove query params
        // url = url.slice(1, url.length); // remove trailing '/'
        this.organizationService.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (!res)
                return;
            // check if we can show vacation requests item in menu
            _this.showVacationRequestItem = !!res.filter(function (orga) { return orga.vacation_request_active; }).length;
        });
        // subscribe for title changes
        this.ds.titleObservable.subscribe(function (title) {
            _this.title = title;
        });
        // subscribe for online changes
        this.ds.onlineObservable.subscribe(function (online) {
            _this.online = online;
        });
        // subscribe for loading changes
        this.ds.loadingObservable.subscribe(function (loading) {
            _this.loading = loading;
        });
        this.activatedRoute.firstChild.data.subscribe(function (data) {
            _this.title = data.title || "";
            _this.back = data.back || false;
            _this.close = data.close || false;
        });
        // subscribe for future route data changes
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activatedRoute; }), map(function (route) {
            while (route.firstChild)
                route = route.firstChild;
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }), switchMap(function (route) { return route.data; }), distinct())
            .subscribe(function (event) {
            // refresh organizations
            _this.organizationService.getAllForCurrentAccount();
            // toggle title if desired
            if (event['title'])
                _this.title = event['title'];
            else
                _this.title = _this.titleService.getTitle();
            // toggle nav button if desired
            if (event['back'])
                _this.back = event['back'];
            else
                _this.back = false;
        });
        // Handle tab param
        if (this.activatedRoute.snapshot.queryParams.tab)
            globals.selectedTabs[url] = this.activatedRoute.snapshot.queryParams.tab;
        this.router.events.subscribe(function (event) {
            var scrollContainer = document.querySelector('.filter_list');
            if (!scrollContainer)
                scrollContainer = document.querySelector('.mat-drawer-content');
            // Handle stuff when navigation starts
            if (event instanceof NavigationStart) {
                var url_old = _this.router.routerState.snapshot.url;
                url_old = url_old.split("?")[0]; // remove query params
                // Add scroll position to positions
                if (scrollContainer)
                    _this.scrollLocations.positions[url_old] = scrollContainer.scrollTop;
                // Add current active tab to tabsSelected
                if (document.querySelector('.mat-drawer-content mat-tab-group'))
                    globals.selectedTabs[url_old] = document.querySelector('.mat-drawer-content mat-tab-group').getAttribute('ng-reflect-selected-index');
                // Reset active tab to 0 when trigger is not "popstate"
                if (event.navigationTrigger != 'popstate') {
                    var event_url = event.url;
                    event_url = event_url.split("?")[0]; // remove query params
                    // event_url = event_url.slice(1, event_url.length); // remove trailing '/'
                    globals.selectedTabs[event_url] = 0;
                }
                // Handle stuff when navigation ends
            }
            else if (event instanceof NavigationEnd) {
                // Wait for render
                setTimeout(function () {
                    // Set Scroll Position
                    if (scrollContainer) {
                        var event_url = event.url;
                        event_url = event_url.split("?")[0]; // remove query params
                        var scrollToY = _this.scrollLocations.positions[event_url] || 0;
                        if (typeof scrollContainer.scrollTo === "function") {
                            scrollContainer.scrollTo(0, scrollToY);
                        }
                        else {
                            scrollContainer.scrollTop = scrollToY;
                        }
                    }
                }, 5);
            }
        });
    };
    /**
     * Navigates back
     */
    DashboardComponent.prototype.onBackClick = function () {
        if (window.history.length > 1)
            this.location.back();
        else
            this.router.navigateByUrl('/');
    };
    return DashboardComponent;
}());
export { DashboardComponent };
