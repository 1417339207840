<app-toolbar-actions *ngIf="availabilityOrgas && availabilityOrgas.length">
  <button mat-icon-button (click)="toggleAvailabilityMode()">
    <fa-icon [icon]="faCalendarCheck"></fa-icon>
  </button>
</app-toolbar-actions>

<div *ngIf="!isAvailabilityMode" class="header">
  <mat-checkbox [(ngModel)]="disposCheck" (change)="refreshEvents()">Dispositionen</mat-checkbox>
  <mat-checkbox [(ngModel)]="rostersCheck" (change)="refreshEvents()">Dienstplaneinträge</mat-checkbox>
</div>
<div *ngIf="isAvailabilityMode" class="header header_availability_mode">
  <div i18n>Verfügbarkeitsmodus aktiv</div>
  <div class="buttons">
    <button mat-mini-fab aria-label="Hilfe" (click)="openAvailabilityInfo()">
      <fa-icon [icon]="faQuestion"></fa-icon>
    </button>
    <button mat-mini-fab aria-label="Neu" (click)="createNewAvailability()">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
    <button mat-mini-fab aria-label="Schließen" (click)="toggleAvailabilityMode()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</div>
<div *ngIf="isAvailabilityMode && isMultiOrga" class="header">
  <ng-container *ngFor="let orga of availabilityOrgas">
    <mat-checkbox [(ngModel)]="availabilityOrgasChecks[orga.id]" (change)="orgaFilterChange()">{{ orga.title }}</mat-checkbox>
  </ng-container>
</div>

<div style="position:relative" class="calendar-wrapper" [ngClass]="isAvailabilityMode ? 'availablity_mode_on' : ''">
  <div class="calendar-header" *ngIf="events$">
    <button mat-icon-button
      mwlCalendarPreviousView
      [view]="views[tabIndex]"
      [(viewDate)]="viewDate"
      (viewDateChange)="onViewChange();fetchEvents();activeDayIsOpen = false">
      <fa-icon [icon]="faChevronLeft" ></fa-icon>
    </button>
    <div class="col-md-4 h2-wrapper">
      <h2 class="mat-h2">{{ viewDate | calendarDate:(views[tabIndex] + 'ViewTitle'):locale }}</h2>
    </div>
    <button mat-icon-button
      mwlCalendarNextView
      [view]="views[tabIndex]"
      [(viewDate)]="viewDate"
      (viewDateChange)="onViewChange();fetchEvents();activeDayIsOpen = false">
      <fa-icon [icon]="faChevronRight" ></fa-icon>
    </button>
  </div>

  <ng-template #availabilityCellTemplate let-day="day" let-locale="locale">
    <div
      class="cal-cell-top no-context-menu"
      [ngClass]="day.availability"
      [class.available]="day.available"
      [class.not-available]="day.available === false"
    >
      <span class="cal-day-number">
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
    </div>
    <div class="cal-events" *ngIf="day.events.length > 0">
        <div
          class="cal-event"
          *ngFor="let event of day.events; trackBy: trackByEventId"
          [ngStyle]="{ backgroundColor: event.color?.primary }"
          [ngClass]="event?.cssClass"
        ></div>
      </div>
  </ng-template>

  <mat-tab-group
    mat-stretch-tabs
    headerPosition="above"
    [(selectedIndex)]="tabIndex"
    (selectedIndexChange)="onTabIndexChange($event)"
    class="calendar"
    *ngIf="events$ | async; let events"
  >
    <mat-tab label="Monat" i18n-label>
      <mwl-calendar-month-view *ngIf="tabIndex == 0"
        [locale]="locale"
        [weekStartsOn]="weekStart"
        [viewDate]="viewDate"
        [events]="events"
        (eventClicked)="viewDate = $event.event.start;tabIndex = 2"
        (dayClicked)="onDayClicked($event)"
        (beforeViewRender)="beforeMonthViewRender($event)"
        [cellTemplate]="isAvailabilityMode ? availabilityCellTemplate : null"
      ></mwl-calendar-month-view>

    </mat-tab>
    <mat-tab label="Woche" i18n-label [disabled]="isAvailabilityMode">
      <mwl-calendar-week-view *ngIf="tabIndex == 1"
        precision="minutes"
        hourSegmentHeight="25"
        [locale]="locale"
        [viewDate]="viewDate"
        [events]="events"
        [weekStartsOn]="1"
        (eventClicked)="viewDate = $event.event.start;tabIndex = 2">
      </mwl-calendar-week-view>
    </mat-tab>
    <mat-tab label="Tag" i18n-label [disabled]="isAvailabilityMode">
      <mwl-calendar-day-view  *ngIf="tabIndex == 2"
        hourSegmentHeight="25"
        [locale]="locale"
        [viewDate]="viewDate"
        [events]="events"
        (eventClicked)="onEventClick($event)">
      </mwl-calendar-day-view>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-container *ngIf="isAvailabilityMode" >
  <!--sroll helper-->
  <div class="spacer" style="min-height:3.5rem"></div>

  <div class="floating-button">
    <button mat-raised-button style="margin: 0.8rem 2rem 0.7rem 2rem; flex-grow:1" i18n (click)="saveAvailabilitiesAndExit()">
      Verfügbarkeiten speichern
    </button>
  </div>
</ng-container>
