import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit {

  /*
  Token values:
  .c_id -> orga id
  .s_id -> staff id
  .s_first_name -> staff first name
  .s_last_name -> staff last name
  .s_email -> staff email
  .org -> orga name
  */
  rawToken: string;
  token: object;
  tokenError: boolean = false;
  dashboardRedirect: boolean = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    try{
      this.rawToken = this.route.snapshot.params['token'];
      this.token = jwtHelper.decodeToken(this.rawToken);
    }
    catch(e){
      this.tokenError = true;
    }
    if(this.route.snapshot.queryParams.dashboard)
      this.dashboardRedirect = true;
  }

}
