<main>
  <h2 class="mat-h2 app-list-heading">Persönliche Daten</h2>
  <mat-nav-list>
    <mat-list-item class="right-icon mat-elevation-z1" (click)="onEditNameClick()">
      <div>
        <span i18n>Name</span>
        <small matLine *ngIf="account">{{account.first_name}} {{account.last_name}}</small>
      </div>
      <fa-icon mat-list-icon [icon]="faEdit"></fa-icon>
    </mat-list-item>
  </mat-nav-list>

  <h2 class="mat-h2 app-list-heading">Anmeldung</h2>
  <mat-nav-list>
    <mat-list-item class="right-icon mat-elevation-z1" (click)="onEditEmailClick()">
      <div>
        <span i18n>E-Mail Adresse</span>
        <small matLine *ngIf="account">{{account.email}}</small>
      </div>
      <fa-icon mat-list-icon [icon]="faEdit"></fa-icon>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="right-icon mat-elevation-z1" (click)="onEditPasswordClick()">
      <span i18n>Passwort ändern</span>
      <fa-icon mat-list-icon [icon]="faEdit"></fa-icon>
    </mat-list-item>
  </mat-nav-list>
  <mat-nav-list>
    <mat-list-item class="right-icon mat-elevation-z1" (click)="onLogoutClick()">
      <div>
        Abmelden
      </div>
      <fa-icon mat-list-icon [icon]="faSignOutAlt"></fa-icon>
    </mat-list-item>
  </mat-nav-list>

  <h2 class="mat-h2 app-list-heading toolbar-heading">
    <span i18n>Verknüpfte Organisationen</span>
    <!--<button mat-icon-button matTooltip="Verbindung hinzufügen" i18n-matTooltip (click)="onAddConnectionClick()">
      <fa-icon [icon]="faPlus" size="lg"></fa-icon>
    </button>-->
  </h2>
  <mat-list *ngIf="organizations">
    <ng-template ngFor let-org [ngForOf]="organizations" let-i="index">
      <mat-divider *ngIf="i > 0"></mat-divider>
      <mat-list-item class="mat-elevation-z1">
        <span>{{org.title}}</span>
      </mat-list-item>
    </ng-template>
  </mat-list>
  <p class="text-center color-text-grey" i18n *ngIf="!organizations">Du bist mit keiner Organisation verknüpft</p>
</main>