var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { TaskService } from '../shared/task.service';
import { SubTaskService } from '../shared/sub-task.service';
import { MyErrorHandler } from '../../core/classes/my-error-handler.class';
import { faCalendarAlt, faInfo, faUsers, faRocket, faCheckSquare, faInfoCircle, faExclamation, faBriefcase, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faBuilding, faCalendarTimes, faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
var TaskDetailComponent = /** @class */ (function (_super) {
    __extends(TaskDetailComponent, _super);
    function TaskDetailComponent(route, ts, sts, router, scrollLocations, location, myErrorHandler) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.ts = ts;
        _this.sts = sts;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.location = location;
        _this.myErrorHandler = myErrorHandler;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faBuilding = faBuilding;
        _this.faInfo = faInfo;
        _this.faSquare = faSquare;
        _this.faCheckSquare = faCheckSquare;
        _this.faCalendarTimes = faCalendarTimes;
        _this.faUsers = faUsers;
        _this.faRocket = faRocket;
        _this.faInfoCircle = faInfoCircle;
        _this.faFileAlt = faFileAlt;
        _this.faExclamation = faExclamation;
        _this.faBriefcase = faBriefcase;
        _this.faCheck = faCheck;
        _this.subTaskGroups = [];
        _this.disableCompleteButton = false;
        _this.fileCategories = [];
        _this.fileUploading = false;
        return _this;
    }
    TaskDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (res) {
                if (res)
                    _this.update(res);
            });
        });
    };
    TaskDetailComponent.prototype.update = function (task) {
        var _this = this;
        this.task = task;
        // build sub task groups
        if (task.sub_tasks) {
            this.subTaskGroups = [
                // editable
                this.task.sub_tasks.filter(function (s) { return s.editable; }),
                // not editable
                this.task.sub_tasks.filter(function (s) { return !s.editable; }),
            ].filter(function (a) { return a.length; });
        }
        // group files by category
        if (task.files) {
            this.fileCategories = [];
            task.files.forEach(function (file) {
                var category = _this.fileCategories.find(function (val) { return val.id == (file.cat ? file.cat.id : 0); });
                if (!category) {
                    _this.fileCategories.push({
                        id: file.cat ? file.cat.id : 0,
                        title: file.cat ? file.cat.title : 'Ohne Kategorie',
                        files: [file]
                    });
                }
                else {
                    category.files.push(file);
                }
            });
        }
    };
    TaskDetailComponent.prototype.deadlineDateFormat = function () {
        var format = 'dd.MM.yy';
        if (this.task.deadline) {
            var date = createDateFromDatetime(this.task.deadline);
            if (date.getFullYear() == (new Date()).getFullYear())
                format = 'dd.MM.';
        }
        return format;
    };
    TaskDetailComponent.prototype.routeProject = function (project) {
        this.router.navigate(['/projects/' + this.task.organization + '/' + project.id]);
    };
    TaskDetailComponent.prototype.routeJob = function (job) {
        this.router.navigate(['/jobs/' + this.task.organization + '/' + job.id]);
    };
    TaskDetailComponent.prototype.completeButton = function () {
        var _this = this;
        this.disableCompleteButton = true;
        this.complete(function () {
            _this.location.back();
        });
    };
    TaskDetailComponent.prototype.complete = function (callback) {
        var _this = this;
        if (!callback)
            this.task.completed = 1;
        // Check if some sub tasks are not completed yet
        if (!this.task.sub_tasks || !this.task.sub_tasks.filter(function (s) { return !s.completed; }).length
            || window.confirm('Es wurden noch nicht alle Punkte von der Checkliste abgehakt, möchtest du trotzdem diese Aufgabe als erledigt markieren?')) {
            return this.ts.complete(this.task).subscribe(function () {
                _this.disableCompleteButton = false;
                _this.task.completed = 1;
                if (callback) {
                    callback();
                }
            }, function (err) {
                _this.task.completed = 0;
                _this.disableCompleteButton = false;
                _this.myErrorHandler.showError(err);
            });
        }
        else {
            this.disableCompleteButton = false;
        }
    };
    TaskDetailComponent.prototype.incomplete = function () {
        var _this = this;
        this.task.completed = 0;
        return this.ts.incomplete(this.task).subscribe(function () {
            _this.task.completed = 0;
        }, function (err) {
            _this.task.completed = 1;
            _this.myErrorHandler.showError(err);
        });
    };
    TaskDetailComponent.prototype.completeToggle = function () {
        if (this.task.completed)
            this.incomplete();
        else
            this.complete();
    };
    TaskDetailComponent.prototype.subTaskCompleteToggle = function (event) {
        var _this = this;
        var sub_task = this.task.sub_tasks.find(function (s) { return s.id == event.option.value; });
        var request;
        if (event.option.selected) {
            request = this.sts.complete(sub_task);
        }
        else {
            request = this.sts.incomplete(sub_task);
        }
        return request.subscribe(function (res) {
            // callback success
            _this.task.sub_tasks = _this.task.sub_tasks.map(function (s) { return s.id == sub_task.id ? res : s; });
        }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    TaskDetailComponent.prototype.onFileAdded = function () {
        this.reloadData();
        document.querySelector('.mat-tab-body-active main').scrollTo(0, 0);
    };
    return TaskDetailComponent;
}(ComponentInit));
export { TaskDetailComponent };
