/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "./settings-nav-item/settings-nav-item.component.ngfactory";
import * as i5 from "@angular/router";
import * as i6 from "./settings-nav-item/settings-nav-item.component";
import * as i7 from "../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i8 from "@angular/material/divider";
import * as i9 from "./settings.component";
import * as i10 from "../core/services/update.service";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i1.ɵdid(2, 704512, null, 0, i3.MatNavList, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "app-settings-nav-item", [["routerLink", "/settings/account"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SettingsNavItemComponent_0, i4.RenderType_SettingsNavItemComponent)), i1.ɵdid(4, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 49152, null, 0, i6.SettingsNavItemComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Account"])), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i7.View_MatDivider_0, i7.RenderType_MatDivider)), i1.ɵdid(8, 49152, null, 0, i8.MatDivider, [], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "app-settings-nav-item", [["routerLink", "/settings/notifications"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SettingsNavItemComponent_0, i4.RenderType_SettingsNavItemComponent)), i1.ɵdid(10, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(11, 49152, null, 0, i6.SettingsNavItemComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Benachrichtigungen"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i1.ɵdid(14, 704512, null, 0, i3.MatNavList, [], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 3, "app-settings-nav-item", [["routerLink", "/settings/calendar-subscribe"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SettingsNavItemComponent_0, i4.RenderType_SettingsNavItemComponent)), i1.ɵdid(16, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(17, 49152, null, 0, i6.SettingsNavItemComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Kalender abonnieren (iCal)"])), (_l()(), i1.ɵeld(19, 0, null, null, 15, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i1.ɵdid(20, 704512, null, 0, i3.MatNavList, [], null, null), (_l()(), i1.ɵeld(21, 0, null, 0, 5, "mat-list-item", [["class", "mat-elevation-z1 mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadApp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(22, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["App neu laden"])), (_l()(), i1.ɵeld(27, 0, null, 0, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i7.View_MatDivider_0, i7.RenderType_MatDivider)), i1.ɵdid(28, 49152, null, 0, i8.MatDivider, [], null, null), (_l()(), i1.ɵeld(29, 0, null, 0, 5, "mat-list-item", [["class", "mat-elevation-z1 mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cleanCache() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(30, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 4, { _lines: 1 }), i1.ɵqud(335544320, 5, { _avatar: 0 }), i1.ɵqud(335544320, 6, { _icon: 0 }), (_l()(), i1.ɵted(34, 2, ["Cache s\u00E4ubern (", ")"]))], function (_ck, _v) { var currVal_0 = "/settings/account"; _ck(_v, 4, 0, currVal_0); var currVal_5 = "/settings/notifications"; _ck(_v, 10, 0, currVal_5); var currVal_6 = "/settings/calendar-subscribe"; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (i1.ɵnov(_v, 8).vertical ? "vertical" : "horizontal"); var currVal_2 = i1.ɵnov(_v, 8).vertical; var currVal_3 = !i1.ɵnov(_v, 8).vertical; var currVal_4 = i1.ɵnov(_v, 8).inset; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = (i1.ɵnov(_v, 22)._avatar || i1.ɵnov(_v, 22)._icon); var currVal_8 = (i1.ɵnov(_v, 22)._avatar || i1.ɵnov(_v, 22)._icon); _ck(_v, 21, 0, currVal_7, currVal_8); var currVal_9 = (i1.ɵnov(_v, 28).vertical ? "vertical" : "horizontal"); var currVal_10 = i1.ɵnov(_v, 28).vertical; var currVal_11 = !i1.ɵnov(_v, 28).vertical; var currVal_12 = i1.ɵnov(_v, 28).inset; _ck(_v, 27, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = (i1.ɵnov(_v, 30)._avatar || i1.ɵnov(_v, 30)._icon); var currVal_14 = (i1.ɵnov(_v, 30)._avatar || i1.ɵnov(_v, 30)._icon); _ck(_v, 29, 0, currVal_13, currVal_14); var currVal_15 = _co.localStorageSize; _ck(_v, 34, 0, currVal_15); }); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i9.SettingsComponent, [i10.UpdateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsComponentNgFactory = i1.ɵccf("ng-component", i9.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
