<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>Lupax-Konto hinzufügen</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
  <div mat-dialog-content>
    <p i18n>
      Melde dich mit deinem Lupax-Konto an, um alles an einem Ort zu sehen.
    </p>
    <mat-form-field>
      <input matInput type="email" placeholder="E-Mail Adresse" i18n-placeholder formControlName="email" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Passwort" i18n-placeholder formControlName="password" required>
    </mat-form-field>
  </div>
  <mat-dialog-actions>
    <button mat-button i18n type="button" (click)="dialog.close()">
      Abbrechen
    </button>
    <button mat-raised-button color="primary" i18n type="submit">
      Hinzufügen
    </button>
  </mat-dialog-actions>
</form>