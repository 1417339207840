import { Component, OnInit } from '@angular/core';

import { AccountService } from '../../core/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {

  activated = false;
  requesting = true;
  countdown = 5;
  error;

  constructor(
    private acc: AccountService,
    private router: Router, 
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    let token = this.route.snapshot.params['token'];
    this.acc.activate(token)
      .subscribe(res => {
        this.activated = true;
        this.requesting = false;

        // countdown redirect time;
        let ival = setInterval(()=>{
          this.countdown--;
          if(this.countdown <= 0){
            clearInterval(ival);
            this.router.navigate(['/auth/login']);
          }
        }, 1000);
      }, res => {
        this.requesting = false;
        this.error = res.error.text;
      });
  }

}
