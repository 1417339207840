import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';

import { environment } from '../../../environments/environment';

// Error Handler
@Injectable({
  providedIn: 'root',
})
export class MyErrorHandler implements ErrorHandler {
	lastError:string;
	lastErrorTime:Date;

	constructor(
        protected http: HttpClient,
		protected dialog: MatDialog
    ){}

	handleError(error: any) {
		console.error(error);

		// Error with JSON parse -> clear cache (local storage) and reload
		if(error && error.name == 'SyntaxError' && error.message.includes('Unexpected token') && error.message.includes('JSON')){
			for(let key in localStorage){
				if(key != 'accessToken') localStorage.removeItem(key);
			}
			console.log(Object.keys(localStorage).length);
			if(document.location.hash != "#error-reload"){
				document.location.hash = "#error-reload";
				document.location.reload();
			}
		}
		if(error.name == 'HttpErrorResponse' && ((error.error && error.error.text) || error.includes("Fehler aufgetreten"))){
			let error_string = error.error ? error.error.text.replace(/<\/?[^>]+(>|$)/g, "") : '';

			if(this.lastError != error_string || !this.lastErrorTime || Date.now() - this.lastErrorTime.getTime() > 5000){
				alert(error_string);
			}

			this.lastError = error_string;
			this.lastErrorTime = new Date();
		}

		// Log Error on Server
		if(error.name != 'HttpErrorResponse' && (!this.lastErrorTime || Date.now() - this.lastErrorTime.getTime() > 5000)){
			this.logError(error);
		}
		throw(error);
	}

	logError(error: any, show_alert: boolean = true){
		let payload = {
			app_version: environment.version,
			error_name: error.name,
			error_text: error.message || error.toString(),
			error_content: error.stack + "\n" + window.navigator.vendor + "\n" + window.navigator.userAgent
		}
		this.http.post('/v1/error-log', payload).subscribe(
			() => {
				console.log('error last', this.lastErrorTime, this.lastErrorTime ? Date.now() - this.lastErrorTime.getTime() : null);
				if(show_alert && (!this.lastErrorTime || Date.now() - this.lastErrorTime.getTime() > 5000)){
					this.lastErrorTime = new Date();
					alert('Ein unerwarteter Fehler ist aufgetreten. Der Fehler wurde automatisch an Lupax weitergeleitet. Sollte der Fehler häufiger auftreten wenden Sie sich bitte an den Lupax-Support.');
				}
			},
			() => {
				if(show_alert && (!this.lastErrorTime || Date.now() - this.lastErrorTime.getTime() > 5000)){
					this.lastErrorTime = new Date();
					alert('Ein unerwarteter Fehler ist aufgetreten. Der Fehler konnte NICHT an Lupax weitergeleitet werden. Bitte wenden Sie sich an den Lupax-Support.');
				}
			}
		);
	}

	showError(err){
		if(err.error && err.error.text){
			this.dialog.open(OkDialogComponent, {data: err.error.text.replace(/<\/?[^>]+(>|$)/g, "") })
		} else {
			this.dialog.open(OkDialogComponent, {data: 'Unbekannter Fehler' });
			this.logError(err, false);
		}

	}
}
