import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ActivateComponent } from './activate/activate.component';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { ActivateResendComponent } from './activate-resend/activate-resend.component';
import { PasswordReset1Component } from './password-reset1/password-reset1.component';
import { PasswordReset2Component } from './password-reset2/password-reset2.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
		LoginComponent,
    RegisterComponent,
    ActivateComponent,
    ActivateResendComponent,
    PasswordReset1Component,
    PasswordReset2Component,
  ],
  providers: [
    AuthService,
    AuthGuard,
  ]
})
export class AuthModule { }
