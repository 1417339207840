var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DispositionService = /** @class */ (function (_super) {
    __extends(DispositionService, _super);
    function DispositionService(http) {
        var _this = _super.call(this, http, 'dispositions', true, 'data.id') || this;
        _this.http = http;
        return _this;
    }
    /**
     * Returns dispositions for current account
     * @param options
     */
    DispositionService.prototype.getAllForCurrentAccount = function (options) {
        var fromString = null, toString = null;
        var params = {};
        // date_from
        if (options.date_from) {
            var from = new Date(options.date_from);
            from.setHours(0, 0, 0, 0);
            fromString = this.dateToString(from);
            params['date_from'] = fromString;
        }
        // date_to
        if (options.date_to) {
            toString = this.dateToString(options.date_to);
            params['date_to'] = toString;
        }
        // get cached data
        var data = this.filterModelsByDate(fromString, toString);
        // types
        if (options.types && options.types.length) {
            data = data.filter(function (dispo) { return options.types.includes(dispo.type); });
            params['types[]'] = options.types;
        }
        // organizations
        if (options.organizations && options.organizations.length) {
            data = data.filter(function (dispo) { return options.organizations.includes(dispo.organization); });
            params['organizations[]'] = options.organizations;
        }
        // HTTP REQUEST
        var http = this.http.get('/v1/dispositions', { params: params });
        // build clear data
        var clearData = {
            type: 'period',
            data: { from: fromString, to: toString }
        };
        return this.cachedRequest(data, http, clearData);
    };
    DispositionService.prototype.getAllForOrganizationAndStaff = function () {
    };
    DispositionService.ngInjectableDef = i0.defineInjectable({ factory: function DispositionService_Factory() { return new DispositionService(i0.inject(i1.HttpClient)); }, token: DispositionService, providedIn: "root" });
    return DispositionService;
}(CachableModelService));
export { DispositionService };
