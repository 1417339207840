var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OrganizationService = /** @class */ (function (_super) {
    __extends(OrganizationService, _super);
    function OrganizationService(http) {
        var _this = _super.call(this, http, 'organizations', false) || this;
        _this.http = http;
        _this.organizationsOfAcc = new BehaviorSubject(JSON.parse(localStorage.getItem('organizationsOfAcc')));
        return _this;
    }
    OrganizationService.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.organizationsOfAcc.next([]);
        localStorage.removeItem('organizationsOfAcc');
    };
    OrganizationService.prototype.getAllForCurrentAccount = function () {
        var _this = this;
        var http = this.http.get('/v1/organizations')
            .pipe(tap(function (res) {
            _this.updateModels(res);
            _this.organizationsOfAcc.next(res);
            localStorage.setItem('organizationsOfAcc', JSON.stringify(res));
        }));
        // check if data is available
        if (localStorage.getItem('organizationsOfAcc')) {
            // if so, return cached data and make a request for possible changed in the background
            http.subscribe();
            return this.organizationsOfAcc.asObservable();
        }
        else
            // otherwise just return the request
            return http;
    };
    OrganizationService.prototype.getById = function (id) {
        var orga = this.models.find(function (val) { return val.id == id; });
        if (orga)
            return of(orga);
        return this.http.get('/v1/organizations/' + id);
    };
    OrganizationService.prototype.getOrganizationsOfCurrentAccountObservable = function () {
        return this.organizationsOfAcc.asObservable();
    };
    Object.defineProperty(OrganizationService.prototype, "organizationsOfCurrentAccount", {
        /**
         * Returns orgas of account.
         * Does not sync with server.
         */
        get: function () {
            var orgas = this.organizationsOfAcc.getValue();
            if (!orgas)
                return [];
            return orgas;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Converts organizations to select options
     * @param orgas
     * @param addEmptyOption
     */
    OrganizationService.prototype.organizationsToOptions = function (orgas, addEmptyOption) {
        if (addEmptyOption === void 0) { addEmptyOption = true; }
        var options = orgas.map(function (orga) {
            return { value: orga.id, label: orga.title };
        });
        if (addEmptyOption) {
            options.unshift({
                value: null,
                label: 'Alle'
            });
        }
        return options;
    };
    OrganizationService.ngInjectableDef = i0.defineInjectable({ factory: function OrganizationService_Factory() { return new OrganizationService(i0.inject(i1.HttpClient)); }, token: OrganizationService, providedIn: "root" });
    return OrganizationService;
}(CachableModelService));
export { OrganizationService };
