import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { VacationRequestListComponent } from './vacation-request-list/vacation-request-list.component';
import { VacationRequestDetailComponent } from './vacation-request-detail/vacation-request-detail.component';
import { VacationRequestNewComponent } from './vacation-request-new/vacation-request-new.component';
import { VacationRequestService } from './shared/vacation-request.service';
import { VacationRequestResolver } from './shared/vacation-request.resolver'


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    VacationRequestListComponent,
	  VacationRequestDetailComponent,
    VacationRequestNewComponent
  ],
  providers: [
    VacationRequestService,
    VacationRequestResolver
  ]
})
export class VacationRequestsModule { }
