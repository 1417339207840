import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../core/services/account.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  templateUrl: './activate-resend.component.html',
  styleUrls: ['./activate-resend.component.css']
})
export class ActivateResendComponent implements OnInit {

  form: FormGroup;
  success: boolean;
  error: string;
  formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    private acc: AccountService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  onSubmit(){
    if(this.form.valid){
      this.formSubmitAttempt = true;
      this.acc.resendActivationToken(this.form.value.email)
        .subscribe(res => {
          this.formSubmitAttempt = false;
          this.success = true;
        }, res => {
          this.error = res.error.text;
          this.formSubmitAttempt = false;
        })
    }
  }

}
