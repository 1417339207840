  <ng-container *ngIf="job">
    <mat-tab-group mat-stretch-tabs headerPosition="above" class="tabs-with-icon"
        [selectedIndex]="tabSelected" (selectedIndexChange)="tabSelected = $event">
    	<mat-tab>
        <ng-template mat-tab-label>
          <fa-icon mat-list-icon [icon]="faInfoCircle"></fa-icon> <span i18n class="label-text">Allgemein</span>
        </ng-template>
        <main>
          <!-- Informations -->
          <h2 class="mat-h2 app-list-heading" i18n>Informationen</h2>
          <mat-list>
            <mat-list-item class="show-all">
                <fa-icon mat-list-icon [icon]="faFlag"></fa-icon>
                <span mat-line>
                  {{job.type.title}}&nbsp;-&nbsp;{{job.title}}
                </span>
              </mat-list-item>
              <mat-divider></mat-divider>
            <mat-list-item *ngIf="job.company">
                <fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
                <span mat-line>{{job.company.title}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
                <span mat-line>
                    <app-date-span [start]="job.dispo_start" [end]="job.dispo_end"></app-date-span>&nbsp;
                    <small *ngIf="job.date_start || job.date_end">({{job.date_start | date:'shortTime'}} - {{job.date_end | date:'shortTime'}})</small>
                </span>
            </mat-list-item>

            <mat-divider *ngIf="job.project && job.project.client && job.project.client.name"></mat-divider>
            <mat-list-item *ngIf="job.project && job.project.client && job.project.client.name" class="show-all">
              <fa-icon mat-list-icon [icon]="faMale"></fa-icon>
              <span mat-line>
                {{job.project.client.name}}
              </span>
            </mat-list-item>

            <mat-divider *ngIf="job.responsible || (job.responsible_extra && job.responsible_extra.length > 0)"></mat-divider>
            <mat-list-item class="show-all" *ngIf="job.responsible || (job.responsible_extra && job.responsible_extra.length > 0)">
              <fa-icon mat-list-icon [icon]="faUser"></fa-icon>
              <div mat-line>
                <ng-container *ngIf="job.responsible">
                  <span>{{job.responsible.first_name}} {{job.responsible.last_name}}</span>&nbsp;
                  <a *ngIf="job.responsible.firm_mobile"
                      class="color-text-primary"
                      style="text-decoration: none"
                      href="tel:{{job.responsible.firm_mobile}}">
                    {{job.responsible.firm_mobile}}
                  </a>
                  <span *ngIf="job.responsible.firm_mobile && job.responsible.firm_tel"> | </span>
                  <a *ngIf="job.responsible.firm_tel"
                      class="color-text-primary"
                      style="text-decoration: none"
                      href="tel:{{job.responsible.firm_tel}}">
                    {{job.responsible.firm_tel}}
                  </a>
                </ng-container>
                <div *ngIf="job.responsible_extra && job.responsible_extra.length > 0" style="margin-top: 5px;">
                    <ng-container *ngFor="let r of job.responsible_extra; first as isFirst;">
                        <br *ngIf="!isFirst"/>
                        <small>
                            <span>{{r.first_name}} {{r.last_name}}</span>&nbsp;
                            <a *ngIf="r.firm_mobile"
                            class="color-text-primary"
                            style="text-decoration: none"
                            href="tel:{{r.firm_mobile}}">
                                {{r.firm_mobile}}
                            </a>
                            <span *ngIf="r.firm_mobile && r.firm_tel"> | </span>
                            <a *ngIf="r.firm_tel"
                            class="color-text-primary"
                            style="text-decoration: none"
                            href="tel:{{r.firm_tel}}">
                                {{r.firm_tel}}
                            </a>
                        </small>
                    </ng-container>
                </div>
              </div>
            </mat-list-item>

            <!-- Description -->
            <ng-container *ngIf="job.description">
              <mat-divider></mat-divider>
              <mat-list-item *ngIf="job.description" class="no-select show-all" disableRipple="true">
                <fa-icon mat-list-icon [icon]="faInfo"></fa-icon>
                <div mat-line [innerHTML]="job.description"></div>
              </mat-list-item>
            </ng-container>

            <!-- MP Confirmation -->
            <ng-container *ngIf="job.mission_confirmation">
              <mat-divider></mat-divider>
              <mat-list-item class="no-select" disableRipple="true">
                <fa-icon mat-list-icon [icon]="faHandshake"></fa-icon>
                <span mat-line>
                  <span i18n>Auftragsbestätigung</span>:&nbsp;
                  <a class="color-text-primary"
                      style="text-decoration: none; cursor: pointer;"
                      (click)="routeMissionProcess(job.mission_confirmation)">
                    {{job.mission_confirmation.title}}
                  </a>
                </span>
              </mat-list-item>
            </ng-container>

          </mat-list>

          <!-- Address -->
          <h2 class="mat-h2 app-list-heading" *ngIf="job.address || job.address_note || job.address_contact_person || job.address_contact_phone" i18n>Adresse</h2>
          <mat-list>
            <mat-list-item *ngIf="job.client && job.client.name" class="show-all">
              <fa-icon mat-list-icon [icon]="faThumbtack"></fa-icon>
              <span mat-line>
                {{job.client.name}}
              </span>
            </mat-list-item>
            <mat-divider *ngIf="job.client && job.client.name"></mat-divider>

            <a mat-list-item [href]="mapsLink" target="_blank" *ngIf="job.address" class="show-all">
              <fa-icon mat-list-icon [icon]="faLocationArrow"></fa-icon>
              <span mat-line class="color-text-primary">{{job.address.text}}</span>
            </a>

            <mat-divider *ngIf="job.address_note"></mat-divider>
            <mat-list-item *ngIf="job.address_note" class="no-select show-all" disableRipple="true">
              <fa-icon mat-list-icon [icon]="faInfo"></fa-icon>
              <span mat-line>{{job.address_note}}</span>
            </mat-list-item>

            <mat-divider *ngIf="job.address_contact_person"></mat-divider>
            <div style="display:flex" *ngIf="job.address_contact_person">
              <mat-list-item *ngIf="job.address_contact_person" class="no-select show-all" style="flex:1" disableRipple="true">
                <fa-icon mat-list-icon [icon]="faComment"></fa-icon>
                <span mat-line>{{job.address_contact_person}}</span>
              </mat-list-item>
              <a mat-list-item *ngIf="job.address_contact_phone" href="tel:{{job.address_contact_phone}}" style="flex:1" class="show-all">
                <fa-icon mat-list-icon [icon]="faPhone"></fa-icon>
                <span mat-line class="color-text-primary">{{job.address_contact_phone}}</span>
              </a>
            </div>
          </mat-list>

          <!-- Additional Informations -->
          <ng-container *ngIf="job.extra_input && job.extra_input.length > 0">
            <h2 class="mat-h2 app-list-heading" i18n>Weitere Informationen</h2>
            <app-info-list [infos]="job.extra_input"></app-info-list>
          </ng-container>

          <!-- Action -->
          <h2 class="mat-h2 app-list-heading toolbar-heading">
            <span i18n>Erledigt</span>
            <mat-slide-toggle color="primary" [checked]="!!job.tour_completed" (change)="OnDoneToggle($event)"></mat-slide-toggle>
          </h2>
        </main>
      </mat-tab>

      <!-- Files -->
      <ng-container>
        <mat-tab>
          <ng-template mat-tab-label>
            <fa-icon mat-list-icon [icon]="faFileAlt"></fa-icon>
            <span i18n class="label-text">Dateien ({{(job.files ? job.files.length : 0) + (job.project && job.project.files ? job.project.files.length : 0)}})</span>
          </ng-template>
          <main>

            <!-- Job Files -->
            <ng-container>
              <h2 class="mat-h2 app-list-heading" i18n>Job-Dateien</h2>
              <mat-card class="content-section" *ngIf="!job.files || job.files.length < 1">
                <mat-card-content i18n><em>Es sind noch keine Dateien vorhanden!</em></mat-card-content>
              </mat-card>
              <app-file-list *ngIf="job.files && job.files.length > 0"
                [files]="job.files"
                (filesUpdated)="reloadData()"></app-file-list>
              <app-file-upload table='job' [row]="job.id"
                  [organization]="job.organization"
                  (fileAdded)="onFileAdded()"></app-file-upload>
            </ng-container>

            <!-- Project Files -->

            <ng-container *ngIf="job.project">
              <h2 class="mat-h2 app-list-heading" i18n>Projekt-Dateien</h2>
              <mat-card class="content-section" *ngIf="!job.project.files || job.project.files.length < 1">
                <mat-card-content i18n><em>Es sind noch keine Dateien vorhanden!</em></mat-card-content>
              </mat-card>
              <ng-template ngFor let-cat [ngForOf]="projectFileCategories">
                <h3 class="mat-h3" *ngIf="projectFileCategories.length > 1">{{cat.title}}</h3>
                <app-file-list [files]="cat.files" [categoriesAvailable]="job.project.file_categories" (filesUpdated)="reloadData()"></app-file-list>
              </ng-template>
              <app-file-upload table='project' [row]="job.project.id"
                  [categoriesAvailable]="job.project.file_categories"
                  [organization]="job.project.organization"
                  (fileAdded)="onFileAdded()"></app-file-upload>
              <!--<app-file-list [files]="job.project.files"></app-file-list>-->
            </ng-container>
          </main>
        </mat-tab>
      </ng-container>

      <!-- Crew -->
      <ng-container *ngIf="job.crew && job.crew.length > 0">
        <mat-tab>
          <ng-template mat-tab-label>
            <fa-icon mat-list-icon [icon]="faUsers"></fa-icon> <span i18n class="label-text">Crew ({{crewAmount}})</span>
          </ng-template>
          <main>
            <h2 class="mat-h2 app-list-heading">Crew</h2>
            <ng-container *ngFor="let group of job.crew">
              <mat-list class="small-list" *ngIf="group.staffs && group.staffs.length > 0" style="margin-bottom: 15px;">
                <mat-list-item class="no-select head">{{group.qualification.title}}</mat-list-item>
                <mat-divider></mat-divider>
                <ng-container *ngFor="let staff of group.staffs; last as isLast;">
                    <mat-list-item class="no-select">
                      <div style="display: flex;width:100%;">
                        <div style="flex:1">{{staff.first_name}} {{staff.last_name}}</div>
                        <a *ngIf="staff.mobile"
                            href="tel:{{staff.mobile}}"
                            class="color-text-primary"
                            style="text-decoration:none;cursor:pointer;flex:1 0;text-align:right;">
                          {{staff.mobile}}
                        </a>
                      </div>

                   </mat-list-item>
                   <mat-divider *ngIf="!isLast"></mat-divider>
                </ng-container>
              </mat-list>
            </ng-container>

            <!-- Timesheets -->
            <ng-container *ngIf="job.timesheets && job.timesheets.length > 0">
                <h2 class="mat-h2 app-list-heading">{{orga.timesheet_wording ? orga.timesheet_wording.plural : 'Arbeitsscheine'}}</h2>
                <ng-container *ngFor="let timesheet of job.timesheets; last as isLast;">
                    <mat-list class="small-list"
                        style="margin-bottom: 16px;">
                      <mat-list-item class="pointer" (click)="routeTimesheet(timesheet)">
                          <fa-icon mat-list-icon [icon]="faSignature"></fa-icon>
                          <span mat-line>{{timesheet.sign_date | date:'short'}} <em class="pale">von</em>&nbsp;&nbsp;{{timesheet.sign_legitimate}}</span>
                      </mat-list-item>
                      <mat-list-item class="pointer" (click)="routeTimesheet(timesheet)">
                          <fa-icon mat-list-icon [icon]="faUsers"></fa-icon>
                          <span mat-line>{{staffNamesOfTimesheet(timesheet)}}</span>
                      </mat-list-item>
                      <app-file-entry *ngIf="timesheet.file"
                        [file]="timesheet.file"></app-file-entry>
                    </mat-list>
                </ng-container>
            </ng-container>

            <button *ngIf="job.timesheet_create && crewAmount" type="button" mat-raised-button color="primary" (click)="showJobTimesheetCreateDialog(job)" style="margin-left: 12px;">
                <fa-icon mat-icon [icon]="faBusinessTime" style="margin-right: 8px;"></fa-icon>
                <span i18n>{{orga?.timesheet_wording ? orga.timesheet_wording.singular : 'Arbeitsschein'}} erstellen</span>
            </button>
          </main>
        </mat-tab>
      </ng-container>

      <!-- Tasks -->
      <ng-container *ngIf="job.tasks && job.tasks.length > 0">
          <mat-tab>
              <ng-template mat-tab-label>
                  <fa-icon mat-list-icon [icon]="faCheckSquare"></fa-icon> <span class="label-text" i18n>Aufgaben ({{job.tasks.length}})</span>
              </ng-template>
              <main>
                  <ng-container *ngFor="let data of taskGroups">
                      <h2 i18n class="mat-h2 app-list-heading">{{data.title}}</h2>
                      <mat-nav-list class="round-list">
                          <a mat-list-item class="round-list-item mat-elevation-z1" (click)="routeTask(t)" *ngFor="let t of data.tasks">
                              <fa-layers mat-list-icon class="fa-fw">
                                  <fa-icon
                                  [icon]="t.completed ? faCheckSquare : faSquare"
                                      class="{{ t.completed ? 'color-text-green' : 'color-text-primary' }}"></fa-icon>
                              </fa-layers>
                              <div mat-line>
                                  <h4 style="flex:1 0;">{{t.title}}</h4>
                                  <div style="flex:1;" *ngIf="t.priority" style="text-align: right;">
                                      <span *ngIf="t.priority > 0" class="color-text-red">
                                         <fa-icon [icon]="faExclamation" class="color-text-red"></fa-icon>&nbsp;
                                         <strong>Hoch</strong>
                                      </span>
                                      <span *ngIf="t.priority < 0" class="color-text-grey">
                                          <fa-icon [icon]="faExclamation" class="color-text-grey"></fa-icon>&nbsp;
                                          <strong>Niedrig</strong>
                                      </span>
                                  </div>
                              </div>
                              <div mat-line>
                                  <div style="flex:1 0;">
                                      <ng-container *ngIf="t.deadline">
                                          <fa-icon [icon]="faCalendarTimes"></fa-icon>&nbsp;
                                          <app-date-span [start]="t.deadline" [end]="null" [relativeWords]="true"></app-date-span>
                                      </ng-container>
                                  </div>

                                  <div style="display: flex; overflow: hidden;">
                                      <div class="word_wrap">
                                          <span *ngFor="let staff of t.responsibles; last as isLast">
                                              <span>{{staff.first_name}} {{staff.last_name}}</span>
                                              <span *ngIf="!isLast">, </span>
                                          </span>&nbsp;
                                          <fa-icon [icon]="faUsers" style="flex-shrink: 0;"></fa-icon>
                                      </div>
                                  </div>
                              </div>
                          </a>
                      </mat-nav-list>
                  </ng-container>
              </main>
          </mat-tab>
      </ng-container>

      <!-- Project Informations -->
      <ng-container *ngIf="job.project">
        <mat-tab>
          <ng-template mat-tab-label>
            <fa-icon mat-list-icon [icon]="faRocket"></fa-icon> <span class="label-text" i18n>Projekt</span>
          </ng-template>
          <main>
            <h2 class="mat-h2 app-list-heading" i18n>Projektinformationen</h2>
            <mat-list>
              <mat-list-item class="show-all">
                <fa-icon mat-list-icon [icon]="faRocket"></fa-icon>
                <a mat-line
                        class="color-text-primary"
                        style="text-decoration: none; cursor: pointer;"
                        (click)="routeProject(job.project)">
                    {{job.project.title}}
                </a>
              </mat-list-item>
              <!-- Project: Notes -->
              <ng-container *ngIf="job.project.description">
                <mat-divider></mat-divider>
                <mat-list-item *ngIf="job.project.description" class="no-select show-all" disableRipple="true">
                  <fa-icon mat-list-icon [icon]="faInfo"></fa-icon>
                  <div mat-line [innerHTML]="job.project.description"></div>
                </mat-list-item>
              </ng-container>
            </mat-list>

            <!-- Project: Additional Information -->
            <ng-container *ngIf="job.project.extra_input && job.project.extra_input.length > 0">
              <h2 class="mat-h2 app-list-heading" i18n>Weitere Informationen</h2>
              <app-info-list [infos]="job.project.extra_input"></app-info-list>
            </ng-container>
          </main>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-container>
<ng-container *ngIf="!job">
    <h2 class="mat-h2" i18n>Es konnten keine Daten gefunden werden. Internetverbindung vorhanden?</h2>
</ng-container>
