<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>Personen auswählen</h1>

<mat-dialog-content>
    <section class="select-staffs">
        <mat-checkbox color="primary"
                [checked]="allSelected()"
                [indeterminate]="someSelected()"
                (change)="setAllSelected($event.checked)">
            <strong>Alle</strong>
        </mat-checkbox>
        <ul>
            <ng-container *ngFor="let group of staffs; index as i">
                <li *ngIf="group.staffs && group.staffs.length">
                    <mat-checkbox color="primary"
                            [checked]="allInGroupSelected(i)"
                            [indeterminate]="someInGroupSelected(i)"
                            (change)="setGroupSelected(i, $event.checked)">
                        <strong>{{group.qualification.title}}</strong>
                    </mat-checkbox>
                    <ul>
                        <li *ngFor="let staff of group.staffs">
                            <mat-checkbox color="primary"
                                    [(ngModel)]="staff.selected"
                                    (ngModelChange)="selectedChanged(i)">
                                {{staff.first_name + ' ' + staff.last_name}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </section>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: space-between;">
    <button mat-button mat-dialog-close class="light_bg">
        <ng-container i18n>Abbrechen</ng-container>
    </button>
    <button mat-raised-button i18n type="button" color="primary" (click)="onSubmit()">
        Weiter
    </button>
</mat-dialog-actions>
