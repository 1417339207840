import {FilterConfig, FilterType, GroupableOption, GroupedOptions, SelectFilterConfig, GroupedSelectFilterConfig, GroupToggleSelectFilterConfig} from "./interfaces";
import { Option } from "src/app/core/interfaces/option";

import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

/**
 * Abstract Filter class.
 * Extend this class when defining new Filters
 */
export abstract class Filter<T> {

	public label: string
	protected _value: T
	public abstract type: FilterType
	public storable?: boolean	= false //whether filter shall be stored
	protected _visible: boolean = true	//whether filter shall be displayed
	protected _selectable: boolean = true	//whether filter shall be selectable

	constructor(config: FilterConfig<T>) {

		// copy values from config into class
		return Object.assign(this, config);
	}

	public get visible():boolean {
		return this._visible
	}
	public set visible(value:boolean) {
		this._visible = value;
	}
	public get selectable():boolean{
		return this._selectable
	}
	public set selectable(value:boolean){
		this._selectable = value;
	}
	public get value():T{
		return this._value
	}
	public set value(value:T){
		this._value = value;
	}
}

/**
 * This class describes toggable filters.
 * These Filters can only be used in a Filters Object ()Object containing multiple filters)
 */
export abstract class ToggableFilter<T> {

	public filterToggle: string;	// object key of filter in filters object that should toggle this filter

	public abstract toggle(value:any): void
}

/**
 * abstract select filter
 */
export abstract class AbstractSelectFilter<T> extends Filter<T> {

	public abstract options: Option<T>[]
	public abstract get selected(): Option<T>
}

/**
 * Date Filter
 */
export class DateFilter extends Filter<Date>{

	public type:FilterType = FilterType.DATE;

	/**
	 * Returns whether today is selected
	 */
	get today(): boolean {
		return this.value.toDateString() === new Date().toDateString();
	}

	/**
	 * Sets new date
	 */
	public set value(value:Date) {
        let new_value = createDateFromDatetime(value);
        if(isNaN(new_value.getTime())){
            alert('Datum ist fehlerhaft');
        } else {
            this._value = new_value;
        }
	}

	public get value(): Date {
		return this._value
	}
}

/**
 * Select Filter
 */
export class SelectFilter<T> extends AbstractSelectFilter<T> {

	public options: Option<T>[]
	public type = FilterType.SELECT

	constructor(config:SelectFilterConfig<T>) {
		super(config);
	}

	/**
	 * Returns selected option
	 */
	get selected(): Option<T> {

		return this.options.find(o => o.value == this.value);
	}
}

/**
 * Select Filter for nested/grouped options
 */
export class GroupedSelectFilter<T> extends Filter<T> {

	public type = FilterType.GROUPED_SELECT
	public options: GroupableOption<T>[]
	private selectedOption: Option<T>

	constructor(config:GroupedSelectFilterConfig<T>) {
		super(config);
	}

	/**
	 * Retrieves selected option
	 */
	get selected(): Option<T> {

		// when calling this getter mutiple times, return the previous value if it hasn't changed
		if (this.selectedOption && this.selectedOption.value === this.value) {

			return this.selectedOption;
		}

		/**
		 * recurively find the matched value option and return it
		 * @param options
		 */
		const findMatch = (options: GroupableOption<T>[]) => {

			for (let option of options) {

				if (option.value && option.value === this.value)
					return option;

				if (option.options) {
					// when options contains a match, return it
					const match = findMatch(option.options)
					if (match) return match;
				}
			}

			// when no match, return null
			return null;
		}

		this.selectedOption = findMatch(this.options)
		return this.selectedOption;
	}
}

/**
 * Select Filter for grouped options that shall be toggled by another filter value
 */
export class GroupToggleSelectFilter<T> extends SelectFilter<T> implements ToggableFilter<T> {

	public filterToggle: string;
	public groupedOptions: GroupedOptions<T>;
	private toggleValue:any = null;

	constructor(config:GroupToggleSelectFilterConfig<T>) {
		super(<SelectFilterConfig<T>> <any> config);
	}

	/**
	 * Return options based in group toggle filter
	 */
	get options() {

		return <Option<T>[]> (this.groupedOptions[this.toggleValue] || [])
	}

	get selectable() {
		return this._selectable && !!this.toggleValue;
	}

	get visible() {
		return this._visible && !!this.toggleValue;
	}

	toggle(value?:string) {
		this.toggleValue = value;
	}
}

/**
 * Number Filter
 */
export class NumberFilter extends Filter<number> {

	public type = FilterType.NUMBER
}

/**
 * Text Filter
 */
export class TextFilter extends Filter<string> {

	public type = FilterType.TEXT
}
