import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Roster } from './roster.model';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { RosterModel } from './roster.class';
import { map, tap } from 'rxjs/operators';
import Model from 'src/app/core/classes/model.class';
import { Constructor } from '@angular/material/core/typings/common-behaviors/constructor';

@Injectable({
  providedIn: 'root'
})
export class RosterService extends CachableModelService<RosterModel>{

  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'rosters');
  }

  static TYPES = {
    AVAILABLE: 'available',
    NOT_AVAILABLE: 'not_available',
  }

  /**
   * Returns dispositions for current account
   * @param from date from (defaults to current day)
   * @param to date to
   * @param useCache DONT USE IT, AS THE CACHE ISN'T PERMANENT
   */
  getAllForCurrentAccount(from:Date = null, to:Date = null, useCache:boolean = false):Observable<RosterModel[]>{
    // set params
    let fromString:string = null, toString:string = null;
    let params = {};

    // set from
    if(!from){
      from = new Date();
      from.setHours(0,0,0,0);
    }

    // from string
    fromString = this.dateToString(from);
    params['date_from'] = fromString;

    // to string
    if(to){
      toString = this.dateToString(to);
      params['date_to'] = toString;
    }

    // build clear data
    let clearData = {
      type: 'period',
      data: { from: fromString, to: toString }
    }

    // get cached data
    let data = this.filterModelsByDate(fromString, toString);

    let result:Observable<RosterModel[]>;
    if (data.length > 0 && useCache) {
      result = of(data);
    }
    else {
      let http = this.http.get<Roster[]>('/v1/rosters', {params:params});

      result = this.cachedRequest(data, http, clearData)
    }

    return result.pipe(this.initModelsPipe(RosterModel));
  }

  /**
   * Delete models of organization
   * @param models 
   * @param orga 
   */
  public deleteAllByOrga(models:RosterModel[], orga:number|string):Observable<void> {
    const ids = models.map(model => model.id.toString());

    return this.http.delete<void>(`/v1/organizations/${orga}/rosters`, {params:{'ids[]': ids}})
      .pipe(tap(() => super.deleteModels(models)))
  }

  /**
   * Replaces models
   * @param models
   * @returns roster models
   */
  public replaceAll(models:RosterModel[]):Observable<RosterModel[]> {

    return this.http.put<Roster[]>('/v1/rosters', this.modelsToApiObjects(models))
      .pipe(
        this.initModelsPipe(RosterModel),
        tap(models => super.updateModels(models))
      )
  }

  /**
   * Creates models
   * @param models 
   * @returns roster models
   */
  public createAll(models:RosterModel[]):Observable<RosterModel[]> {

    return this.http.post<Roster[]>('/v1/rosters', this.modelsToApiObjects(models))
      .pipe(
        this.initModelsPipe(RosterModel),
        tap(models => super.updateModels(models))
      )
  }
}
