import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JobDetails, Job } from './job.model';
import { Observable } from 'rxjs';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';

interface ListOptions {
  projects?: [number],
}

@Injectable({
  providedIn: 'root'
})
export class JobService extends CachableModelService<JobDetails>{

  constructor(
    protected http: HttpClient
  ) {
    super(http, 'jobs');
  }

  getAllByOrganization(orgaId:number, options: ListOptions): Observable<Job[]> {
    const params:any = {};
    if (options.projects) params['projects[]'] = options.projects;

    return this.http.get<Job[]>(`/v1/organizations/${orgaId}/jobs`, {params: params});
  }

  getByOrganizationAndId(orga, id):Observable<JobDetails>{
    // get cached job
    let data = this.findModelByOrganizationAndId(orga, id);
    // If we found nothing -> get data from tour list
    if(!data && this.route && this.route.queryParams && this.route.queryParams.tour_id){
      let tours = JSON.parse(localStorage.getItem('tours'));
      if(tours){
          let tour = tours.find(val => {
            return this.fetchFromObject(val, 'id') == this.route.queryParams.tour_id && val['organization'] == orga;
          });
          if(tour) data = tour.jobs.find(val => { return val.id == id; });
      }
    }


    let http = this.http.get<JobDetails>('/v1/organizations/'+orga+'/jobs/'+id);
    return this.cachedRequest(data, http);
  }

  setCompleted(orga, id, bool){
    return this.cachedPost('/v1/organizations/'+orga+'/jobs/'+id+'/completed', {tour_completed:bool});
  }

  createTimesheet(orga, job, data){
      console.log('create timesheet', data);
      return this.cachedPost(`/v1/organizations/${orga}/jobs/${job}/timesheet`, {
          data
      });
  }

  deleteTimesheet(orga, job, id){
      console.log('delete timesheet', id);
      return this.http.delete(`/v1/organizations/${orga}/jobs/${job}/timesheet/${id}`).pipe(
          tap(res => { return res; })
      );
  }
}
