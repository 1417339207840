<div>
  <div class="start">
      <div class="time">{{start | date:'shortTime'}}</div>
      <div class="date">{{start | date:'mediumDate'}}</div>
  </div>
  <mat-nav-list class="list">
    <div *ngFor="let entry of sortedEntries" class="entry">
      <div class="time" [ngClass]="entry.color ? entry.color + '_bg' : ''">
        {{entry.start | date:'shortTime'}}
        <ng-container *ngIf="entry.end || end">
          <span i18n>-</span>
          {{entry.end || end | date:'shortTime'}}
        </ng-container>
      </div>
      <mat-card class="mat-elevation-z1 item" (click)="onClick(entry)">
        <div class="text">
          <div class="title">{{entry.title}}</div>
          <div *ngIf="entry.subtitle" class="subtitle">{{entry.subtitle}}</div>
        </div>
        <div *ngIf="entry.aside" class="aside" [innerHTML]="entry.aside"></div>
      </mat-card>
      <div *ngIf="actions.length && !entry.disableAction">
        <fa-icon
          *ngFor="let action of actions"
          [icon]="action.icon"
          size="lg"
          class="action"
          (click)="action.callback(entry)"
          [title]="entry.title"
        ></fa-icon>
      </div>
    </div>
  </mat-nav-list>

  <div class="end" *ngIf="end">
      <div class="time">{{end | date:'shortTime'}}</div>
      <div class="date">{{end | date:'mediumDate'}}</div>
  </div>
</div>
