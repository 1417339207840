import { Component, OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { VacationRequest } from '../shared/vacation-request.model';
import { VacationRequestService } from '../shared/vacation-request.service';
import { Organization } from '../../core/models/organization.model';
import { OrganizationService } from '../../core/services/organization.service';

import { MatTabGroup, MatTab, MatDialog } from '@angular/material';

import { faCheck, faTimes, faQuestion, faPlus } from '@fortawesome/free-solid-svg-icons';

interface Data {
	organizationId: number,
	companyId: number,
	companyTitle: string,
	entries: VacationRequest[]
};

@Component({
	templateUrl: './vacation-request-list.component.html',
	styleUrls: ['./vacation-request-list.component.css']
})
export class VacationRequestListComponent extends ComponentInit {
	faCheck = faCheck;
	faTimes = faTimes;
	faQuestion = faQuestion;
	faPlus = faPlus;

	selected = 0;

	data:Data[] = [];
	columnsToDisplay = ['type', 'period', 'duration'];

	constructor(
        private route: ActivatedRoute,
		private vrs: VacationRequestService,
		private os: OrganizationService,
		protected router: Router,
	    protected scrollLocations: ScrollLocations
	) { super(router, scrollLocations, route); }

	init(){
		// Get Organizations
		this.os.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
			if(!res) return;
			res.forEach(orga => {
				let orga_index = this.data.findIndex(element => { return element['organizationId'] == orga.id && element['companyId'] == orga.company; });
				if(orga_index > -1){
					this.data[orga_index].companyTitle = orga.title;
				} else {
					this.data.push({
						organizationId: orga.id,
						companyId: orga.company,
						companyTitle: orga.title,
						entries: []
					});
				}
			})
			this.selected = this.tabSelected;
	    });

		this.getEntries();
	}

	reload(){
		this.getEntries();
	}

	getEntries(){
		// Get vacation request entries
		this.vrs.getVacationRequests().subscribe(entries => {
			// Reset entries
			this.data.forEach((value, key) => {
				this.data[key].entries = [];
			})

			// :: Data Check
			if(!entries) return;

			entries.forEach(entry => {
				// Check if orga entry already exists
				let orga_index = this.data.findIndex(element => { return element['organizationId'] == entry.organization && element['companyId'] == entry.company; });
				if(orga_index == -1){
					orga_index = -1 + this.data.push({
						companyTitle: '',
						organizationId: entry.organization,
						companyId: entry.company,
						entries: []
					});
				}
				this.data[orga_index].entries.push(entry);
			});
		});
	}

	typeTitle(entry){
        return entry.type;
    }

    durationTitle(entry){
        return 'Tage/Stunden';
    }

    statusTitle(entry){
        return entry.status;
    }

	statusIcon(entry){
		if(entry.status == 2) return this.faTimes;
		else if(entry.status == 1) return this.faCheck;
		else return this.faQuestion;
	}

	statusColor(entry){
		if(entry.status == 2) return 're_fg';
		else if(entry.status == 1) return 'gr_fg';
		else return 'ye_fg';
	}

	newRequest(orga, company_id){
    	this.router.navigate(['/vacation-request-new/'+orga], { queryParams: { company: company_id} });
    }

	routeRequest(orga_id, entry_id){
		this.router.navigate(['/vacation-request/'+orga_id + '/' + entry_id]);
	}
}
