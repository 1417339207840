import { Component, OnInit } from '@angular/core';
import { ComponentInit } from '../core/classes/component-init.class';
import { untilDestroyed } from 'ngx-take-until-destroy';

import {
    faClock,
    faBriefcase,
    faShareAlt,
    faTruck,
    faPlay,
    faStop,
    faEllipsisV,
    faStopwatch,
    faHistory,
    faCoffee
} from '@fortawesome/free-solid-svg-icons';

import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

import { DispositionService } from '../dispositions/shared/disposition.service';
import { Disposition } from '../dispositions/shared/disposition.model';
import { WorkService } from '../work-schedules/shared/work.service';
import { WorkSchedule, WorkScheduleEntry, WorkScheduleBreak } from '../work-schedules/shared/work-schedule.model';
import { MatDialog } from '@angular/material';
import { WorkScheduleDialogComponent, DialogType, defaultDialogOptions } from '../work-schedules/work-schedule-dialog/work-schedule-dialog.component';
import { WorkScheduleBreakDialogComponent, BreakDialogType, defaultBreakDialogOptions } from '../work-schedules/work-schedule-break-dialog/work-schedule-break-dialog.component';
import { Organization } from '../core/models/organization.model';
import { OrganizationService } from '../core/services/organization.service';
import { AccountService } from '../core/services/account.service';
import { Account } from '../core/models/account.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../core/globals/scroll-locations';

import { ProjectService } from '../projects/shared/project.service';
import { Project } from '../projects/shared/project.model';

import { QualificationService } from 'src/app/core/services/qualification.service';
import { Qualification } from 'src/app/core/models/qualification.model';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent extends ComponentInit implements OnInit {

    faClock = faClock;
    faBriefcase = faBriefcase;
    faShareAlt = faShareAlt;
    faTruck = faTruck;
    faPlay = faPlay;
    faStop = faStop;
    faEllipsisV = faEllipsisV;
    faStopwatch = faStopwatch;
    faHistory = faHistory;
    faCoffee = faCoffee;

    account:Account;
    dispos:Disposition[];

    overtimeOrgaIndex = 0;
    vacationLeftOrgaIndex = 0;

    workSchedule:WorkSchedule; // current work schedule
    workScheduleBreak:WorkScheduleBreak; // current work schedule break
    workScheduleEntry?:WorkScheduleEntry;
    workTime;  //object containing hours and minutes
    projectTime;
    workTimeThisWeek = {
        hours: null,
        minutes: null
    };
    showOvertimeTile:boolean = false;
    showVacationLeftTile:boolean = false;

    isProjectRunning:Boolean;
    projectRunningTitle:string;
    qualificationRunning:Qualification;

    orgas:Organization[];

    currentDate = new Date();

    constructor(
        private ds: DispositionService,
        public ws: WorkService,
        public os: OrganizationService,
        private as: AccountService,
        private dialog: MatDialog,
        public router: Router,
        private projectService: ProjectService,
        private qualificationService: QualificationService,
        protected scrollLocations: ScrollLocations
    ) {
        super(router, scrollLocations);
    }

    hourToTimeObj(h:number){
        let hours = Math.floor(Math.abs(h)) * Math.sign(h);
        return {
            hours: hours,
            minutes: Math.floor(h*60 - hours*60)
        }
    }

    init(){
        this.as.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(res => {
            if(!res) return;
            this.account = res;
            this.workTimeThisWeek = this.hourToTimeObj(res.summary.work_hours_this_week);
            this.showOvertimeTile = !!res.summary.overtime.length;
            this.showVacationLeftTile = !!res.summary.vacation_left.length;
        });

        this.ws.getCurrent().pipe(untilDestroyed(this)).subscribe(res => {
            if(res === null){
                this.workSchedule = null;
                this.workScheduleBreak = null;
            } else if(res){
                this.workSchedule = res;
                this.calcWorkTime();

                // break (set current break only, when the end of last break of ws is still null)
                if(this.workSchedule.breaks && this.workSchedule.breaks.length && !this.workSchedule.breaks[this.workSchedule.breaks.length - 1].end){
                    this.workScheduleBreak = this.workSchedule.breaks[this.workSchedule.breaks.length - 1]
                } else {
                    this.workScheduleBreak = null;
                }
            }
        });

        // Disp Entries
        this.getDispoEntries();

        this.os.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
            if(!res) return;
            this.orgas = res;
        });

        // update curr date every minute so the elapsed working time is displayed correctly
        setInterval(()=>{
            this.calcWorkTime();
            this.calcProjectTime();
        }, 60000)

        this.ws.currWorkScheduleEntry.pipe(untilDestroyed(this)).subscribe(entry => {
            this.workScheduleEntry = entry;
            this.calcProjectTime();

            this.isProjectRunning = !!entry

            // get running project
            if(entry && entry.project){
                this.projectService.getByOrganizationAndId(entry.organization, entry.project)
                .toPromise()
                .then(project => this.projectRunningTitle = this.isProjectRunning ? project.title : null);
            } else if(entry && entry.comment){
                this.projectRunningTitle = this.isProjectRunning ? entry.comment : null;
            } else this.projectRunningTitle = null;

            // get running qualification
            if(entry && entry.qualification){
                this.qualificationService.getByOrganizationAndId(entry.organization, entry.qualification)
                .toPromise()
                .then(qualification => this.qualificationRunning = this.isProjectRunning ? qualification : null);
            } else this.qualificationRunning = null;
        });
    }

    getDispoEntries(){
        let to = new Date();
        to.setDate(to.getDate()+2);
        to.setHours(0, 0, 0, 0);
        this.ds.getAllForCurrentAccount({date_from: new Date(), date_to: to}).pipe(untilDestroyed(this)).subscribe(dispos => {
            if(!dispos) return;

            let fromDate = new Date(); fromDate.setHours(0,0,0,0);
            this.dispos = this.ds.filterModelsByDate(
                this.ds.dateToString(fromDate),
                this.ds.dateToString(to)
            );
        });
    }

    returnOvertime(orgaId){
        let o = this.account.summary.overtime.find(val => {return val.company.organization == orgaId});
        return [this.hourToTimeObj(o.value)];
    }

    reload(){
        this.as.getCurrentAccount();
        this.ws.getCurrent();

        this.getDispoEntries();
    }

    /**
    * start work
    * @param orga
    */
    startWork(orga:number, past:Boolean = false, company:number = null){
        this.dialog.open(WorkScheduleDialogComponent, {
            ...defaultDialogOptions,
            data:{
                type: DialogType.StartWork,
                past: past,
                organization: orga,
                company: company
            }
        }).afterClosed().subscribe(workSchedule => {
            if(!workSchedule){
                this.reloadData();
                return;
            }

            // Check if start of ws is more than 20 hours in the past -> redirect to ws overview
            if(Date.now() - (createDateFromDatetime(workSchedule.date_in)).getTime() > (12 * 3600 * 1000)){
                this.router.navigateByUrl(`/work-schedule/${workSchedule.organization}/${workSchedule.id}`);
            } else {
                this.workSchedule = workSchedule;
            }
        });
    }

    /**
    * trigger ending work
    */
    endWork(){
        this.router.navigateByUrl('/work-schedules/review');
    }

    startBreak(){
        this.dialog.open(WorkScheduleBreakDialogComponent, {
            ...defaultBreakDialogOptions,
            data:{
                type: BreakDialogType.StartBreak,
                ws: this.workSchedule,
                organization: this.workSchedule.organization
            }
        }).afterClosed().subscribe(workScheduleBreak => {
            if(!workScheduleBreak){
                this.reloadData();
                return;
            }
        });
    }

    endBreak(){
        this.dialog.open(WorkScheduleBreakDialogComponent, {
            ...defaultBreakDialogOptions,
            data:{
                type: BreakDialogType.EndBreak,
                id: this.workScheduleBreak.id,
                start: this.workScheduleBreak.start,
                ws: this.workSchedule,
                organization: this.workSchedule.organization
            }
        }).afterClosed().subscribe(workScheduleBreak => {
            if(!workScheduleBreak){
                this.reloadData();
                return;
            }
        });
    }

    breakTime(){
        if(this.workScheduleBreak){
            const start = createDateFromDatetime(this.workScheduleBreak.start);
            const end = this.workScheduleBreak.end ? createDateFromDatetime(this.workScheduleBreak.end) : new Date();

            let minutes = Math.max(0, (end.getTime() - start.getTime())/1000/60);

            return {
                hours: Math.floor(minutes/60),
                minutes: Math.floor(minutes%60)
            };
        } else {
            return null;
        }
    }

    calcWorkTime(){
        if(this.workSchedule){
            let minutes = (new Date().getTime() - createDateFromDatetime(this.workSchedule.date_in).getTime())/1000/60;
            this.workTime = {
                hours: Math.floor(minutes/60),
                minutes: Math.floor(minutes%60)
            };
        }
    }

    calcProjectTime(){
        if(this.workScheduleEntry){
            let minutes = (new Date().getTime() - createDateFromDatetime(this.workScheduleEntry.start).getTime())/1000/60;
            this.projectTime = {
                hours: Math.floor(minutes/60),
                minutes: Math.floor(minutes%60)
            };
        }
    }

    endProject() {
        this.dialog.open(WorkScheduleDialogComponent, {
            ...defaultDialogOptions,
            data:{
                type: DialogType.EndProject,
                schedule: this.workSchedule,
                entry: this.ws.currWorkScheduleEntry.getValue()
            }
        }).afterClosed().subscribe(entry => {
            if(entry !== undefined) this.ws.setCurrWorkScheduleEntry(!entry || entry.end ? null : entry);
        })
    }

    startProject() {
        this.dialog.open(WorkScheduleDialogComponent, {
            ...defaultDialogOptions,
            data:{
                type: DialogType.StartProject,
                schedule: this.workSchedule,
            }
        }).afterClosed().subscribe(entry => {
            if(entry !== undefined) this.ws.setCurrWorkScheduleEntry(entry)
        });
    }

}
