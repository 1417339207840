<main *ngIf="settings">
	<h2 class="mat-h2 app-list-heading toolbar-heading">
		<span i18n>E-Mail Benachrichtigungen</span>
		<mat-slide-toggle [(ngModel)]="settings.email_notifications" (change)="onMailChange()"></mat-slide-toggle>
	</h2>
	<mat-selection-list *ngIf="settings.email_notifications" (selectionChange)="onSelectionUpdate($event)">
		<!--<mat-list-option class="mat-elevation-z1" [selected]="!!settings.mission_change_mail" value="mission_change_mail">
			<span i18n>Job-Änderungen</span>
		</mat-list-option>
		<mat-divider></mat-divider>-->
		<mat-list-option class="mat-elevation-z1" [selected]="!!settings.mission_reminder_mail" value="mission_reminder_mail">
			<span i18n>Job-Erinnerung</span>
		</mat-list-option>
		<mat-divider></mat-divider>
		<mat-list-option class="mat-elevation-z1" [selected]="!!settings.mission_offer_mail" value="mission_offer_mail">
			<span i18n>Job-Angebote</span>
		</mat-list-option>
		<mat-divider></mat-divider>
		<mat-list-option class="mat-elevation-z1" [selected]="!!settings.dispo_daily_mail" value="dispo_daily_mail">
			<span i18n>Dispositions-Änderungen</span>
		</mat-list-option>
	</mat-selection-list>

	<h2 class="mat-h2 app-list-heading toolbar-heading">
		<span i18n>Push Benachrichtigungen</span>
		<mat-slide-toggle (change)="onPushChange($event)" [checked]="pushEnabled"></mat-slide-toggle>
	</h2>
	<mat-selection-list *ngIf="pushEnabled" (selectionChange)="onSelectionUpdate($event)">
		<mat-list-option class="mat-elevation-z1" [selected]="!!settings.mission_change_push" value="mission_change_push">
		<span i18n>Job-Änderungen</span>
		</mat-list-option>
		<mat-divider></mat-divider>
		<mat-list-option class="mat-elevation-z1" [selected]="!!settings.mission_reminder_push" value="mission_reminder_push">
		<span i18n>Job-Erinnerung</span>
		</mat-list-option>
		<mat-divider></mat-divider>
		<mat-list-option class="mat-elevation-z1" [selected]="!!settings.mission_offer_push" value="mission_offer_push">
		<span i18n>Job-Angebote</span>
		</mat-list-option>
		<mat-divider></mat-divider>
	</mat-selection-list>
</main>