import { Option } from "src/app/core/interfaces/option";
import { Filter, SelectFilter, DateFilter, GroupedSelectFilter, GroupToggleSelectFilter } from "./classes";

export interface FilterConfig<T> {
	label: string,
	value: T,
	storable?: boolean,	//whether filter shall be stored
	visible?: boolean,	//whether filter shall be displayed
	selectable?: boolean,	//whether filter shall be selectable
}

export interface GroupToggleSelectFilterConfig<T> extends FilterConfig<T> {

	filterToggle: string,	// the filter name that toggles the groups
	groupedOptions: GroupedOptions<T>,
}

export interface SelectFilterConfig<T> extends FilterConfig<T> {
	options: Option<T>[],
}

export interface GroupableOption<T> {
	label: string,
	value?: T,
	options?: GroupableOption<T>[],
}

export interface GroupedSelectFilterConfig<T> extends FilterConfig<T> {
	options: GroupableOption<T>[],
}

export type GroupedOptions<T> = {
	[key in any]: Option<T>[];
}

export type Filters = {
	[key in string]: Filter<any>
}

export enum FilterType {
	TEXT = 'text',
	DATE = 'date',
	NUMBER = 'number',
	SELECT = 'select',
	GROUPED_SELECT = 'groupedSelect',
	GROUP_TOGGLE_SELECT = 'groupToggleSelect',
}