var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { formatDate } from '@angular/common';
import { createDateFromDatetime, createDateFromDateAndTime } from 'src/app/core/utils/date-utils';
import { WorkService } from '../shared/work.service';
import { AccountService } from '../../core/services/account.service';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { MyErrorHandler } from '../../core/classes/my-error-handler.class';
export var BreakDialogType;
(function (BreakDialogType) {
    BreakDialogType[BreakDialogType["StartBreak"] = 0] = "StartBreak";
    BreakDialogType[BreakDialogType["EditBreak"] = 1] = "EditBreak";
    BreakDialogType[BreakDialogType["EndBreak"] = 2] = "EndBreak";
    BreakDialogType[BreakDialogType["CreateBreak"] = 3] = "CreateBreak";
})(BreakDialogType || (BreakDialogType = {}));
;
export var defaultBreakDialogOptions = {
    minWidth: 250,
    maxWidth: 430,
    autoFocus: false,
    width: '95vw'
};
var WorkScheduleBreakDialogComponent = /** @class */ (function () {
    function WorkScheduleBreakDialogComponent(fb, dialogBreak, dialog, accountService, workService, myErrorHandler, data, locale) {
        this.fb = fb;
        this.dialogBreak = dialogBreak;
        this.dialog = dialog;
        this.accountService = accountService;
        this.workService = workService;
        this.myErrorHandler = myErrorHandler;
        this.data = data;
        this.locale = locale;
        this.types = BreakDialogType;
        this.date = new Date();
        this.showDateInput = false;
        this.organization = this.data.organization || this.data.ws && this.data.ws.organization || null;
    }
    /**
    * date to date format
    * @param dateString
    */
    WorkScheduleBreakDialogComponent.prototype.formatDate = function (dateString) {
        if (typeof dateString === 'string' && dateString.length > 11)
            dateString = createDateFromDatetime(dateString);
        return formatDate(dateString, 'yyyy-MM-dd', this.locale);
    };
    /**
    * date to time format
    * @param dateString
    */
    WorkScheduleBreakDialogComponent.prototype.formatTime = function (dateString) {
        if (typeof dateString === 'string' && dateString.length > 11)
            dateString = createDateFromDatetime(dateString);
        return formatDate(dateString, 'HH:mm', this.locale);
    };
    WorkScheduleBreakDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Get current account
        this.accountService.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(function (res) {
            if (!res)
                return;
            _this.account = res;
        });
        // adjust this.date
        if (this.data.ws.date_in)
            this.date = createDateFromDatetime(this.data.ws.date_in);
        var group = {
            startDate: [this.formatDate(this.data.start || this.date), Validators.required],
            startTime: [this.formatTime(this.data.start || new Date()), Validators.required],
            endDate: [this.formatDate(this.data.end || this.date), Validators.required],
            endTime: [this.formatTime(this.data.end || new Date()), Validators.required],
        };
        this.form = this.fb.group(group);
        if (this.data.ws.date_in && (this.data.ws.date_out || this.data.ws_date_out || this.date)) {
            if (this.formatDate(this.data.ws.date_in) != this.formatDate(this.data.ws.date_out || this.data.ws_date_out || this.date))
                this.showDateInput = true;
        }
    };
    /**
    * calculates duration from two dates in hours
    * @param start
    * @param end
    */
    WorkScheduleBreakDialogComponent.prototype.calculateDuration = function (start, end) {
        return Math.round((end.getTime() - start.getTime()) / 36000) / 100;
    };
    /**
    * updates duration according to dates
    */
    WorkScheduleBreakDialogComponent.prototype.updateDuration = function () {
        var start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
        var end = createDateFromDateAndTime(this.form.value.endDate, this.form.value.endTime);
        if (!start || !end)
            return;
        this.form.controls['duration'].setValue(this.calculateDuration(start, end));
    };
    WorkScheduleBreakDialogComponent.prototype.breakDuration = function () {
        var start = createDateFromDateAndTime(this.form.value.startDate, this.form.value.startTime);
        var end = createDateFromDateAndTime(this.form.value.endDate, this.form.value.endTime);
        var minutes = start && end ? Math.max(0, (end.getTime() - start.getTime()) / 1000 / 60) : 0;
        return {
            hours: Math.floor(minutes / 60),
            minutes: Math.floor(minutes % 60)
        };
        // return Math.round((end.getTime() - start.getTime()) / 36000) / 100;
    };
    /**
* Called when submit button is pressed
*/
    WorkScheduleBreakDialogComponent.prototype.submit = function () {
        this.futureDateError = false;
        this.beforeStartDateError = false;
        if (this.form.valid) {
            var values = __assign({}, this.form.value);
            var now = new Date();
            if (values.startDate)
                values.start = createDateFromDateAndTime(values.startDate, values.startTime);
            if (values.endDate)
                values.end = createDateFromDateAndTime(values.endDate, values.endTime);
            // check for valid date
            if (isNaN(values.start) || isNaN(values.end))
                return this.dialog.open(OkDialogComponent, { data: 'Datum/Uhrzeit ist fehlerhaft.' });
            if (values.startDate && values.endDate) {
                if (now < values.start || now < values.end) {
                    this.futureDateError = true;
                    return;
                }
                if (this.data.type != BreakDialogType.StartBreak && values.start > values.end) {
                    this.beforeStartDateError = true;
                    return;
                }
            }
            if (typeof values.start === 'string')
                values.start = createDateFromDatetime(values.start);
            if (typeof values.end === 'string')
                values.end = createDateFromDatetime(values.end);
            switch (this.data.type) {
                case BreakDialogType.StartBreak:
                    this.startBreak(values);
                    break;
                case BreakDialogType.CreateBreak:
                    this.createBreak(values);
                    break;
                case BreakDialogType.EditBreak:
                    if (!this.data.id)
                        this.createBreak(values);
                    else
                        this.editBreak(values);
                    break;
                case BreakDialogType.EndBreak:
                    this.editBreak(values);
                    break;
                default:
                    this.dialog.open(OkDialogComponent, { data: 'Unkown Dialog Type' });
            }
        }
    };
    /**
    * starts work schedule entry
    * @param form
    */
    WorkScheduleBreakDialogComponent.prototype.startBreak = function (form) {
        var _this = this;
        return this.workService.createBreak(this.data.ws.organization, this.data.ws.id, {
            start: form.start,
        }).pipe(untilDestroyed(this)).subscribe(function (entry) { return _this.dialogBreak.close(entry); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleBreakDialogComponent.prototype.createBreak = function (form) {
        var _this = this;
        return this.workService.createBreak(this.data.ws.organization, this.data.ws.id, {
            start: form.start,
            end: form.end,
        }).pipe(untilDestroyed(this)).subscribe(function (entry) { return _this.dialogBreak.close(entry); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleBreakDialogComponent.prototype.editBreak = function (form) {
        var _this = this;
        return this.workService.editBreak(this.organization, this.data.id, {
            start: form.start,
            end: form.end
        }).pipe(untilDestroyed(this)).subscribe(function (entry) { return _this.dialogBreak.close(entry); }, function (err) { return _this.myErrorHandler.showError(err); });
    };
    WorkScheduleBreakDialogComponent.prototype.ngOnDestroy = function () { };
    return WorkScheduleBreakDialogComponent;
}());
export { WorkScheduleBreakDialogComponent };
