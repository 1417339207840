import { formatDate } from '@angular/common';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
import * as i0 from "@angular/core";
var ListGroupService = /** @class */ (function () {
    function ListGroupService(locale) {
        this.locale = locale;
        this.groupsRaw = [
            { slug: 'past', name: 'Vergangenheit', data: [] },
            { slug: 'today', name: 'Heute', data: [] },
            { slug: 'tomorrow', name: 'Morgen', data: [] },
            { slug: 'this_week', name: 'Diese Woche', data: [] },
            { slug: 'next_week', name: 'Nächste Woche', data: [] },
            { slug: 'future', name: 'Weitere', data: [] }
        ];
    }
    ListGroupService.prototype.getGroups = function (entries, startKey, endKey) {
        var _this = this;
        if (entries === void 0) { entries = null; }
        if (startKey === void 0) { startKey = 'start'; }
        if (endKey === void 0) { endKey = null; }
        if (!entries)
            return this.groupsRaw;
        var groups = JSON.parse(JSON.stringify(this.groupsRaw));
        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        var tomorrow = new Date(Date.now() + 86400000); //+1Tag
        var nextWeek = new Date(Date.now() + 86400000 * 7); //+1Woche
        var todayYearWeek = this.getYearWeek(today);
        var nextYearWeek = this.getYearWeek(nextWeek);
        entries.forEach(function (entry) {
            // assign entries to specific lists
            var start = createDateFromDatetime(entry[startKey]);
            var end = endKey ? createDateFromDatetime(entry[endKey]) : null;
            var short = formatDate(start, 'shortDate', _this.locale);
            var yearWeek = _this.getYearWeek(start);
            if (end && end < today)
                groups[0].data.push(entry);
            else if (short == formatDate(today, 'shortDate', _this.locale) || (start <= today && end >= today))
                groups[1].data.push(entry);
            else if (short == formatDate(tomorrow, 'shortDate', _this.locale))
                groups[2].data.push(entry);
            else if (yearWeek == todayYearWeek)
                groups[3].data.push(entry);
            else if (yearWeek == nextYearWeek)
                groups[4].data.push(entry);
            else
                groups[5].data.push(entry);
        });
        return groups;
    };
    ListGroupService.prototype.getYearWeek = function (date) {
        // Get YearWeek
        var weekNumber = parseInt(formatDate(date, 'w', this.locale));
        // Fix Bug in angular with weekNumber:
        // date pipe does not recognize "locale" in case of first day of week. It handles sunday as first day of week,
        // so "sunday" will get the next week number. It was fixes in a later angular version
        if (date.getDay() == 0)
            weekNumber -= 1;
        return formatDate(date, 'y ', this.locale) + weekNumber;
    };
    ListGroupService.ngInjectableDef = i0.defineInjectable({ factory: function ListGroupService_Factory() { return new ListGroupService(i0.inject(i0.LOCALE_ID)); }, token: ListGroupService, providedIn: "root" });
    return ListGroupService;
}());
export { ListGroupService };
