import { NgModule } from '@angular/core';
import { RosterService } from './shared/roster.service';
import { SharedModule } from '../shared/shared.module';
import { RosterDialogComponent } from './roster-dialog/roster-dialog.component';
import { RosterAvailabilityInfoComponent } from './roster-availability-info/roster-availability-info.component';


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    RosterDialogComponent,
    RosterAvailabilityInfoComponent
  ],
  providers: [
    RosterService,
  ],
  entryComponents: [
    RosterDialogComponent,
    RosterAvailabilityInfoComponent
  ],
  exports: [
    RosterDialogComponent,
    RosterAvailabilityInfoComponent
  ]
})
export class RostersModule { }
