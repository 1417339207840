import { PressedEvent } from "../events/pressed-event";

export class RegisterPressedEvent {

	private pressStart = null;

	constructor() {
		this.initEventListeners();
	}

	/**
	 * init event handlers
	 */
	private initEventListeners() {
		// triggers
		window.addEventListener('mousedown', this.onPressStart);
		window.addEventListener('touchstart', this.onPressStart);

		// breaks
		window.addEventListener('mouseup', this.onPressEnd);
		window.addEventListener('mousemove', this.onPressEnd);
		window.addEventListener('touchend', this.onPressEnd);
		window.addEventListener('touchmove', this.onPressEnd);
	}

	/**
	 * on press start handler.
	 * When not released in a certain amount of time,
	 * a pressed event will be fired on target
	 * @param event
	 */
	private onPressStart(event) {

		let pressStart = this.pressStart = new Date();
		setTimeout(() => {
			// only when no other press has started, dispatch event
			if (pressStart === this.pressStart) {

				const target = event.target;

				/* Disable context menu on hold and class 'no-context-menu' present
				 * This isn't ideal, as the user isn't able to open it.
				 * aAnice work-around would be, to only hide context menus for DOM-Nodes
				 * that listen to the pressed event, but JS doen't support that ;(
				 */
				if (target.classList.contains('no-context-menu'))
					target.oncontextmenu = () => false;

				target.dispatchEvent(new PressedEvent());
			}
		}, 250);
	}

	/**
	 * on press end handler
	 */
	private onPressEnd() {

		this.pressStart = null;
	}
}
