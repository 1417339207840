import { Component, ViewChild, ComponentFactoryResolver, Injector, ApplicationRef, OnDestroy, AfterViewInit } from '@angular/core';
import { TemplatePortal, PortalHost, CdkPortal, DomPortalHost } from '@angular/cdk/portal';

@Component({
  selector: 'app-toolbar-actions',
  template: '<ng-template cdk-portal><ng-content></ng-content></ng-template>',
})
export class ToolbarActionsComponent implements AfterViewInit, OnDestroy{
  @ViewChild(CdkPortal) portal: TemplatePortal<any>;

  private portalHost: PortalHost;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
  ) { }

  ngAfterViewInit() {

    // Create a portalHost from a DOM element
    this.portalHost = new DomPortalHost(
      document.querySelector('#toolbar-actions-container'),
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    // Attach portal to host
    this.portalHost.attach(this.portal);
  }

  ngOnDestroy(): void {
    this.portalHost.detach();
  }

}
