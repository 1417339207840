import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-list',
  templateUrl: './info-list.component.html',
  styleUrls: ['./info-list.component.css']
})
export class InfoListComponent {
    
  accToken:string = JSON.parse(localStorage.getItem('accessToken'));

  @Input() infos: Object[];

  constructor() { }

}
