import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var TitleService = /** @class */ (function () {
    function TitleService(titleService) {
        this.titleService = titleService;
        this.title = new BehaviorSubject(this.titleService.getTitle());
    }
    /**
     * returns app title as string
     */
    TitleService.prototype.getTitle = function () {
        //this.title.next(this.titleService.getTitle());
        return this.title.getValue();
    };
    /**
     * sets new app title
     * @param newTitle
     */
    TitleService.prototype.setTitle = function (newTitle) {
        //this.titleService.setTitle(newTitle);
        this.title.next(newTitle);
    };
    /**
     * returns app title as observable
     */
    TitleService.prototype.getTitleAsObservable = function () {
        return this.title.asObservable();
    };
    TitleService.ngInjectableDef = i0.defineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.inject(i1.Title)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
