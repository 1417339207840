import { NgModule } from '@angular/core';
import { TourDetailComponent } from './tour-detail/tour-detail.component';
import { SharedModule } from '../shared/shared.module';
import { TourResolver } from './shared/tour.resolver'
import { TourService } from './shared/tour.service';
import { TourListComponent } from './tour-list/tour-list.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    TourDetailComponent,
    TourListComponent,
  ],
  providers: [
    TourResolver,
    TourService,
  ]
})
export class ToursModule { }
