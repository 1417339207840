<main *ngIf="entry">
	<h2 class="mat-h2 app-list-heading">Antrag</h2>
	<mat-list class="mat-elevation-z1">
		<!-- Company -->
		<mat-list-item>
			<fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
			<span mat-line *ngIf="orga">
				{{orga.title}}
			</span>
		</mat-list-item>
		<mat-divider></mat-divider>

		<!-- Type -->
		<mat-list-item class="show-all">
			<fa-icon mat-list-icon [icon]="faTag" class="mat-list-icon ng-fa-icon"></fa-icon>
			<div mat-line>{{ entry.type_title }}</div>
		</mat-list-item>
		<mat-divider></mat-divider>

		<!-- Period -->
		<mat-list-item class="show-all">
			<fa-icon mat-list-icon [icon]="faCalendarAlt"
				class="mat-list-icon ng-fa-icon"></fa-icon>
			<div mat-line class="flex">
				<app-date-span [start]="entry.start" [end]="entry.end" [dateOnly]="entry.type != 'free' && entry.type != 'not_available'"></app-date-span>&nbsp;
				<span>({{ entry.amount }})</span>
			</div>
		</mat-list-item>
	</mat-list>

	<!-- Conflicts -->
	<ng-container *ngIf="entry.conflicts && entry.conflicts.length > 0">
		<h2 class="app-list-heading mat-h2" i18n>Konflikte</h2>
		<mat-card class="content-section" [ngStyle]="{backgroundColor: '#b6514f', color: 'white'}">
			<mat-card-content>
				<p><b i18n>Achtung, es gibt Konflikte für den ausgewählten Zeitraum:</b></p>
				<ul>
					<li *ngFor="let conflict of entry.conflicts">{{ conflict }}</li>
				</ul>
			</mat-card-content>
		</mat-card>
	</ng-container>

	<!-- Locks -->
	<ng-container *ngIf="entry.locks && entry.locks.length > 0">
		<h2 class="app-list-heading mat-h2" i18n>Urlaubssperren</h2>
		<mat-list class="mat-elevation-z1">
			<ng-container *ngFor="let lock of entry.locks; last as isLast">
				<mat-list-item class="show-all">
					<fa-icon mat-list-icon [icon]="faBan" class="mat-list-icon ng-fa-icon"></fa-icon>
					<span mat-line>
						<app-date-span [start]="lock.start" [end]="lock.end" [dateOnly]="true"></app-date-span>
						&nbsp;<b>{{lock.title}}</b>
					</span>
				</mat-list-item>
				<mat-divider *ngIf="!isLast"></mat-divider>
			</ng-container>
		</mat-list>
	</ng-container>


	<!-- Notes -->
	<ng-container *ngIf="entry.note">
		<h2 class="mat-h2 app-list-heading" i18n>Kommentar</h2>
		<mat-card class="content-section mat-elevation-z1">
			<mat-card-content [innerHTML]="entry.note"></mat-card-content>
		</mat-card>
	</ng-container>

	<!-- Instances -->
	<ng-container *ngIf="entry.instances">
		<h2 class="mat-h2 app-list-heading" i18n>Status</h2>
		<mat-list class="mat-elevation-z1">
			<mat-list-item>
				<fa-icon mat-list-icon [icon]="faPlus"></fa-icon>
				<span mat-line>
					<strong i18n>Erstellt</strong>
					<small [ngStyle]="{float: 'right'}">{{entry.created | date:'dd.MM.yy'}} | {{entry.created | date:'shortTime'}}</small>
				</span>
			</mat-list-item>
			<mat-divider></mat-divider>
			<ng-container *ngFor="let instance of entry.instances; last as isLast">
				<mat-list-item>
					<fa-icon mat-list-icon [icon]="instanceIcon(instance)" [ngClass]="instanceColor(instance)"></fa-icon>
					<span mat-line>
						<strong>{{instance.user_name}}</strong>
						<small *ngIf="instance.date" [ngStyle]="{float: 'right'}">
							{{instance.date | date:'dd.MM.yy'}} | {{instance.date | date:'shortTime'}}
						</small>
					</span>
				</mat-list-item>
				<mat-divider *ngIf="!isLast"></mat-divider>
			</ng-container>
		</mat-list>
	</ng-container>

	<!-- Answer -->
	<ng-container *ngIf="entry.note_answer">
		<h2 class="mat-h2 app-list-heading" i18n>Antwort</h2>
		<mat-card class="content-section mat-elevation-z1">
			<mat-card-content [innerHTML]="entry.note_answer"></mat-card-content>
		</mat-card>
	</ng-container>

	<!-- Delete -->
	<div class="flexbox row button-wrapper" *ngIf="!entry.status">
		<button mat-raised-button class="flex spinner-button re_bg" i18n type="button" [disabled]="submitting" (click)="delete()">
			<mat-spinner diameter="20" *ngIf="submitting" ></mat-spinner>
			Antrag löschen
		</button>
	</div>
</main>
