var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { RosterModel } from '../shared/roster.class';
import { formatDate } from '@angular/common';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { DateService } from 'src/app/core/services/date.service';
export var RETURN_ACTIONS = {
    NEW: 'new',
    CHANGE: 'change',
    DELETE: 'delete',
};
var RosterDialogComponent = /** @class */ (function () {
    function RosterDialogComponent(fb, orgaService, dialog, date, data, locale) {
        this.fb = fb;
        this.orgaService = orgaService;
        this.dialog = dialog;
        this.date = date;
        this.data = data;
        this.locale = locale;
        this.orgas = this.orgaService.organizationsOfCurrentAccount;
    }
    RosterDialogComponent.prototype.ngOnInit = function () {
        var roster = this.data.roster;
        var date = roster && roster.start || this.data.date || new Date();
        var start = roster && roster.start || this.date.startOfDay(date);
        var end = roster && roster.end || this.date.endOfDay(date);
        this.form = this.fb.group({
            start: formatDate(start, 'HH:mm', this.locale),
            end: formatDate(end, 'HH:mm', this.locale),
            date: formatDate(date, 'yyyy-MM-dd', this.locale),
            type: roster && roster.type || null,
            organization: roster && roster.organization || (this.orgas.length === 1 && this.orgas[0].id) || null,
        });
    };
    /**
     * Updates roster, or creates new roster with applied form values
     * @param data
     */
    RosterDialogComponent.prototype.updateRosterWithFormValues = function (data) {
        var roster = this.data.roster || new RosterModel({});
        return Object.assign(roster, __assign({}, data, { start: this.date.mapHoursToDate(new Date(data.date), data.start), end: this.date.mapHoursToDate(new Date(data.date), data.end) }));
    };
    RosterDialogComponent.prototype.onSubmit = function () {
        if (this.form.valid) {
            this.dialog.close({
                action: this.data.roster ? RETURN_ACTIONS.CHANGE : RETURN_ACTIONS.NEW,
                roster: this.updateRosterWithFormValues(this.form.value)
            });
        }
    };
    RosterDialogComponent.prototype.onDelete = function () {
        this.dialog.close({ action: RETURN_ACTIONS.DELETE });
    };
    return RosterDialogComponent;
}());
export { RosterDialogComponent };
