import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { CachableModelService } from '../../core/classes/cachable-model-service.class';

import { SubTask } from './sub-task.model';
import { TaskAssignService } from './task-assign.service';


@Injectable({
    providedIn: 'root'
})
export class SubTaskService extends CachableModelService<SubTask>{


    constructor(
        protected http: HttpClient,
        @Inject(LOCALE_ID) protected locale: string,
        protected tas: TaskAssignService
    ) {
        super(http, 'subTasks');
    }

    getByOrganizationAndId(orga, id):Observable<SubTask>{
        let data = this.findModelByOrganizationAndId(orga, id);

        let http = this.http.get<SubTask>('/v1/organizations/'+orga+'/sub-tasks/'+id);
        return this.cachedRequest(data, http);
    }

    complete(sub_task:SubTask):Observable<any>{
		return this.http.post('/v1/organizations/'+sub_task.organization+'/sub-tasks/'+sub_task.id+'/complete', {})
			.pipe(tap(res => {
                this.updateModel(res);
            }));
    }

    incomplete(sub_task:SubTask):Observable<any>{
		return this.http.post('/v1/organizations/'+sub_task.organization+'/sub-tasks/'+sub_task.id+'/incomplete', {})
			.pipe(tap(res => {
                this.updateModel(res);
            }));
    }
}
