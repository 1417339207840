import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventService } from './shared/event.service';
import { EventResolver } from './shared/event.resolver';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    EventDetailComponent
  ],
  providers: [
    EventService,
    EventResolver,
  ]
})
export class EventsModule { }
