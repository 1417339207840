/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job-timesheet-create-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i3 from "@angular/material/checkbox";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "./job-timesheet-create-dialog.component";
import * as i13 from "../../core/services/date.service";
import * as i14 from "@angular/router";
var styles_JobTimesheetCreateDialogComponent = [i0.styles];
var RenderType_JobTimesheetCreateDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobTimesheetCreateDialogComponent, data: {} });
export { RenderType_JobTimesheetCreateDialogComponent as RenderType_JobTimesheetCreateDialogComponent };
function View_JobTimesheetCreateDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-checkbox", [["class", "mat-checkbox"], ["color", "primary"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.selected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.selectedChanged(_v.parent.parent.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵdid(2, 8568832, null, 0, i3.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i1.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i1.ɵdid(4, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵted(7, 0, [" ", " "]))], function (_ck, _v) { var currVal_14 = "primary"; _ck(_v, 2, 0, currVal_14); var currVal_15 = _v.context.$implicit.selected; _ck(_v, 4, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 2).indeterminate; var currVal_3 = i1.ɵnov(_v, 2).checked; var currVal_4 = i1.ɵnov(_v, 2).disabled; var currVal_5 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_7 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 6).ngClassValid; var currVal_12 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); var currVal_16 = ((_v.context.$implicit.first_name + " ") + _v.context.$implicit.last_name); _ck(_v, 7, 0, currVal_16); }); }
function View_JobTimesheetCreateDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-checkbox", [["class", "mat-checkbox"], ["color", "primary"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setGroupSelected(_v.parent.context.index, $event.checked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i3.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i1.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"], checked: [1, "checked"], indeterminate: [2, "indeterminate"] }, { change: "change" }), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTimesheetCreateDialogComponent_3)), i1.ɵdid(8, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "primary"; var currVal_8 = _co.allInGroupSelected(_v.parent.context.index); var currVal_9 = _co.someInGroupSelected(_v.parent.context.index); _ck(_v, 3, 0, currVal_7, currVal_8, currVal_9); var currVal_11 = _v.parent.context.$implicit.staffs; _ck(_v, 8, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = _v.parent.context.$implicit.qualification.title; _ck(_v, 5, 0, currVal_10); }); }
function View_JobTimesheetCreateDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTimesheetCreateDialogComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.staffs && _v.context.$implicit.staffs.length); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_JobTimesheetCreateDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "color-text-primary mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i8.MatDialogTitle, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Personen ausw\u00E4hlen"])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i8.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 8, "section", [["class", "select-staffs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "mat-checkbox", [["class", "mat-checkbox"], ["color", "primary"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setAllSelected($event.checked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i1.ɵdid(8, 8568832, null, 0, i3.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i1.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"], checked: [1, "checked"], indeterminate: [2, "indeterminate"] }, { change: "change" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alle"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTimesheetCreateDialogComponent_1)), i1.ɵdid(13, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["style", "justify-content: space-between;"]], null, null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i8.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "light_bg"], ["mat-button", ""], ["mat-dialog-close", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).dialogRef.close(i1.ɵnov(_v, 18).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(18, 606208, null, 0, i8.MatDialogClose, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(19, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Abbrechen"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Weiter "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_8 = "primary"; var currVal_9 = _co.allSelected(); var currVal_10 = _co.someSelected(); _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.staffs; _ck(_v, 13, 0, currVal_11); var currVal_15 = ""; _ck(_v, 18, 0, currVal_15); var currVal_18 = "primary"; _ck(_v, 22, 0, currVal_18); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 8).id; var currVal_2 = null; var currVal_3 = i1.ɵnov(_v, 8).indeterminate; var currVal_4 = i1.ɵnov(_v, 8).checked; var currVal_5 = i1.ɵnov(_v, 8).disabled; var currVal_6 = (i1.ɵnov(_v, 8).labelPosition == "before"); var currVal_7 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_12 = (i1.ɵnov(_v, 17).disabled || null); var currVal_13 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); var currVal_14 = (i1.ɵnov(_v, 18).ariaLabel || null); _ck(_v, 16, 0, currVal_12, currVal_13, currVal_14); var currVal_16 = (i1.ɵnov(_v, 22).disabled || null); var currVal_17 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); _ck(_v, 21, 0, currVal_16, currVal_17); }); }
export function View_JobTimesheetCreateDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-job-timesheet-create-dialog", [], null, null, null, View_JobTimesheetCreateDialogComponent_0, RenderType_JobTimesheetCreateDialogComponent)), i1.ɵdid(1, 114688, null, 0, i12.JobTimesheetCreateDialogComponent, [i8.MatDialogRef, i13.DateService, i14.Router, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JobTimesheetCreateDialogComponentNgFactory = i1.ɵccf("app-job-timesheet-create-dialog", i12.JobTimesheetCreateDialogComponent, View_JobTimesheetCreateDialogComponent_Host_0, {}, {}, []);
export { JobTimesheetCreateDialogComponentNgFactory as JobTimesheetCreateDialogComponentNgFactory };
