<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>Bestätige deine Identität</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput type="email" placeholder="E-Mail Adresse" i18n-placeholder formControlName="email" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Passwort" i18n-placeholder formControlName="password" required>
    </mat-form-field>
    <mat-error *ngIf="authFailedError" i18n>Identifikation fehlgeschlagen, versuche es erneut</mat-error>
  </div>
  <mat-dialog-actions>
    <button mat-button i18n type="button" (click)="dialog.close()">
      Abbrechen
    </button>
    <button mat-raised-button color="primary" i18n class="spinner-button" type="submit" [disabled]="disabled">
      <mat-spinner diameter="20"></mat-spinner>
      Weiter
    </button>
  </mat-dialog-actions>
</form>