var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from "@angular/router";
import { JobTimesheetService } from './job-timesheet.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';
var JobTimesheetResolver = /** @class */ (function (_super) {
    __extends(JobTimesheetResolver, _super);
    function JobTimesheetResolver(s, r) {
        return _super.call(this, s, 'job-timesheets', r) || this;
    }
    return JobTimesheetResolver;
}(CachableClientModelResolver));
export { JobTimesheetResolver };
