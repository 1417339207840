<main>
  <app-loading-spinner [loading]="!dispos" [loadError]="loadError"></app-loading-spinner>
  <p class="text-top color-text-grey text-center" *ngIf="dispos && dispos.length == 0">
    Keine Einträge vorhanden
  </p>
  <ng-container *ngFor="let list of listGroups">
    <ng-container *ngIf="list.data.length > 0">
      <h2 i18n class="mat-h2 app-list-heading">{{list.name}}</h2>
      <app-disposition-list [dispositions]="list.data"></app-disposition-list>
    </ng-container>
  </ng-container>
</main>
