<app-filter-header [inputs]="filterInputs" storage="project-list" (callback)="getProjects($event)">
    <main>
      <app-loading-spinner [loading]="!projects" [loadError]="loadError" [retry]="reload" (click)="reload()"></app-loading-spinner>
      <p class="text-top color-text-grey text-center" *ngIf="projects && projects.length == 0">
        Keine Einträge vorhanden
      </p>
      <ng-container *ngFor="let list of listGroups">
        <ng-container *ngIf="list.data.length > 0">
          <h2 i18n class="mat-h2 app-list-heading">{{list.name}}</h2>
          <mat-nav-list>
            <a mat-list-item class="round-list-item mat-elevation-z1" (click)="routeProject(p)" *ngFor="let p of list.data">
              <fa-layers mat-list-icon class="fa-fw">
                <fa-icon [icon]="faRocket" [class]="(p.status && p.status.color) ? p.status.color + '_fg' : 'color-text-primary'"></fa-icon>
              </fa-layers>
              <h4 mat-line>{{p.title}}</h4>
              <div mat-line>
                <div style="flex:1 0;">
                  <fa-icon [icon]="faCalendarAlt"></fa-icon>&nbsp;
                  <app-date-span [start]="p.date_start" [end]="p.date_end" [dateOnly]="true"></app-date-span>&nbsp;
                </div>
                <div style="flex:1;" *ngIf="p.responsible" style="text-align: right;">
                  <fa-icon [icon]="faUser"></fa-icon>&nbsp;
                  <span>{{p.responsible.first_name}} {{p.responsible.last_name}}</span>
                </div>
              </div>
            </a>
          </mat-nav-list>
        </ng-container>
      </ng-container>
    </main>
</app-filter-header>
