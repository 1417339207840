import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
    faFile,
    faFilePdf,
    faFileWord,
    faFilePowerpoint,
    faFileExcel,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileArchive,
    faEdit
} from '@fortawesome/free-regular-svg-icons';

import { File } from '../../core/models/file.model';
import { FileCategory } from '../../core/models/file-category.model';

import { MatDialog } from '@angular/material';
import { FileEditDialogComponent } from '../../shared/file-edit-dialog/file-edit-dialog.component';


@Component({
    selector: 'app-file-entry',
    templateUrl: './file-entry.component.html',
    styleUrls: ['./file-entry.component.css']
})
export class FileEntryComponent {

    @Input() file: File;
    @Input() categoriesAvailable: FileCategory;
    @Output() fileUpdated = new EventEmitter();

    faFile = faFile;
    faFilePdf = faFilePdf;
    faFileWord = faFileWord;
    faFilePowerpoint = faFilePowerpoint;
    faFileExcel = faFileExcel;
    faFileImage = faFileImage;
    faFileAudio = faFileAudio;
    faFileVideo = faFileVideo;
    faFielArchive = faFileArchive;
    faEdit = faEdit;

    accToken:string = JSON.parse(localStorage.getItem('accessToken'));

    constructor(private dialog: MatDialog,) {

    }

    editFile(e, file){
        e.preventDefault();
        e.stopPropagation();

        this.dialog.open(FileEditDialogComponent, {
            data: {
                file: file,
                categoriesAvailable: this.categoriesAvailable
            }
        }).afterClosed().subscribe(files_updated => {
            if(files_updated) this.fileUpdated.emit();
        });
    }


    mapExtensionToIcon(extension){
        switch(extension){
            case 'pdf':
                return this.faFilePdf;
            case 'doc':
            case 'docx':
            case 'uot':
                return this.faFileWord;
            case 'ppt':
            case 'pptx':
            case 'uop':
                return this.faFilePowerpoint;
            case 'xls':
            case 'xlsx':
            case 'uos':
                return this.faFileExcel;
            case 'img':
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
            case 'gif':
            case 'sgv':
                return this.faFileImage;
            case 'mp3':
            case 'wav':
            case 'ogg':
                return this.faFileAudio;
            case 'mp4':
                return this.faFileVideo;
            case 'zip':
            case 'iso':
            case 'rar':
                return this.faFielArchive;
            default:
                return this.faFile;
        }
    }
}
