var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var JobService = /** @class */ (function (_super) {
    __extends(JobService, _super);
    function JobService(http) {
        var _this = _super.call(this, http, 'jobs') || this;
        _this.http = http;
        return _this;
    }
    JobService.prototype.getAllByOrganization = function (orgaId, options) {
        var params = {};
        if (options.projects)
            params['projects[]'] = options.projects;
        return this.http.get("/v1/organizations/" + orgaId + "/jobs", { params: params });
    };
    JobService.prototype.getByOrganizationAndId = function (orga, id) {
        var _this = this;
        // get cached job
        var data = this.findModelByOrganizationAndId(orga, id);
        // If we found nothing -> get data from tour list
        if (!data && this.route && this.route.queryParams && this.route.queryParams.tour_id) {
            var tours = JSON.parse(localStorage.getItem('tours'));
            if (tours) {
                var tour = tours.find(function (val) {
                    return _this.fetchFromObject(val, 'id') == _this.route.queryParams.tour_id && val['organization'] == orga;
                });
                if (tour)
                    data = tour.jobs.find(function (val) { return val.id == id; });
            }
        }
        var http = this.http.get('/v1/organizations/' + orga + '/jobs/' + id);
        return this.cachedRequest(data, http);
    };
    JobService.prototype.setCompleted = function (orga, id, bool) {
        return this.cachedPost('/v1/organizations/' + orga + '/jobs/' + id + '/completed', { tour_completed: bool });
    };
    JobService.prototype.createTimesheet = function (orga, job, data) {
        console.log('create timesheet', data);
        return this.cachedPost("/v1/organizations/" + orga + "/jobs/" + job + "/timesheet", {
            data: data
        });
    };
    JobService.prototype.deleteTimesheet = function (orga, job, id) {
        console.log('delete timesheet', id);
        return this.http.delete("/v1/organizations/" + orga + "/jobs/" + job + "/timesheet/" + id).pipe(tap(function (res) { return res; }));
    };
    JobService.ngInjectableDef = i0.defineInjectable({ factory: function JobService_Factory() { return new JobService(i0.inject(i1.HttpClient)); }, token: JobService, providedIn: "root" });
    return JobService;
}(CachableModelService));
export { JobService };
