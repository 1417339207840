import { Component, OnInit } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';

import { EventService } from '../shared/event.service';
import { OrganizationService } from '../../core/services/organization.service';
import { Organization } from '../../core/models/organization.model';
import { Event } from '../shared/event.model';
import { ActivatedRoute, Router } from '@angular/router';

import { ScrollLocations } from '../../core/globals/scroll-locations';

import {
  faMapMarker,
  faInfo,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarCheck,
  faCalendarAlt,
  faBuilding,
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent extends ComponentInit {

  faCalendarCheck = faCalendarCheck;
  faCalendarAlt = faCalendarAlt;
  faBuilding = faBuilding;
  faMapMarker = faMapMarker;
  faInfo = faInfo;
  faUsers = faUsers;

  event:Event;

  constructor(
    private route: ActivatedRoute,
    private es: EventService,
    private os: OrganizationService,
    protected router: Router,
    protected scrollLocations: ScrollLocations
) { super(router, scrollLocations, route); }

  init() {
    this.route.data.subscribe(data => {
      if(data.resolved.model)
        this.update(data.resolved.model);
      data.resolved.updates.subscribe(res => {
        if(res) this.update(res);
      });
    });
  }

  update(event){
    this.event = event;
  }

}
