/**
 * constructs date from date and time strings
 */
export function createDateFromDateAndTime(date:string, time:string) {
	if (!date || !time) return null;

	let times = time.split(':');
	let time_zone_offset = new Date(date + 'T' + times[0] + ':' + times[1] + ':' + (times[2] || '00') + '+01:00').toString().match(/([-\+][0-9:]+)\s/)[1];

	// adjust time zone offset string to fix issues in older safari versions (+0100 -> +01:00)
	if(time_zone_offset.indexOf(':') < 0)
		time_zone_offset = time_zone_offset.slice(0, 3) + ':' + time_zone_offset.slice(3);

	let date_time_string = date + 'T' + times[0] + ':' + times[1] + ':' + (times[2] || '00') + time_zone_offset;

	// console.log('building date: ', date, time, date_time_string);

	return new Date(date_time_string);
	/* const times = time.split(':');
	const datetime = new Date(date);
	datetime.setHours(<any> times[0], <any> times[1], 0);
	return datetime; */
}

export function createDateFromDatetime(datetime:string|Date): Date{
	if(datetime instanceof Date) return datetime;
	if(!datetime) return new Date();

	if(datetime.length > 11 && datetime.indexOf('T') < 0){
		let date = datetime.split(" ")[0];
		let time_zone_offset = new Date(date).toString().match(/([-\+][0-9:]+)\s/)[1];

		// adjust time zone offset string to fix issues in older safari versions (+0100 -> +01:00)
		if(time_zone_offset.indexOf(':') < 0)
			time_zone_offset = time_zone_offset.slice(0, 3) + ':' + time_zone_offset.slice(3);

		datetime.replace(' ', 'T');
		datetime += time_zone_offset;
	}

	// console.log('building date: ', datetime);

	return new Date(datetime);
}
