var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Model from "../../core/classes/model.class";
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
var RosterModel = /** @class */ (function (_super) {
    __extends(RosterModel, _super);
    function RosterModel(obj) {
        return _super.call(this, __assign({}, obj, { start: createDateFromDatetime(obj.start), end: createDateFromDatetime(obj.end) })) || this;
    }
    RosterModel.prototype.toApiObject = function () {
        return __assign({}, new Object(this), { start: this.start.toISOString(), end: this.end.toISOString() });
    };
    return RosterModel;
}(Model));
export { RosterModel };
