var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewChecked } from '@angular/core';
import { ComponentInit } from '../../../core/classes/component-init.class';
import { ScrollLocations } from '../../../core/globals/scroll-locations';
import { faShareAlt, faQuestion, faUsers, faCheck, faTimes, faCrosshairs, } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt, faHandshake, faCalendarAlt, } from '@fortawesome/free-regular-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { MissionProcessService } from '../../shared/mission-process.service';
import { DashboardService } from '../../../core/services/dashboard.service';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../../shared/ok-dialog/ok-dialog.component';
var MissionProcessDetailComponent = /** @class */ (function (_super) {
    __extends(MissionProcessDetailComponent, _super);
    function MissionProcessDetailComponent(route, mps, ds, dialog, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.mps = mps;
        _this.ds = ds;
        _this.dialog = dialog;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.faShareAlt = faShareAlt;
        _this.faQuestion = faQuestion;
        _this.faUsers = faUsers;
        _this.faFileAlt = faFileAlt;
        _this.faHandshake = faHandshake;
        _this.faCheck = faCheck;
        _this.faTimes = faTimes;
        _this.faCrosshairs = faCrosshairs;
        _this.faCalendarAlt = faCalendarAlt;
        _this.stages = [
            {
                position: 'top',
                title: 'Ausschreibung erstellt',
                icon: _this.faShareAlt,
                state: 'pale',
                line: {
                    y1: 30,
                    y2: 75
                }
            },
            {
                position: 'bottom',
                title: 'Ausschreibung beantworten',
                icon: _this.faQuestion,
                state: 'pale',
                line: {
                    y1: 75,
                    y2: 30
                }
            },
            {
                position: 'top',
                title: 'Interessenten-Pool antworten',
                icon: _this.faUsers,
                state: 'pale',
                line: {
                    y1: 30,
                    y2: 30
                }
            },
            {
                position: 'top',
                title: 'Angebot erstellt',
                icon: _this.faFileAlt,
                state: 'pale',
                line: {
                    y1: 30,
                    y2: 75
                }
            },
            {
                position: 'bottom',
                title: 'Angebot beantworten',
                icon: _this.faQuestion,
                state: 'pale',
                line: {
                    y1: 75,
                    y2: 75
                }
            },
            {
                position: 'bottom',
                title: 'Auftragsbestätigung',
                icon: _this.faHandshake,
                state: 'pale',
                line: null
            }
        ];
        _this.disableAll = false;
        _this.selection = {
            files: [],
            contract: [],
        };
        _this.selectionRequiredError = false;
        return _this;
    }
    MissionProcessDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            // get model update
            data.resolved.updates.subscribe(function (mp) {
                if (mp)
                    _this.update(mp);
            });
        });
    };
    MissionProcessDetailComponent.prototype.update = function (missionProcess) {
        if (missionProcess.state == 'confirmation')
            this.ds.setTitle('Auftragsbestätigung');
        var i = 0;
        switch (missionProcess.state) {
            case 'confirmation':
            case 'cancel':
                i = 5;
                break;
            case 'offer':
                i = 3;
                break;
            case 'proposal':
                i = 1;
        }
        for (var j = 0; j < i; j++) {
            this.stages[j].state = 'completed';
        }
        switch (missionProcess.answer) {
            case 'rejected':
                this.stages[j].state = 'completed';
                j++;
            case 'declined':
                this.stages[j].state = 'aborted';
                break;
            case 'accepted':
                this.stages[j].state = 'completed';
                j++;
            case null:
                if (missionProcess.state == 'confirmation')
                    this.stages[j].state = 'completed';
                else if (missionProcess.state == 'cancel')
                    this.stages[j].state = 'aborted';
                else
                    this.stages[j].state = 'pending';
                break;
        }
        // reset remaining
        for (var k = j + 1; k < this.stages.length; k++) {
            this.stages[k].state = 'pale';
        }
        this.mp = missionProcess;
        if (missionProcess.required_files)
            this.files = missionProcess.required_files;
        else if (missionProcess.info_files)
            this.files = missionProcess.info_files;
    };
    MissionProcessDetailComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        setTimeout(function () { return _this.adjustLines(); });
    };
    MissionProcessDetailComponent.prototype.adjustLines = function () {
        // get first icon
        var elem = document.querySelector(".stages .stage:first-child");
        if (!elem)
            return;
        // adjust lines
        var width = document.querySelector(".stages .stage:first-child").clientWidth;
        this.linesX1 = width / 2;
        this.linesX2 = width + (width / 2);
    };
    /**
     * checks selection, only when it is an offer
     */
    MissionProcessDetailComponent.prototype.checkSelection = function () {
        if (this.mp.state != 'offer')
            return true;
        // if there are files / cotnract and the option is not checked --> error
        if ((this.mp.contract_for_services && this.selection.contract.length == 0) || (this.mp.required_files && this.selection.files.length == 0)) {
            this.selectionRequiredError = true;
            return false;
        }
        this.selectionRequiredError = false;
        return true;
    };
    /**
     * Accept Button
     */
    MissionProcessDetailComponent.prototype.onProcessAcceptClick = function () {
        var _this = this;
        if (!this.checkSelection())
            return;
        if (this.disableAll)
            return;
        this.disableAll = true;
        this.mps.accept(this.mp).subscribe(function (res) {
            _this.disableAll = false;
            _this.update(res.mission_process);
            _this.dialog.open(OkDialogComponent, { data: res.message });
        }, function (res) {
            _this.disableAll = false;
            _this.dialog.open(OkDialogComponent, { data: res.error.text });
        });
    };
    /**
     * Decline button
     */
    MissionProcessDetailComponent.prototype.onProcessDeclineClick = function () {
        var _this = this;
        if (!this.checkSelection())
            return;
        if (this.disableAll)
            return;
        this.disableAll = true;
        this.mps.decline(this.mp).subscribe(function (res) {
            _this.disableAll = false;
            _this.update(res.mission_process);
            _this.dialog.open(OkDialogComponent, { data: res.message });
        }, function (res) {
            _this.disableAll = false;
            _this.dialog.open(OkDialogComponent, { data: res.error.text });
        });
    };
    /**
     * Save preferences
     */
    MissionProcessDetailComponent.prototype.onProcessSaveClick = function () {
        var _this = this;
        if (this.disableAll)
            return;
        this.disableAll = true;
        // retrieve ansers for each entry
        var entries = this.mp.entries.reduce(function (total, val, i) {
            total[val.id] = val.answer;
            return total;
        }, {});
        this.mps.save(this.mp, entries).subscribe(function (res) {
            _this.disableAll = false;
            _this.update(res.mission_process);
            _this.dialog.open(OkDialogComponent, { data: res.message });
        }, function (res) {
            _this.disableAll = false;
            _this.dialog.open(OkDialogComponent, { data: res.error.text });
        });
    };
    return MissionProcessDetailComponent;
}(ComponentInit));
export { MissionProcessDetailComponent };
