import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { CachableModelService } from '../../core/classes/cachable-model-service.class';

import { TaskAssign } from './task-assign.model';

import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class TaskAssignService extends CachableModelService<TaskAssign>{

    constructor(
        protected http: HttpClient,
        @Inject(LOCALE_ID) protected locale: string,
    ) {
        super(http, 'taskAssigns');
    }


    /**
     * Returns all work schedule entries of month (yyyy-mm)
     */
    getTaskAssigns(options):Observable<TaskAssign[]>{
        let params = {
            date_from: this.dateToString(options.date_from, 'yyyy-MM-dd'),
            state: options.state
        }

        // get cached data
        let data = this.models;
        let http = this.http.get<TaskAssign[]>('/v1/task-assigns', {params: params});

        return this.cachedRequest(data, http);
    }

    getByOrganizationAndId(orga, id):Observable<TaskAssign>{
        let data = this.findModelByOrganizationAndId(orga, id);
        let http = this.http.get<TaskAssign>('/v1/organizations/'+orga+'/task-assigns/'+id);
        return this.cachedRequest(data, http);
    }

    complete(taskAssign:TaskAssign):Observable<any>{
		return this.http.post('/v1/organizations/'+taskAssign.organization+'/task-assigns/'+taskAssign.id+'/complete', {})
			.pipe(tap(res => this.updateModel(res)));
    }

    incomplete(taskAssign:TaskAssign):Observable<any>{
		return this.http.post('/v1/organizations/'+taskAssign.organization+'/task-assigns/'+taskAssign.id+'/incomplete', {})
			.pipe(tap(res => this.updateModel(res)));
    }
}
