import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface TimelineEntry {
  id?: number,
  type: String,
  start: Date,
  end: Date,
  title: String,
  subtitle?: String,
  aside?: String,
  color?: String,
  disableAction?: Boolean
}

export interface Action {
  icon: IconDefinition,
  callback: (entry: TimelineEntry) => any,
  title: String,
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent {

  @Input() start: Date;
  @Input() end: Date;
  @Input() entries: TimelineEntry[];
  @Output() selected = new EventEmitter<TimelineEntry>();
  @Input() actions: Action[] = [];

  constructor() { }

  /**
   * gets sorted entries by date
   */
  get sortedEntries() {
    return this.entries.sort((a, b) => {
      return a.start.getTime() - b.start.getTime() + (a.end && b.end ? a.end.getTime() - b.end.getTime() : 0);
    })
  }

  onClick(entry: TimelineEntry) {
    this.selected.emit(entry);
  }

}
