import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { CalendarModule, CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

/* import * as Sentry from '@sentry/angular'
import { RewriteFrames } from '@sentry/integrations' */

// locale registration
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// misc
import { environment } from '../environments/environment';

// modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { SettingsModule } from './settings/settings.module';
import { MissionsModule } from './missions/missions.module';
import { DispositionsModule } from './dispositions/dispositions.module';
import { ToursModule } from './tours/tours.module';
import { ProjectsModule } from './projects/projects.module';
import { JobsModule } from './jobs/jobs.module';
import { EventsModule } from './events/events.module';
import { WorkSchedulesModule } from './work-schedules/work-schedules.module';
import { TasksModule } from './tasks/tasks.module';
import { VacationRequestsModule } from './vacation-requests/vacation-requests.module';
import { RostersModule } from './rosters/rosters.module';

import { SignaturePadModule } from 'angular2-signaturepad';

// components
import { AppComponent } from './app.component';
import { InvitationComponent } from './invitation/invitation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FilesComponent } from './files/files.component';
import { ImprintComponent } from './imprint/imprint.component';

import { CustomDateFormatter } from './calendar/shared/custom-date-formatter.provider';

import { MyErrorHandler } from './core/classes/my-error-handler.class';


import { ScrollLocations } from './core/globals/scroll-locations';

// Fixes Scroll Issue on with calendar views (need to overwrite hammerjs)
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
declare var Hammer: any;
export class MyHammerConfig extends HammerGestureConfig {
	buildHammer(element: HTMLElement) {
		let mc = new Hammer(element, {
			touchAction: "pan"
		});
		// let mc = new Hammer(element);
		return mc;
	}
}

// Disable Ripple Effect
import { RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};

// Set Default Options for Dialog
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material';
const globalMatDialogConfig = {
	...new MatDialogConfig(),
	maxWidth: '90vw'
}




@NgModule({
	declarations: [
		AppComponent,
		InvitationComponent,
		DashboardComponent,
		HomeComponent,
		CalendarComponent,
		FilesComponent,
		ImprintComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		CoreModule,
		SharedModule,
		AuthModule,
		SettingsModule,
		MissionsModule,
		DispositionsModule,
		ToursModule,
		ProjectsModule,
		JobsModule,
		EventsModule,
		WorkSchedulesModule,
		TasksModule,
		VacationRequestsModule,
		RostersModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
		AppRoutingModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
		SignaturePadModule

	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'de-DE' },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: globalMatDialogConfig},
		{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
		{
			provide: CalendarDateFormatter,
			useClass: CustomDateFormatter
		},
		ScrollLocations,
		{ provide: ErrorHandler, useClass: MyErrorHandler }
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule { }
