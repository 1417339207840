var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ComponentInit } from '../core/classes/component-init.class';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { faClock, faBriefcase, faShareAlt, faTruck, faPlay, faStop, faEllipsisV, faStopwatch, faHistory, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';
import { DispositionService } from '../dispositions/shared/disposition.service';
import { WorkService } from '../work-schedules/shared/work.service';
import { MatDialog } from '@angular/material';
import { WorkScheduleDialogComponent, DialogType, defaultDialogOptions } from '../work-schedules/work-schedule-dialog/work-schedule-dialog.component';
import { WorkScheduleBreakDialogComponent, BreakDialogType, defaultBreakDialogOptions } from '../work-schedules/work-schedule-break-dialog/work-schedule-break-dialog.component';
import { OrganizationService } from '../core/services/organization.service';
import { AccountService } from '../core/services/account.service';
import { Router } from '@angular/router';
import { ScrollLocations } from '../core/globals/scroll-locations';
import { ProjectService } from '../projects/shared/project.service';
import { QualificationService } from 'src/app/core/services/qualification.service';
var HomeComponent = /** @class */ (function (_super) {
    __extends(HomeComponent, _super);
    function HomeComponent(ds, ws, os, as, dialog, router, projectService, qualificationService, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations) || this;
        _this.ds = ds;
        _this.ws = ws;
        _this.os = os;
        _this.as = as;
        _this.dialog = dialog;
        _this.router = router;
        _this.projectService = projectService;
        _this.qualificationService = qualificationService;
        _this.scrollLocations = scrollLocations;
        _this.faClock = faClock;
        _this.faBriefcase = faBriefcase;
        _this.faShareAlt = faShareAlt;
        _this.faTruck = faTruck;
        _this.faPlay = faPlay;
        _this.faStop = faStop;
        _this.faEllipsisV = faEllipsisV;
        _this.faStopwatch = faStopwatch;
        _this.faHistory = faHistory;
        _this.faCoffee = faCoffee;
        _this.overtimeOrgaIndex = 0;
        _this.vacationLeftOrgaIndex = 0;
        _this.workTimeThisWeek = {
            hours: null,
            minutes: null
        };
        _this.showOvertimeTile = false;
        _this.showVacationLeftTile = false;
        _this.currentDate = new Date();
        return _this;
    }
    HomeComponent.prototype.hourToTimeObj = function (h) {
        var hours = Math.floor(Math.abs(h)) * Math.sign(h);
        return {
            hours: hours,
            minutes: Math.floor(h * 60 - hours * 60)
        };
    };
    HomeComponent.prototype.init = function () {
        var _this = this;
        this.as.getCurrentAccount().pipe(untilDestroyed(this)).subscribe(function (res) {
            if (!res)
                return;
            _this.account = res;
            _this.workTimeThisWeek = _this.hourToTimeObj(res.summary.work_hours_this_week);
            _this.showOvertimeTile = !!res.summary.overtime.length;
            _this.showVacationLeftTile = !!res.summary.vacation_left.length;
        });
        this.ws.getCurrent().pipe(untilDestroyed(this)).subscribe(function (res) {
            if (res === null) {
                _this.workSchedule = null;
                _this.workScheduleBreak = null;
            }
            else if (res) {
                _this.workSchedule = res;
                _this.calcWorkTime();
                // break (set current break only, when the end of last break of ws is still null)
                if (_this.workSchedule.breaks && _this.workSchedule.breaks.length && !_this.workSchedule.breaks[_this.workSchedule.breaks.length - 1].end) {
                    _this.workScheduleBreak = _this.workSchedule.breaks[_this.workSchedule.breaks.length - 1];
                }
                else {
                    _this.workScheduleBreak = null;
                }
            }
        });
        // Disp Entries
        this.getDispoEntries();
        this.os.getOrganizationsOfCurrentAccountObservable().subscribe(function (res) {
            if (!res)
                return;
            _this.orgas = res;
        });
        // update curr date every minute so the elapsed working time is displayed correctly
        setInterval(function () {
            _this.calcWorkTime();
            _this.calcProjectTime();
        }, 60000);
        this.ws.currWorkScheduleEntry.pipe(untilDestroyed(this)).subscribe(function (entry) {
            _this.workScheduleEntry = entry;
            _this.calcProjectTime();
            _this.isProjectRunning = !!entry;
            // get running project
            if (entry && entry.project) {
                _this.projectService.getByOrganizationAndId(entry.organization, entry.project)
                    .toPromise()
                    .then(function (project) { return _this.projectRunningTitle = _this.isProjectRunning ? project.title : null; });
            }
            else if (entry && entry.comment) {
                _this.projectRunningTitle = _this.isProjectRunning ? entry.comment : null;
            }
            else
                _this.projectRunningTitle = null;
            // get running qualification
            if (entry && entry.qualification) {
                _this.qualificationService.getByOrganizationAndId(entry.organization, entry.qualification)
                    .toPromise()
                    .then(function (qualification) { return _this.qualificationRunning = _this.isProjectRunning ? qualification : null; });
            }
            else
                _this.qualificationRunning = null;
        });
    };
    HomeComponent.prototype.getDispoEntries = function () {
        var _this = this;
        var to = new Date();
        to.setDate(to.getDate() + 2);
        to.setHours(0, 0, 0, 0);
        this.ds.getAllForCurrentAccount({ date_from: new Date(), date_to: to }).pipe(untilDestroyed(this)).subscribe(function (dispos) {
            if (!dispos)
                return;
            var fromDate = new Date();
            fromDate.setHours(0, 0, 0, 0);
            _this.dispos = _this.ds.filterModelsByDate(_this.ds.dateToString(fromDate), _this.ds.dateToString(to));
        });
    };
    HomeComponent.prototype.returnOvertime = function (orgaId) {
        var o = this.account.summary.overtime.find(function (val) { return val.company.organization == orgaId; });
        return [this.hourToTimeObj(o.value)];
    };
    HomeComponent.prototype.reload = function () {
        this.as.getCurrentAccount();
        this.ws.getCurrent();
        this.getDispoEntries();
    };
    /**
    * start work
    * @param orga
    */
    HomeComponent.prototype.startWork = function (orga, past, company) {
        var _this = this;
        if (past === void 0) { past = false; }
        if (company === void 0) { company = null; }
        this.dialog.open(WorkScheduleDialogComponent, __assign({}, defaultDialogOptions, { data: {
                type: DialogType.StartWork,
                past: past,
                organization: orga,
                company: company
            } })).afterClosed().subscribe(function (workSchedule) {
            if (!workSchedule) {
                _this.reloadData();
                return;
            }
            // Check if start of ws is more than 20 hours in the past -> redirect to ws overview
            if (Date.now() - (createDateFromDatetime(workSchedule.date_in)).getTime() > (12 * 3600 * 1000)) {
                _this.router.navigateByUrl("/work-schedule/" + workSchedule.organization + "/" + workSchedule.id);
            }
            else {
                _this.workSchedule = workSchedule;
            }
        });
    };
    /**
    * trigger ending work
    */
    HomeComponent.prototype.endWork = function () {
        this.router.navigateByUrl('/work-schedules/review');
    };
    HomeComponent.prototype.startBreak = function () {
        var _this = this;
        this.dialog.open(WorkScheduleBreakDialogComponent, __assign({}, defaultBreakDialogOptions, { data: {
                type: BreakDialogType.StartBreak,
                ws: this.workSchedule,
                organization: this.workSchedule.organization
            } })).afterClosed().subscribe(function (workScheduleBreak) {
            if (!workScheduleBreak) {
                _this.reloadData();
                return;
            }
        });
    };
    HomeComponent.prototype.endBreak = function () {
        var _this = this;
        this.dialog.open(WorkScheduleBreakDialogComponent, __assign({}, defaultBreakDialogOptions, { data: {
                type: BreakDialogType.EndBreak,
                id: this.workScheduleBreak.id,
                start: this.workScheduleBreak.start,
                ws: this.workSchedule,
                organization: this.workSchedule.organization
            } })).afterClosed().subscribe(function (workScheduleBreak) {
            if (!workScheduleBreak) {
                _this.reloadData();
                return;
            }
        });
    };
    HomeComponent.prototype.breakTime = function () {
        if (this.workScheduleBreak) {
            var start = createDateFromDatetime(this.workScheduleBreak.start);
            var end = this.workScheduleBreak.end ? createDateFromDatetime(this.workScheduleBreak.end) : new Date();
            var minutes = Math.max(0, (end.getTime() - start.getTime()) / 1000 / 60);
            return {
                hours: Math.floor(minutes / 60),
                minutes: Math.floor(minutes % 60)
            };
        }
        else {
            return null;
        }
    };
    HomeComponent.prototype.calcWorkTime = function () {
        if (this.workSchedule) {
            var minutes = (new Date().getTime() - createDateFromDatetime(this.workSchedule.date_in).getTime()) / 1000 / 60;
            this.workTime = {
                hours: Math.floor(minutes / 60),
                minutes: Math.floor(minutes % 60)
            };
        }
    };
    HomeComponent.prototype.calcProjectTime = function () {
        if (this.workScheduleEntry) {
            var minutes = (new Date().getTime() - createDateFromDatetime(this.workScheduleEntry.start).getTime()) / 1000 / 60;
            this.projectTime = {
                hours: Math.floor(minutes / 60),
                minutes: Math.floor(minutes % 60)
            };
        }
    };
    HomeComponent.prototype.endProject = function () {
        var _this = this;
        this.dialog.open(WorkScheduleDialogComponent, __assign({}, defaultDialogOptions, { data: {
                type: DialogType.EndProject,
                schedule: this.workSchedule,
                entry: this.ws.currWorkScheduleEntry.getValue()
            } })).afterClosed().subscribe(function (entry) {
            if (entry !== undefined)
                _this.ws.setCurrWorkScheduleEntry(!entry || entry.end ? null : entry);
        });
    };
    HomeComponent.prototype.startProject = function () {
        var _this = this;
        this.dialog.open(WorkScheduleDialogComponent, __assign({}, defaultDialogOptions, { data: {
                type: DialogType.StartProject,
                schedule: this.workSchedule,
            } })).afterClosed().subscribe(function (entry) {
            if (entry !== undefined)
                _this.ws.setCurrWorkScheduleEntry(entry);
        });
    };
    return HomeComponent;
}(ComponentInit));
export { HomeComponent };
