import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent{

  @Input() loading:boolean;
  @Input() loadError:boolean;
  @Input() retry: Function;
  @Input() diameter: number = 80;

  constructor() { }

  onRetryClick(){
    this.retry();
  }

}
