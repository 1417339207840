import { HttpClient } from '@angular/common/http';
import { IdentityService } from './identity.service';
import { flatMap, tap } from 'rxjs/operators';
import { empty, BehaviorSubject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./identity.service";
import * as i2 from "../../auth/auth.service";
import * as i3 from "@angular/common/http";
var AccountService = /** @class */ (function () {
    function AccountService(identity, auth, http) {
        this.identity = identity;
        this.auth = auth;
        this.http = http;
        // restore account
        this.account = new BehaviorSubject(JSON.parse(localStorage.getItem('account')));
    }
    AccountService.prototype.update = function (acc) {
        this.account.next(acc);
        localStorage.setItem('account', JSON.stringify(acc));
    };
    AccountService.prototype.clear = function () {
        this.account.next(null);
        localStorage.removeItem('account');
    };
    /**
     * updates email address
     * @param string  email
     * @return boolean
     *
     */
    AccountService.prototype.updateEmail = function (email) {
        var _this = this;
        var payload = {
            email: email,
            credentials: {
                email: null,
                password: null
            }
        };
        return this.identity.confirmIdentity()
            .pipe(flatMap(function (cred) {
            if (!cred)
                return empty();
            // identity confirmed, now send data to server
            // add credentials to payload
            payload.credentials = cred;
            // set email request
            return _this.http.post('/v1/account/email', payload)
                .pipe(tap(function () {
                //update acc on success
                var acc = _this.account.getValue();
                acc.email = email;
                _this.update(acc);
            }));
        }), flatMap(function (obs) {
            // re-login on success, as the auth token is no longer valid
            return _this.auth.login(email, payload.credentials.password);
        }));
    };
    /**
     * updates password
     * @param string  password
     * @return boolean
     */
    AccountService.prototype.updatePassword = function (password) {
        var _this = this;
        var payload = {
            password: password,
            credentials: {
                email: null,
                password: null
            }
        };
        return this.identity.confirmIdentity()
            .pipe(flatMap(function (cred) {
            if (!cred)
                return empty();
            // identity confirmed, now send data to server
            // add credentials to payload
            payload.credentials = cred;
            // set email request
            return _this.http.post('/v1/account/password', payload);
        }), flatMap(function (obs) {
            // re-login on success, as the auth token is no longer valid
            return _this.auth.login(payload.credentials.email, password);
        }));
    };
    AccountService.prototype.getCurrentAccount = function () {
        var _this = this;
        this.http.get('/v1/account').subscribe(function (res) {
            _this.account.next(res);
            localStorage.setItem('account', JSON.stringify(res));
        });
        return this.account.asObservable();
    };
    AccountService.prototype.getAccountAsObservable = function () {
        return this.account.asObservable();
    };
    AccountService.prototype.updateName = function (firstname, lastname) {
        var _this = this;
        var payload = {
            first_name: firstname,
            last_name: lastname
        };
        return this.http.post('/v1/account/name', payload)
            .pipe(tap(function () {
            // update acc on success
            var acc = _this.account.getValue();
            acc.first_name = firstname;
            acc.last_name = lastname;
            _this.update(acc);
        }));
    };
    /**
     * creates new lupax account
     * @param firstname
     * @param lastname
     * @param email
     * @param password
     * @param inviteToken
     */
    AccountService.prototype.createAccount = function (firstname, lastname, email, password, inviteToken) {
        var payload = {
            account: {
                email: email,
                password: password,
                first_name: firstname,
                last_name: lastname
            },
            inviteToken: undefined
        };
        if (inviteToken)
            payload.inviteToken = inviteToken;
        return this.http.post('/v1/account', payload);
    };
    /**
     * activates
     * @param token
     */
    AccountService.prototype.activate = function (token) {
        return this.http.post('/v1/account/activate', { token: token });
    };
    /**
     * resend activation token to email
     * @param email
     */
    AccountService.prototype.resendActivationToken = function (email) {
        return this.http.post('/v1/account/activate/resend', { email: email });
    };
    AccountService.prototype.applyInvite = function (token) {
        return this.http.post('/v1/account/invite', { token: token });
    };
    /**
     * resets passwort of account specified by email
     * @param email
     */
    AccountService.prototype.resetPassword = function (email) {
        return this.http.post('/v1/account/password/reset', { email: email });
    };
    /**
     * sets new password for account specified by token
     * @param password
     * @param token
     */
    AccountService.prototype.setPasswordToken = function (password, token) {
        return this.http.post('/v1/account/password/token', { password: password, token: token });
    };
    /**
     * updates one account setting
     * @param name
     * @param value
     */
    AccountService.prototype.updateSetting = function (name, value) {
        var settings = {};
        settings[name] = value;
        return this.updateSettings(settings);
    };
    /**
     * updates settings
     * @param settings
     */
    AccountService.prototype.updateSettings = function (settings) {
        var _this = this;
        return this.http.post('/v1/account/settings', settings).pipe(tap(function (res) {
            //update chached account
            var a = _this.account.getValue();
            a.settings = Object.assign(a.settings, settings);
            _this.update(a);
        }));
    };
    /**
     * updates staff settings
     * @param settings
     */
    AccountService.prototype.updateStaffSetting = function (orga_id, type, name, value) {
        var _this = this;
        return this.http.post('/v1/organizations/' + orga_id + '/staffs/current/setting', {
            type: type,
            name: name,
            value: value
        }).pipe(tap(function (res) {
            //update chached account
            var a = _this.account.getValue();
            if (!a.staff_settings[orga_id][type])
                a.staff_settings[orga_id][type] = {};
            a.staff_settings[orga_id][type][name] = value;
            _this.update(a);
        }));
    };
    AccountService.prototype.accountWithEmailExists = function (email) {
        return this.http.post('/v1/accounts/exists', { email: email });
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.IdentityService), i0.inject(i2.AuthService), i0.inject(i3.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
