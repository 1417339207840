import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tour } from './tour.model';
import { Observable } from 'rxjs';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { JobDetails } from '../../jobs/shared/job.model';
import { Vehicle } from './vehicle.model';

@Injectable({
  providedIn: 'root'
})
export class TourService extends CachableModelService<Tour>{
  fromMin:Date;
  toMax:Date;

  listCacheModel: CachableModelService<Tour>;
  listVehicleCacheModel: CachableModelService<Vehicle>;

  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'tours');

    this.listCacheModel = new CachableModelService(http, 'toursList');
    this.listVehicleCacheModel = new CachableModelService(http, 'vehicleList');
  }

  /**
   * Retrieves all tours of all organizations for the current user
   */
  getAllForCurrentAccount(params):Observable<Tour[]>{
    // get cached data
    let data = this.listCacheModel.getAll();

    params = {...params};

    if(params.date_from instanceof Date) params.date_from.setHours(0,0,0,0);
    params.date_from = this.dateToString(params.date_from);

    let http = this.http.get<Tour[]>('/v1/tours', {params: params});

    return this.listCacheModel.cachedRequest(data, http);
  }

  /**
   * Retrieves a tour by organization and id
   * @param orga
   * @param id
   */
  getByOrganizationAndId(orga, id):Observable<Tour>{
    // Get Data
    let data = this.findModelByOrganizationAndId(orga, id);
    // If we found nothing -> get data from tour list
    if(!data) data = this.listCacheModel.findModelByOrganizationAndId(orga, id);

    let http = this.http.get<JobDetails>('/v1/organizations/'+orga+'/tours/'+id);
    return this.cachedRequest(data, http);
  }

  setCompleted(orga, id, bool){
    return this.http.post('/v1/organizations/'+orga+'/tours/'+id+'/completed', {completed:bool});
  }

  /**
   * Retrieves all vehicles of all tours for the current user
   */
  getAllVehicles():Observable<Vehicle[]>{
    const cache = this.listVehicleCacheModel;
    const http = this.http.get<Vehicle[]>('/v1/tours/vehicles');

    return cache.cachedRequest(cache.getAll(), http);
  }
}
