import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { EventService } from './event.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class EventResolver extends CachableClientModelResolver<Event>{

	constructor(s:EventService, r:Router) {
		super(s, 'calendar', r);
	}
}
