import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DispositionService } from './shared/disposition.service';
import { DispositionsComponent } from './dispositions.component';
import { DispositionListComponent } from './disposition-list/disposition-list.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    DispositionsComponent,
    DispositionListComponent,
  ],
  providers: [
    DispositionService,
  ],
  exports: [
    DispositionListComponent
  ]
})
export class DispositionsModule { }
