var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { faHome, faCheck, faTruck, faRoad, faLocationArrow, faUser, faUsers, faMapMarkerAlt, faBriefcase, faCar, faBed, faMale, } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCalendarAlt, faBuilding, } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { TourService } from '../shared/tour.service';
import { JobService } from '../../jobs/shared/job.service';
import { OrganizationService } from '../../core/services/organization.service';
import { ScrollLocations } from '../../core/globals/scroll-locations';
var TourDetailComponent = /** @class */ (function (_super) {
    __extends(TourDetailComponent, _super);
    function TourDetailComponent(route, router, ts, js, os, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.router = router;
        _this.ts = ts;
        _this.js = js;
        _this.os = os;
        _this.scrollLocations = scrollLocations;
        _this.faHome = faHome;
        _this.faCheck = faCheck;
        _this.faClock = faClock;
        _this.faTruck = faTruck;
        _this.faRoad = faRoad;
        _this.faLocationArrow = faLocationArrow;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faUser = faUser;
        _this.faUsers = faUsers;
        _this.faMapMarkerAlt = faMapMarkerAlt;
        _this.faBriefcase = faBriefcase;
        _this.faCar = faCar;
        _this.faBed = faBed;
        _this.faBuilding = faBuilding;
        _this.faMale = faMale;
        _this.falseVar = false;
        _this.fileCategories = [];
        return _this;
    }
    TourDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (tour) {
                if (tour)
                    _this.update(tour);
            });
        });
    };
    TourDetailComponent.prototype.update = function (tour) {
        var _this = this;
        this.tour = tour;
        this.generateMapsLink();
        // group files by category
        if (tour.files) {
            this.fileCategories = [];
            tour.files.forEach(function (file) {
                var category = _this.fileCategories.find(function (val) { return val.id == (file.cat ? file.cat.id : 0); });
                if (!category) {
                    _this.fileCategories.push({
                        id: file.cat ? file.cat.id : 0,
                        title: file.cat ? file.cat.title : 'Ohne Kategorie',
                        files: [file]
                    });
                }
                else {
                    category.files.push(file);
                }
            });
        }
    };
    TourDetailComponent.prototype.generateMapsLink = function () {
        var s = 'https://www.google.com/maps/dir';
        var last = null;
        // add start address
        if (this.tour.address_start) {
            s += '/' + encodeURIComponent(this.tour.address_start.text);
            last = this.tour.address_start.text;
        }
        // add jobs
        for (var j in this.tour.jobs) {
            if (this.tour.jobs[j].address) {
                if (this.tour.jobs[j].address.text != last) {
                    s += '/' + encodeURIComponent(this.tour.jobs[j].address.text);
                    last = this.tour.jobs[j].address.text;
                }
            }
        }
        // add end address
        if (this.tour.address_end && this.tour.address_end.text != last)
            s += '/' + encodeURIComponent(this.tour.address_end.text);
        this.mapsLink = s;
    };
    TourDetailComponent.prototype.getMapsLinkByAddressText = function (text) {
        return text ? 'https://maps.google.com/?daddr=' + encodeURIComponent(text) : '';
    };
    TourDetailComponent.prototype.onDoneToggle = function (e) {
        var _this = this;
        this.ts.setCompleted(this.tour.organization, this.tour.id, e.checked).subscribe(function (res) {
            if (res) {
                //set completed flag to all jobs
                for (var key in _this.tour.jobs)
                    _this.tour.jobs[key].tour_completed = e.checked;
                //success
                _this.tour.completed = e.checked;
            }
            else {
                e.source.checked = !e.source.checked;
            }
        }, function (err) {
            e.source.checked = !e.source.checked;
        });
    };
    TourDetailComponent.prototype.routeJob = function (j) {
        this.router.navigate(["/jobs/" + this.tour.organization + "/" + j.id], { queryParams: { tour_id: j.tour } });
    };
    return TourDetailComponent;
}(ComponentInit));
export { TourDetailComponent };
