import { TitleService } from './title.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./title.service";
var DashboardService = /** @class */ (function () {
    function DashboardService(ts) {
        this.ts = ts;
        this.title = new BehaviorSubject(this.ts.getTitle());
        this.online = new BehaviorSubject(true);
        this.loading = new BehaviorSubject(false);
    }
    DashboardService.prototype.setTitle = function (t) {
        this.title.next(t);
    };
    Object.defineProperty(DashboardService.prototype, "titleObservable", {
        get: function () {
            return this.title.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    DashboardService.prototype.setOnline = function (bool) {
        this.online.next(bool);
    };
    Object.defineProperty(DashboardService.prototype, "onlineObservable", {
        get: function () {
            return this.online.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    DashboardService.prototype.setLoading = function (bool) {
        return this.loading.next(bool);
    };
    Object.defineProperty(DashboardService.prototype, "loadingObservable", {
        get: function () {
            return this.loading.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    DashboardService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.inject(i1.TitleService)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
