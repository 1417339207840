import Model from "../../core/classes/model.class";

import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

export class RosterModel extends Model {
	id: number;
	type: string;
	start: Date;
	end: Date;
	title: string;
	shift: number;
	duration: string;
	days: number;
	organization: number;
	company: {
		id: number,
		title: string,
	};

	constructor(obj:any) {
		super({
			...obj,
			start: createDateFromDatetime(obj.start),
			end: createDateFromDatetime(obj.end),
		});
	}

	public toApiObject() {

		return {
			...new Object(this),
			start: this.start.toISOString(),
			end: this.end.toISOString(),
		}
	}
}
