/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i8 from "@fortawesome/angular-fontawesome";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i11 from "@angular/material/progress-spinner";
import * as i12 from "@angular/common";
import * as i13 from "./file-upload.component";
import * as i14 from "../../core/services/file.service";
import * as i15 from "@angular/router";
import * as i16 from "../../core/globals/scroll-locations";
import * as i17 from "@angular/material/dialog";
import * as i18 from "../../core/services/account.service";
var styles_FileUploadComponent = [i0.styles];
var RenderType_FileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "button", [["class", "spinner-button"], ["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "fa-icon", [["class", "ng-fa-icon"], ["mat-icon", ""], ["style", "margin-right: 8px;"]], [[8, "innerHTML", 1]], null, null, i7.View_FaIconComponent_0, i7.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i8.FaIconComponent, [i9.DomSanitizer], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Datei hochladen"])), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i10.View_MatSpinner_0, i10.RenderType_MatSpinner)), i1.ɵdid(8, 49152, null, 0, i11.MatSpinner, [i1.ElementRef, i4.Platform, [2, i12.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i11.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "button", [["class", "spinner-button"], ["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "fa-icon", [["class", "ng-fa-icon"], ["mat-icon", ""], ["style", "margin-right: 8px;"]], [[8, "innerHTML", 1]], null, null, i7.View_FaIconComponent_0, i7.RenderType_FaIconComponent)), i1.ɵdid(12, 573440, null, 0, i8.FaIconComponent, [i9.DomSanitizer], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Foto aufnehmen"])), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i10.View_MatSpinner_0, i10.RenderType_MatSpinner)), i1.ɵdid(16, 49152, null, 0, i11.MatSpinner, [i1.ElementRef, i4.Platform, [2, i12.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i11.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), (_l()(), i1.ɵeld(17, 0, [["fileInput", 1]], null, 0, "input", [["accept", "image/*,application/pdf"], ["hidden", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, [["cameraInput", 1]], null, 0, "input", [["accept", "image/*,application/pdf"], ["capture", "environment"], ["hidden", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.uploading; var currVal_3 = "primary"; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _co.faUpload; _ck(_v, 4, 0, currVal_5); var currVal_9 = "20"; _ck(_v, 8, 0, currVal_9); var currVal_12 = _co.uploading; var currVal_13 = "primary"; _ck(_v, 10, 0, currVal_12, currVal_13); var currVal_15 = _co.faCamera; _ck(_v, 12, 0, currVal_15); var currVal_19 = "20"; _ck(_v, 16, 0, currVal_19); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_4); var currVal_6 = i1.ɵnov(_v, 8)._noopAnimations; var currVal_7 = i1.ɵnov(_v, 8).diameter; var currVal_8 = i1.ɵnov(_v, 8).diameter; _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8); var currVal_10 = (i1.ɵnov(_v, 10).disabled || null); var currVal_11 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_10, currVal_11); var currVal_14 = i1.ɵnov(_v, 12).renderedIconHTML; _ck(_v, 11, 0, currVal_14); var currVal_16 = i1.ɵnov(_v, 16)._noopAnimations; var currVal_17 = i1.ɵnov(_v, 16).diameter; var currVal_18 = i1.ɵnov(_v, 16).diameter; _ck(_v, 15, 0, currVal_16, currVal_17, currVal_18); }); }
export function View_FileUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permission(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-upload", [], null, null, null, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i1.ɵdid(1, 4440064, null, 0, i13.FileUploadComponent, [i14.FileService, i15.Router, i16.ScrollLocations, i17.MatDialog, i18.AccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadComponentNgFactory = i1.ɵccf("app-file-upload", i13.FileUploadComponent, View_FileUploadComponent_Host_0, { organization: "organization", categoriesAvailable: "categoriesAvailable", table: "table", row: "row" }, { fileAdded: "fileAdded" }, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };
