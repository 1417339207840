<main>
    <app-loading-spinner [loading]="!missionProcesses" [loadError]="loadError" [retry]="reload"></app-loading-spinner>
    <p class="text-top color-text-grey text-center" *ngIf="missionProcesses && missionProcesses.length == 0" i18n>
      Keine Einträge vorhanden
    </p>

    <ng-container *ngFor="let list of listGroups">
      <ng-container *ngIf="list.data.length > 0">
        <h2 i18n class="mat-h2 app-list-heading">{{list.name}}</h2>
        <app-mission-process-list [missionProcesses]="list.data"></app-mission-process-list>
      </ng-container>
    </ng-container>
  </main>
