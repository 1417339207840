import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { DashboardService } from '../services/dashboard.service';

/** This injector will add the api location for every http request */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	loadingTimeout:any;

	constructor(
		private dash: DashboardService,
	){}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		// for external locations continue
		if(req.url.includes('://') || req.url.includes('./'))
			return next.handle(req);

		// prepend api server location
		const apiReq = req.clone({
			url: environment.apiUrl+req.url
		});

		if(req.method == 'POST') this.dash.setLoading(true);

		return next.handle(apiReq).pipe(tap(succ => {
			this.dash.setOnline(true);

			// Stop Loading indicator, when we received http response
			if(succ instanceof HttpResponse){
				clearTimeout(this.loadingTimeout);
				this.dash.setLoading(false);
			}

			// Start Loading indicator when cached data was returned
			if(!(succ instanceof HttpResponse)){
				clearTimeout(this.loadingTimeout);
				this.loadingTimeout = setTimeout(() => {
					this.dash.setLoading(true);
				}, 300);
			}


		}, err => {
			console.error(err);
			// Stop Loading indicator
			clearTimeout(this.loadingTimeout);
			this.dash.setLoading(false);

			// if(err.name == 'HttpErrorResponse' && err.error && err.error.text)
				// alert(err.error.text.replace(/<\/?[^>]+(>|$)/g, ""));

			//set offline, when offline
			if(err.status == 0 || err.status == 504)
				this.dash.setOnline(false);
		}));
	}
}
