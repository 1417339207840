import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '../../core/services/account.service';

@Component({
  templateUrl: './edit-email-dialog.component.html'
})
export class EditEmailDialogComponent implements OnInit {

  form: FormGroup;
  existsError:boolean = false;
  disabled:boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<EditEmailDialogComponent>,
    private as: AccountService,
    @Inject(MAT_DIALOG_DATA) public email:string
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  onSubmit(){
    if(this.form.valid){
      this.disabled = true;
      this.existsError = false;

      // check if account with email exists
      this.as.accountWithEmailExists(this.form.value.email).subscribe(bool => {
        if(bool){
          // already exists --> show error
          this.existsError = true;
          this.disabled = false;
        }
        else
          // otherwise return new email
          this.dialog.close(this.form.value.email);
      })
    }
  }
}
