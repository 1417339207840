import { Component, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Project } from '../shared/project.model';
import { ComponentInit } from '../../core/classes/component-init.class';

import { MatDialog } from '@angular/material';

import { MatTab } from '@angular/material';

import {
  faUser,
  faMale,
  faLocationArrow,
  faInfo,
  faFlag,
  faPhone,
  faRocket,
  faHandshake,
  faUsers,
  faInfoCircle,
  faBriefcase,
  faClock,
  faBookmark,
  faUpload,
  faCheckSquare,
  faCalendarTimes,
  faExclamation
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
  faComment,
  faBuilding,
  faFileAlt,
  faSquare
} from '@fortawesome/free-regular-svg-icons';

import { ProjectJob } from '../../jobs/shared/job.model';

import { ScrollLocations } from '../../core/globals/scroll-locations';
import { Crew } from 'src/app/jobs/shared/crew/crew.model';
import { Staff } from 'src/app/core/models/staff.model';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent extends ComponentInit {
  @ViewChildren(MatTab) tabs;

  project:Project;

  staffsPerJob?: {
    job:ProjectJob,
    staffs: Staff[],
  }[];
  staffSize:Number;

  fileCategories: any[] = [];
  fileUploading: boolean = false;

  taskGroups: any[] = [];

  tab_num = 0;
  tabSelected = 0;
  SWIPE_ACTION = { LEFT: 'swiperight', RIGHT: 'swipeleft' };

  faCalendarAlt = faCalendarAlt;
  faUser = faUser;
  faMale = faMale;
  faLocationArrow = faLocationArrow;
  faInfo = faInfo;
  faComment = faComment;
  faFlag = faFlag;
  faPhone = faPhone;
  faBuilding = faBuilding;
  faRocket = faRocket;
  faHandshake = faHandshake;
  faFileAlt = faFileAlt;
  faUsers = faUsers;
  faInfoCircle = faInfoCircle;
  faBriefcase = faBriefcase;
  faClock = faClock;
  faBookmark = faBookmark;
  faUpload = faUpload;
  faCheckSquare = faCheckSquare;
  faSquare = faSquare;
  faCalendarTimes = faCalendarTimes;
  faExclamation = faExclamation;

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected scrollLocations: ScrollLocations
  ) {
    super(router, scrollLocations, route);
  }

  init(){
    this.route.data.subscribe(data => {
      if(data.resolved.model)
        this.update(data.resolved.model);
      data.resolved.updates.subscribe(project => {
        if(project) this.update(project)
      });
    });
  }

  update(project){
    this.project = project;

    if(project.jobs){
      let jobsWithCrew = project.jobs.filter((job:ProjectJob) => job.crew);

      /* map staffs per crewGroup per job to job */
      this.staffsPerJob = jobsWithCrew.map((job:ProjectJob) => {
        let staffMap = new Map<Number, Staff>();
        job.crew.forEach((crew:Crew) => crew.staffs.forEach((staff:Staff) => staffMap.set(staff.id, staff)));

        return {job: job, staffs: Array.from(staffMap.values()).slice(0)};
      });

      /* get total staff, remove duplicates, and return the staff size */
      this.staffSize = this.staffsPerJob.reduce((staffSet:Set<Number>, group:any) => {
        group.staffs.forEach((staff:Staff) => staffSet.add(staff.id));
        return staffSet;
      }, new Set<Number>()).size;
    }

    // group files by category
    if(project.files){
        this.fileCategories = [];
        project.files.forEach(file => {
            let category = this.fileCategories.find(val => val.id == (file.cat ? file.cat.id : 0));
            if(!category){
                this.fileCategories.push({
                    id: file.cat ? file.cat.id : 0,
                    title: file.cat ? file.cat.title : 'Ohne Kategorie',
                    files: [file]
                });
            } else {
                category.files.push(file);
            }
        });
    }

    // group tasks by completed/incomplete
    if(project.tasks){
        this.taskGroups = [
            {
                title: 'Ausstehend',
                tasks: project.tasks.filter(t => !t.completed)
            },
            {
                title: 'Erledigt',
                tasks: project.tasks.filter(t => t.completed)
            }
        ].filter(g => g.tasks.length);
    }

    // Update Tab Count (Wait for rendering)
    setTimeout(() => { this.tab_num = this.tabs.length; });
  }

  routeJob(job, params){
    this.router.navigate(['/jobs/'+job.organization+'/'+job.id], {queryParams: params});
  }

  routeTask(task){
    this.router.navigate(['/tasks/'+task.organization+'/'+task.id]);
  }

  swipe(eType){
    if(eType === this.SWIPE_ACTION.LEFT && this.tabSelected > 0)
      this.tabSelected--;
    else if(eType === this.SWIPE_ACTION.RIGHT && this.tabSelected < (this.tab_num - 1))
      this.tabSelected++;
  }

  onFileAdded(){
    this.reloadData();
    document.querySelector('.mat-tab-body-active main').scrollTo(0, 0);
  }

}
