import { Component, OnInit, Input } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import {
  faTruck,
  faCheck,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
  faCalendarCheck
} from '@fortawesome/free-regular-svg-icons';

import { Disposition } from '../shared/disposition.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-disposition-list',
  templateUrl: './disposition-list.component.html',
  styleUrls: ['./disposition-list.component.css']
})
export class DispositionListComponent extends ComponentInit implements OnInit {

  @Input() dispositions:Disposition[];
  @Input() relativeWords:boolean = false;

  faCheck = faCheck;
  faCalendarAlt = faCalendarAlt;
  faBriefcase = faBriefcase;

  icons = {
    'job': faBriefcase,
    'tour': faTruck,
    'event': faCalendarCheck
  }

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected scrollLocations: ScrollLocations
  ) {
      super(router, scrollLocations, route);
  }

  routeDispo(d){
    this.router.navigate([d.type+'s', d.organization, d.data.id]);
  }

  getJobsString(tour){
    let jobs = [];
    if(!tour.jobs) return '';

    tour.jobs.forEach(job => {
        if(job.title && !jobs.includes(job.title))
            jobs.push(job.title)
    });
    return jobs.join(' + ');
  }

}
