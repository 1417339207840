import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { WorkSchedule } from './work-schedule.model';
import { WorkService } from './work.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class WorkScheduleResolver extends CachableClientModelResolver<WorkSchedule>{

  constructor(s: WorkService, r:Router) {
    super(s, 'workSchedules', r);
  }
}
