import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { Qualification } from '../models/qualification.model';
import { HttpClient } from '@angular/common/http';

interface Options {
  project?: number,
  date?: string | Date
}

@Injectable({
    providedIn: 'root'
})
export class QualificationService{

    constructor(
        protected http: HttpClient,
        @Inject(LOCALE_ID) protected locale: string,
    ) {

    }
    
    getByOrganizationAndId(orga_id:number, id:number):Observable<Qualification>{
        return this.http.get<Qualification>('/v1/organizations/'+orga_id+'/qualifications/'+id);
    }
    
    /**
     * Returns all qualfications of current staff for specific organization
     */
     getAllByOrganizazionAndCurrentStaff(orga_id:number, options:Options = {}):Observable<Qualification[]>{
         const params:any = {};
         if(options.project) params['project'] = options.project;
         if(options.date) params['date'] = formatDate(options.date, 'yyyy-MM-dd', this.locale);

         return this.http.get<Qualification[]>('/v1/organizations/'+orga_id+'/staffs/current/qualifications/', { params: params });
     }
}
