import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  /**
   * Sets time to beginning of day
   */
  startOfDay(date:Date = new Date()):Date {

    date = new Date(date);
    date.setHours(0, 0, 0, 0);

    return date;
  }

  /**
   * Sets time to end of day
   * @param date
   */
  endOfDay(date:Date = new Date()): Date {

    date = new Date(date);
    date.setHours(23, 59, 59, 999);

    return date;
  }

  /**
   * maps hours like 'hh:mm:ss:ms' where (m,s,ms are optional) to a date
   * @param date
   * @param hours
   */
  mapHoursToDate(date:Date, hours:string) {
    const arr = hours.split(':').map(str => parseInt(str));
    date.setHours(arr[0], arr[1] || 0, arr[2] || 0, arr[3] || 0);
    return date;
  }

  secondsToHMS(secs) {
    function z(n){return (n<10?'0':'') + Math.floor(n);}
    var sign = secs < 0? '-':'';
    secs = Math.abs(secs);
    return sign + z(secs/3600 |0) + ':' + z((secs%3600) / 60 |0) + ':' + z(secs%60);
  }
}
