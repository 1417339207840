import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { VacationRequest } from './vacation-request.model';
import { VacationRequestService } from './vacation-request.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class VacationRequestResolver extends CachableClientModelResolver<VacationRequest>{

  constructor(s: VacationRequestService, r:Router) {
    super(s, 'vacationRequests', r);
  }
}
