<mat-dialog-content>{{data.text}}</mat-dialog-content>
<mat-dialog-actions style="justify-content:flex-end">
  <button mat-button mat-dialog-close>
    <ng-container *ngIf="data.cancelTitle; else cancelText">{{data.cancelTitle}}</ng-container>
    <ng-template #cancelText i18n>Abbrechen</ng-template>
  </button>
  <button mat-raised-button [mat-dialog-close]="true" [color]="data.okColor || 'primary'">
    <ng-container *ngIf="data.okTitle;else okText">{{data.okTitle}}</ng-container>
    <ng-template #okText i18n>Okay</ng-template>
  </button>
</mat-dialog-actions>
