<ng-container *ngIf="project">
    <mat-tab-group mat-stretch-tabs headerPosition="above" class="tabs-with-icon"
            [selectedIndex]="tabSelected" (selectedIndexChange)="tabSelected = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                <fa-icon mat-list-icon [icon]="faInfoCircle"></fa-icon> <span i18n class="label-text">Allgemein</span>
            </ng-template>
            <main>
                <!-- Informations -->
                <h2 class="mat-h2 app-list-heading" i18n>Informationen</h2>
                <mat-list>
                    <!-- Title -->
                    <mat-list-item class="show-all">
                        <fa-icon mat-list-icon [icon]="faRocket"></fa-icon>
                        <span mat-line>{{project.title}}</span>
                    </mat-list-item>
                    <mat-divider></mat-divider>

                    <!-- Company -->
                    <mat-list-item>
                        <fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
                        <span mat-line>{{project.company.title}}</span>
                    </mat-list-item>
                    <mat-divider></mat-divider>

                    <!-- Period -->
                    <mat-list-item>
                        <fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
                        <span mat-line>
                            <app-date-span [start]="project.date_start" [end]="project.date_end"></app-date-span>
                        </span>
                    </mat-list-item>

                    <!-- Status -->
                    <mat-divider *ngIf="project.status"></mat-divider>
                    <mat-list-item *ngIf="project.status">
                        <fa-icon mat-list-icon [icon]="faBookmark"></fa-icon>
                        <span mat-line>{{project.status.title}}</span>
                    </mat-list-item>

                    <!-- Responsible -->
                    <mat-divider *ngIf="project.responsible || (project.responsible_extra && project.responsible_extra.length > 0)"></mat-divider>
                    <mat-list-item class="show-all" *ngIf="project.responsible || (project.responsible_extra && project.responsible_extra.length > 0)">
                        <fa-icon mat-list-icon [icon]="faUser"></fa-icon>
                        <div mat-line>
                            <ng-container *ngIf="project.responsible">
                                <span>{{project.responsible.first_name}} {{project.responsible.last_name}}</span>&nbsp;
                                <a *ngIf="project.responsible.firm_mobile"
                                class="color-text-primary"
                                style="text-decoration: none"
                                href="tel:{{project.responsible.firm_mobile}}">
                                    {{project.responsible.firm_mobile}}
                                </a>
                                <span *ngIf="project.responsible.firm_mobile && project.responsible.firm_tel"> | </span>
                                <a *ngIf="project.responsible.firm_tel"
                                class="color-text-primary"
                                style="text-decoration: none"
                                href="tel:{{project.responsible.firm_tel}}">
                                    {{project.responsible.firm_tel}}
                                </a>
                            </ng-container>
                            <div *ngIf="project.responsible_extra && project.responsible_extra.length > 0" style="margin-top: 5px;">
                                <ng-container *ngFor="let r of project.responsible_extra; first as isFirst;">
                                    <br *ngIf="!isFirst"/>
                                    <small>
                                        <span>{{r.first_name}} {{r.last_name}}</span>&nbsp;
                                        <a *ngIf="r.firm_mobile"
                                        class="color-text-primary"
                                        style="text-decoration: none"
                                        href="tel:{{r.firm_mobile}}">
                                            {{r.firm_mobile}}
                                        </a>
                                        <span *ngIf="r.firm_mobile && r.firm_tel"> | </span>
                                        <a *ngIf="r.firm_tel"
                                        class="color-text-primary"
                                        style="text-decoration: none"
                                        href="tel:{{r.firm_tel}}">
                                            {{r.firm_tel}}
                                        </a>
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>

                <!-- Client -->
                <ng-container *ngIf="project.client">
                    <h2 class="mat-h2 app-list-heading" i18n>Kunde</h2>
                    <mat-list>
                        <mat-list-item>
                            <fa-icon mat-list-icon [icon]="faMale"></fa-icon>
                            <span mat-line>{{project.client.name}}</span>
                        </mat-list-item>

                        <ng-container *ngFor="let phone of project.client.phone_numbers">
                            <mat-divider></mat-divider>
                            <mat-list-item class="no-select show-all" disableRipple="true">
                                <fa-icon mat-list-icon [icon]="faPhone"></fa-icon>
                                <a href="tel:{{phone}}" mat-line class="color-text-primary" style="text-decoration: none">{{phone}}</a>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </ng-container>

                <!-- Description -->
                <ng-container *ngIf="project.description">
                    <h2 class="app-list-heading mat-h2" i18n>Notizen</h2>
                    <mat-card class="content-section">
                      <mat-card-content [innerHTML]="project.description"></mat-card-content>
                    </mat-card>
                </ng-container>



                <!-- Additional Informations -->
                <ng-container *ngIf="project.extra_input && project.extra_input.length > 0">
                    <h2 class="mat-h2 app-list-heading" i18n>Weitere Informationen</h2>
                    <app-info-list [infos]="project.extra_input"></app-info-list>
                </ng-container>
            </main>
        </mat-tab>

        <!-- Files -->
        <mat-tab>
            <ng-template mat-tab-label>
                <fa-icon mat-list-icon [icon]="faFileAlt"></fa-icon>
                <span i18n class="label-text">Dateien ({{project.files ? project.files.length : 0}})</span>
            </ng-template>
            <main>
                <h2 class="mat-h2 app-list-heading" i18n
                        *ngIf="!fileCategories || !fileCategories.length">
                    Dateien
                </h2>
                <mat-card class="content-section" *ngIf="!project.files || project.files.length < 1">
                    <mat-card-content i18n><em>Es sind noch keine Dateien vorhanden!</em></mat-card-content>
                </mat-card>
                <ng-template *ngIf="fileCategories" ngFor let-cat [ngForOf]="fileCategories">
                  <h2 class="mat-h2 app-list-heading">{{cat.title}}</h2>
                  <app-file-list [files]="cat.files" [categoriesAvailable]="project.file_categories" (filesUpdated)="reloadData()"></app-file-list>
                </ng-template>

                <app-file-upload table='project' [row]="project.id"
                    [categoriesAvailable]="project.file_categories"
                    [organization]="project.organization"
                    (fileAdded)="onFileAdded()"></app-file-upload>
            </main>
        </mat-tab>

        <!-- Jobs -->
        <ng-container *ngIf="project.jobs && project.jobs.length > 0">
            <mat-tab>
                <ng-template mat-tab-label>
                    <fa-icon mat-list-icon [icon]="faBriefcase"></fa-icon> <span class="label-text" i18n>Jobs ({{project.jobs.length}})</span>
                </ng-template>
                <main>
                    <mat-nav-list class="round-list" style="margin-top: 20px;">
                        <ng-container *ngFor="let j of project.jobs">
                            <a mat-list-item class="round-list-item mat-elevation-z1" (click)="routeJob(j)">
                                <fa-layers mat-list-icon class="fa-fw">
                                    <fa-icon [icon]="faBriefcase" class="color-text-primary"></fa-icon>
                                </fa-layers>
                                <h4 mat-line style="font-weight: normal;">
                                    <b>{{j.type.title}}</b>&nbsp;-&nbsp;{{j.title}}
                                </h4>
                                <div mat-line>
                                    <span style="flex-grow:1">
                                        <fa-icon [icon]="faCalendarAlt"></fa-icon>&nbsp;
                                        <app-date-span [start]="j.dispo_start" [end]="j.dispo_end"></app-date-span>
                                    </span>
                                </div>
                            </a>
                        </ng-container>
                    </mat-nav-list>
                </main>
            </mat-tab>
        </ng-container>

        <!-- Crew -->
        <ng-container *ngIf="staffsPerJob && staffsPerJob.length">
            <mat-tab>
                <ng-template mat-tab-label>
                    <fa-icon mat-list-icon [icon]="faUsers"></fa-icon> <span i18n class="label-text">Crew ({{staffSize}})</span>
                </ng-template>
                <main>
                    <h2 class="mat-h2 app-list-heading">Crew</h2>
                    <ng-container *ngFor="let staffGroup of staffsPerJob">
                        <mat-list *ngIf="staffGroup.staffs && staffGroup.staffs.length"
                                class="small-list pointer"
                                style="margin-bottom: 15px;"
                                (click)="routeJob(staffGroup.job, {tab: 2})">
                            <mat-list-item class="no-select head" style="padding: 5px 0">
                                <span mat-line>
                                    <b>{{staffGroup.job.type.title}}</b>&nbsp;-<small>&nbsp;{{staffGroup.job.title}}</small>
                                </span>
                                <small mat-line>
                                    <app-date-span [start]="staffGroup.job.dispo_start" [end]="staffGroup.job.dispo_end" style="font-size:0.9em"></app-date-span>
                                </small>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                            <ng-container *ngFor="let staff of staffGroup.staffs; last as isLast;">
                                <mat-list-item class="no-select">
                                    <div style="display: flex;width:100%;">
                                        <div style="flex:1">{{staff.first_name}} {{staff.last_name}}</div>
                                        <a
                                            *ngIf="staff.mobile"
                                            href="tel:{{staff.mobile}}"
                                            class="color-text-primary"
                                            style="text-decoration:none;cursor:pointer;flex:1 0;text-align:right;"
                                        >
                                            {{staff.mobile}}
                                        </a>
                                    </div>
                                </mat-list-item>
                                <mat-divider *ngIf="!isLast"></mat-divider>
                            </ng-container>
                        </mat-list>
                    </ng-container>
                </main>
            </mat-tab>
        </ng-container>

        <!-- Tasks -->
        <ng-container *ngIf="project.tasks && project.tasks.length > 0">
            <mat-tab>
                <ng-template mat-tab-label>
                    <fa-icon mat-list-icon [icon]="faCheckSquare"></fa-icon> <span class="label-text" i18n>Aufgaben ({{project.tasks.length}})</span>
                </ng-template>
                <main>
                    <ng-container *ngFor="let data of taskGroups">
                        <h2 i18n class="mat-h2 app-list-heading">{{data.title}}</h2>
                        <mat-nav-list class="round-list">
                            <a mat-list-item class="round-list-item mat-elevation-z1" (click)="routeTask(t)" *ngFor="let t of data.tasks">
                                <fa-layers mat-list-icon class="fa-fw">
        						    <fa-icon
                                    [icon]="t.completed ? faCheckSquare : faSquare"
                                        class="{{ t.completed ? 'color-text-green' : 'color-text-primary' }}"></fa-icon>
                                </fa-layers>
                                <div mat-line>
        						    <h4 style="flex:1 0;">{{t.title}}</h4>
                                    <div style="flex:1;" *ngIf="t.priority" style="text-align: right;">
        								<span *ngIf="t.priority > 0" class="color-text-red">
        								   <fa-icon [icon]="faExclamation" class="color-text-red"></fa-icon>&nbsp;
        								   <strong>Hoch</strong>
        							  	</span>
        							  	<span *ngIf="t.priority < 0" class="color-text-grey">
        									<fa-icon [icon]="faExclamation" class="color-text-grey"></fa-icon>&nbsp;
        									<strong>Niedrig</strong>
        								</span>
        			                </div>
                                </div>
        						<div mat-line>
        							<div style="flex:1 0;">
                                        <ng-container *ngIf="t.deadline">
            								<fa-icon [icon]="faCalendarTimes"></fa-icon>&nbsp;
                							<app-date-span [start]="t.deadline" [end]="null" [relativeWords]="true"></app-date-span>
                                        </ng-container>
        			                </div>

                                    <div style="display: flex; overflow: hidden;">
                                        <div class="word_wrap">
                                            <span *ngFor="let staff of t.responsibles; last as isLast">
                                                <span>{{staff.first_name}} {{staff.last_name}}</span>
                                                <span *ngIf="!isLast">, </span>
                                            </span>&nbsp;
                                            <fa-icon [icon]="faUsers" style="flex-shrink: 0;"></fa-icon>
                                        </div>
                                    </div>
        						</div>
        					</a>
                        </mat-nav-list>
                    </ng-container>
                </main>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</ng-container>
