import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { TaskAssign } from './task-assign.model';
import { TaskAssignService } from './task-assign.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';

@Injectable()
export class TaskAssignResolver extends CachableClientModelResolver<TaskAssign>{

	constructor(s:TaskAssignService, r:Router) {
		super(s, 'task-assigns', r);
	}
}
