var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ComponentInit } from '../../core/classes/component-init.class';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { FileService } from '../../core/services/file.service';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
var FileEditDialogComponent = /** @class */ (function (_super) {
    __extends(FileEditDialogComponent, _super);
    function FileEditDialogComponent(fb, route, fileService, router, scrollLocations, dialog, dialogFileEdit, data, locale) {
        var _this = _super.call(this, router, scrollLocations) || this;
        _this.fb = fb;
        _this.route = route;
        _this.fileService = fileService;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.dialog = dialog;
        _this.dialogFileEdit = dialogFileEdit;
        _this.data = data;
        _this.locale = locale;
        _this.loading = false;
        _this.submitting = false;
        _this.file = _this.data.file;
        console.log(_this.data, _this.data.file);
        _this.form = _this.fb.group({
            title: [_this.file.title, Validators.required],
            cat: [_this.file.cat ? _this.file.cat.id : 0]
        });
        return _this;
    }
    FileEditDialogComponent.prototype.init = function () {
        this.titleInput.nativeElement.focus();
        this.titleInput.nativeElement.select();
    };
    FileEditDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.form.valid)
            return this.dialog.open(OkDialogComponent, { data: 'Bitte fülle das Formular korrekt aus.' });
        this.submitting = true;
        this.fileService.update(this.file.organization, this.file.id, {
            title: this.form.value.title,
            cat: this.form.value.cat
        }).subscribe(function (res) {
            _this.submitting = false;
            if (!res || !res.id)
                return _this.dialog.open(OkDialogComponent, { data: 'Es ist ein Fehler aufgetreten.' });
            _this.dialogFileEdit.close(true);
        }, function (err) {
            _this.submitting = false;
            _this.dialog.open(OkDialogComponent, { data: err.error.text });
        });
    };
    FileEditDialogComponent.prototype.onDelete = function () {
        var _this = this;
        if (window.confirm("Willst du wirklich diese Datei löschen?")) {
            this.submitting = true;
            this.fileService.delete(this.file.organization, this.file.id).subscribe(function () {
                _this.submitting = false;
                _this.dialogFileEdit.close(true);
            }, function (err) {
                _this.submitting = false;
                _this.dialog.open(OkDialogComponent, { data: err.error.text });
            });
        }
    };
    return FileEditDialogComponent;
}(ComponentInit));
export { FileEditDialogComponent };
