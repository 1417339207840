import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Router } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { File } from '../../core/models/file.model';
import { FileCategory } from '../../core/models/file-category.model';
import { FileService } from '../../core/services/file.service';

import { AccountService } from '../../core/services/account.service';
import { Account } from '../../core/models/account.model';

import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { FileEditDialogComponent } from '../../shared/file-edit-dialog/file-edit-dialog.component';

import { faUpload, faCamera } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent extends ComponentInit {
    faUpload = faUpload;
    faCamera = faCamera;

	uploading: boolean = false;
    account: Account;

    @Input() organization: number;
    @Input() categoriesAvailable: FileCategory[];
    @Input() table: string;
    @Input() row: number;

    @Output() fileAdded = new EventEmitter();

	constructor(
		private fileService: FileService,
		protected router: Router,
		protected scrollLocations: ScrollLocations,
        private dialog: MatDialog,
        private accountService: AccountService
	) {
		super(router, scrollLocations);
	}

    init(){
        this.accountService.getAccountAsObservable().pipe(untilDestroyed(this)).subscribe(res => {
            if(!res) return;
            this.account = res;
        });
    }

    permission(){
        return this.account && this.account.file_upload_orgas
            && this.account.file_upload_orgas.filter(o => o.id == this.organization).length;
    }

    onFileSelected(event){
        const inputNode: any = event.target;

        // :: file selected check
        if(!inputNode.files || !inputNode.files[0])
            return;

        // :: check file size
        if(inputNode.files[0].size > 10 * 1048576)
            return this.dialog.open(OkDialogComponent, {data: 'Maximale Dateigröße: 10 MB'});

        this.uploading = true;
        this.fileService.upload(this.organization, inputNode.files[0], {
            table: this.table,
            row: this.row
        }).pipe(untilDestroyed(this)).subscribe((file:File) => {
            this.uploading = false;
            this.dialog.open(FileEditDialogComponent, {
                data: {
                    file: file,
                    categoriesAvailable: this.categoriesAvailable
                }
            }).afterClosed().subscribe(() => {
                this.fileAdded.emit(file);
                inputNode.value = null;
            });
        }, err => {
            this.uploading = false;
            this.dialog.open(OkDialogComponent, {data:err.error.text});
            inputNode.value = null;
        });
    }
}
