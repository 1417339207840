import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { DeactivateDialogGuard } from './core/guards/deactivate-dialog.guard';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ActivateComponent } from './auth/activate/activate.component';
import { ActivateResendComponent } from './auth/activate-resend/activate-resend.component';
import { PasswordReset1Component } from './auth/password-reset1/password-reset1.component';
import { PasswordReset2Component } from './auth/password-reset2/password-reset2.component';
import { InvitationComponent } from './invitation/invitation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FilesComponent } from './files/files.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountSettingsComponent } from './settings/account-settings/account-settings.component';
import { NotificationsSettingsComponent } from './settings/notifications-settings/notifications-settings.component';
import { CalendarSubscribeComponent } from './settings/calendar-subscribe/calendar-subscribe.component';
import { DispositionsComponent } from './dispositions/dispositions.component';
import { TourListComponent } from './tours/tour-list/tour-list.component';
import { TourDetailComponent } from './tours/tour-detail/tour-detail.component';
import { TourResolver } from './tours/shared/tour.resolver';
import { ProjectListComponent } from './projects/project-list/project-list.component';
import { ProjectDetailComponent } from './projects/project-detail/project-detail.component';
import { ProjectResolver } from './projects/shared/project.resolver';
import { JobDetailComponent } from './jobs/job-detail/job-detail.component';
import { JobTimesheetComponent } from './jobs/job-timesheet/job-timesheet.component';
import { JobTimesheetCreateComponent } from './jobs/job-timesheet-create/job-timesheet-create.component';
import { JobResolver } from './jobs/shared/job.resolver';
import { JobTimesheetResolver } from './jobs/shared/job-timesheet.resolver';
import { TaskDetailComponent } from './tasks/task-detail/task-detail.component';
import { TaskResolver } from './tasks/shared/task.resolver';
import { TaskAssignListComponent } from './tasks/task-assign-list/task-assign-list.component';
import { TaskAssignDetailComponent } from './tasks/task-assign-detail/task-assign-detail.component';
import { TaskAssignResolver } from './tasks/shared/task-assign.resolver';
import { MissionProcessResolver } from './missions/shared/mission-process.resolver';
import { MissionProcessDetailComponent } from './missions/mission-processes/mission-process-detail/mission-process-detail.component';
import { MissionProcessesComponent } from './missions/mission-processes/mission-processes.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { EventResolver } from './events/shared/event.resolver';
import { MissionProcessConfirmationsComponent } from './missions/mission-processes/mission-process-confirmations/mission-process-confirmations.component';
import { ImprintComponent } from './imprint/imprint.component';
import { WorkScheduleListComponent } from './work-schedules/work-schedule-list/work-schedule-list.component';
import { VacationRequestResolver } from './vacation-requests/shared/vacation-request.resolver';
import { VacationRequestListComponent } from './vacation-requests/vacation-request-list/vacation-request-list.component';
import { VacationRequestNewComponent } from './vacation-requests/vacation-request-new/vacation-request-new.component';
import { VacationRequestDetailComponent } from './vacation-requests/vacation-request-detail/vacation-request-detail.component';
import { WorkScheduleResolver } from './work-schedules/shared/work-schedule.resolver';
import { WorkScheduleOverviewComponent } from './work-schedules/work-schedule-overview/work-schedule-overview.component';
var ɵ0 = { title: 'Home' }, ɵ1 = { title: 'Kalender' }, ɵ2 = { title: 'Kalender' }, ɵ3 = { title: 'Termin', back: true }, ɵ4 = { title: 'Meine Agenda' }, ɵ5 = { title: 'Alle Touren' }, ɵ6 = { title: 'Tour', back: true }, ɵ7 = { title: 'Alle Projekte' }, ɵ8 = { title: 'Projekt', back: true }, ɵ9 = { title: 'Job', back: true }, ɵ10 = { title: 'Arbeitsschein erstellen', back: true }, ɵ11 = { title: 'Arbeitsschein', back: true }, ɵ12 = { title: 'Meine Aufgaben' }, ɵ13 = { title: 'Aufgabe', back: true }, ɵ14 = { title: 'Aufgabe', back: true }, ɵ15 = { title: 'Ausschreibungen' }, ɵ16 = { title: 'Auftragsbestätigungen' }, ɵ17 = { title: 'Ausschreibung', back: true }, ɵ18 = { title: 'Dateien' }, ɵ19 = { title: 'Einstellungen' }, ɵ20 = { title: 'Account', back: true }, ɵ21 = { title: 'Kalender abonnieren', back: false }, ɵ22 = { title: 'Benachrichtigungen', back: true }, ɵ23 = { title: 'Impressum/Datenschutz' }, ɵ24 = { title: 'Arbeitszeiten' }, ɵ25 = {
    back: true,
    title: 'Arbeitsübersicht',
    current: true
}, ɵ26 = { title: 'Arbeitsübersicht', back: true }, ɵ27 = { title: 'Urlaubsanträge' }, ɵ28 = { title: 'Urlaubsantrag erstellen', back: true }, ɵ29 = { title: 'Urlaubsantrag', back: true };
var appRoutes = [
    { path: 'invite/:token', component: InvitationComponent },
    { path: 'auth/login/:token', component: LoginComponent },
    { path: 'auth/login', component: LoginComponent },
    { path: 'auth/register/:token', component: RegisterComponent },
    { path: 'auth/register', component: RegisterComponent },
    { path: 'auth/activate/resend', component: ActivateResendComponent },
    { path: 'auth/activate/:token', component: ActivateComponent },
    { path: 'auth/password/reset', component: PasswordReset1Component },
    { path: 'auth/password/reset/:token', component: PasswordReset2Component },
    { path: '', component: DashboardComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateDialogGuard], children: [
            { path: '', component: HomeComponent, canDeactivate: [DeactivateDialogGuard], data: ɵ0 },
            { path: 'calendar', redirectTo: 'calendar/month/' },
            { path: 'calendar/:view', component: CalendarComponent, canDeactivate: [DeactivateDialogGuard], data: ɵ1 },
            { path: 'calendar/:view/:date', component: CalendarComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ2
            },
            { path: 'events/:orga/:id', component: EventDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ3, resolve: { resolved: EventResolver }
            },
            { path: 'dispositions', component: DispositionsComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ4
            },
            { path: 'tours', component: TourListComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ5
            },
            { path: 'tours/:orga/:id', component: TourDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ6, resolve: { resolved: TourResolver }
            },
            { path: 'projects', component: ProjectListComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ7
            },
            { path: 'projects/:orga/:id', component: ProjectDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ8,
                resolve: { resolved: ProjectResolver }
            },
            { path: 'jobs/:orga/:id', component: JobDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ9, resolve: { resolved: JobResolver }
            },
            { path: 'jobs/:orga/:id/timesheet', component: JobTimesheetCreateComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ10, resolve: { resolved: JobResolver }
            },
            { path: 'timesheets/:orga/:id', component: JobTimesheetComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ11, resolve: { resolved: JobTimesheetResolver }
            },
            /* TASK */
            { path: 'task-assigns', component: TaskAssignListComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ12
            },
            { path: 'task-assigns/:orga/:id', component: TaskAssignDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ13, resolve: { resolved: TaskAssignResolver }
            },
            { path: 'tasks/:orga/:id', component: TaskDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ14, resolve: { resolved: TaskResolver }
            },
            /* MISSIONS */
            { path: 'missions/processes', component: MissionProcessesComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ15
            },
            { path: 'missions/processes/confirmations', component: MissionProcessConfirmationsComponent,
                canDeactivate: [DeactivateDialogGuard], data: ɵ16
            },
            { path: 'missions/processes/:orga/:id', component: MissionProcessDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ17, resolve: { resolved: MissionProcessResolver }
            },
            /* FILES */
            { path: 'files', component: FilesComponent, canDeactivate: [DeactivateDialogGuard], data: ɵ18 },
            /* SETTINGS */
            { path: 'settings', component: SettingsComponent, canDeactivate: [DeactivateDialogGuard], data: ɵ19 },
            { path: 'settings/account', component: AccountSettingsComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ20
            },
            { path: 'settings/calendar-subscribe', component: CalendarSubscribeComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ21
            },
            { path: 'settings/notifications', component: NotificationsSettingsComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ22
            },
            /* IMPRINT */
            { path: 'imprint', component: ImprintComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ23
            },
            /* WORK SCHEDULES */
            { path: 'work-schedules', component: WorkScheduleListComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ24
            },
            {
                path: 'work-schedules/review',
                component: WorkScheduleOverviewComponent,
                canDeactivate: [DeactivateDialogGuard],
                data: ɵ25
            },
            { path: 'work-schedule/:orga/:id', component: WorkScheduleOverviewComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ26, resolve: { resolved: WorkScheduleResolver }
            },
            /* VACATION REQUESTS */
            { path: 'vacation-requests', component: VacationRequestListComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ27
            },
            { path: 'vacation-request-new/:orga', component: VacationRequestNewComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ28
            },
            { path: 'vacation-request/:orga/:id', component: VacationRequestDetailComponent, canDeactivate: [DeactivateDialogGuard],
                data: ɵ29, resolve: { resolved: VacationRequestResolver }
            }
        ] },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
    // or alternatively to a Page Not Found page
    //{ path: '**', component: PageNotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29 };
