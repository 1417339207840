var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { Router, ActivatedRoute } from '@angular/router';
import { MissionProcessService } from '../shared/mission-process.service';
import { ScrollLocations } from '../../core/globals/scroll-locations';
var MissionProcessesComponent = /** @class */ (function (_super) {
    __extends(MissionProcessesComponent, _super);
    function MissionProcessesComponent(route, mps, router, scrollLocations) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.mps = mps;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.loadError = false;
        _this.reload = _this.loadMissionProcesses.bind(_this);
        _this.listGroups = [
            { name: 'Ausstehend', data: [] },
            { name: 'Beantwortet', data: [] },
        ];
        return _this;
    }
    MissionProcessesComponent.prototype.init = function () {
        this.loadMissionProcesses();
    };
    MissionProcessesComponent.prototype.loadMissionProcesses = function () {
        var _this = this;
        this.loadError = false;
        this.mps.getAllProposalsForCurrentAccount(new Date(), null).subscribe(function (processes) {
            if (!processes)
                return;
            var group0 = [];
            var group1 = [];
            processes.forEach(function (process) {
                if (!process.error) {
                    if (process.answer)
                        group1.unshift(process);
                    else
                        group0.push(process);
                }
            });
            //asign data
            _this.listGroups[0].data = group0;
            _this.listGroups[1].data = group1;
            _this.missionProcesses = processes;
        }, function (err) {
            _this.loadError = true;
        });
    };
    return MissionProcessesComponent;
}(ComponentInit));
export { MissionProcessesComponent };
