var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { TaskAssignService } from './task-assign.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./task-assign.service";
var TaskService = /** @class */ (function (_super) {
    __extends(TaskService, _super);
    function TaskService(http, locale, tas) {
        var _this = _super.call(this, http, 'tasks') || this;
        _this.http = http;
        _this.locale = locale;
        _this.tas = tas;
        return _this;
    }
    TaskService.prototype.getByOrganizationAndId = function (orga, id) {
        var data = this.findModelByOrganizationAndId(orga, id);
        // If we found nothing -> get data from task assigns
        if (!data) {
            var taskAssigns = JSON.parse(localStorage.getItem('taskAssigns'));
            if (taskAssigns) {
                var task = taskAssigns.find(function (val) {
                    return val['task'] == id && val['organization'] == orga;
                });
                if (task)
                    data = task;
            }
        }
        var http = this.http.get('/v1/organizations/' + orga + '/tasks/' + id);
        return this.cachedRequest(data, http);
    };
    TaskService.prototype.complete = function (task) {
        var _this = this;
        return this.http.post('/v1/organizations/' + task.organization + '/tasks/' + task.id + '/complete', {})
            .pipe(tap(function (res) {
            _this.updateModel(res);
            // Update task assign entry
            var taskAssigns = JSON.parse(localStorage.getItem('taskAssigns'));
            if (taskAssigns) {
                var entry = taskAssigns.find(function (entry) {
                    return entry['task'] == task.id && entry['organization'] == task.organization;
                });
                if (entry) {
                    entry.completed = 1;
                    _this.tas.updateModel(entry);
                }
            }
        }));
    };
    TaskService.prototype.incomplete = function (task) {
        var _this = this;
        return this.http.post('/v1/organizations/' + task.organization + '/tasks/' + task.id + '/incomplete', {})
            .pipe(tap(function (res) {
            _this.updateModel(res);
            // Update task assign entry
            var taskAssigns = JSON.parse(localStorage.getItem('taskAssigns'));
            if (taskAssigns) {
                var entry = taskAssigns.find(function (entry) {
                    return entry['task'] == task.id && entry['organization'] == task.organization;
                });
                if (entry) {
                    entry.completed = 0;
                    _this.tas.updateModel(entry);
                }
            }
        }));
    };
    TaskService.ngInjectableDef = i0.defineInjectable({ factory: function TaskService_Factory() { return new TaskService(i0.inject(i1.HttpClient), i0.inject(i0.LOCALE_ID), i0.inject(i2.TaskAssignService)); }, token: TaskService, providedIn: "root" });
    return TaskService;
}(CachableModelService));
export { TaskService };
