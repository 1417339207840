var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ComponentInit } from '../core/classes/component-init.class';
import { ScrollLocations } from '../core/globals/scroll-locations';
import { Router, ActivatedRoute } from '@angular/router';
import { DispositionService } from './shared/disposition.service';
import { ListGroupService } from '../core/services/list-group.service';
var DispositionsComponent = /** @class */ (function (_super) {
    __extends(DispositionsComponent, _super);
    function DispositionsComponent(route, router, scrollLocations, disposService, listGroupService, locale) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.disposService = disposService;
        _this.listGroupService = listGroupService;
        _this.locale = locale;
        _this.loadError = false;
        _this.listGroups = _this.listGroupService.getGroups();
        return _this;
    }
    DispositionsComponent.prototype.init = function () {
        this.reload();
    };
    DispositionsComponent.prototype.reload = function () {
        var _this = this;
        this.loadError = false;
        this.disposService.getAllForCurrentAccount({ date_from: new Date() }).subscribe(function (res) {
            if (!res)
                return;
            _this.listGroups = _this.listGroupService.getGroups(res, 'start');
            _this.dispos = res;
        }, function (res) {
            _this.loadError = true;
        });
    };
    return DispositionsComponent;
}(ComponentInit));
export { DispositionsComponent };
