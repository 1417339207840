import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import {Observable} from 'rxjs/Observable';

import { MatSnackBar } from '@angular/material';

import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
	date_version = null;
	last_update_check = null;
	constructor(
		private swUpdate: SwUpdate,
		private http: HttpClient,
		private snackbar: MatSnackBar
  	){
	}

	public init(): void{
		let date_version_stored = localStorage.getItem('versionDate');
		if(!date_version_stored)
			this.setVersionDate(new Date());
		else
			this.date_version = new Date(date_version_stored);

		if(environment.production){
            interval(3 * 60 * 1000).subscribe(() => this.checkForUpdates());
			window.addEventListener("focus", () => {
				if(!this.last_update_check || Date.now() - this.last_update_check > 30 * 1000){
					this.checkForUpdates();
				}
			})
			this.checkForUpdates();
        }
		this.last_update_check = Date.now();
	}

	public setVersionDate(date): void {
		this.date_version = date;
		localStorage.setItem('versionDate', date.toString());
	}

	public getVersionDate(callback){
		this.http.get("./index.html?no-cache="+Date.now(), { observe: 'response', responseType: 'text' }).subscribe((res:any) => {
			let date = null;
			if(res && res.headers)
				date = new Date(res.headers.get('last-modified'));
			callback(date)
    	});
	}

	public checkForUpdates(): void{
		console.log('checking for updates ...');

		this.last_update_check = Date.now();
		this.getVersionDate(date => {
			console.log(this.date_version, date);
			if(date && date > this.date_version){
				this.setVersionDate(date);
				document.location.reload();
			}

		})

	}

	private promptUser(): void{
		/* console.log('updating to new version');
		alert('updating to new version');
		this.swUpdate.activateUpdate().then(() => document.location.reload()); */
	}
}
