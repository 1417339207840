import { Component, OnInit } from '@angular/core';

import { File } from '../core/models/file.model';
import { FileService } from '../core/services/file.service';

interface Data {
  organizationId: number,
  companyTitle: string,
  categories: {
    name: string,
    files: File[]
  }[]
};

@Component({
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  data:Data[] = [];

  constructor(
    private fs: FileService,
  ) { }

  ngOnInit() {
    this.fs.getAllForCurrentAccount().subscribe(arr => {
      if(!arr) return;

      this.data = [];

      //add each element
      arr.forEach(element => {
        //check if company already in array
        let orga = this.data.find(val => {
          return val.organizationId == element.organization && val.companyTitle == element.company.title;
        });

        if(!orga){
          //if not, add it
          let i = this.data.push({
            organizationId:element.organization,
            companyTitle:element.company.title,
            categories:[]
          });

          orga = this.data[i-1];
        }

        //TODO: change by locale
        let categoryName = 'Ohne Kategorie';

        if(element.cat) categoryName = element.cat.title;

        //check if category exists
        let category = orga.categories.find(val => {
          return (val.name == categoryName);
        });

        if(!category){
          //if not, add it with file
          let o = {
            name: categoryName,
              files: [element]
          };

          if(categoryName)
            orga.categories.push(o);
          else
            // add generic category at front
            // TODO: change name depending on locale
            orga.categories.unshift(o);
        }
        else {
          //find existing file
          let fileIndex = category.files.findIndex(val => {
            return val.id == element.id
          });

          if(fileIndex != -1)
            //replace file
            category.files[fileIndex] = element;
          else
            // or add file
            category.files.push(element);
        }
      });
    });
  }
}
