var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { TaskAssignService } from './task-assign.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./task-assign.service";
var SubTaskService = /** @class */ (function (_super) {
    __extends(SubTaskService, _super);
    function SubTaskService(http, locale, tas) {
        var _this = _super.call(this, http, 'subTasks') || this;
        _this.http = http;
        _this.locale = locale;
        _this.tas = tas;
        return _this;
    }
    SubTaskService.prototype.getByOrganizationAndId = function (orga, id) {
        var data = this.findModelByOrganizationAndId(orga, id);
        var http = this.http.get('/v1/organizations/' + orga + '/sub-tasks/' + id);
        return this.cachedRequest(data, http);
    };
    SubTaskService.prototype.complete = function (sub_task) {
        var _this = this;
        return this.http.post('/v1/organizations/' + sub_task.organization + '/sub-tasks/' + sub_task.id + '/complete', {})
            .pipe(tap(function (res) {
            _this.updateModel(res);
        }));
    };
    SubTaskService.prototype.incomplete = function (sub_task) {
        var _this = this;
        return this.http.post('/v1/organizations/' + sub_task.organization + '/sub-tasks/' + sub_task.id + '/incomplete', {})
            .pipe(tap(function (res) {
            _this.updateModel(res);
        }));
    };
    SubTaskService.ngInjectableDef = i0.defineInjectable({ factory: function SubTaskService_Factory() { return new SubTaskService(i0.inject(i1.HttpClient), i0.inject(i0.LOCALE_ID), i0.inject(i2.TaskAssignService)); }, token: SubTaskService, providedIn: "root" });
    return SubTaskService;
}(CachableModelService));
export { SubTaskService };
