var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from "@angular/router";
import { VacationRequestService } from './vacation-request.service';
import { CachableClientModelResolver } from '../../core/classes/cachable-client-model-resolver.class';
var VacationRequestResolver = /** @class */ (function (_super) {
    __extends(VacationRequestResolver, _super);
    function VacationRequestResolver(s, r) {
        return _super.call(this, s, 'vacationRequests', r) || this;
    }
    return VacationRequestResolver;
}(CachableClientModelResolver));
export { VacationRequestResolver };
