<main>
  <app-loading-spinner [loading]="!mp"></app-loading-spinner>
  <ng-container *ngIf="mp">
    <br />
    <div class="stages">
      <div class="stage" *ngFor="let stage of this.stages; let i = index" [ngClass]="[stage.state, stage.position]">
        <div class="icon color-app-background">
          <fa-icon [icon]="stage.icon"></fa-icon>
        </div>

        <div class="icon_overlay" *ngIf="(stage.state == 'completed' || stage.state == 'aborted') && (i==1||i==2||i==4||(i==5 && stage.state == 'aborted'))">
          <fa-icon [icon]="faCheck" class="color-text-green" *ngIf="stage.state == 'completed'"></fa-icon>
          <fa-icon [icon]="faTimes" class="color-text-red" *ngIf="stage.state == 'aborted'"></fa-icon>
        </div>

        <svg class="stage_line" *ngIf="stage.line">
          <line stroke-width="2px" [attr.x1]="linesX1" [attr.y1]="stage.line.y1" [attr.x2]="linesX2" [attr.y2]="stage.line.y2"/>
        </svg>
      </div>
    </div>

    <h2 class="mat-h2 app-list-heading" i18n>Informationen</h2>
    <mat-card>
      <div class="info_list">
        <div class="item">
          <div class="title color-text-icon-grey">Firma</div>
          <div class="content">{{mp.company.title}}</div>
        </div>
        <ng-container *ngFor="let key of mp.info | keys; let i = index">
          <mat-divider></mat-divider>
          <div class="item">
            <div class="title color-text-icon-grey" i18n>{{mp.info[key].title}}</div>
            <div class="content" [innerHTML]="mp.info[key].value"></div>
          </div>
        </ng-container>
      </div>
    </mat-card>

    <ng-container *ngIf="mp.entries">
      <h2 class="mat-h2 app-list-heading">{{mp.entries.length}} <ng-container i18n>Einsätze</ng-container></h2>
      <mat-card *ngFor="let entry of mp.entries">
        <mat-card-content>
          <div class="info_list">
            <ng-container *ngFor="let key of entry.info | keys">
              <mat-divider></mat-divider>
              <div class="item" [ngClass]="{'color-background-secondary':key=='title'}">
                <div class="title color-text-icon-grey" *ngIf="key!='title'" i18n>{{entry.info[key].title}}</div>
                <div class="content" [innerHTML]="entry.info[key].value"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="!mp.locked && mp.state == 'proposal'">
              <mat-divider></mat-divider>
              <div class="item">
                <mat-radio-group  class="flexbox row" style="justify-content:space-around;width:100%" [(ngModel)]="entry.answer">
                  <mat-radio-button [value]="0" i18n>Keine Angabe</mat-radio-button>
                  <mat-radio-button [value]="2" color="warn" i18n>Ablehnen</mat-radio-button>
                  <mat-radio-button [value]="1" color="success" i18n>Annehmen</mat-radio-button>
                </mat-radio-group>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <ng-container *ngIf="files && files.length > 0">
      <h2 class="mat-h2 app-list-heading">Dateien</h2>
      <app-file-list [files]="files"></app-file-list>
      <mat-selection-list [(ngModel)]="selection.files" [disabled]="disableAll" *ngIf="mp.state=='offer'">
        <mat-list-option class="mat-elevation-z1" value="files">
          <span [ngClass]="{'color-text-red':selectionRequiredError}" i18n>Ich habe alle Dateien gesehen</span>
        </mat-list-option>
      </mat-selection-list>
    </ng-container>

    <ng-container *ngIf="mp.contract_for_services && mp.state=='offer'">
      <h2 class="mat-h2 app-list-heading">Verträge</h2>
      <mat-nav-list>
        <a mat-list-item class="color-text-primary mat-elevation-z1" [href]="mp.contract_for_services.url" target="_blank" i18n>
          Werkvertrag
        </a>
      </mat-nav-list>
      <mat-selection-list [(ngModel)]="selection.contract" [disabled]="disableAll">
        <mat-list-option class="mat-elevation-z1" value="contract">
          <span [ngClass]="{'color-text-red':selectionRequiredError}">{{mp.contract_for_services.title}}</span>
        </mat-list-option>
      </mat-selection-list>
    </ng-container>


    <div class="flexbox row button-wrapper" *ngIf="mp.state != 'confirmation' && mp.answer!='rejected'">
      <ng-container *ngIf="mp.state == 'offer' || (!mp.entries && mp.state == 'proposal' && !mp.locked)">
        <button mat-raised-button (click)="onProcessDeclineClick()" class="flex spinner-button" [ngClass]="{'visible':!disableAll}"
            color="warn" i18n [disabled]="mp.answer=='declined' || disableAll" style="margin-right:15px">
          <mat-spinner diameter="20" *ngIf="disableAll"></mat-spinner>
          Ablehnen
        </button>
        <button mat-raised-button (click)="onProcessAcceptClick()" class="flex spinner-button" [ngClass]="{'visible':!disableAll}" color="success" i18n [disabled]="mp.answer=='accepted' || disableAll">
          <mat-spinner diameter="20" *ngIf="disableAll"></mat-spinner>
          Annehmen
        </button>
      </ng-container>
      <ng-container *ngIf="mp.state == 'proposal' && mp.entries && !mp.locked">
        <button mat-raised-button (click)="onProcessSaveClick()" class="flex spinner-button" color="primary" i18n [disabled]="disableAll">
          <mat-spinner diameter="20"></mat-spinner>
          Speichern
        </button>
      </ng-container>
    </div>
    <p *ngIf="mp.locked && mp.state != 'offer'" class="color-text-grey text-center" style="margin-top:1rem" i18n>Für diese Ausschreibung kann keine Antwort mehr abgegeben werden.</p>
    <p *ngIf="mp.answer" class="text-center"
        [ngClass]="mp.answer=='declined' ? 'color-text-red' : mp.answer=='accepted' ? 'color-text-green' : 'color-text-grey'"
        style="margin-top:1rem">
      <ng-container *ngIf="mp.answer=='accepted'">Angenommen </ng-container>
      <ng-container *ngIf="mp.answer=='declined'">Abgelehnt </ng-container>
      <ng-container *ngIf="mp.answer=='rejected'">Von Firma abgelehnt </ng-container>
      am {{mp.answer_date | date:'short'}}
    </p>
  </ng-container>
</main>
