import { NgModule } from '@angular/core';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { SharedModule } from '../shared/shared.module';
import { ProjectResolver } from './shared/project.resolver'
import { ProjectService } from './shared/project.service';
import { ProjectListComponent } from './project-list/project-list.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ProjectDetailComponent,
    ProjectListComponent,
  ],
  providers: [
    ProjectResolver,
    ProjectService,
  ]
})
export class ProjectsModule { }
