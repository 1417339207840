import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
    faFile,
    faFilePdf,
    faFileWord,
    faFilePowerpoint,
    faFileExcel,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileArchive,
    faEdit
} from '@fortawesome/free-regular-svg-icons';

import { File } from '../../core/models/file.model';
import { FileCategory } from '../../core/models/file-category.model';


@Component({
    selector: 'app-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.css']
})
export class FileListComponent {

    @Input() files: File[];
    @Input() categoriesAvailable: FileCategory[];
    @Output() filesUpdated = new EventEmitter();

    faFile = faFile;
    faFilePdf = faFilePdf;
    faFileWord = faFileWord;
    faFilePowerpoint = faFilePowerpoint;
    faFileExcel = faFileExcel;
    faFileImage = faFileImage;
    faFileAudio = faFileAudio;
    faFileVideo = faFileVideo;
    faFielArchive = faFileArchive;
    faEdit = faEdit;

    accToken:string = JSON.parse(localStorage.getItem('accessToken'));

    constructor() {
    }

}
