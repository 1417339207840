import { Component, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { ComponentInit } from '../../core/classes/component-init.class';
import { FilterHeaderComponent } from '../../shared/filters/filter-header/filter-header.component';
import { DateFilter, SelectFilter } from 'src/app/shared/filters/shared/classes';

import { Router, ActivatedRoute } from '@angular/router';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { ListGroup } from '../../core/models/list-group.model';
import { ListGroupService } from '../../core/services/list-group.service';

import { TaskAssign } from '../shared/task-assign.model';
import { TaskAssignService } from '../shared/task-assign.service';

import { faGavel, faTruckLoading, faCalendarTimes, faCheck, faCheckSquare, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';


@Component({
	templateUrl: './task-assign-list.component.html',
	styleUrls: ['./task-assign-list.component.css']
})
export class TaskAssignListComponent extends ComponentInit {
	@ViewChild(FilterHeaderComponent) filterComponent;

	loadError:boolean = false;

	taskAssigns:TaskAssign[] = [];

	listGroups:ListGroup<TaskAssign>[];

	filterInputs = {
		date_from: new DateFilter({
	      label: 'Ab',
	      value: new Date()
	    }),
		state: new SelectFilter({
	      label: 'Status',
	      options: [
			  {value: 'all', label: 'Alle'},
			  {value: 'incomplete', label: 'Nicht Erledigt'},
			  {value: 'completed', label: 'Erledigt'}
		  ],
	      value: 'all',
	      storable: true
	    })
	};

	faCheck = faCheck;
	faCalendarTimes = faCalendarTimes;
	faExclamation = faExclamation;
	icons = {
		'task': faSquare,
		'activity': faGavel,
		'tour_before': faTruckLoading,
		'tour_after': faTruckLoading
	}

	constructor(
		private route: ActivatedRoute,
		private ts: TaskAssignService,
		private listGroupService: ListGroupService,
		protected router: Router,
	    protected scrollLocations: ScrollLocations,
		@Inject(LOCALE_ID) private locale: string,
	) {
		super(router, scrollLocations, route);
		this.listGroups = this.listGroupService.getGroups();
	}

	reload(){
      this.filterComponent && this.filterComponent.reload();
    }

	init(){
		// this.getEntries();
	}

	getEntries(options){
		this.ts.getTaskAssigns(options).subscribe(res => {
	    	if(!res) return;

	    	// Check if data has changed
	    	if(JSON.stringify(this.taskAssigns) == JSON.stringify(res)) return;

	    	this.listGroups = this.listGroupService.getGroups(res, 'start', 'end');
	    	this.taskAssigns = res;

			// Scroll to "today"
			if(!document.querySelector('.filter_list').scrollTop && !this.scrollLocations['positions'][this.router['url']]){
				setTimeout(() => {
					// Get Position of "Today" Chapter
					let group_past = document.querySelector('.group-past');
					if(group_past)
						document.querySelector('.filter_list').scrollTo(0, group_past.clientHeight);
				});
			}

	    }, res => { this.loadError = true; })

	}

	icon(t){
		if(t.type == 'task' && t.completed) return faCheckSquare;
		else return this.icons[t.type];
	}

	routeTaskAssign(t){
		if(t.type == 'task'){
			this.router.navigate(['tasks', t.organization, t.task]);
		} else {
			this.router.navigate(['task-assigns', t.organization, t.id]);
		}

	}
}
