<div class="month-header">
	<button mat-icon-button
		(click)="monthChange('prev')">
		<fa-icon [icon]="faChevronLeft" ></fa-icon>
	</button>
	<div class="col-md-4 h2-wrapper">
		<h2 class="mat-h2">{{ month | date: 'MMMM' }}</h2>
	</div>
	<button mat-icon-button [ngStyle]="{visibility: current_month ? 'hidden' : 'visible'}"
			(click)="monthChange('next')">
		<fa-icon [icon]="faChevronRight" ></fa-icon>
	</button>
</div>

<ng-container *ngIf="!data || data.length < 1">
	<div class="empty-header mat-elevation-z1"></div>
	<main>
		<mat-card class="content-section">
			<mat-card-content>
				<em>Es wurden keine Einträge gefunden</em>
			</mat-card-content>
		</mat-card>
	</main>
</ng-container>

<mat-tab-group mat-stretch-tabs>
	<mat-tab *ngFor="let obj of data">
		<ng-template mat-tab-label i18n>
			{{obj.company.title}}
		</ng-template>
		<ng-template matTabContent>
			<main>
				<ng-container *ngIf="!obj.weeks || obj.weeks.length < 1">
					<mat-card class="content-section" style="margin-bottom: 20px;">
			        	<mat-card-content>
							<em>Es wurden keine Einträge gefunden</em>
						</mat-card-content>
					</mat-card>
				</ng-container>
				<table *ngFor="let week of obj.weeks" mat-table [dataSource]="week.entries" class="mat-elevation-z1">
					<!-- Weekday -->
					<ng-container matColumnDef="weekday">
						<td mat-cell *matCellDef="let element" nowrap [ngClass]="{'color-text-red': !element.date_out}">
							<strong>{{element.date_in | date:'EEE'}}</strong>
						</td>
						<td mat-footer-cell *matFooterCellDef colspan="2" nowrap>Woche {{week.week_number}}</td>
					</ng-container>

					<!-- Date -->
					<ng-container matColumnDef="date">
						<td mat-cell *matCellDef="let element" nowrap [ngClass]="{'color-text-red': !element.date_out}">
							<strong>{{element.date_in | date:'dd.MM'}}</strong>
						</td>
						<td mat-footer-cell *matFooterCellDef style="display:none;"></td>
					</ng-container>

					<!-- Time -->
					<ng-container matColumnDef="time">
						<td mat-cell *matCellDef="let element" nowrap [ngClass]="{'color-text-red': !element.date_out}">
							{{element.date_in | date:'shortTime'}} - {{element.date_out ? (element.date_out | date:'shortTime') : '??:??'}}
						</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>

					<!-- Break/Daily-Time-Ignore -->
					<ng-container matColumnDef="break">
						<td mat-cell *matCellDef="let element" nowrap>
							<ng-container *ngIf="element.break + element.daily_time_ignore">-{{element.break + element.daily_time_ignore}} min</ng-container>
						</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>

					<!-- Title -->
					<ng-container matColumnDef="comment">
						<td mat-cell *matCellDef="let element">
							<fa-icon *ngIf="element.request_pending" [icon]="faHandPointUp" size="lg" class="or_fg"
								style="margin-right: 5px"
								title="Laufendes Genehmigungsverfahren"
								i18n-title></fa-icon>
							{{element.comment}}
						</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>

					<!-- Duration -->
					<ng-container matColumnDef="duration">
						<td mat-cell *matCellDef="let element" nowrap>{{ element.date_out ? formatDuration(element.duration) : '??:??' }}</td>
						<td mat-footer-cell *matFooterCellDef nowrap>{{week.work | number: '1.1-2'}} h</td>
					</ng-container>

					<tr mat-row (click)="router.navigate(['/work-schedule/'+ row.organization + '/' + row.id])" *matRowDef="let row; columns: columnsToDisplay;"></tr>
					<tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
				</table>
				<ng-container *ngIf="isPastLoggingAllowed(obj.orga_id)">
                    <button mat-raised-button class="ws-logging-past"
							color="primary"
                            (click)="logWork(obj.orga_id, obj.company.id)">
                        <fa-icon [icon]="faHistory"></fa-icon>&nbsp;
                        <span i18n>Arbeitszeit nachtragen</span>
                    </button>
                </ng-container>
			</main>
		</ng-template>
	</mat-tab>
</mat-tab-group>
