<h1 mat-dialog-title class="mat-h2" class="color-text-primary" i18n>Name ändern</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput type="text" placeholder="Vorname" i18n-placeholder formControlName="firstname" required [value]="name.firstname">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Nachname" i18n-placeholder formControlName="lastname" required [value]="name.lastname">
    </mat-form-field>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" i18n type="submit">
      Ändern
    </button>
  </mat-dialog-actions>
</form>