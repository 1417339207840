var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentInit } from '../../core/classes/component-init.class';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollLocations } from '../../core/globals/scroll-locations';
import { TaskAssignService } from '../shared/task-assign.service';
import { faGavel, faTruckLoading, faCalendarAlt, faInfo, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare, faBuilding } from '@fortawesome/free-regular-svg-icons';
var TaskAssignDetailComponent = /** @class */ (function (_super) {
    __extends(TaskAssignDetailComponent, _super);
    function TaskAssignDetailComponent(route, ts, router, scrollLocations, location) {
        var _this = _super.call(this, router, scrollLocations, route) || this;
        _this.route = route;
        _this.ts = ts;
        _this.router = router;
        _this.scrollLocations = scrollLocations;
        _this.location = location;
        _this.faCalendarAlt = faCalendarAlt;
        _this.faBuilding = faBuilding;
        _this.faInfo = faInfo;
        _this.faTruck = faTruck;
        _this.icons = {
            'task': faSquare,
            'activity': faGavel,
            'tour_before': faTruckLoading,
            'tour_after': faTruckLoading
        };
        _this.disableCompleteButton = false;
        return _this;
    }
    TaskAssignDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            if (data.resolved.model)
                _this.update(data.resolved.model);
            data.resolved.updates.subscribe(function (res) {
                if (res)
                    _this.update(res);
            });
        });
    };
    TaskAssignDetailComponent.prototype.update = function (taskAssign) {
        this.taskAssign = taskAssign;
    };
    TaskAssignDetailComponent.prototype.icon = function (t) {
        if (t.type == 'task' && t.completed)
            return faCheckSquare;
        else
            return this.icons[t.type];
    };
    TaskAssignDetailComponent.prototype.routeTour = function (tour_id) {
        this.router.navigate(['/tours/' + this.taskAssign.organization + '/' + tour_id]);
    };
    TaskAssignDetailComponent.prototype.completeButton = function () {
        var _this = this;
        this.disableCompleteButton = true;
        this.complete(function () {
            _this.location.back();
        });
    };
    TaskAssignDetailComponent.prototype.complete = function (callback) {
        var _this = this;
        if (!callback)
            this.taskAssign.completed = 1;
        return this.ts.complete(this.taskAssign).subscribe(function (res) {
            _this.disableCompleteButton = false;
            if (callback) {
                _this.taskAssign.completed = 1;
                callback();
            }
        }, function (res) {
            _this.taskAssign.completed = 0;
            _this.disableCompleteButton = false;
        });
    };
    TaskAssignDetailComponent.prototype.incomplete = function () {
        var _this = this;
        this.taskAssign.completed = 0;
        return this.ts.incomplete(this.taskAssign).subscribe(function (res) { }, function (res) {
            _this.taskAssign.completed = 1;
        });
    };
    TaskAssignDetailComponent.prototype.completeToggle = function () {
        if (this.taskAssign.completed)
            this.incomplete();
        else
            this.complete();
    };
    return TaskAssignDetailComponent;
}(ComponentInit));
export { TaskAssignDetailComponent };
