import { Component, ViewChild, ElementRef, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { ComponentInit } from '../../core/classes/component-init.class';
import { ScrollLocations } from '../../core/globals/scroll-locations';

import { JobDetails } from '../shared/job.model';
import { Organization } from '../../core/models/organization.model';

import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { SignaturePad } from 'angular2-signaturepad';

import { JobTimesheetService } from '../shared/job-timesheet.service';
import { OrganizationService } from '../../core/services/organization.service';

import {
    faCheck,
    faUndo,
    faFlag,
    faCalendarAlt,
    faMale,
    faSignature,
    faInfo
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-job-timesheet-create',
    templateUrl: './job-timesheet-create.component.html',
    styleUrls: ['./job-timesheet-create.component.css']
})
export class JobTimesheetCreateComponent extends ComponentInit {

    @ViewChild(SignaturePad) signaturePad: SignaturePad;
    @ViewChild('signaturePadWrapper') signaturePadWrapper: ElementRef;
    private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
        'canvasWidth': 500,
        'canvasHeight': 250
    };

    faCheck = faCheck;
    faUndo = faUndo;
    faFlag = faFlag;
    faCalendarAlt = faCalendarAlt;
    faMale = faMale;
    faSignature = faSignature;
    faInfo = faInfo;

    job:JobDetails;
    orga:Organization;

    staffs = [];
    columnsToDisplay = ['name', 'start', 'end', 'break', 'duration'];

    showSignaturePad = false;
    submitting: boolean = false;

    form: FormGroup;
    formValuesCached: Object = {};


    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private jobTimesheetService: JobTimesheetService,
        private orgaService: OrganizationService,
        protected router: Router,
        protected scrollLocations: ScrollLocations,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        super(router, scrollLocations, route);
    }

    init(){
        console.log(this.job);
        this.route.data.subscribe(data => {
            if(data.resolved.model) this.update(data.resolved.model);
            data.resolved.updates.subscribe(job => {
                if(job) this.update(job);
                else if(!this.job) this.job = null;
            });
        });

        this.orgaService.getOrganizationsOfCurrentAccountObservable().subscribe(res => {
            if(res && this.job) this.orga = res.find(o => o.id == this.job.organization);
        });
    }

    afterInit(){
        this.adjustDrawCanvasWidth();
        window.onresize = () => {
            setTimeout(() => this.adjustDrawCanvasWidth(), 100);
        }
    }

    update(job){
        this.job = job;

        // Get cached form value
        if(localStorage.getItem('timesheets')){
            try {
                this.formValuesCached = JSON.parse(localStorage.getItem('timesheets'))[job.id];
            } catch(e){}
        }

        // set staffs
        this.staffs = job.crew;

        // filter staffs when param "staffs" is given
        if(this.route.snapshot.queryParamMap.getAll('staffs').length){
             let staffs_selected = this.route.snapshot.queryParamMap.getAll('staffs');
             this.staffs = this.staffs.filter(g => {
                 console.log('before', g.staffs);
                 g.staffs = g.staffs.filter(s => staffs_selected.includes(s.id.toString()));
                 console.log(g.staffs);
                 return g.staffs.length;
             });
        }


        this.buildForm();
    }

    buildForm(){
        this.form = this.fb.group({
            staffs: this.fb.group([]),
            note: [this.formValuesCached ? this.formValuesCached['note'] : ''],
            signature: ['', Validators.required],
            sign_legitimate: [this.formValuesCached ? this.formValuesCached['sign_legitimate'] : '', Validators.required],
            sign_note: [this.formValuesCached ? this.formValuesCached['sign_note'] : ''],
        })

        for(let group of this.staffs){
            for(let staff of group.staffs){
                (this.form.get('staffs') as FormGroup).addControl(staff.id, this.fb.group({
                    ...staff, ...{
                    start: [this.getTimeForForm('start', staff.id), Validators.required],
                    break: [this.getTimeForForm('break', staff.id), Validators.required],
                    end: [this.getTimeForForm('end', staff.id), Validators.required],
                    qualification: group.qualification
                }}))
            }
        }
    }

    getStaffCount(staffs){
        let count = staffs.reduce((c, s) => c + (s.custom_amount || 1), 0);
        return count;
    }

    getTimeForForm(type, staff_id, use_cache = true){
        // get staff data from cache
        if(use_cache && this.formValuesCached && this.formValuesCached['staffs'] && this.formValuesCached['staffs'][staff_id] && this.formValuesCached['staffs'][staff_id][type])
            return this.formValuesCached['staffs'][staff_id][type];


        // get staff data from crew
        let staff = this.staffs.reduce((p, c) => c.staffs.find(s => s.id == staff_id) || p, {});
        let date = staff.project_assign ? staff.project_assign[type] : this.job['dispo_' + type];

        if(type == 'break') return date || 0;
        else return formatDate(date, 'HH:mm', this.locale);
    }

    staffDuration(staff_id){
        let start = this.form.get('staffs')['controls'][staff_id]['controls']['start'].value;
        let end = this.form.get('staffs')['controls'][staff_id]['controls']['end'].value;
        let pause_minutes = this.form.get('staffs')['controls'][staff_id]['controls']['break'].value;

        let start_minutes = this.hmToMinutes(start);
        let end_minutes = this.hmToMinutes(end);

        if(start_minutes > end_minutes) end_minutes += 60 * 24;

        return (end_minutes - start_minutes - pause_minutes) / 60;
    }

    qualificationDuration(quali_id){
        let group = this.staffs.find(g => g.qualification.id == quali_id);
        let sum = 0;
        for(let staff of group.staffs)
            sum += (this.staffDuration(staff.id) * (staff.custom_amount || 1));

        return sum;
    }

    hmToMinutes(hm){
        if(!hm) return 0;
        let hm_arr = hm.split(':');
        return (+hm_arr[0]) * 60 + (+hm_arr[1]);
    }

    numberToLocaleString(number){
        return number.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }


    onFormChange(){
        let timesheets = localStorage.getItem('timesheets') ? JSON.parse(localStorage.getItem('timesheets')) : {};
        if(!timesheets[this.job.id]) timesheets[this.job.id] = {staffs: {}};

        // Save note and signature
        timesheets[this.job.id].note = this.form.value.note;
        timesheets[this.job.id].sign_legitimate = this.form.value.sign_legitimate;
        timesheets[this.job.id].sign_note = this.form.value.sign_note;
        timesheets[this.job.id].signature = this.form.value.signature;

        // Save only start & end of staffs, when its value was changed
        for(let staff of Object.values(this.form.value.staffs)){
            console.log(staff);
            if(!timesheets[this.job.id].staffs[staff['id']])
                timesheets[this.job.id].staffs[staff['id']] = {};

            if(staff['start'] != this.getTimeForForm('start', staff['id'], false))
                timesheets[this.job.id].staffs[staff['id']].start = staff['start'];

            if(staff['end'] != this.getTimeForForm('end', staff['id'], false))
                timesheets[this.job.id].staffs[staff['id']].end = staff['end'];

            if(staff['break'] != this.getTimeForForm('break', staff['id'], false))
                timesheets[this.job.id].staffs[staff['id']].break = staff['break'];
        }
        localStorage.setItem('timesheets', JSON.stringify(timesheets));
    }

    toggleShowSignaturePad(){
        if(this.showSignaturePad)
            this.showSignaturePad = false;
        else {
            this.showSignaturePad = true;
            setTimeout(() => {
                this.adjustDrawCanvasWidth();
            }, 10);

        }
    }

    adjustDrawCanvasWidth(){
        if(!this.showSignaturePad || !this.signaturePadWrapper) return;
        let width = this.signaturePadWrapper.nativeElement.clientWidth;
        this.signaturePadWrapper.nativeElement.querySelectorAll('canvas')[0].setAttribute('width', width);
    }

    resetDraw(){
        this.signaturePad.clear();
        this.adjustDrawCanvasWidth();
        this.form.controls['signature'].setValue('');
    }

    drawComplete() {
        // will be notified of szimek/signature_pad's onEnd event
        this.form.controls['signature'].setValue(this.signaturePad.toDataURL());
    }

    drawStart() {
        // will be notified of szimek/signature_pad's onBegin event
        console.log('begin drawing');
    }

    onSubmit(){
        if(!this.form.valid)
			return this.dialog.open(OkDialogComponent, {data: 'Bitte fülle das Formular korrekt aus.'});

		this.submitting = true;

		let data = {
			staffs: this.form.value.staffs,
            note: this.form.value.note,
			sign_legitimate: this.form.value.sign_legitimate,
            sign_note: this.form.value.sign_note,
            signature: this.signaturePad.toDataURL()
		}

        this.jobTimesheetService.create(this.job.organization, this.job.id, data).subscribe(res => {
            this.router.navigate([`/jobs/${this.job.organization}/${this.job.id}`], { queryParams: {tab: 2}, replaceUrl: true });
            this.submitting = false;
        }, err => {
            this.submitting = false;
            this.dialog.open(OkDialogComponent, {data:err.error.text});
            console.log('error', err);
        });
    }

    // resetTimesheet(){
    //     this.dialog.open(ConfirmDialogComponent, {data: {
    //       text: 'Möchten Sie wirklich diesen Arbeitsschein zurücksetzen? Die Unterschrift geht damit verloren, wiederherstellen nicht möglich!',
    //       okTitle: 'Zurücksetzen',
    //       okColor: 'warn'
    //   }}).afterClosed().subscribe(result => {
    //         if(!result) return;
    //
    //         this.submitting = true;
    //         this.jobService.setTimesheet(this.job.organization, this.job.id, null).subscribe(res => {
    //             console.log(res);
    //             this.submitting = false;
    //             this.update(res);
    //         }, err => {
    //             this.submitting = false;
    //             this.dialog.open(OkDialogComponent, {data:err.error.text});
    //             console.log('error', err);
    //         });
    //     });
    // }
}
