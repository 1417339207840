var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { tap } from 'rxjs/operators';
var MissionProcessService = /** @class */ (function (_super) {
    __extends(MissionProcessService, _super);
    function MissionProcessService(http) {
        var _this = _super.call(this, http, 'missionProcesses') || this;
        _this.http = http;
        _this.confirmationCacheModel = new CachableModelService(http, 'missionProcessesConfirmations');
        _this.proposalCacheModel = new CachableModelService(http, 'missionProcessesProposals');
        return _this;
    }
    /**
     * Returns mission processes for current account
     * @param from date from
     * @param to date to
     */
    MissionProcessService.prototype.getAllConfirmationsForCurrentAccount = function (from, to) {
        if (from === void 0) { from = null; }
        if (to === void 0) { to = null; }
        // set params
        var params = {
            state: 'confirmation,cancel'
        };
        if (from)
            params['date_from'] = this.dateToString(from);
        if (to)
            params['date_to'] = this.dateToString(to);
        // get cached data
        var data = this.confirmationCacheModel.getAll();
        // make request
        var http = this.http.get('/v1/missions/processes', { params: params });
        return this.confirmationCacheModel.cachedRequest(data, http);
    };
    /**
     * Returns mission processes for current account
     * @param from date from
     * @param to date to
     */
    MissionProcessService.prototype.getAllProposalsForCurrentAccount = function (from, to) {
        if (from === void 0) { from = null; }
        if (to === void 0) { to = null; }
        // set params
        var params = {
            state: 'proposal,offer'
        };
        if (from)
            params['date_from'] = this.dateToString(from);
        if (to)
            params['date_to'] = this.dateToString(to);
        // get cached data
        var data = this.proposalCacheModel.getAll();
        // make request
        var http = this.http.get('/v1/missions/processes', { params: params });
        return this.proposalCacheModel.cachedRequest(data, http);
    };
    MissionProcessService.prototype.getByOrganizationAndId = function (orga, id) {
        // Get Data
        var data = this.findModelByOrganizationAndId(orga, id);
        // If we found nothing, try to get data from proposals or confirmations
        // Can not use this anymore, because the data differs to much from single to list entry
        /* if(!data) data = this.proposalCacheModel.findModelByOrganizationAndId(orga, id);
        if(!data) data = this.confirmationCacheModel.findModelByOrganizationAndId(orga, id); */
        var http = this.http.get('/v1/organizations/' + orga + '/missions/processes/' + id);
        return this.cachedRequest(data, http);
    };
    MissionProcessService.prototype.accept = function (mp) {
        var _this = this;
        var payload = {
            bundle: !!mp.entries,
            state: mp.state,
        };
        return this.http.post('/v1/organizations/' + mp.organization + '/missions/processes/' + mp.id + '/accept', payload)
            .pipe(tap(function (res) { return _this.updateModel(res.mission_process); }));
    };
    MissionProcessService.prototype.decline = function (mp) {
        var _this = this;
        var payload = {
            bundle: !!mp.entries,
            state: mp.state,
        };
        return this.http.post('/v1/organizations/' + mp.organization + '/missions/processes/' + mp.id + '/decline', payload)
            .pipe(tap(function (res) { return _this.updateModel(res.mission_process); }));
    };
    MissionProcessService.prototype.save = function (mp, answers) {
        var _this = this;
        var payload = {
            answers: answers,
            bundle: !!mp.entries,
            state: mp.state,
        };
        return this.http.post('/v1/organizations/' + mp.organization + '/missions/processes/' + mp.id + '/save', payload)
            .pipe(tap(function (res) { return _this.updateModel(res.mission_process); }));
    };
    return MissionProcessService;
}(CachableModelService));
export { MissionProcessService };
