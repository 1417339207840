import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Disposition } from './disposition.model';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';

export interface AllForCurrentAccountOptions {
  date_from?:Date,
  date_to?:Date,
  types?:Array<String>,
  organizations?:Array<Number>,
}

@Injectable({
  providedIn: 'root'
})
export class DispositionService extends CachableModelService<Disposition>{
  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'dispositions', true, 'data.id');
  }

  /**
   * Returns dispositions for current account
   * @param options
   */
  getAllForCurrentAccount(options:AllForCurrentAccountOptions):Observable<Disposition[]>{
    let fromString:string = null, toString:string = null;
    let params:any = {};

    // date_from
    if(options.date_from){
      const from = new Date(options.date_from);
      from.setHours(0,0,0,0);

      fromString = this.dateToString(from);
      params['date_from'] = fromString;
    }

    // date_to
    if(options.date_to){
      toString = this.dateToString(options.date_to);
      params['date_to'] = toString;
    }

    // get cached data
    let data = this.filterModelsByDate(fromString, toString);

    // types
    if (options.types && options.types.length) {
      data = data.filter(dispo => options.types.includes(dispo.type));
      params['types[]'] = options.types;
    }

    // organizations
    if (options.organizations && options.organizations.length) {
      data = data.filter(dispo => options.organizations.includes(dispo.organization));
      params['organizations[]'] = options.organizations;
    }

    // HTTP REQUEST
    let http = this.http.get<Disposition[]>('/v1/dispositions', {params:params});

    // build clear data
    let clearData = {
      type: 'period',
      data: { from: fromString, to: toString }
    }

    return this.cachedRequest(data, http, clearData);
  }

  getAllForOrganizationAndStaff() {

  }
}
