/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dispositions.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./disposition-list/disposition-list.component.ngfactory";
import * as i3 from "./disposition-list/disposition-list.component";
import * as i4 from "@angular/router";
import * as i5 from "../core/globals/scroll-locations";
import * as i6 from "@angular/common";
import * as i7 from "../shared/loading-spinner/loading-spinner.component.ngfactory";
import * as i8 from "../shared/loading-spinner/loading-spinner.component";
import * as i9 from "./dispositions.component";
import * as i10 from "./shared/disposition.service";
import * as i11 from "../core/services/list-group.service";
var styles_DispositionsComponent = [i0.styles];
var RenderType_DispositionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DispositionsComponent, data: {} });
export { RenderType_DispositionsComponent as RenderType_DispositionsComponent };
function View_DispositionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-top color-text-grey text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Keine Eintr\u00E4ge vorhanden "]))], null, null); }
function View_DispositionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "mat-h2 app-list-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-disposition-list", [], null, null, null, i2.View_DispositionListComponent_0, i2.RenderType_DispositionListComponent)), i1.ɵdid(4, 4440064, null, 0, i3.DispositionListComponent, [i4.ActivatedRoute, i4.Router, i5.ScrollLocations], { dispositions: [0, "dispositions"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.data; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_DispositionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispositionsComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.data.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DispositionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i7.View_LoadingSpinnerComponent_0, i7.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i8.LoadingSpinnerComponent, [], { loading: [0, "loading"], loadError: [1, "loadError"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispositionsComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispositionsComponent_2)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.dispos; var currVal_1 = _co.loadError; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.dispos && (_co.dispos.length == 0)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.listGroups; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_DispositionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DispositionsComponent_0, RenderType_DispositionsComponent)), i1.ɵdid(1, 4440064, null, 0, i9.DispositionsComponent, [i4.ActivatedRoute, i4.Router, i5.ScrollLocations, i10.DispositionService, i11.ListGroupService, i1.LOCALE_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DispositionsComponentNgFactory = i1.ɵccf("ng-component", i9.DispositionsComponent, View_DispositionsComponent_Host_0, {}, {}, []);
export { DispositionsComponentNgFactory as DispositionsComponentNgFactory };
