<main>
	<ng-container *ngIf="taskAssign">
		<h2 class="mat-h2 app-list-heading">Informationen</h2>
		<mat-list>
			<!-- Title -->
			<mat-list-item class="mat-elevation-z1">
				<fa-icon mat-list-icon [icon]="icon(taskAssign)" class="{{'mat-list-icon ng-fa-icon' + (taskAssign.completed ? ' color-text-green' : '') }}"></fa-icon>
				<span mat-line><b>{{taskAssign.title}}</b></span>
			</mat-list-item>
			<mat-divider></mat-divider>

			<!-- Company -->
			<mat-list-item class="mat-elevation-z1">
				<fa-icon mat-list-icon [icon]="faBuilding"></fa-icon>
				<span mat-line>{{taskAssign.company.title}}</span>
			</mat-list-item>
			<mat-divider></mat-divider>

			<!-- Period -->
			<mat-list-item class="mat-elevation-z1">
				<fa-icon mat-list-icon [icon]="faCalendarAlt"></fa-icon>
				<span mat-line>
					<app-date-span [start]="taskAssign.start" [end]="taskAssign.end" [relativeWords]="true"></app-date-span>
				</span>
			</mat-list-item>
		</mat-list>

		<!-- Notes -->
		<ng-container *ngIf="taskAssign.description">
			<h2 class="mat-h2 app-list-heading" i18n>Notizen</h2>
			<mat-card class="content-section">
				<mat-card-content [innerHTML]="taskAssign.description"></mat-card-content>
			</mat-card>
		</ng-container>

		<!-- Tour -->
		<ng-container *ngIf="taskAssign.tour_data">
			<h2 class="mat-h2 app-list-heading" i18n>Tour</h2>
			<mat-list>
				<mat-list-item class="show-all">
					<fa-icon mat-list-icon [icon]="faTruck"></fa-icon>
					<a mat-line
							class="color-text-primary"
							style="text-decoration: none; cursor: pointer;"
							(click)="routeTour(taskAssign.tour)">
						{{taskAssign.tour_data.title}}
					</a>
				</mat-list-item>
			</mat-list>
		</ng-container>

		<!-- Action (Completed) -->
		<ng-container *ngIf="!taskAssign.completed">
			<div class="flexbox row button-wrapper">
				<button mat-raised-button (click)="completeButton()" class="flex spinner-button" color="success" i18n [disabled]="disableCompleteButton">
					<mat-spinner diameter="20"></mat-spinner>
					Als erledigt markieren
				</button>
			</div>
		</ng-container>
		<h2 class="mat-h2 app-list-heading toolbar-heading">
			<span i18n>Erledigt</span>
			<mat-slide-toggle color="primary" [checked]="!!taskAssign.completed" (change)="completeToggle($event)"></mat-slide-toggle>
		</h2>
	</ng-container>
</main>
