import { Component, OnInit, Input } from '@angular/core';
import { createDateFromDatetime } from 'src/app/core/utils/date-utils';

@Component({
  selector: 'app-date-span',
  templateUrl: './date-span.component.html',
  styleUrls: ['./date-span.component.css']
})
export class DateSpanComponent implements OnInit {

  @Input() start:string;  // Y-m-d H:i:s
  @Input() end:string;
  @Input() short:boolean = false;
  @Input() relativeWords:boolean = false;
  @Input() dateOnly:boolean = false;
  @Input() showWeekday:boolean = true;

  startDate:Date;
  endDate:Date;

  startDateFormat:string = 'dd.MM.yy';
  endDateFormat:string = 'dd.MM.yy';

  startYesterday:boolean;
  startToday:boolean;
  startTomorrow:boolean;
  endYesterday:boolean;
  endToday:boolean;
  endTomorrow:boolean;

  sameDay:boolean;

  constructor() { }

  ngOnInit() {
    this.startDate = createDateFromDatetime(this.start);
    this.endDate = createDateFromDatetime(this.end);

    // Modify Date format (when date has same year as current -> dont show it)
    if(this.startDate.getFullYear() == (new Date()).getFullYear())
        this.startDateFormat = 'dd.MM.';
    if(this.endDate.getFullYear() == (new Date()).getFullYear())
        this.endDateFormat = 'dd.MM.';

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate()-1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate()+1);

    if(this.relativeWords)
      if(this.startDate.toDateString() == yesterday.toDateString())
        this.startYesterday = true;
      else if(this.startDate.toDateString() == today.toDateString())
        this.startToday = true;
      else if(this.startDate.toDateString() == tomorrow.toDateString())
        this.startTomorrow = true;

    if(this.relativeWords)
      if(this.endDate.toDateString() == yesterday.toDateString())
        this.endYesterday = true;
      else if(this.endDate.toDateString() == today.toDateString())
        this.endToday = true;
      else if(this.endDate.toDateString() == tomorrow.toDateString())
        this.endTomorrow = true;

    if(this.startDate.getTime() > this.endDate.getTime()-86400000)
      this.sameDay = true;
  }

}
