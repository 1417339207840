import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
        this.VAPID_PUBLIC_KEY = 'BADqZbl6TJSQePWVscn1q4oVEd33ZnjqsjHpMQmMJMez6MGIivZifi36QiIdDAb2t5BKI07vFXdy43zKba4wTCQ';
        var item = localStorage.getItem('pushSubscriptionId');
        this.subscribed = new BehaviorSubject(!!item);
    }
    NotificationService.prototype.clear = function () {
        this.unsubscribe().subscribe();
        localStorage.removeItem('changedPushSubscription');
    };
    Object.defineProperty(NotificationService.prototype, "getVAPID", {
        get: function () {
            return this.VAPID_PUBLIC_KEY;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Subscribes current user to push notifications
     * @param object  browser subscription object
     */
    NotificationService.prototype.subscribe = function (sub) {
        var _this = this;
        localStorage.setItem('changedPushSubscription', 'true');
        // return Observable
        return this.http.post('/v1/push', sub).pipe(map(function (sid) {
            if (sid) {
                localStorage.setItem('pushSubscriptionId', JSON.stringify(sid));
                _this.subscribed.next(true);
                return true;
            }
            return false;
        }));
    };
    /**
     * Unsubscribes user from push notifications.
     * Should be called by logout!
     */
    NotificationService.prototype.unsubscribe = function () {
        var _this = this;
        // read subscription id from local storage
        var sid = localStorage.getItem('pushSubscriptionId');
        // nothing to unsubscribe
        if (!sid)
            return of(null);
        // remove subscription
        return this.http.delete('/v1/push/' + sid)
            .pipe(tap(function () {
            localStorage.removeItem('pushSubscriptionId');
            _this.subscribed.next(false);
        }));
    };
    Object.defineProperty(NotificationService.prototype, "isSubscribed", {
        get: function () {
            return this.subscribed.getValue();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Returns whether the user has subscribed at some point, or changed the subscription
     */
    NotificationService.prototype.changedSubscription = function () {
        if (localStorage.getItem('changedPushSubscription'))
            return true;
        else
            return false;
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
