import { formatDate } from '@angular/common';
import Model from './model.class';
import { map } from 'rxjs/operators';
import { Constructor } from '@angular/material/core/typings/common-behaviors/constructor';
import { Observable, OperatorFunction } from 'rxjs';

export class ModelService<T> {

  /**
   * Returns rxjs pipe to init models from api
   * @param modelConstructor 
   * @return rxjs pipe operator 
   */
  protected initModelsPipe(modelConstructor:Constructor<T>):OperatorFunction<object[], T[]> {

    return map((objects:Array<object>) => objects.map(obj => new modelConstructor(obj)));
  }

  /**
   * Converts models to api objects for transportation
   * @param models 
   */
  modelsToApiObjects(models:Model[]) {
    return models.map((model:Model) => model.toApiObject());
  }

  /**
   * converts Date to API-conform string
   * @param date
   */
  public dateToString(date:Date, format:string='yyyy-MM-dd HH:mm:ss'):string{
    return formatDate(date, format, 'de-DE');
  }

  /**
   *
   * @param list list of models
   * @param from from dateString
   * @param to to dateString
   * @param on attribute to filter (default: start)
   */
  public filterListByDate(list:T[], from:string = null, to:string = null, on:string="start"){
    let filtered_list = list.filter(val => {
      if(from && to)
        return from <= val[on] && val[on] <= to;
      else if(from)
        return from <= val[on];
      else if(to)
        return val[on] <= to;
      else return true;
    });

    // Sort
    filtered_list.sort((a,b) => (a[on] > b[on]) ? 1 : ((b[on] > a[on]) ? -1 : 0));

    return filtered_list;
  }

  /**
   * helper function fo updateList
   * @param obj
   * @param prop
   */
  public fetchFromObject(obj, prop){
    var _index = prop.indexOf('.')
    if(_index > -1) {
        return this.fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
    }
    return obj[prop];
  }

  /**
   * Replaces array elements in list, compared by 'on'
   * @param list the list
   * @param arr the item
   * @param on the identifier
   * @param orga is it a client model?
   */
  protected updateList(list:T[], arr:T[], on='id', orga=false):T[]{
    arr.forEach(item => {
      list = this.updateListItem(list, item, on, orga);
    });
    return list;
  }

  protected getListItemIndex(list:T[], item:T, on='id', orga=false):number {
    return list.findIndex(val => {
      if(orga)
        return this.fetchFromObject(val, on) == this.fetchFromObject(item, on) && item['organization'] == val['organization'];
      else
        return this.fetchFromObject(val, on) == this.fetchFromObject(item, on);
    });
  }

  /**
   * updates/inserts item in list
   * @param list the list
   * @param item the item
   * @param on the identifier
   * @param orga is it a client model?
   */
  protected updateListItem(list:T[], item:T, on='id', orga=false):T[]{
    const i = this.getListItemIndex(list, item, on, orga);
    switch(i){
      case -1:
        list.push(item);
      default:
        list[i] = item;
    }
    return list;
  }

  protected deleteListItem(list:T[], item:T, on="id", orga=false):T[] {

    const i = this.getListItemIndex(list, item, on, orga);
    switch(i){
      case -1:
        return list;
      default:
        return list.splice(i, 1);
    }
  }
}
