/**
 * converts number to duration format
 */
export function formatDuration(hours: number): string {
	/* if(!num) return '0,00 h';
	
	return num.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}) + ' h'; */
	
	if(!hours) return '00:00';
	
	let h = Math.floor(hours);
	let m = Math.round((hours * 60) % 60);
	let h_str = h < 10 ? '0' + h : h;
	let m_str = m < 10 ? '0' + m : m;
	return `${h_str}:${m_str}`;
}