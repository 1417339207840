import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CachableModelService } from '../../core/classes/cachable-model-service.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService extends CachableModelService<Event>{

  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'events');
  }

  getByOrganizationAndId(orga, id):Observable<Event>{
    let data = this.findModelByOrganizationAndId(orga, id);
    let http = this.http.get<Event>('/v1/organizations/'+orga+'/events/'+id);
    return this.cachedRequest(data, http);
  }
}
